import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const ItemContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e5e8ee;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  cursor: ${(props) => (props.draggable ? 'grab' : 'default')};
`;

export const LeftElements = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

export const NumberAndDragIconContainer = styled.div`
  display: flex;
  gap: 14px;
`;

export const Number = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #00205b;
`;

export const NameAndBadgeContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const Name = styled.h1`
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #00205b;
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  border-radius: 320px;
  height: 24px;
  padding: 0 8px;
  font-size: 12.6px;
  font-weight: 600;
  line-height: 16.9px;
  text-align: center;
  background: ${(props) => props.bg_color};
  color: ${(props) => props.color};
`;

export const RightElements = styled.div`
  display: flex;
  gap: 8px;
  flex-shrink: 0;
`;

export const Button = styled.button`
  display: flex;
  gap: 10px;
  padding: 0 16px;
  height: 40px;
  border-radius: 320px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: ${(props) => (props.hasBorder ? '2px solid #E2E5EE' : 'none')};

  &:hover {
    border: ${(props) => (props.hasBorder ? '2px solid #B7BED7' : 'none')};
    background: ${(props) => (props.hasBorder ? '#fff' : '#DAF5F1')};
  }

  &:disabled {
    border: ${(props) => (props.hasBorder ? '2px solid #E2E5EE' : 'none')};
    cursor: default;
    pointer-events: none;
  }
`;

export const ButtonText = styled.p`
  margin: 0;
  font-size: 14.2px;
  font-weight: 600;
  line-height: normal;
  color: ${(props) => (props.disabled ? '#8B97BD' : '#077859')};
`;

export const ContainerArrow = styled.div`
  border: 2px solid #e5e8ee;
  border-radius: 1000px;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  transform: ${(props) => (props.open ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
`;
