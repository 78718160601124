import styled from 'styled-components';
import { ReactComponent as SendIcon } from '~/assets/drops-send.svg';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  width: 568px;
`;

export const Header = styled.div`
  padding: 48px 56px 24px;
  background: #ffffff;
  border-bottom: 1px solid #e5e8ee;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #00205b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const ContentItem = styled.div`
  height: 48px;
  display: flex;
  gap: 8px;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentTitle = styled.h1`
  margin: 0;
  text-transform: uppercase;
  width: 180px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #2a2c30;
  flex-shrink: 0;
`;

export const ContentDescription = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #2a2c30;
`;

export const LinkButton = styled.button`
  border: none;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 2px solid #e5e8ee;
  border-radius: 320px;
  background: #fff;

  :hover {
    border-color: #d1d7e1;
  }
`;

export const LinkButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ObservationContainer = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid #e5e8ee;
  border-bottom: 1px solid #e5e8ee;
  position: fixed;
  width: 568px;
  min-height: 250px;
`;

export const ObservationTitle = styled.p`
  margin: 0;
  color: #2a2c30;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PopperContainer = styled.div`
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonPopper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  min-height: 48px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00205b;
  background: #fff;
  border: none;
`;

export const ContainerSwitch = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
`;

export const SwitchButton = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 32px;
  height: 20px;
  background: ${(props) => (props.checked ? '#01CFB5' : '#8f9db7')};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  margin-bottom: 0;
`;

export const SwitchLabel = styled.p`
  margin: 0;
  font-size: 14px;
  color: #00205b;
`;

export const CircleButton = styled.div`
  position: absolute;
  top: 4px;
  left: ${(props) => (props.checked ? 'calc(100% - 16px)' : '4px')};
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: #fff;
  transition: 0.2s;
`;

export const Button = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  gap: 10px;
  height: 40px;
  background: #019d8a;
  border-radius: 320px;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  :hover {
    background: #01b69f;
  }
`;

export const StyledSendIcon = styled(SendIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: #fff;
  }
`;

export const InitialsName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #e5e8ee;
  border-radius: 1600px;
  flex-shrink: 0;
  flex-grow: 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #8f9db7;
  position: relative;

  :hover {
    & .delete-icon {
      display: flex;
    }
  }
`;

export const NoteText = styled.div`
  color: #4f5154;
  font-family: Montserrat;
  font-size: 14.2px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.958px;
`;

export const NoteUser = styled.div`
  color: #2a2c30;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const ContainerRowRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-right: auto;
  align-items: center;
`;

export const NotesContainer = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  overflow-y: auto;
  margin-top: 250px;
  height: calc(100vh - 350px);
`;

export const NotesHeader = styled.div`
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const NoteDate = styled.div`
  color: #2a2c30;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
