import React, { useState } from 'react';

import * as S from './styles';

const EditableLabel = ({ initialText, id, updateDataName }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);

  const handleLabelClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    updateDataName(id, text);
  };

  return (
    <div style={{ width: '100%' }}>
      {isEditing ? (
        <S.InputText
          type="text"
          value={text}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          autoFocus
        />
      ) : (
        <S.Container>
          <S.Name onClick={handleLabelClick}>{text}</S.Name>
        </S.Container>
      )}
    </div>
  );
};

export default EditableLabel;
