import React, { useState, useEffect } from 'react';
import { IoChevronDownSharp } from 'react-icons/io5';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { MULTIPLIER } from '../../variables';

import * as S from './styles';

function MultiplierDropdown({ activityId, selectedMultiplier, handleChange }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = anchorEl;
  const id = open ? `multiplier-id-${activityId}` : undefined;

  const handleClick = (event) => {
    if (!anchorEl) {
      return setAnchorEl(event.currentTarget);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const returnMultiplier = (multiplier) => {
    if (!multiplier) return '-';
    return `${multiplier}x`;
  };

  useEffect(() => {
    document.addEventListener('scroll', handleClose, true);
    return () => {
      document.removeEventListener('scroll', handleClose, true);
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <S.DropdownContainer>
        <S.DropdownButton onClick={handleClick}>
          <IoChevronDownSharp size={16} color="#001c50" />
          <S.DropdownButtonText>{returnMultiplier(selectedMultiplier)}</S.DropdownButtonText>
        </S.DropdownButton>

        <Popper
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ maxHeight: ' calc(100vh - 228px)', zIndex: '1201' }}
        >
          <S.DropdownMenu>
            {MULTIPLIER.map((item) => {
              return (
                <S.DropdownItem
                  onClick={() => {
                    handleChange(item, activityId);
                    handleClose();
                  }}
                >
                  <S.ItemTag>{item.label}</S.ItemTag>
                </S.DropdownItem>
              );
            })}
          </S.DropdownMenu>
        </Popper>
      </S.DropdownContainer>
    </ClickAwayListener>
  );
}

export default MultiplierDropdown;
