import React from 'react';
import Modal from '@material-ui/core/Modal';
import SuccessMessage from '~/components/UploadVideoS3/SuccessMessage';
import CloudLogo from '~/assets/drops-cloud-logo.svg';
import DismissLogo from '~/assets/dismiss_logo.svg';
import DownloadLogo from '~/assets/donwload-logo.svg';
import InfoLogo from '~/assets/information_logo.svg';
import PlusLogo from '~/assets/drops-plus-logo.svg';
import * as I from '~/components/VideoCommentUpload/styles';
import * as S from './styles';

function ImportModal({ open, handleCsvFile, handleClose, handleImport, disabled, csvFile }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <S.Container>
        <S.Header>
          <S.CloseContainer onClick={handleClose}>
            <img src={DismissLogo} alt="Close modal" />
          </S.CloseContainer>
          <S.ModalTitle>Importar flashcards</S.ModalTitle>
        </S.Header>
        <S.Content>
          <S.AlertBox>
            <img src={InfoLogo} alt="info" />
            Para utilizar este importador, use o template como referência para gerar o CSV a ser
            importado aqui.
          </S.AlertBox>
          <S.ImportButton
            onClick={() => {
              window.open(
                'https://docs.google.com/spreadsheets/d/1bNg20urApRLcOEHujL1kJayMWbLUlbvsc_bVZ-sWMDQ/edit?usp=sharing',
                '_blank',
              );
            }}
          >
            <img src={DownloadLogo} alt="import flashcards" />
            Baixar template
          </S.ImportButton>
          <div>
            {csvFile ? (
              <>
                <SuccessMessage
                  csv
                  title="Enviado com sucesso"
                  subtitle="O arquivo foi processado"
                />
              </>
            ) : (
              <>
                <I.MainContainer style={{ position: 'relative', left: '3px', width: '380px' }}>
                  <I.IconGroup>
                    <I.PlusContainer style={{ right: 160 }}>
                      <img src={PlusLogo} alt="Plus logo" />
                    </I.PlusContainer>
                    <I.Group>
                      <img src={CloudLogo} alt="Cloud logo" />
                    </I.Group>
                  </I.IconGroup>
                  <I.MainLb>Clique e selecione um arquivo</I.MainLb>
                  <I.InputHide>
                    <label htmlFor="file">
                      <input
                        type="file"
                        id="file"
                        accept="text/csv"
                        onChange={(event) => handleCsvFile(event.target.files[0])}
                      />
                      <I.BtSelect>Selecionar arquivo</I.BtSelect>
                    </label>
                  </I.InputHide>
                </I.MainContainer>
              </>
            )}
          </div>
        </S.Content>
        <S.Footer>
          <S.SubmitButton onClick={handleImport} disabled={disabled}>
            Importar
          </S.SubmitButton>
        </S.Footer>
      </S.Container>
    </Modal>
  );
}

export default ImportModal;
