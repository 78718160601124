import React, { useRef, useState } from 'react';
import ProtectedContent from '~/components/ProtectedContent';
import EditableLabel from '../EditableLabel';
import { ReactComponent as DragIcon } from '~/assets/drag-indicator.svg';

import Icons from '../Icons';
import * as S from './styles';
import * as P from '../styles';

function ChildList({
  childData,
  childAllowDrag,
  updateOrderList,
  allowEditChildLabel,
  updateDataChildName,
}) {
  const [data, setData] = useState(childData);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const onDragStart = (index) => {
    dragItem.current = index;
  };

  const onDragEnter = (index) => {
    dragOverItem.current = index;
  };

  const onSortOptions = (dragItem, dragOverItem) => {
    const _itemsOptions = [...data];
    const draggedItemContent = _itemsOptions.splice(dragItem, 1)[0];
    _itemsOptions.splice(dragOverItem, 0, draggedItemContent);
    setData(_itemsOptions);

    const ids = _itemsOptions.map((item) => item.id);
    updateOrderList(ids);
  };

  const RenderButton = (button, child) => {
    return (
      <P.Button
        key={`button_${button.icon}_${child.name}`}
        hasBorder={button.hasBorder}
        onClick={button.action(child.id)}
      >
        <Icons iconName={button.icon} />
        <P.ButtonText>{button.text}</P.ButtonText>
      </P.Button>
    );
  };

  return (
    <>
      {data && data.length > 0 ? (
        <S.Container>
          {data.map((child, index) => {
            return (
              <S.Content
                draggable={childAllowDrag}
                onDragStart={() => onDragStart(index)}
                onDragEnter={() => onDragEnter(index)}
                onDragEnd={() => onSortOptions(dragItem.current, dragOverItem.current)}
              >
                <P.LeftElements>
                  {childAllowDrag && (
                    <P.NumberAndDragIconContainer>
                      <P.Number>{child.order_number}</P.Number>
                      <DragIcon />
                    </P.NumberAndDragIconContainer>
                  )}
                  {allowEditChildLabel ? (
                    <EditableLabel
                      id={child.id}
                      initialText={child.name}
                      updateDataName={updateDataChildName}
                    />
                  ) : (
                    <P.Name>
                      {child.id}
                      {child.id && ' - '}
                      {child.name}
                    </P.Name>
                  )}
                </P.LeftElements>
                <P.RightElements>
                  {child.buttons && child.buttons.length > 0 && (
                    <>
                      {child.buttons.map((button) => {
                        return !!button.permission ? (
                          <ProtectedContent permissions={[button.permission]}>
                            {RenderButton(button, child)}
                          </ProtectedContent>
                        ) : (
                          RenderButton(button, child)
                        );
                      })}
                    </>
                  )}
                </P.RightElements>
              </S.Content>
            );
          })}
        </S.Container>
      ) : (
        <S.EmptyChild />
      )}
    </>
  );
}

export default ChildList;
