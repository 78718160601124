import styled from 'styled-components';

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled(FlexColumn)`
  height: 100%;
  width: 100%;
  border-left: 1px solid #DADADB;
`;

export const Header = styled(FlexColumn)`
  position: relative;
  gap: 24px;
  padding: 48px 56px 32px;
  border-bottom: 1px solid #DADADB;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 25.6px;
  font-weight: 600;
  line-height: 34.2px;
  color: #00205B;
`;

export const Subtitle = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 12.6px;
  font-weight: 400;
  color: #00205B;
`;

export const CloseButtonStyle = { 
	position: 'absolute', 
	top: '16px',
	right: '16px',
	fill: '#ACACAE',
	cursor: 'pointer',
	width: '24px',
	height: '24px',
};

export const Content = styled(FlexColumn)`
  gap: 24px;
  padding: 40px 56px;
  overflow-y: scroll;
`;

export const TextContainer = styled(FlexColumn)`
  gap: 16px;
`;

export const ContentTitle = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #00205B;
`;

export const ContentSubtitle = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 21.3px;
  color: #00205B;
`;

export const SubtitleContainer = styled(FlexColumn)`
  gap: 16px;
  padding-left: 16px;
`;

export const LoaderContainer = styled(FlexColumn)`
  height: 90vh;
  justify-content: center;
  align-items: center;
`;