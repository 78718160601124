import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { updateProfileSuccess, updateStudentSuccess } from './actions';

export function* updateProfile({ payload }) {
  // try {
  //   const { id, name, email, birthday, speciality, student } = payload.data;
  //   const getAuth = (state) => state.auth;
  //   const auth = yield select(getAuth);
  //   apiPlatform.defaults.headers.Authorization = `Bearer ${auth.token}:${auth.uid}`;

  //   if (birthday || speciality) {
  //     const data = {};
  //     if (birthday) {
  //       data.birthday = birthday;
  //     }
  //     if (speciality) {
  //       if (speciality === -1) {
  //         data.speciality = null;
  //       } else {
  //         data.speciality = speciality;
  //       }
  //     }

  //     yield call(apiPlatform.patch, `student/${student}/`, data);
  //   }

  //   const responseUser = yield call(apiPlatform.put, `user/${id}/`, { name, email });
  //   toast.success('Dados atualizados');
  //   yield put(updateProfileSuccess(responseUser.data));
  // } catch (err) {
  //   toast.error('Erro ao atualizar perfil, confira seus dados');
  // }
}

export function* updateProfileLocal({ payload }) {
  try {
    yield put(updateProfileSuccess(payload.data));
  } catch (err) {
    toast.error('Erro ao atualizar perfil, confira seus dados');
  }
}

export function* updateAvatar({ payload }) {
  // try {
  //   const { id, avatar, student } = payload.data;
  //   if (avatar) {
  //     const formData = new FormData();

  //     formData.append('avatar', avatar);

  //     formData.append('user', id);

  //     const response = yield call(apiPlatform.patch, `student/${student}/`, formData, {
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     yield put(updateStudentSuccess(response.data));
  //     toast.success('Foto atualizada');
  //   }
  // } catch (err) {
  //   toast.error('Erro ao atualizar perfil, confira seus dados');
  // }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest('@user/UPDATE_PROFILE_LOCAL_REQUEST', updateProfileLocal),
  takeLatest('@user/UPDATE_AVATAR_REQUEST', updateAvatar),
]);
