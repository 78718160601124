import styled from 'styled-components';

export const BackgroundCover = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition: visibility 0.2s linear 0.2s, opacity 0.2s linear;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9999;

  .titleImage {
    position: relative;
    margin-top: -20px;
    font-size: 12px;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: visibility 0.33s linear 0.33s, opacity 0.33s linear;
  z-index: 999;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-right: -50px;
  }

  .inner-box {
    transition: all 0.4s !important ;
    cursor: pointer;
  }
  .inner-box:hover {
    transform: scale(0.9);
  }
`;

export const ChevronContainer = styled.div`
  width: 50px;
  border-radius: 25px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  margin-right: ${(props) => (props.isPrev ? '100px' : '0px')};
  transition: opacity 0.6s ease-out;
  cursor: ${(props) => (props.show ? 'pointer' : 'default')};

  .icon {
    transition: all 0.4s !important ;
  }
  .icon:hover {
    transform: scale(0.9);
  }
`;

export const MenuRight = styled.div`
  position: absolute;
  display: flex;
  right: 0px;
  /* padding-top: 10px; */
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column-reverse;

  background: #00205b;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  .inner-box {
    transition: all 0.4s !important ;
    cursor: pointer;
  }
  .inner-box:hover {
    transform: scale(0.9);
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: grabbing;
`;

export const ImageBase = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const ImageZoom = styled.div`
  border: solid red 1px;
`;
