/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Collapse from '@material-ui/core/Collapse';
import HeaderComponent from '~/components/HeaderComponent';
import CardComponent from '~/components/CardComponent';
import Tabs from '~/components/Tabs';
import Loader from '~/components/Loader';
import EmptyContent from '~/components/EmptyContent';
import ItemCard from './ItemCard';
import Filters from './Filters';
import { useFlashcards, useFlashcardsItems } from '~/hooks-querys/flashcards';
import { Mixpanel } from '~/services/analytics';
import { deepEqual } from '~/utils/util';
import { ReactComponent as ChevronRightIcon } from '~/assets/drops-chevron-right-blue.svg';

import * as S from './styles';

const initialPropsSpecialities = {
  total_flashcard: 0,
  disabled: true,
  parent_tags: [],
};

const specialities = [
  {
    abbreviation: 'CM',
    ...initialPropsSpecialities,
  },
  {
    abbreviation: 'PED',
    ...initialPropsSpecialities,
  },
  {
    abbreviation: 'PREV',
    ...initialPropsSpecialities,
  },
  {
    abbreviation: 'GO',
    ...initialPropsSpecialities,
  },
  {
    abbreviation: 'CIR',
    ...initialPropsSpecialities,
  },
];

function Flashcards() {
  const [selectedFolder, setSelectedFolder] = useState({});
  const [clickFilter, setClickFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [tabsInfo, setTabsInfo] = useState(specialities);
  const [selectedTab, setSelectedTab] = useState(0);
  const [folderData, setFolderData] = useState([]);
  const [fetchItem, setFetchItem] = useState(false);
  const [itemsFilters, setItemsFilters] = useState({});
  const [itemsData, setItemsData] = useState([]);
  const [totalFlashcards, setTotalFlashcards] = useState(0);
  const [totalDecks, setTotalDecks] = useState(0);
  const [savedSpecialities, setSavedSpecialities] = useState(null);
  const [savedResidencyDegrees, setSavedResidencyDegrees] = useState(null);
  const { data: flashcardsData, isFetching: fetchingFlashcards } = useFlashcards(
    { ...filter },
    {
      config: {
        enabled: clickFilter,
      },
    },
  );

  const { data: flashcardItemsData, isFetching: fetchingFlashcardItems } = useFlashcardsItems(
    { ...filter, ...itemsFilters },
    {
      config: {
        enabled: fetchItem && Object.keys(itemsFilters).length > 0,
      },
    },
  );

  const handleCollapseFolder = (item, specialityId) => {
    if (`${item?.parent_tag}${specialityId}` === item?.uniqueId) {
      if (deepEqual(item, selectedFolder)) {
        setSelectedFolder({});
        return;
      }

      setSelectedFolder(item);

      const updatedItemFilters = {
        parent_tag: item.parent_tag,
        speciality: specialityId,
      };

      setItemsFilters(updatedItemFilters);
      setFetchItem(true);
    }
  };

  const handleFilters = (filters, extraData) => {
    if (deepEqual(filter, filters)) {
      return;
    }

    if (filters.search && filters.search.length < 4) {
      toast.error('Digite mais do que três letras para a busca');
      return;
    }

    Mixpanel.track("Clicou 'Busca de flashcards - filtrar flashcards'", {
      Pesquisa: filters?.search,
      Tags: extraData?.tagName,
      'Busca por ID': filters?.flashcard,
      'Nível de residência': filters.residency_degree,
      Especialidade: extraData?.specialityName,
      'Status do flashcard': extraData?.status,
      Instituição: extraData?.institution,
    });

    setFilter(filters);
    setTotalFlashcards(0);
    setTotalDecks(0);
    setClickFilter(true);
    setTabsInfo(specialities);
  };

  const clearAll = () => {
    setFilter({});
    setClickFilter(false);
  };

  const getFolderItems = (folder) => {
    if (!folder || folderData.length <= 0) return [];

    const selectedFolderData = folderData.filter((item) => {
      return folder === item.abbreviation;
    });

    const dataWithUniqueId = selectedFolderData[0]?.parent_tags.map((item) => {
      return {
        ...item,
        uniqueId: `${item.parent_tag}${selectedFolderData[0].id}`,
        specialityId: selectedFolderData[0].id,
      };
    });

    return dataWithUniqueId;
  };

  const handleTabClick = (teste) => {
    let matchSpecialityIndex;
    specialities.forEach((element, index) => {
      if (element.abbreviation === teste) {
        matchSpecialityIndex = index;
        return;
      }
    });

    setSelectedTab(matchSpecialityIndex);
  };

  const getActiveTab = (tabs) => {
    let matchSpecialityIndex = 0;

    tabs.every((tab, index) => {
      if (tab.total_flashcard > 0) {
        matchSpecialityIndex = index;
        return false;
      }
      return true;
    });

    return matchSpecialityIndex;
  };

  const getFlashcardsNumbers = (tabs) => {
    let flashcardsNumber = 0;

    tabs.forEach((tab) => {
      flashcardsNumber = flashcardsNumber + tab.total_flashcard;
    });

    return flashcardsNumber;
  };

  const getDecksNumbers = (tabs) => {
    let decksNumber = 0;

    tabs.forEach((tab) => {
      tab.parent_tags.forEach((itemTab) => {
        decksNumber = decksNumber + itemTab.total_decks;
      });
    });

    return decksNumber;
  };

  const headerData = (item) => {
    const itemResidencyDegree = savedResidencyDegrees.filter((residencyDegree) => {
      return filter.residency_degree.toUpperCase() === residencyDegree.abbreviation.toUpperCase();
    });

    const itemSpeciality = savedSpecialities.filter((speciality) => {
      return selectedFolder.specialityId === speciality.id;
    });

    const data = {
      parent_tag: selectedFolder.name,
      tag: item.name,
      residency_degree: itemResidencyDegree[0].name,
      speciality: itemSpeciality[0].name,
    };

    return data;
  };

  useEffect(() => {
    if (!flashcardItemsData) return;
    setItemsData(flashcardItemsData);
    setFetchItem(false);
  }, [flashcardItemsData]);

  useEffect(() => {
    setClickFilter(false);

    if (!flashcardsData) return;

    const updatedTabs = tabsInfo.map(
      (obj) => flashcardsData.find((o) => o.abbreviation === obj.abbreviation) || obj,
    );
    setTabsInfo(updatedTabs);

    setTotalFlashcards(getFlashcardsNumbers(updatedTabs));
    setTotalDecks(getDecksNumbers(updatedTabs));
    setSelectedTab(getActiveTab(updatedTabs));
    setFolderData(flashcardsData);
  }, [flashcardsData]);

  const renderFolders = (item, specialityId, index, lastItem) => {
    const open = item?.uniqueId === selectedFolder?.uniqueId;

    return (
      <Collapse
        collapsedSize={70}
        in={open}
        style={{ marginBottom: 32 }}
        key={`${item?.uniqueId}_{folder}`}
      >
        <CardComponent type="minimal">
          <S.Folder
            style={{ cursor: 'pointer' }}
            onClick={() => handleCollapseFolder(item, specialityId)}
            open={open}
            lastItem={lastItem}
          >
            <S.DivCol style={{ justifyContent: 'center', alignItems: 'flex-start' }}>
              <S.FolderTitle>{item.name}</S.FolderTitle>
            </S.DivCol>
            <S.DivRow>
              <S.FolderCount>
                <strong>{item.total_decks} </strong> {item.total_decks > 1 ? 'itens' : 'item'}
              </S.FolderCount>
              <S.ContainerArrow open={open}>
                <ChevronRightIcon />
              </S.ContainerArrow>
            </S.DivRow>
          </S.Folder>
          {fetchingFlashcardItems ? (
            <S.ContainerLoader>
              <Loader spinner="ring" text="" />
            </S.ContainerLoader>
          ) : (
            <>
              {itemsData?.map((cardItem) => (
                <ItemCard
                  title={item.name}
                  item={cardItem}
                  speciality={specialityId}
                  parentTag={item.parent_tag}
                  residencyDegree={filter.residency_degree}
                  key={`${cardItem.id}${cardItem.name}`}
                  searchText={filter.search}
                  searchId={filter.flashcard}
                  institutions={filter.institution}
                  published={filter.published}
                  reported={filter.reported}
                  pending={filter.pending}
                  getHeaderData={headerData}
                />
              ))}
            </>
          )}
        </CardComponent>
      </Collapse>
    );
  };

  return (
    <>
      <HeaderComponent title="Flashcards" description="Crie e gerencie flashcards" />
      <S.Wrap>
        <S.Container>
          <Filters
            handleFilters={handleFilters}
            handleClearAll={clearAll}
            loading={clickFilter}
            setSavedSpecialities={setSavedSpecialities}
            setSavedResidencyDegrees={setSavedResidencyDegrees}
          />
          <S.InformationsLabel>
            <span>
              Mostrando <b> {totalFlashcards} </b> flashcard em <b> {totalDecks} </b> decks
            </span>
          </S.InformationsLabel>
          <Tabs id="tabs-list-exams" selectedTab={selectedTab} setOnClick={handleTabClick}>
            {tabsInfo.map((speciality) => {
              return (
                <div
                  label={speciality.abbreviation}
                  id={`${speciality.abbreviation}-flashcards`}
                  disabled={speciality.disabled}
                  numberBadge={speciality.total_flashcard}
                  key={`${speciality.abbreviation}-flashcards`}
                >
                  {fetchingFlashcards ? (
                    <Loader spinner="ring" text="" />
                  ) : (
                    <>
                      {flashcardsData && flashcardsData.length > 0 ? (
                        <>
                          {getFolderItems(speciality.abbreviation)?.map((item, index) => {
                            return renderFolders(
                              item,
                              speciality.id,
                              index,
                              folderData.length - 1 === index,
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {Object.keys(filter).length === 0 ? (
                            <EmptyContent
                              title="Utilize o filtro para encontrar um flashcard"
                              subtitle="Selecione os critérios acima e clique em ”Filtrar”"
                            />
                          ) : (
                            <EmptyContent
                              title="Nenhum flashcard encontrado"
                              subtitle="Altere os critérios dos filtros e tente novamente"
                              isError={true}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </Tabs>
        </S.Container>
      </S.Wrap>
    </>
  );
}

export default Flashcards;
