import { useCallback } from 'react';
import { useQueries } from '@tanstack/react-query';

import { FlashcardAPICMS } from '~/services/apiCallsCMS';

const combineResults = (data) =>
  data.reduce((acc, curr) => {
    if (!curr.data) return acc;

    return [...acc, curr.data];
  }, []);

const getFlashcardsByArray = async (id) => {
  try {
    const response = await FlashcardAPICMS.get_info(id);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useGetFlashcardsByArray(arrayOfFlashcards, options) {
  const results = useQueries({
    queries: arrayOfFlashcards?.ids?.map((object) => {
      return {
        queryKey: ['getFlashcardsByArray', object],
        queryFn: () => getFlashcardsByArray(object),
        staleTime: 0,
        cacheTime: 0,
        ...options?.config,
      };
    }),
  });

  const refetchAll = useCallback(() => {
    results.forEach((result) => result.refetch());
  }, [results]);

  const refetchSpecificId = useCallback(
    (id) => {
      const result = results.filter((result) => result.data.id === id);
      if (result.length > 0) return results[0].refetch();
    },
    [results],
  );

  return {
    data: combineResults(results),
    isLoading: results.some((query) => query.isLoading),
    isFetching: results.some((query) => query.isFetching),
    isSuccess: results.every((query) => query.isSuccess),
    refetchAll: refetchAll,
    refetchSpecificId: refetchSpecificId,
  };
}
