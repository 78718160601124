import React from 'react';
import { BiPlusCircle } from 'react-icons/bi';

import * as S from './styles';

function Icons({ iconName }) {
  const ReturnIcon = (icon) => {
    switch (icon) {
      case 'add':
        return <BiPlusCircle size={20} color="#077859" />;
      case 'delete':
        return <S.StyledDeleteIcon />;
      case 'edit':
        return <S.StyledEditIcon />;
      case 'view':
        return <S.StyledViewIcon />;
      case 'search':
        return <S.StyledSearchIcon />;
      case 'configuration':
        return <S.StyledConfigurationIcon />;
      default:
        return <></>;
    }
  };

  return <>{ReturnIcon(iconName)}</>;
}

export default Icons;
