import React from 'react';

import { GoEye } from 'react-icons/go';
import Tags from '~/components/Tags';
import Checkbox from '~/components/Checkbox';

import * as S from './styles';

function DocumentItem({ item, selectedItems, setSelectedItems }) {
	const getTags = (item) => {
		const coursesNamesItems = item.courses_names.slice(0, 4).map(function (item) {
			return {
				textColor: '#00205B',
				backgroundColor: '#E5E8EE',
				text: `${item}`,
			};
		});

		return [
			{
				type: 'secondary',
				textColor: '#00205B',
				text: item.subject_name,
			},
			...coursesNamesItems,
		];
	};

	const handleCheck = (id) => {
		if (selectedItems.includes(id)) {
			const selectedWithRemovedId = selectedItems.filter((item) => item !== id);

			setSelectedItems(selectedWithRemovedId);
			return;
		}

		setSelectedItems((selectedItems) => [...selectedItems, id]);
		return;
	};

	return (
		<S.ItemContainer>
			<S.ItemHeader>
				<Checkbox
					id={item.id}
					label=""
					checked={selectedItems.includes(item.id)}
					onChange={() => handleCheck(item.id)}
					value={item.id}
					styleContainer={{ margin: '5px 0 0', width: '21px' }}
					styleLabel={{ marginBottom: 0 }}
					checkboxOutineColor="#ccc"
				/>
				<S.ItemLabel onClick={() => handleCheck(item.id)}>
					{`[${item.id}] - ${item.name}`}
				</S.ItemLabel>
			</S.ItemHeader>
		</S.ItemContainer>
	);
}

export default DocumentItem;
