import { useQuery } from '@tanstack/react-query';

import { FlashcardAPICMS } from '~/services/apiCallsCMS';

const listFlashcard = async (params) => {
  try {
    const response = await FlashcardAPICMS.list(params);
    if (!response && !response.data) {
      throw new Error('No results found');
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useListFlashcard(params, options) {
  return useQuery({
    queryKey: ['listFlashcard', params],
    queryFn: () => listFlashcard(params),
    staleTime: 0,
    ...options?.config,
  });
}
