/* eslint-disable no-await-in-loop */
import { apiCMS } from './api';

export default class parentApiCMS {
  static async customCalls({
    url,
    call,
    data,
    request,
    retryCount = 3,
    checkStatus,
    skipRetry,
    config,
  }) {
    if (!url || !call) return;
    let response = false;

    if (config) {
      request = { ...request, ...config };

      const newData = new FormData();

      for (const key of Object.keys(data)) {
        newData.append(key, data[key]);
      }

      data = newData;
    }

    if (skipRetry) {
      if (data) {
        response = await call(url, data, request);
      } else {
        response = await call(url, request);
      }
      if (response.data) {
        return response;
      }
      return false;
    }

    for (let retries = retryCount; retries >= 0; retries - 1) {
      try {
        if (data) {
          response = await call(url, data, request);
        } else {
          response = await call(url, request);
        }
        if (checkStatus && checkStatus === response.status) {
          break;
        }
        if (response.data) {
          break;
        }
      } catch (err) {
        response = false;
        retries -= 1;
      }
    }
    return response;
  }

  static async list(extra, cancelToken) {
    const params = new URLSearchParams(extra).toString();
    const request = {
      cancelToken,
    };
    return this.customCalls({ url: `${this.url}/?${params}`, call: apiCMS.get, request });
  }

  static async get(id, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({ url: `${this.url}/${id}/`, call: apiCMS.get, request });
  }

  static async create(data, cancelToken, withFormData = false) {
    const request = {
      cancelToken,
    };
    if (withFormData) {
      const result = await this.customCalls({
        url: `${this.url}/`,
        call: apiCMS.post,
        data,
        request,
        skipRetry: true,
        config: {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        },
      });
      return result;
    }
    const result = await this.customCalls({
      url: `${this.url}/`,
      call: apiCMS.post,
      data,
      request,
      skipRetry: true,
    });
    return result;
  }

  static async update(id, data, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/${id}/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
    });
  }

  static async bulkUpdate(data, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/bulk_update/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
    });
  }

  static async updateFile(id, data, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/${id}/`,
      call: apiCMS.patch,
      data,
      request,
      skipRetry: true,
      config: {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  static async delete(id, cancelToken) {
    const request = {
      cancelToken,
    };
    return this.customCalls({
      url: `${this.url}/${id}/`,
      call: apiCMS.delete,
      request,
      checkStatus: 204,
    });
  }
}
