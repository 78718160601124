import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  padding: 48px;
  max-width: 1388px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const CourseList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FooterContainer = styled.div`
  display: flex;
  margin-top: 32px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const FooterLabel = styled.p`
  margin: 0;
  font-size: 12.6px;
  font-weight: 400;
  line-height: 16.9px;
  color: #00205b;
  flex: 1;
`;
