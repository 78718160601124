import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';

import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import Loader from '~/components/Loader';

import TextEditorComponent from '~/components/TextEditorComponent';
import history from '~/services/history';

import { Chapter } from '~/services';
import { toast } from 'react-toastify';
import { Mixpanel } from '~/services/analytics';
import * as S from './styles';

function Bibliography({ changeStep, chapter, editMode = false }) {
  const [loading, setLoading] = useState(false);
  const [bibliography, setBibliography] = useState({ text: '' });
  const [defaultValue, setDefaultValue] = useState();

  const bibliographyRef = useRef();

  const getChapter = useCallback(async (cancelToken, id) => {
    setLoading(true);
    try {
      const { data } = await Chapter.get(id, cancelToken);
      if (data?.bibliography) {
        setDefaultValue(data?.bibliography);
        setBibliography({ ...bibliography, text: data?.bibliography });
      }
    } catch (error) {
      console.error('Erro ao carregar a bibliografia. Tente novamente.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (editMode) {
      Mixpanel.track("Clicou 'Tela do capítulo - Opções de edição de capítulo'", {
        'Item selecionado': 'Bibliografia',
      });
    }
    const source = Axios.CancelToken.source();
    getChapter(source.token, chapter?.id);
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    bibliographyRef.current = bibliography.text;
  }, [bibliography]);

  async function handlePreview() {
    setLoading(true);
    try {
      await Chapter.update(chapter?.id, { bibliography: bibliography.text });
      Mixpanel.track(
        editMode
          ? "Clicou 'Tela do capítulo - Bibliografia - Botão revisar o capítulo'"
          : "Clicou 'Novo capítulo - Bibliografia - Botão revisar o capítulo'",
        {
          'Nome do capítulo': chapter.name,
          'Conteúdo preenchido': bibliography.text !== '' ? 'Sim' : 'Não',
        },
      );
      history.push({
        pathname: `/chapter/preview/${chapter?.id}`,
        state: {
          removeFooter: true,
          chapter,
          backTo: `/chapter/edit/${chapter?.id}`,
          editMode,
        },
      });
      toast.success('Sucesso ao editar a bibliografia.');
    } catch (error) {
      toast.error('Erro ao editar a bibliografia. Tente novamente.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <S.Content>
          {!editMode && (
            <S.Header>
              <S.TitleHeader>{`Bibliografia - ${chapter.name}`}</S.TitleHeader>
            </S.Header>
          )}
          <S.Container>
            <TextEditorComponent
              textHTML={defaultValue}
              onEdit={(text) => setBibliography({ ...bibliography, text })}
              showWarning
            />
          </S.Container>
          <S.FooterActions>
            <S.ButtonBack
              type="button"
              onClick={() => {
                if (editMode) {
                  history.push('/chapter');
                } else {
                  changeStep({ second: true, third: false });
                }
              }}
            >
              <MdArrowBack size={18} color="#01c3aa" />
              Voltar
            </S.ButtonBack>
            <S.BoxButtons>
              <S.ButtonSave onClick={() => handlePreview()}>
                Revisão
                <MdArrowForward size={18} color="#FFF" />
              </S.ButtonSave>
            </S.BoxButtons>
          </S.FooterActions>
        </S.Content>
      )}
    </>
  );
}

export default Bibliography;
