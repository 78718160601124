import React from 'react';
import { useSelector } from 'react-redux';

export default function ProtectedContent({ children, permissions }) {
  const userPermissions = useSelector((state) => state.auth.permissions);
  let showContent = false;

  showContent = userPermissions.some((permission: string) => permissions?.includes(permission));

  return showContent && <>{children}</>;
}
