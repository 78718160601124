import styled from 'styled-components';
import { ReactComponent as SaveTrack } from '~/assets/review_save.svg';

export const Wrap = styled.div`
  display: flex;
  padding: 48px;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const Container = styled.div`
  max-width: 1388px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(24, 26, 30, 0.08), 0px 0px 2px 0px rgba(24, 26, 30, 0.16);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 56px;
  border-top: ${(props) => (props.noBorder ? 'none' : '1px solid #e5e8ee')};
  gap: 40px;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 544px;
`;

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  justify-content: space-between;
  width: 100%;
`;

export const StepContainer = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;
`;

export const StepText = styled.span`
  display: flex;
  align-items: center;
  gap: 16px;
  color: #00205b;
  font-size: 22.8px;
  font-weight: 600;
  line-height: 30.4px;
`;

export const StepOrder = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background: #01c3aa;
  color: #fff;
  font-size: 20.3px;
  font-weight: 600;
  line-height: 36.5px;
`;

export const SectionText = styled.div`
  color: #00205b;
  font-size: 20.3px;
  font-weight: 600;
  line-height: 27px;
`;

export const QuestionsCount = styled.div`
  display: flex;
  width: 50px;
  height: 26px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 1000px;
  background: #e5e8ee;
  color: #00205b;
  text-align: center;
  font-size: 14.2px;
  font-weight: 600;
  line-height: 18.958px;
`;

export const QuestionsText = styled.span`
  color: #00205b;
  font-size: 12.6px;
  font-weight: 400;
  line-height: 16.9px;
`;

export const ImportContainer = styled.div`
  input[type='file'] {
    display: none;
  }
  display: flex;
  padding: 40px 56px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const ImportButton = styled.button`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #01b69f;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.3px;
  border: none;
  background: #fff;
  :hover {
    background: #e5faf7;
    border-radius: var(--pill, 320px);
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  width: calc(100% - 64px);
  margin: 0 32px;
  gap: 24px;
  height: 72px;
  background: #fff;
  box-shadow: 0px 8px 32px rgba(24, 26, 30, 0.16), 0px 32px 64px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  justify-content: space-between;
  position: sticky;
  bottom: 20px;
  z-index: 1000;
`;

export const FlashcardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 2px solid #e5e8ee;
  background: #fff;
  width: 100%;
`;

export const FlashcardContent = styled.div`
  color: #00205b;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  height: 24px;
  line-height: 24px;
  whitespace: nowrap;
`;

export const FlashcardHeader = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
`;

export const FlashcardInfoContent = styled.div`
  display: flex;
  gap: 24px;
`;

export const QuestionButtonsContent = styled.div`
  display: flex;
  gap: 24px;
`;

export const QuestionButton = styled.button`
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  border-radius: 1000px;
  border: none;
  background: #fff;
  color: #01c3aa;
  text-align: center;
  font-size: 14.2px;
  font-weight: 600;
  line-height: 18.958px;
  :hover {
    background: #e5faf7;
  }
  :disabled {
    color: #d1d7e1;
    cursor: default;
    background: none;

    svg {
      path {
        fill: #d1d7e1;
      }
    }
  }
`;

export const StyledSaveIcon = styled(SaveTrack)`
  path {
    fill: ${(props) => (props.disabled ? '#8f9db7' : '#fff')};
  }
`;
