import React, { useEffect, useRef, useState } from 'react';

import ItemSpecialties from './ItemSpecialties';

import { Container } from './styles';

const ListSpecialties = ({
  options,
  setOptions,
  removeSpecialtie,
  onSortOptions,
  setChangeValueOption,
  allowDrag,
  hiddenRemoveIcon,
}) => {
  const [itemsOptions, setItemsOptions] = useState(null);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  useEffect(() => {
    setItemsOptions(options);
  }, [options]);

  const onDragStart = (index) => {
    dragItem.current = index;
  };

  const onDragEnter = (index) => {
    dragOverItem.current = index;
  };

  const changeOption = () => {
    setChangeValueOption(itemsOptions);
    setOptions(itemsOptions);
  };

  return (
    <Container>
      {itemsOptions &&
        itemsOptions.map((item, index) => {
          return (
            <ItemSpecialties
              draggable={allowDrag}
              index={index}
              key={`${item.name}-${index}`}
              option={item}
              onDragStart={onDragStart}
              onDragEnter={onDragEnter}
              onDragEnd={() => onSortOptions(dragItem.current, dragOverItem.current)}
              onDragOver={(e) => e.preventDefault()}
              changeOption={changeOption}
              hiddenRemove={itemsOptions.length !== 1 || !hiddenRemoveIcon}
              removeSpecialtie={removeSpecialtie}
            />
          );
        })}
    </Container>
  );
};

export default ListSpecialties;
