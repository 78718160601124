/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import HeaderComponent from '~/components/HeaderComponent';
import Loader from '~/components/Loader';
import Filters from './components/Filters';
import List from './components/List';
import NewEmptyContent from '~/components/NewEmptyContent';
import { useGetFlashcardDeck } from '~/hooks-querys/flashcard_deck';
import history from '~/services/history';
import { Mixpanel } from '~/services/analytics';
import * as S from './styles';

function FlashcardsDecks() {
  const [filters, setFilters] = useState({});
  const [getParams, setGetParams] = useState({});
  const [emptyState, setEmptyState] = useState(false);
  const [noSearch, setNoSearch] = useState(true);

  const {
    data: dataFlashcardDeck,
    isFetching: loadingDataFlashcardDeck,
    refetch: refetchDataFlashcardDeck,
  } = useGetFlashcardDeck(
    { ...getParams },
    {
      config: { enabled: false },
    },
  );

  const handleFilters = () => {
    const mixpanelParams = {
      ...(filters?.title && { Pesquisa: filters.title }),
      ...(filters?.id && { 'Buscar por ID': filters.id }),
      ...(filters?.tags && { Tags: filters.tags.label }),
      ...(filters?.institution && { Instituição: [filters.institution.label] }),
      ...(filters?.residency_degree && { 'Nível de Residência': filters.residency_degree.label }),
      ...(filters?.speciality && { Especialidade: [filters.speciality.label] }),
      ...(filters?.status && { Status: [filters.status.label] }),
    };
    Mixpanel.track("Clicou 'Lista de Decks - Botão de Filtrar'", mixpanelParams);
    setEmptyState(false);
    setNoSearch(false);
    refetchDataFlashcardDeck();
  };

  const handleNewDeck = () => {
    Mixpanel.track("Clicou 'Lista de Decks - Novo Deck'");
    return history.push('/flashcards-decks/new');
  };

  useEffect(() => {
    const params = {
      ...(filters?.title && { title: filters.title }),
      ...(filters?.id && { id: filters.id }),
      ...(filters?.tags && { tags: filters.tags.value }),
      ...(filters?.institution && { institutions: [filters.institution.value] }),
      ...(filters?.residency_degree && { residency_degree: filters.residency_degree.value }),
      ...(filters?.speciality && { specialities: [filters.speciality.value] }),
      ...(filters?.status && filters?.status.value === 3 && { status: true }),
    };

    setGetParams(params);
  }, [filters]);

  useEffect(() => {
    if (loadingDataFlashcardDeck) return;

    if (!dataFlashcardDeck?.results) {
      setNoSearch(true);
      return;
    }
    if (dataFlashcardDeck?.results.length === 0) {
      setEmptyState(true);
    }
  }, [dataFlashcardDeck, loadingDataFlashcardDeck]);

  return (
    <>
      <HeaderComponent
        title="Decks Flashcards"
        action={handleNewDeck}
        actionText="Adicionar novo"
        permissions={['add_flashcard']}
      />
      <S.Wrap>
        <S.Container>
          <Filters handleFilters={handleFilters} filters={filters} setFilters={setFilters} />
          {noSearch ? (
            <NewEmptyContent
              title="Utilize o filtro para encontrar um deck de flashcards"
              subtitle="Selecione os critérios acima e clique em ”Filtrar”"
            />
          ) : (
            <>
              {emptyState ? (
                <NewEmptyContent
                  title="Nenhum deck de flashcards encontrado"
                  subtitle="Altere os filtros e tente novamente"
                />
              ) : (
                <>
                  {loadingDataFlashcardDeck ? (
                    <Loader />
                  ) : (
                    <List data={dataFlashcardDeck?.results} />
                  )}
                </>
              )}
            </>
          )}
        </S.Container>
      </S.Wrap>
    </>
  );
}

export default FlashcardsDecks;
