import styled, { css } from 'styled-components';
import Truncate from 'react-truncate';

export const Container = styled.div`
  width: 100%;
  justify-content: center;
  background-color: #f5f5fb;
`;

export const FilterContainer = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow:
    0px 0px 2px rgba(24, 26, 30, 0.16),
    0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gap ? '17px' : 0)};
`;

export const Header = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #00205b;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: flex-end;
`;

export const Filter = styled.div`
  max-width: 604px;
  width: 100%;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  gap: 10px;

  input {
    width: 100%;
    height: 19px;
    border: none;
    background: none;

    ::placeholder {
      color: #3d5582;
      font-weight: 400;
      font-size: 14.2px;
      line-height: 19px;
    }
  }
`;

export const ButtonFilter = styled.button`
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }
`;

export const ItemTitle = styled(Truncate)`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #00205b;
`;

export const ItemSubtitle = styled.span`
  font-weight: 400;
  font-size: 14.2px;
  line-height: 19px;
  color: #3d5582;

  strong {
    font-weight: 600;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Folder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: ${(props) => (props.lastItem || props.open ? 'none' : '1px solid #d1d7e1')};
  padding-bottom: 24px;
`;

export const FolderOrder = styled.div`
  font-weight: 400;
  font-size: 14.2px;
  line-height: 19px;
  color: #00205b;
`;

export const FolderTitle = styled.div`
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;
`;

export const FolderCount = styled.span`
  font-weight: 400;
  font-size: 14.2px;
  color: #00205b;

  strong {
    font-weight: 600;
  }
`;

export const ContainerArrow = styled.div`
  border: 2px solid #e5e8ee;
  border-radius: 1000px;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  transform: ${(props) => (props.open ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: all 0.2s ease-in-out;
`;

export const DivCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivRow = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ContainerLoader = styled.div`
  background-color: #fff;
  height: 120px;
  width: 100%;
  display: grid;
  place-items: center;
  box-shadow:
    0px 0px 2px rgba(24, 26, 30, 0.16),
    0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
`;

export const ExamsContainer = styled.div`
  width: 100%;
`;

export const ListExams = styled.div`
  position: relative;
  width: 100%;
`;

export const MainContainer = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #f5f5fb;
`;

export const ViewCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`;

export const ViewRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-inline: 20px;
`;

export const ContainerPageState = styled.div`
  margin-top: 40px;
  height: 458px;
  display: grid;
`;

export const RowCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  gap: 24px;
`;

export const ContainerTitle = styled.div`
  display: flex;
  gap: 16px;
`;

export const Chip = styled.div`
  display: flex;
  align-items: center;
  background: #e5e8ee;
  border-radius: 1000px;
  height: 24px;
  padding-inline: 8px;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
`;

export const Divisor = styled.div`
  width: 100%;
  border-top: 1px solid #e5e8ee;
`;

export const CardProgress = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ChipCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 26px;
  background: #fcdbdf;
  color: #ee1f38;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  ${(props) =>
    props.complete &&
    css`
      background: #d8f4e6;
      color: #0cba66;
    `}
`;

export const EditButton = styled.button`
  background-color: transparent;
  height: 48px;
  color: #01c3aa;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding-inline: 20px;
  border: none;
  border-radius: 1000px;
  transition: all 0.2s ease-in-out;

  :hover {
    background-color: #e5faf7;
  }
`;

export const ChipStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 26px;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  padding-inline: 25px;
  border: 1px dashed #0cba66;
  background: #d8f4e6;
  color: #0cba66;
  ${(props) =>
    props.status === 'pending' &&
    css`
      border: 1px dashed #ee1f38;
      background: #fcdbdf;
      color: #ee1f38;
    `}
`;

export const GroupButtonModal = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
`;

export const ContainerSwitch = styled.div`
  display: flex;
  gap: 12px;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 14.2px;
  line-height: 19px;
  color: #00205b;
`;

export const SwitchButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 32px;
  height: 20px;
  background: ${(props) => (props.checked ? '#01CFB5' : '#8f9db7')};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  margin-bottom: 0;
`;

export const CircleButton = styled.div`
  position: absolute;
  top: 4px;
  left: ${(props) => (props.checked ? 'calc(100% - 16px)' : '4px')};
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: #fff;
  transition: 0.2s;
`;

export const ButtonSplit = styled.div`
  color: #00205b;
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: none;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  border: 2px solid #e5e8ee;
  cursor: pointer;
`;

export const ButtonPopper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  min-height: 48px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00205b;
  background: #fff;
  border: none;
`;
