import styled from 'styled-components';

export const TagContainer = styled.div`
  height: 24px;
  padding: 0px 8px;
  border-radius: 1000px;
  background-color: ${(props) => (props.type === 'primary' ? props.backgroundColor : '#fff')};
  border: ${(props) => (props.type === 'primary' ? 'none' : '1px solid ')}${(props) => (props.textColor)};
  color: ${(props) => (props.textColor)};
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: ${(props) => (props.textUppercase ? 'uppercase' : 'none')};
  white-space: nowrap;
`;

