import React, { useRef } from 'react';
import { Ring } from 'react-spinners-css';

import { TextField, MenuItem, Box, Chip, Checkbox, ListItemText } from '@material-ui/core';

import { ReactComponent as DropsChevron } from '~/assets/drops-chevron.svg';

import { InputStyle, LoaderContainer } from './styles';

function SelectComponentMaterialUi({
  placeholder,
  label,
  values,
  selectedValue,
  setSelectedValue,
  disabled,
  loadMore,
  loadingMore,
  shouldLoadMore,
  multiple,
  multipleLimitNumber,
  textfieldStyle,
  menuItemStyle,
  size,
  id,
  limitWidth,
}) {
  const inputRef = useRef();

  const selectProps = multiple
    ? {
        IconComponent: (props) => <DropsChevron {...props} />,
        multiple: multiple,
        renderValue: (selected) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
              maxWidth: limitWidth ? inputRef.current?.offsetWidth - 50 : 'auto',
              position: 'absolute',
            }}
          >
            {selected.map((value) => (
              <Chip key={value} label={getItemLabel(value)} />
            ))}
          </Box>
        ),
      }
    : {
        IconComponent: (props) => <DropsChevron {...props} />,
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: -50,
            horizontal: 'center',
          },
        },
      };

  const loadMoreItems = (e) => {
    if (!loadMore || !shouldLoadMore) return;
    const scrollToBottom =
      e.target.scrollTop + 250 + e.target.clientHeight >= e.target.scrollHeight;
    if (scrollToBottom) {
      loadMore();
    }
  };

  const getItemLabel = (value) => {
    if (values?.length <= 0) return;
    const itemWithValue = values.filter((item) => item.value === value);
    return itemWithValue[0].label;
  };

  const handleChange = (event) => {
    setSelectedValue(event, id);
  };

  return (
    <InputStyle>
      <TextField
        ref={inputRef}
        select
        id={`select-component-${id}`}
        label={label}
        value={selectedValue}
        variant="outlined"
        placeholder={placeholder || ''}
        onChange={handleChange}
        size={size}
        style={{ maxWidth: limitWidth ? inputRef.current?.offsetWidth : 'auto', ...textfieldStyle }}
        SelectProps={selectProps}
        disabled={disabled}
        onScroll={loadMoreItems}
      >
        {values.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={
              selectedValue?.length >= multipleLimitNumber && !selectedValue.includes(option.value)
            }
            style={{ ...menuItemStyle, maxWidth: inputRef.current?.offsetWidth }}
          >
            {multiple ? (
              <>
                <Checkbox checked={selectedValue?.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </>
            ) : (
              <>{option.label}</>
            )}
          </MenuItem>
        ))}
        {loadingMore && (
          <LoaderContainer>
            <Ring size={18} color="#019d8a" />
          </LoaderContainer>
        )}
      </TextField>
    </InputStyle>
  );
}

export default SelectComponentMaterialUi;
