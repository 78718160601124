import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import Axios from 'axios';
import {
  OptionAPICMS,
  QuestionAPICMS,
  QuestionImageAPICMS,
  TagAPICMS,
} from '~/services/apiCallsCMS';
import Button from '~/components/ButtonComponent';
import QuestionAlternatives from './QuestionAlternatives';
import QuestionImages from './QuestionImages';
import { TAG_TYPE } from '~/utils/variables';
import { Enunciated, InputContainer, ContainerActionsButtons } from './styles';

export default function QuestionContent({
  question,
  handleGoToImage,
  editContent,
  action,
  loadTags,
  handleEdit,
  onRefresh,
  setLoading,
}) {
  const tagId = question.subtags;
  const [content, setContent] = useState(question.content);
  const [correctOption, setCorrectOption] = useState(
    question.correct_options.length > 0 ? question.correct_options[0] : null,
  );
  const [alternativesContent, setAlternativesContent] = useState([]);
  const [alternativesImages, setAlternativesImages] = useState([]);
  const [tagSelected, setTagSelected] = useState();
  const [completedAlternatives, setCompletedAlternatives] = useState(false);
  const [completedImages, setCompletedImages] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [paste, setPaste] = useState(false);

  const [questionImgs, setQuestionImgs] = useState([]);

  useEffect(() => {
    setContent(question.content);
  }, [question]);

  useEffect(() => {
    if (!tagId || action !== 'tags') return;

    if (!Array.isArray(tagId) || tagId.length === 0) {
      return;
    }

    const tagIdFromArray = tagId.find((tag) => tag.tag_type === TAG_TYPE.FOCUS).id;

    if (!tagIdFromArray) {
      return toast.error('Erro ao retornar a tag');
    }

    const source = Axios.CancelToken.source();

    TagAPICMS.get(tagIdFromArray, source.token).then((result) => {
      if (result.data) {
        setTagSelected({
          key: result.data.id,
          label: result.data.verbose,
          value: result.data.id,
        });
      }
    });

    return () => {
      source.cancel();
    };
  }, [action, tagId]);

  const changeAlternative = (value) => {
    setAlternativesContent(value);
  };

  const changeImage = (value) => {
    setAlternativesImages(value);
  };

  function verifyContent() {
    for (const property in alternativesContent) {
      if (!alternativesContent[property]) {
        return false;
      }
    }
    return true;
  }

  function verifyImage() {
    for (const property in alternativesImages) {
      if (!alternativesImages[property]) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    setCompletedAlternatives(verifyContent());
    setCompletedImages(verifyImage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alternativesContent, alternativesImages]);

  useEffect(() => {
    if (action === 'text' && question) {
      if (!question.content) {
        handleEdit(true);
      } else {
        handleEdit(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    const alternatives = ['a', 'b', 'c', 'd', 'e'];

    switch (action) {
      case 'text':
        if (!content || (!completedAlternatives && !completedImages)) {
          toast.error('Preencha todas os campos antes de salvar!');
          return;
        }

        try {
          setDisabled(true);
          setLoading(true);
          Promise.all([
            QuestionAPICMS.update(question.id, {
              content,
              ...(correctOption ? { correct_options: [correctOption] } : {}),
            }),
            ...question.options.map((opt, index) => {
              if (alternativesImages[index] instanceof File || alternativesImages[index] === '') {
                return OptionAPICMS.updateFile(opt.id, { image: alternativesImages[index] }).catch(
                  () => {
                    toast.error(
                      `Não foi possível fazer o upload da imagem da alternativa ${alternatives[index]}.`,
                    );
                  },
                );
              }
            }),
            ...question.options.map((opt, index) => {
              return OptionAPICMS.update(opt.id, { content: alternativesContent[index] });
            }),
            ...questionImgs.map((qi, index) => {
              if (qi.id && qi.order !== index + 1) {
                return QuestionImageAPICMS.update(qi.id, { order: index + 1 });
              }
              const source = Axios.CancelToken.source();
              return QuestionImageAPICMS.create(
                {
                  question: question.id,
                  order: index + 1,
                  image: qi.file,
                },
                source.token,
                true,
              ).catch(() => toast.error(`Error ao criar imagem ${index + 1} da questão`));
            }),
            ...question.imagesUri
              .filter((qiOri) => {
                const index = questionImgs.findIndex((qi) => qi.id && qi.id === qiOri.id);
                return index < 0;
              })
              .map((qiOri) => {
                const source = Axios.CancelToken.source();
                return QuestionImageAPICMS.delete(qiOri.id, source.token);
              }),
          ]).finally(() => {
            handleEdit(false);
            onRefresh();
            toast.success('Questão salva com sucesso!');
            setDisabled(false);
            setLoading(false);
          });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (err) {
          toast.error('Ocorreu algum problema!');
          setDisabled(false);
          setLoading(false);
        }
        break;

      case 'tags':
        questionImgs.map((qi) => {
          if (qi.kind) {
            return QuestionImageAPICMS.update(qi.id, { kind: qi.kind });
          }
        });
        toast.success('Tag salva com sucesso!');
        break;

      default:
        break;
    }
  };

  const handlerOnPressKey = useCallback(
    (ev) => {
      if (!editContent) return;

      const key = ev.which || ev.keyCode;
      const ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17;
      if (key === 86 && ctrl) {
        setPaste(true);
      }
    },
    [editContent],
  );

  useEffect(() => {
    window.addEventListener('keydown', handlerOnPressKey);

    return () => {
      window.removeEventListener('keydown', handlerOnPressKey);
    };
  }, [handlerOnPressKey]);

  const handleChangeContent = (value) => {
    if (paste) {
      setContent(value.replaceAll('\n', ' '));
      setPaste(false);
    } else {
      setContent(value);
    }
  };

  const handleDeleteAlternative = (id, index) => {
    OptionAPICMS.delete(id).then((_result) => {
      if (index < question.options.length - 1) {
        const newOptions = question.options.filter((_, i) => i !== index);
        Promise.all(
          newOptions.map((opt, indexOption) => {
            return OptionAPICMS.update(opt.id, { letter: String.fromCharCode(65 + indexOption) });
          }),
        ).then(() => {
          toast.success('Alternativa excluída com sucesso!');
          onRefresh();
        });
      } else {
        toast.success('Alternativa excluída com sucesso!');
        onRefresh();
      }
    });
  };

  const handleAddAlternative = () => {
    const newOptionLetter = String.fromCharCode(65 + question.options.length);
    OptionAPICMS.create({ letter: newOptionLetter, question: question.id }).then(() => {
      toast.success('Alternativa adicionada com sucesso!');
      onRefresh();
    });
  };

  return (
    <>
      {!editContent || action !== 'text' ? (
        <Enunciated>
          {content.split('\n').map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))}
        </Enunciated>
      ) : (
        <Enunciated>
          <InputContainer>
            <textarea
              name="content"
              className="textInput"
              placeholder="Enunciado"
              style={{ width: '100%', resize: 'vertical', minHeight: 150 }}
              value={content}
              onChange={(event) => handleChangeContent(event.target.value)}
            />
          </InputContainer>
        </Enunciated>
      )}
      <QuestionImages
        editContent={editContent}
        action={action}
        question={question}
        questionImgs={questionImgs}
        setQuestionImgs={setQuestionImgs}
        handleGoToImage={handleGoToImage}
      />
      {question.question_type === 'o' && (
        <QuestionAlternatives
          question={question}
          action={action}
          editContent={editContent}
          correctOption={correctOption}
          setCorrectOption={setCorrectOption}
          alternativesContent={alternativesContent}
          alternativesImages={alternativesImages}
          changeImage={changeImage}
          changeAlternative={changeAlternative}
          handleGoToImage={handleGoToImage}
          handleDeleteAlternative={handleDeleteAlternative}
          handleAddAlternative={handleAddAlternative}
        />
      )}

      {(action === 'text' || action === 'tags') && (
        <ContainerActionsButtons>
          <Button
            type="default"
            text="Salvar"
            primaryColor="#fff"
            textColor="#01cfb5"
            onClick={handleSave}
            disabled={disabled}
          />
        </ContainerActionsButtons>
      )}
    </>
  );
}
