import React from 'react';
import Chip from '@material-ui/core/Chip';

import * as S from './styles';

export default function FilterChips({ data, handleDelete }) {
  return (
    <S.Wrap>
      {data.map((item) => (
        <S.StyledChip>
          <Chip
            key={`chip-${item.label}`}
            label={item.label}
            onDelete={() => handleDelete(item.key, item.label, item?.value)}
            variant="outlined"
            deleteIcon={<S.StyledCloseIcon />}
          />
        </S.StyledChip>
      ))}
    </S.Wrap>
  );
}
