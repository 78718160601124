export const validateSections = (parse) => {
  let h2Found = false;

  for (const child of parse) {
    const innerHTML = child.innerHTML.trim();

    if (
      (child.localName === 'h2' || child.localName === 'h3') &&
      (innerHTML === '' || innerHTML === '&nbsp;')
    ) {
      return false;
    }

    if (child.localName === 'h2') {
      h2Found = true;
    } else if (child.localName === 'h3' && !h2Found) {
      return false;
    }
  }

  return true;
};

export const prepareSections = (parse, chapterId) => {
  const idxH2 = [];
  const preparingSections = [];
  const separateSectionsInH2 = [];

  parse.filter((res, index) => {
    if (res.localName === 'h2') {
      idxH2.push(index);
      return true;
    }
    return false;
  });

  for (let i = 0; i < idxH2.length; i++) {
    const h2Section = parse.slice(idxH2[i], idxH2[i + 1]);
    separateSectionsInH2.push(h2Section);
  }

  separateSectionsInH2.forEach((elm) => {
    elm.filter((res, index) => {
      if (res.localName === 'h2') {
        idxH2.push(index);
        return true;
      }
      return false;
    });
  });

  separateSectionsInH2.forEach((separateSection, indexSection) => {
    let i = 0;
    let j = 0;
    const idxH3 = [];
    const section = {
      name: '',
      chapter: chapterId,
      content: '',
      order: null,
      question: [],
      sub_sections: [],
    };
    separateSection.filter((res, index) => {
      if (res.localName === 'h3') {
        idxH3.push(index);
        return true;
      }
      return false;
    });
    preparingSections.push(section);
    separateSection.forEach((tag, indexTag) => {
      if (idxH3[i + 1] && indexTag > idxH3[i + 1]) {
        i += 1;
      }
      const sub_sections = {
        name: '',
        chapter: chapterId,
        content: '',
        order: null,
        question: [],
      };
      if (tag.localName === 'h2') {
        preparingSections[indexSection].name = tag.innerText;
        preparingSections[indexSection].order = indexSection + 1;
        if (tag.id) {
          const id = parseInt(tag.id, 10);
          preparingSections[indexSection] = { ...preparingSections[indexSection], id };
        }
      } else if (tag.localName === 'h3') {
        preparingSections[indexSection].sub_sections.push(sub_sections);
        preparingSections[indexSection].sub_sections[j].name = tag.innerText;
        preparingSections[indexSection].sub_sections[j].order = j + 1;
        if (tag.id) {
          const id = parseInt(tag.id, 10);
          preparingSections[indexSection].sub_sections[j] = {
            ...preparingSections[indexSection].sub_sections[j],
            id,
          };
        }
        j += 1;
      } else {
        if (idxH3.length > 0) {
          if (indexTag < idxH3[i]) {
            preparingSections[indexSection].content += tag.outerHTML;
          } else if (indexTag < idxH3[i + 1]) {
            preparingSections[indexSection].sub_sections[i].content += tag.outerHTML;
          } else {
            preparingSections[indexSection].sub_sections[i].content += tag.outerHTML;
          }
        } else {
          preparingSections[indexSection].content += tag.outerHTML;
        }
      }
    });
  });
  return preparingSections;
};
