import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 24px 40px;
  width: 468px;
  max-height: 650px;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 32px 64px 0px rgba(24, 26, 30, 0.08), 0px 8px 32px 0px rgba(24, 26, 30, 0.16);
  margin: auto;
  position: relative;
  gap: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseContainer = styled.div`
  display: flex;
  cursor: pointer;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const AlertBox = styled.div`
  display: flex;
  padding: 16px 20px;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid #b5cde9;
  color: #3d5582;
  font-size: 14.2px;
  line-height: 18.958px;
`;

export const ImportButton = styled.button`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #01b69f;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.3px;
  border: none;
  background: #fff;
  :hover {
    background: #e5faf7;
    border-radius: var(--pill, 320px);
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SubmitButton = styled.button`
  display: flex;
  height: 48px;
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 320px;
  background: ${(props) => (props.disabled ? '#f0f2f5' : '#01B69F')};
  color: ${(props) => (props.disabled ? '#d1d7e1' : '#fff')};
  text-align: center;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.3px;
`;

//verificar abaixo

export const ModalTitle = styled.h1`
  margin: 0;
  color: #00205b;
  font-size: 25.6px;
  font-weight: 600;
  line-height: 34.2px;
`;
