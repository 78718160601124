import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 468px;
  max-height: 438px;
  min-height: 60px;
  padding: 2px 35px;
  margin-top: 25px;
  overflow: auto;
`;

export const CountContainer = styled.div`
  width: 356px;
  height: 19px;
  gap: 8px;
`;

export const ReportCountContainer = styled.div`
  width: 250px;
  height: 19px;
  gap: 4px;
`;

export const CountReport = styled.span`
  width: 17px;
  height: 19px;
  margin-right: 10px;
  font-size: 14.2px;
  font-weight: 600;
  line-height: 18.96px;
  color: #3d5582;
`;

export const ReportsLabel = styled.span`
  width: 157px;
  height: 19px;
  font-size: 14.2px;
  font-weight: 400;
  line-height: 18.96px;
  color: #3d5582;
`;

export const ReviewContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  gap: 16px;
  width: 356px;
  height: auto;
  border-bottom: 1px solid #f0f2f5;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const ReviewNameLb = styled.div`
  display: flex;
  padding: 0px;
  gap: 24px;
  width: 376px;
  height: 33px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 32.4px;
  color: #00205b;
`;

export const ReviewDateLb = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  left: 330px;
  padding: 0px;
  gap: 3px;
  width: 70px;
  height: 33px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 12.6px;
  font-weight: 400;
  line-height: 16.9px;
  color: #00205b;
`;

export const ReviewComment = styled.span`
  word-wrap: break-word;
  display: flex;
  padding: 0px;
  gap: 16px;
  max-width: 356px;
  height: auto;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 14.2px;
  font-weight: 400;
  line-height: 18.96px;
  color: #00205b;
`;

export const ReviewCategory = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 0px 10px;
  height: 32px;
  background: #daf5f1;
  border-radius: 100px;
  font-weight: 600;
  font-size: 12.6px;
  color: #077859;
`;
