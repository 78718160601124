import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import SwitchMaterialUI from '~/components/SwitchMaterialUI';
import TextEditorComponent from '~/components/TextEditorComponent';
import MultiselectAutocomplete from '~/components/MultiselectAutocomplete';
import Loader from '~/components/Loader';
import SelectWithPagination from '~/components/SelectWithPagination';
import ModalConfirmation from '~/components/ModalConfirmation';
import { Institution } from '~/services/apiCalls/institution';
import { Mixpanel } from '~/services/analytics';
import { ReactComponent as ReportIcon } from '~/assets/flashcards-report.svg';
import { getFlashcardStatus, useWindowSize } from '~/utils/util';
import { selectValueContainerStyle } from '~/pages/AdminExams/Question/QuestionTags/styles';
import { SelectStyle } from '~/pages/_layouts/default/styles';
import { loadCfas } from '~/pages/Chapters/components/Settings/utils';
import { useTagById } from '~/hooks-querys/tag';
import { TAG_TYPE } from '~/utils/variables';
import ModalDelete from '../ModalDelete';
import ModalPublish from '../ModalPublish';
import ErrorReport from '../ErrorReportComponent';

import * as S from './styles';

export default function Essay({
  data,
  number,
  deleteFlashcard,
  publishFlashcard,
  updatedFlashcard,
  loading,
  setNewQuestion,
  setNewAnswer,
}) {
  const containerRef = useRef();
  const { width } = useWindowSize();
  const [published, setPublished] = useState(data?.enabled || false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [question, setQuestion] = useState(data?.question);
  const [answer, setAnswer] = useState(data?.answer);
  const [institutions, setInstitutions] = useState([]);
  const [institutionsLoading, setInstitutionsLoading] = useState(false);
  const [changedInstitution, setChangedInstitution] = useState(false);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const [selectMaxWidth, setSelectMaxWidth] = useState('100%');
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedCfa, setSelectedCfa] = useState(null);
  const [searchCfaId, setSearchCfaId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const mixpanelData = {
    Questão: data?.question,
    Resposta: data?.answer,
    Imagem: data?.has_image ? 'Sim' : 'Não',
    Tags: data?.tag,
    ID: data?.id,
    'Nível de residência': data?.residency_degree,
    Especialidade: data?.speciality,
    'Status do flashcard': getFlashcardStatus(data?.status_error, data?.enabled),
    Instituições: data?.institutions,
  };

  const { data: tagData } = useTagById(searchCfaId, {
    config: {
      enabled: !!searchCfaId,
    },
  });

  const handleCloseDeleteModal = () => {
    return setShowDeleteModal(false);
  };

  const handleClosePublishModal = () => {
    return setShowPublishModal(false);
  };

  const handleCloseReportModal = () => {
    return setShowReportModal(false);
  };

  const handlePublished = () => {
    Mixpanel.track("Clicou 'Edição de flashcards - botão publicar individual'", mixpanelData);

    if (data?.status_error && !published) {
      return setShowPublishModal(true);
    }
    return removeStatus();
  };

  const handleDelete = () => {
    Mixpanel.track("Clicou 'Edição de flashcards - botão excluir'", mixpanelData);
    setShowDeleteModal(false);
    return deleteFlashcard(data?.id);
  };

  const removeStatus = () => {
    publishFlashcard(!published, data?.id);
    setShowPublishModal(false);
    return setPublished(!published);
  };

  useEffect(() => {
    if (data?.enabled === published) return;
    setPublished(data?.enabled);
  }, [data?.enabled]);

  useEffect(() => {
    if (loading) return;
    setAnswer(data?.answer);
    setQuestion(data?.question);
  }, [loading]);

  const transformOptions = (institution) => {
    if (!institution.id || !institution.name) return;
    return {
      label: institution.state ? `${institution.name} - ${institution.state}` : institution.name,
      key: institution.id,
      value: institution.id,
      institution,
    };
  };

  const transformCfaOptions = (cfa) => {
    if (!cfa.id || !cfa.name) return;
    return {
      label: cfa.verbose,
      key: cfa.id,
      value: cfa.id,
    };
  };

  const loadInstitutions = async (search) => {
    setInstitutionsLoading(true);
    let params;
    if (search) {
      params = {
        search: search,
      };
    }

    const { data } = await Institution.list({
      ordering: 'name',
      no_page: 1,
      ...params,
    });

    setInstitutionsLoading(false);
    if (data) {
      return setInstitutions([...institutions, ...data.map(transformOptions)]);
    }
  };

  const filterInstitutions = async (search) => {
    if (!search) {
      return institutions;
    }
    return institutions.filter((item) => search.includes(item.label));
  };

  const handleSelectedOptions = (option) => {
    setSelectedInstitutions(option);
    setChangedInstitution(true);
  };

  const adjustText = (text) => {
    let newText = text;
    // Regular expression to match <p> tags containing <img> tags
    const imgRegex = /(<p[^>]*>.*?<img[^>]+>.*?<\/p>)/g;

    newText = newText.replace(imgRegex, (match) => {
      // Extract the <p> tag
      const pTagMatch = match.match(/<p[^>]*>/);
      const pTag = pTagMatch ? pTagMatch[0] : '';

      // Check if the <p> tag has a style attribute
      const styleMatch = pTag.match(/style="([^"]*)"/);

      let updatedPTag;
      if (styleMatch) {
        let styles = styleMatch[1].trim();

        // Ensure the existing style ends with a single semicolon
        if (styles && !styles.endsWith(';')) {
          styles += ';';
        }

        // Add width: 100% if not already present
        if (!styles.includes('width: 100%;')) {
          styles += ' width: 100%;';
        }

        updatedPTag = pTag.replace(/style="([^"]*)"/, `style="${styles.trim()}"`);
      } else {
        // If no style attribute, add one with width: 100%
        updatedPTag = pTag.replace('<p', '<p style="width: 100%;"');
      }

      // Replace the original <p> tag with the updated one
      return match.replace(pTag, updatedPTag);
    });

    return newText;
  };

  const handleQuestion = (text) => {
    setQuestion(adjustText(text));
  };

  const handleAnswer = (text) => {
    setAnswer(adjustText(text));
  };

  const handleReport = () => {
    Mixpanel.track("Clicou 'Edição de flashcards - botão histórico de reports'", mixpanelData);
    return setShowReportModal(true);
  };

  const handleChangeCfa = (cfa) => {
    setSelectedCfa(cfa);
    updatedFlashcard(null, answer, null, data?.id, [selectedTag, cfa.value], false, false);
  };

  const saveData = (solveReport) => {
    updatedFlashcard(
      question,
      answer,
      selectedInstitutions?.map((item) => item.key),
      data?.id,
      selectedCfa ? [selectedTag, selectedCfa?.value] : [selectedTag],
      true,
      solveReport,
    );
  };

  const handleRemoveReportedStatus = () => {
    setShowConfirmModal(false);
    saveData(true);
  };

  const handleSave = () => {
    if (data?.status_error) {
      setShowConfirmModal(true);
    } else {
      saveData(false);
    }
  };

  const updatedAtDate = () => {
    if (!data?.updated_at) return '-';

    return format(new Date(data?.updated_at), "dd/MM/yyyy '-' HH:mm");
  };
  useEffect(() => {
    if (changedInstitution) {
      const inst = selectedInstitutions.map((item) => item.key);
      updatedFlashcard(null, null, inst, data?.id, null, false, false);
      setChangedInstitution(false);
    }
  }, [changedInstitution]);

  useEffect(() => {
    loadInstitutions();
  }, []);

  useEffect(() => {
    if (question === data?.question) return;
    setNewQuestion(question);
  }, [question]);

  useEffect(() => {
    if (answer === data?.answer) return;
    setNewAnswer(answer);
  }, [answer]);

  useEffect(() => {
    if (!tagData) return;
    setSelectedCfa(transformCfaOptions(tagData));
  }, [tagData, data?.id, data?.tag]);

  useEffect(() => {
    if (data?.institutions.length > 0 && institutions.length > 0) {
      const filteredInstitution = institutions.filter((item) =>
        data?.institutions.includes(item.key),
      );
      setSelectedInstitutions(filteredInstitution);
    } else {
      setSelectedInstitutions([]);
    }
  }, [data?.id, data?.institutions, institutions]);

  useEffect(() => {
    if (!containerRef) return;
    setSelectMaxWidth(`${containerRef.current.offsetWidth}px`);
  }, [containerRef, width]);

  useEffect(() => {
    if (!data?.tag_dict) return;

    setSelectedTag(data?.tag_dict.find((tag) => tag.tag_type === TAG_TYPE.FOCUS)?.id);
    const dataCfaId = data?.tag_dict.find((tag) => tag.tag_type == TAG_TYPE.CFA)?.id;
    if (dataCfaId) {
      return setSearchCfaId(dataCfaId);
    }
    setSelectedCfa(null);
  }, [data?.tag_dict]);

  return (
    <>
      <S.Wrap>
        <S.Container>
          {loading ? (
            <Loader />
          ) : (
            <>
              <S.Header>
                <S.LeftHeaderItems>
                  <S.NumberHeader>{number}</S.NumberHeader>
                  <S.IdLabel>ID: {data.id}</S.IdLabel>
                  {data?.status_error && (
                    <S.WarningChip>
                      <S.StyledWarningIcon />
                      Flashcard reportado
                    </S.WarningChip>
                  )}
                </S.LeftHeaderItems>
                <S.RightHeaderItems>
                  <S.ReportButton onClick={handleReport}>
                    <ReportIcon />
                    Histórico de report
                  </S.ReportButton>
                  <SwitchMaterialUI
                    checked={published}
                    onChange={handlePublished}
                    label="Publicado"
                  />
                  <S.DeleteButton onClick={() => setShowDeleteModal(true)}>
                    <S.StyledDeleteIcon />
                    Excluir
                  </S.DeleteButton>
                  <S.SaveButton onClick={() => handleSave()}>Salvar</S.SaveButton>
                </S.RightHeaderItems>
              </S.Header>
              <S.Content ref={containerRef}>
                <S.ContentContainer>
                  <S.ContentTitle>Questão</S.ContentTitle>
                  <TextEditorComponent
                    textHTML={data?.question}
                    onEdit={(text) => handleQuestion(text)}
                    config={{
                      toolbar: 'bold italic underline image',
                      menubar: false,
                      statusbar: false,
                    }}
                  />
                </S.ContentContainer>
                <S.ContentContainer>
                  <S.ContentTitle>Resposta</S.ContentTitle>
                  <TextEditorComponent
                    textHTML={data?.answer}
                    onEdit={(text) => handleAnswer(text)}
                    config={{
                      toolbar: 'bold italic underline image',
                      menubar: false,
                      statusbar: false,
                    }}
                    headerBgColor="#F0FCFB"
                    textAreaBgColor="#F0FCFB"
                  />
                </S.ContentContainer>
              </S.Content>
              <S.SelectContainer>
                <div style={{ width: selectMaxWidth }}>
                  <MultiselectAutocomplete
                    selectedOptions={selectedInstitutions}
                    setSelectedOptions={handleSelectedOptions}
                    label={institutionsLoading ? 'Carregando...' : 'Instituições'}
                    placeholder="Selecione"
                    options={institutions}
                    fetchSuggestions={filterInstitutions}
                    loading={institutionsLoading}
                    disabled={institutionsLoading}
                  />
                </div>
              </S.SelectContainer>
              <S.SelectContainer>
                <SelectWithPagination
                  loadOptions={(search, loadedOptions, page) =>
                    loadCfas(search, loadedOptions, page, { parent_tag: selectedTag })
                  }
                  text={false}
                  backgroundColor="#F2F2F2"
                  value={selectedCfa}
                  onChange={handleChangeCfa}
                  placeholder="Adicionar CFA"
                  menuPlacement="auto"
                  customNoOptionsMessage="Nenhuma encontrada"
                  singleValueStyle={{ ...selectValueContainerStyle, width: '95%' }}
                  valueContainerStyle={selectValueContainerStyle}
                  optionsStyle={SelectStyle}
                  controlStyle={{
                    ...SelectStyle,
                    width: '100%',
                    borderRadius: 8,
                    background: '#F1F2F2',
                    color: '#747678',
                  }}
                  placeholderStyle={{ color: '#d2d2d2', paddingLeft: 2 }}
                  height={48}
                  width={'100%'}
                  containerWidth={selectMaxWidth}
                />
              </S.SelectContainer>
            </>
          )}
        </S.Container>
        <S.UpdatedAtContainer>
          <S.UpdatedAtLabel>
            Última atualização: <b>{updatedAtDate()}</b>
          </S.UpdatedAtLabel>
        </S.UpdatedAtContainer>
      </S.Wrap>

      <ErrorReport showModal={showReportModal} handleClose={handleCloseReportModal} id={data?.id} />
      <ModalPublish
        showModal={showPublishModal}
        handleClose={handleClosePublishModal}
        removeStatus={removeStatus}
      />
      <ModalDelete
        showModal={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
      <ModalConfirmation
        title="Remover status de reportado"
        message="Eu estou ciente que resolvi o erro reportado, estou retirando a marcação de reportado e  publicando o flashcard para os alunos."
        actionText="Remover status"
        showModal={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleAction={handleRemoveReportedStatus}
      />
    </>
  );
}
