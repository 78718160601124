import { useQuery } from '@tanstack/react-query';

import { GetFlashcardsAPICMS } from '~/services/apiCallsCMS';

const getFlashcards = async (params) => {
  try {
    const response = await GetFlashcardsAPICMS.list(params);
    if (!response && !response.data && !response.data.results) {
      throw new Error('No results found');
    }

    return response.data.results;
  } catch (error) {
    throw error;
  }
};

export default function useFlashcards(params, options) {
  return useQuery({
    queryKey: ['flashcards', params],
    queryFn: () => getFlashcards(params),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
