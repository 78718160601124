import styled from 'styled-components';

export const SpinnerStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 60px;
    height: 60px;
    border: 5px solid #01b69f;
    border-top: 5px solid #f3f3f3;
    border-radius: 50%;
    animation: spinner 2.5s linear infinite;
  }
`;
