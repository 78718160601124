export function updateSearchExamRequest(data) {
  return {
    type: '@search/UPDATE_SEARCH_EXAM_REQUEST',
    payload: { data },
  };
}

export function updateFilterExamRequest(data) {
  return {
    type: '@search/UPDATE_FILTER_EXAM_REQUEST',
    payload: { data },
  };
}

export function updateFilterExamReviewRequest(data) {
  return {
    type: '@search/UPDATE_FILTER_EXAM_REVIEW_REQUEST',
    payload: { data },
  };
}

export function updateFilterNewsRequest(data) {
  return {
    type: '@search/UPDATE_FILTER_NEWS_REQUEST',
    payload: { data },
  };
}

export function updateFilterEventRequest(data) {
  return {
    type: '@search/UPDATE_FILTER_EVENT_REQUEST',
    payload: { data },
  };
}

export function updateSearchTrackRequest(data) {
  return {
    type: '@search/UPDATE_SEARCH_TRACK_REQUEST',
    payload: { data },
  };
}

export function updateSearchSubjectRequest(data) {
  return {
    type: '@search/UPDATE_SEARCH_SUBJECT_REQUEST',
    payload: { data },
  };
}

export function updateSearchGoalRequest(data) {
  return {
    type: '@search/UPDATE_SEARCH_GOAL_REQUEST',
    payload: { data },
  };
}

export function updateSearchModulesRequest(data) {
  return {
    type: '@search/UPDATE_SEARCH_MODULES_REQUEST',
    payload: { data },
  };
}
