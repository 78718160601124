import styled from 'styled-components';

export const VideoContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
  width: 438px;
  height: 253px;
  left: 30%;
  background: #ffffff;
  border: 2px dashed #e5e8ee;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
  border-radius: 8px;
  border: 2px dashed #3d5582;
`;

export const PlayerContainer = styled.div`
  width: 300px;
  height: 150px;
  padding-top: 8px;
  justify-content: center;
  align-items: center;
`;

export const FileName = styled.span`
  width: 210px;
  position: relative;
  top: 5px;
  right: 3px;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  align-items: start;
  color: #00205b;
  flex: none;
  order: 1;
  flex-grow: 1;
`;

export const VideoURL = styled(FileName)`
  font-weight: 400;
  right: 5px;
  top: -4px;
`;

export const FileSize = styled.span`
  padding-right: 80px;
  width: 30px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12.6px;
  position: relative;
  top: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3d5582;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const RemoveFileContainer = styled.div`
  width: 18px;
  height: 18px;
  flex: none;
  order: 0;
  left: 2px;
  flex-grow: 0;
  line-height: 17px;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const IconFileContainer = styled.div`
  width: 20px;
  height: 20px;
  flex: none;
  order: ;
  flex-grow: 0;
  position: relative;
`;

export const ContainerLogoRemove = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 10px;
  right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 10px;
  width: 35px;
  height: 35px;
  border: 2px solid #e5e8ee;
  border-radius: 1000px;
  flex: 1;
  order: 3;
  flex-grow: 0;
  :hover {
    border: 2px solid #3d5582;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  isolation: isolate;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 55px;
  gap: 16px;
  width: 388px;
  height: 33px;
  flex: 1;
  order: 1;
  flex-grow: 0;
`;

export const UrlIconContainer = styled(IconFileContainer)`
  right: 2px;
  height: 30px;
`;
