import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const LoaderContainer = styled.div`
  position: relative;
  width: 72px;
  height: 72px;
`;

export const Loader = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

  border: 2px solid #fff;
  border-top: 2px solid #D1D7E1;
  border-right: 2px solid #D1D7E1;
  border-bottom: 2px solid #D1D7E1;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
`
export const CenterIcon = styled.div`
  @keyframes pulse {
    0% {
      fill: rgba(1,182,159, 0.2);
    }
    25% {
      fill: rgba(1,182,159, 1);
    }
    50% {
      fill: rgba(1,182,159, 0.2);
    }
    75% {
      fill: rgba(1,182,159, 1);
    }
    100% {
      fill: rgba(1,182,159, 0.2);
    }
  }

  path {
    animation: pulse 2s linear infinite;
  };

  height: 24px;
  width: 24px;
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Text = styled.div`
  color: #00205B;
  font-size: 12.6px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.9px;
`