import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '~/components/Checkbox';
import { STATUS_OPTIONS } from '~/utils/variables';

import * as S from './styles';

function StatusCheckbox({ selectedItems, setSelectedItems }) {
  const handleChange = (key, checked) => {
    setSelectedItems({
      ...selectedItems,
      [key]: checked,
    });
  };

  return (
    <S.StatusCheckboxContainer>
      {Object.keys(STATUS_OPTIONS).map(function (key) {
        return (
          <FormControlLabel
            style={{ margin: '0' }}
            control={
              <Checkbox
                label=""
                checked={selectedItems[STATUS_OPTIONS[key].key]}
                onChange={(checked) => handleChange(STATUS_OPTIONS[key].key, checked)}
                name={STATUS_OPTIONS[key].key}
                id={`checkbox-${STATUS_OPTIONS[key].key}`}
                value={selectedItems[STATUS_OPTIONS[key].key]}
                styleContainer={{ margin: '5px 0 0', width: '20px' }}
                styleLabel={{ marginBottom: 0 }}
                checkboxOutineColor="#ccc"
              />
            }
            label={
              <S.StatusTag
                id={`label-${STATUS_OPTIONS[key].key}`}
                for={`checkbox-${STATUS_OPTIONS[key].key}`}
                color={STATUS_OPTIONS[key].color}
                backgroundColor={STATUS_OPTIONS[key].bgColor}
              >
                {STATUS_OPTIONS[key].label}
              </S.StatusTag>
            }
          />
        );
      })}
    </S.StatusCheckboxContainer>
  );
}

export default StatusCheckbox;
