import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
import { Mixpanel } from '~/services/analytics';
import QuestionItem from '~/components/QuestionItem';
import Loader from '~/components/Loader';
import * as S from './styles';
import { getSpecialityColor } from '~/utils/util';
import { loadMore, loadQuestions } from '../utils';

export default function QuestionsTab({ track, action }) {
  const [questions, setQuestions] = useState([]);
  const [next, setNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [allSpecialities, setAllSpecialities] = useState([]);

  async function onLoadMore() {
    if (!next || loading || loadingQuestions) {
      return;
    }
    setLoadingQuestions(true);
    const { questionsRaw, nextRaw } = await loadMore(next, questions);
    setNext(nextRaw);
    setQuestions(questionsRaw);
    setLoadingQuestions(false);
  }

  async function onLoadQuestions(trackId, cancelToken) {
    setLoading(true);
    setLoadingQuestions(true);
    const { questionsRaw, nextRaw } = await loadQuestions(
      trackId,
      'question_number',
      action,
      cancelToken,
    );

    setNext(nextRaw);
    setQuestions(questionsRaw);
    setLoading(false);
    setLoadingQuestions(false);
  }

  useEffect(() => {
    if (!track.id) return;
    const source = Axios.CancelToken.source();
    onLoadQuestions(track.id, source.token);
    Mixpanel.track("Clicou 'Tela da prova - Ações a realizar'", {
      'Nome da prova': track.name,
      Ação:
        action === 'text'
          ? 'Enunciar'
          : action === 'tags'
          ? 'Taguear'
          : action === 'comment' && 'Comentar',
    });
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    const cloneAllSpecialities = [];
    questions.forEach((question) => {
      if (!cloneAllSpecialities.includes(question.speciality_name[0])) {
        cloneAllSpecialities.push(question.speciality_name[0]);
      }
    });
    setAllSpecialities(cloneAllSpecialities);
  }, [questions]);

  function handleShowQuestion(question_number, question_id, speciality) {
    const path = `/exam/${track.id}/question/${question_number}/${question_id}/${action}`;
    const newWindow = window.open(path, '_blank', 'noopener,noreferrer');
    Mixpanel.track("Clicou 'Tela da prova - Editar questão'", {
      'Nome da prova': track.name,
      Questão: question_id,
      Especialidade: speciality,
      'Ação de origem':
        action === 'text'
          ? 'Enunciar'
          : action === 'tags'
          ? 'Taguear'
          : action === 'comment' && 'Comentar',
    });
    if (newWindow) newWindow.opener = null;
  }

  function statusQuestion(item) {
    return (
      (action === 'text' && item.has_content === true) ||
      (action === 'tags' && item.has_tags === true) ||
      (action === 'comment' && item.has_comments === true) ||
      (action === 'video' && item.has_comment_video)
    );
  }

  return (
    <S.Container>
      <S.MainContainer>
        {loading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Loader key="loader-questions-all" />
          </div>
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => onLoadMore()}
            hasMore={!!next}
            loader={<Loader key="loader-questions-all" />}
            useWindow={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {allSpecialities.map((speciality, index) => {
              return (
                <S.ContainerSpecialities key={index}>
                  <S.SpecialityTitle>{speciality || 'Sem Especialidade'}</S.SpecialityTitle>
                  <S.ContainerQuestions>
                    {questions.map((question) => {
                      if (question.speciality_name[0] === speciality) {
                        return (
                          <div key={question.id}>
                            <QuestionItem
                              action={action}
                              itemHeight={56}
                              itemWidth="100%"
                              question={question}
                              check={statusQuestion(question)}
                              onPressItem={() =>
                                handleShowQuestion(
                                  question.question_number,
                                  question.id,
                                  question.specialities,
                                )
                              }
                              colorClass={getSpecialityColor(question.specialities)}
                            />
                          </div>
                        );
                      }
                    })}
                  </S.ContainerQuestions>
                </S.ContainerSpecialities>
              );
            })}
          </InfiniteScroll>
        )}
      </S.MainContainer>
    </S.Container>
  );
}
