import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  padding: 16px 24px;
`;

export const ListOptions = styled.div`
  display: flex;
  gap: 8px;
`;
export const AddText = styled.p`
  width: 100px;
  height: 22px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-top: 18px;
  display: flex;
  align-items: center;
  color: #3d5582;
`;
export const ItemOptions = styled.div`
  padding: 8px 12px;
  background: #fff;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid ${(props) => props.text};
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;
  :hover {
    border: 3px solid ${(props) => props.text};
    background: ${(props) => props.text};
    color: #fff;
  }
  svg:hover {
    fill: #fff;
  }
`;

export const TextOptions = styled.div`
  color: ${(props) => props.text};
  font-size: 16px;
  font-weight: 600;
  line-height: 0px;
  :hover {
    color: #fff;
  }
`;
