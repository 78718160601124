import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  display: flex;
  padding: 0px 40px 24px 40px;
  width: 468px;
  max-height: 650px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--main-white, #fff);
  box-shadow: 0px 32px 64px 0px rgba(24, 26, 30, 0.08), 0px 8px 32px 0px rgba(24, 26, 30, 0.16);
`;

export const ModalDismissContainer = styled.div`
  display: flex;
  cursor: pointer;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 200px;
  top: 20px;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  color: var(--primary-accent, #00205b);
  font-size: 25.6px;
  font-style: normal;
  font-weight: 600;
  line-height: 34.2px; /* 133.594% */
  margin-bottom: 20px;
  margin-top: 10px;
`;

export const ModalAlert = styled.div`
  display: flex;
  margin-top: 20px;
  padding: 16px 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--complementary-blue-030, #b5cde9);
  color: var(--primary-070, #3d5582);
  font-size: 14.2px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.958px; /* 133.507% */
`;

export const ImportButton = styled.button`
  cursor: pointer;
  display: flex;
  height: var(--3-x, 48px);
  padding: 0px var(--125-x, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--075-x, 12px);
  color: var(--interaction-secondary-default, #01b69f);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px; /* 133.125% */
  border: none;
  background: #fff;
  :hover {
    background: #e5faf7;
    border-radius: var(--pill, 320px);
  }
`;

export const FooterModalContainer = styled.div`
  display: flex;
  width: 468px;
  padding: 16px 24px;
  gap: 24px;
  border-radius: 0px 0px 8px 8px;
  background: var(--main-white, #fff);
`;

export const ModalSubmitButton = styled.button`
  display: flex;
  height: var(--3-x, 48px);
  padding: 0px var(--125-x, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--075-x, 12px);
  flex: 1 0 0;
  border-radius: var(--pill, 320px);
  background: ${(props) => (props.disabled ? '#f0f2f5' : '#01B69F')};
  color: ${(props) => (props.disabled ? '#d1d7e1' : '#fff')};
  text-align: center;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px; /* 133.125% */
`;
