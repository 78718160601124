import React from 'react';

import { GoEye } from 'react-icons/go';
import Checkbox from '~/components/Checkbox';

import * as S from './styles';

function FlashcardItem({ flashcard, selectedItems, setSelectedItems }) {
  const handleCheck = (id) => {
    if (selectedItems.includes(id)) {
      const selectedWithRemovedId = selectedItems.filter((item) => item !== id);

      setSelectedItems(selectedWithRemovedId);
      return;
    }

    setSelectedItems(() => [...selectedItems, id]);
    return;
  };

  const openFlashcard = (id) => {
    window.open(`${process.env.REACT_APP_URL}/flashcards-decks/edit?id=${id}`, '_blank');
  };

  return (
    <S.ItemContainer>
      <S.ItemHeader>
        <Checkbox
          id={flashcard.id}
          label=""
          checked={selectedItems.includes(flashcard.id)}
          onChange={() => handleCheck(flashcard.id)}
          value={flashcard.id}
          styleContainer={{ margin: '5px 0 0', width: '21px' }}
          styleLabel={{ marginBottom: 0 }}
          checkboxOutineColor="#ccc"
        />
        <S.ItemLabel onClick={() => handleCheck(flashcard.id)}>
          {`[${flashcard.id}] - ${flashcard.title}`}
        </S.ItemLabel>
        <S.SeeItem onClick={() => openFlashcard(flashcard.id)}>
          <GoEye />
          {'Visualizar'}
        </S.SeeItem>
      </S.ItemHeader>
    </S.ItemContainer>
  );
}

export default FlashcardItem;
