import { Institution } from '~/services/apiCalls/institution';
import { InstitutionAPICMS } from '~/services/apiCallsCMS';

export const transformOptions = (value) => {
  if (!value.id || !value.name) return;

  return {
    label: value.name,
    key: value.id,
    value: value.id,
  };
};

export const getInstitutions = async (institutionsIds) => {
  if (institutionsIds.length > 0) {
    const optionsInstitutions = [];
    await Promise.all(
      institutionsIds.map(async (institutionId) => {
        const { data } = await InstitutionAPICMS.getById(institutionId);
        optionsInstitutions.push(transformOptions(data));
      }),
    );
    return optionsInstitutions;
  }
};

export const loadInstitutions = async (search, loadedOptions, { page }) => {
  const { data } = await Institution.list({
    page,
    search,
    ordering: 'name',
    residency: true,
  });

  return {
    options: data.results.map(transformOptions),
    hasMore: data.next,
    additional: {
      page: page + 1,
    },
  };
};

export const selectWithPaginationStyles = {
  placeholderStyle: {
    display: 'flex',
  },
  valueContainerStyle: {
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap',
  },
  singleValueStyle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
