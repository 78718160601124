import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 24px;
  display: flex;
  gap: 24px;
  align-items: center;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
`;

export const Label = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.3px;
  color: #00205b;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Button = styled.button`
  display: flex;
  gap: 10px;
  padding: 0 16px;
  height: 40px;
  border-radius: 320px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #e2e5ee;
  &:hover {
    border: 2px solid #b7bed7;
  }
`;

export const ButtonText = styled.p`
  margin: 0;
  font-size: 14.2px;
  font-weight: 600;
  line-height: normal;
  color: #077859;
`;
