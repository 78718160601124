import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
  .total {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
