import React from 'react';
import NoCommentIcon from '~/assets/no-comment.svg';
import AddCommntIcon from '~/assets/add-comment-icon.svg';
import { Button } from '../QuestionComment/styles';
import * as S from './styles';

export default function EmptyComment({ action }) {
  return (
    <S.EmptyCommentContainer>
    <S.EmptyComment>
      <S.EmptyCommentIconContaner>
        <S.EmptyCommentIcon src={NoCommentIcon} alt="No comment icon" />  
      </S.EmptyCommentIconContaner>
      <S.EmptyCommentText>
        <S.EmptyCommentTitle>
            Nenhum comentário encontrado
        </S.EmptyCommentTitle>
        <S.EmptyCommentSubtitle>
            Crie um novo comentário clicando no botão abaixo ou gere comentários via IA
        </S.EmptyCommentSubtitle>
        <Button onClick={action}> 
          <img src={AddCommntIcon} alt="Icone de adicionar comentário"/> Criar comentário 
        </Button>
      </S.EmptyCommentText>
    </S.EmptyComment>
  </S.EmptyCommentContainer>
  );
}