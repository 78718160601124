/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Drawer from '@mui/material/Drawer';

import Loader from '~/components/Loader';
import OffCanvas from '~/components/OffCanvas';
import TextEditorComponent from '~/components/TextEditorComponent';

import { LessonDocumentAPICMS } from '~/services/apiCallsCMS';
import { useLessonDocument } from '~/hooks-querys/lessonDocument';
import Axios from 'axios';

import * as S from './styles';

export default function DocumentOffCanvas({ show, close, id }) {
  const [name, setName] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [description, setDescription] = useState(
    'Fala, galera! Segue a ficha-resumo da aula de hoje. PRA CIMA!',
  );
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('Documento de aula');

  const [fileCover, setFileCover] = useState(null);
  const [fileNameCover, setFileNameCover] = useState('Imagem de capa');

  const { data: lessonDocument, isFetching: fetchingLessonDocument } = useLessonDocument(id, {
    config: {
      enabled: !!id && firstLoad,
    },
  });

  function handleFile(event, cover) {
    if (!event.target?.files || event.target?.files.length === 0) {
      return;
    }
    const file = event.target?.files[0];
    if (cover) {
      setFileCover(file);
      setFileNameCover(file.name);
    } else {
      setFile(file);
      setFileName(file.name);
    }
  }

  function save() {
    if (!name) {
      toast.error('Nome é obrigatório');
      return;
    }

    if (!description) {
      toast.error('Descrição é obrigatória');
      return;
    }

    if (!lessonDocument) {
      if (!file) {
        toast.error('Documento é obrigatório');
        return;
      }

      if (!fileCover) {
        toast.error('Imagem de capa é obrigatória');
        return;
      }
    }

    const data = {
      description: description,
      name: name,
    };

    const source = Axios.CancelToken.source();

    if (lessonDocument) {
      LessonDocumentAPICMS.update(lessonDocument.id, data, source.token)
        .then(async (response) => {
          if (fileCover) {
            const result = await LessonDocumentAPICMS.updateFile(response.data.id, {
              cover_image: fileCover,
            });
          }

          if (file) {
            const result = await LessonDocumentAPICMS.updateFile(response.data.id, {
              document: file,
            });
          }

          toast.success('Documento de aula atualizado com sucesso');
          close(response.data.id);
        })
        .catch(function () {
          toast.error('Erro na atualização do documento de aula');
        });
    } else {
      LessonDocumentAPICMS.create(data, source.token)
        .then(async (response) => {
          const result = await LessonDocumentAPICMS.updateFile(response.data.id, {
            cover_image: fileCover,
            document: file,
          });
          toast.success('Documento de aula criado com sucesso');
          close(response.data.id);
        })
        .catch(function () {
          toast.error('Erro na criação do documento de aula');
        });
    }
  }

  useEffect(() => {
    if (lessonDocument) {
      setFirstLoad(false);
      setName(lessonDocument.name);
      setDescription(lessonDocument.description);
      setFileName(lessonDocument.document?.split('lesson/')[1].split('?')[0].substring(0, 70));
      setFileNameCover(
        lessonDocument.cover_image?.split('lesson/')[1].split('?')[0].substring(0, 70),
      );
    }
  }, [lessonDocument]);

  function renderContent() {
    return (
      <>
        {fetchingLessonDocument ? (
          <Loader text="Carregando..." />
        ) : (
          <S.Content>
            <S.StepContainer>
              <S.Step>
                <S.StepOder>1</S.StepOder>
                <S.StepText>Configuração</S.StepText>
              </S.Step>
              <S.RowContainer>
                <S.Label>Nome</S.Label>
                <S.InputStyle>
                  <input
                    id="name"
                    type="text"
                    placeholder="Nome do documento"
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    value={name}
                  />
                </S.InputStyle>
              </S.RowContainer>
              <S.RowContainer>
                <S.Label>Descrição</S.Label>
                <TextEditorComponent
                  textHTML={description}
                  onEdit={(text) => setDescription(text)}
                  simplify
                />
              </S.RowContainer>
              <S.RowContainer>
                <S.Label>Imagem de capa</S.Label>
                <S.Label>
                  <form>
                    <label htmlFor="cover">
                      <input
                        id="cover"
                        type="file"
                        accept="image/*"
                        onChange={(event) => handleFile(event, true)}
                        style={{ display: 'none' }}
                      />
                      <S.CustomInputFile>
                        <S.FileName>{fileNameCover}</S.FileName>
                        <S.ButtonSelectFile type="button">Selecionar</S.ButtonSelectFile>
                      </S.CustomInputFile>
                    </label>
                  </form>
                </S.Label>
              </S.RowContainer>
              <S.RowContainer>
                <S.Label>Documento</S.Label>
                <S.Label>
                  <form>
                    <label htmlFor="file">
                      <input
                        id="file"
                        type="file"
                        accept="pdf"
                        onChange={(event) => handleFile(event, false)}
                        style={{ display: 'none' }}
                      />
                      <S.CustomInputFile>
                        <S.FileName>{fileName}</S.FileName>
                        <S.ButtonSelectFile type="button">Selecionar</S.ButtonSelectFile>
                      </S.CustomInputFile>
                    </label>
                  </form>
                </S.Label>
              </S.RowContainer>
            </S.StepContainer>
          </S.Content>
        )}
      </>
    );
  }

  return (
    <OffCanvas
      show={show}
      close={close}
      name="Adicionar documento de aula"
      children={renderContent()}
      actionAdd={save}
    ></OffCanvas>
  );
}
