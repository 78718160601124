import { useQuery } from '@tanstack/react-query';

import { FlashcardDeckAPICMS } from '~/services/apiCallsCMS';

const getFlashcardDeck = async (params) => {
  try {
    const response = await FlashcardDeckAPICMS.list(params);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useGetFlashcardDeck(params, options) {
  return useQuery({
    queryKey: ['getFlashcardDeck', params],
    queryFn: () => getFlashcardDeck(params),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
