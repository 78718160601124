import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(24, 26, 30, 0.16), 0px 8px 16px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  min-width: ${(props) => props.minWidth || '240px'};
`;
