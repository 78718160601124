import React, { useState } from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import * as S from './styles';

function ButtonTextWithPopper({ title, icon, children }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = anchorEl;
  const id = open ? 'button-with-popper' : undefined;

  const handleClick = (event) => {
    if (!anchorEl) {
      return setAnchorEl(event.currentTarget);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <S.DropdownButton onClick={handleClick}>
          <S.DropdownButtonText>{title}</S.DropdownButtonText> {icon}
        </S.DropdownButton>

        <Popper
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ maxHeight: ' calc(100vh - 228px)', zIndex: 1190 }}
        >
          {children}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

export default ButtonTextWithPopper;
