import produce from 'immer';

const INITIAL_STATE = {
    selectedTrack: null,
};

export default function track(state = INITIAL_STATE, action = {}) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@tracks/SET_TRACK': {
        draft.selectedTrack = action.payload;
        break;
      }

      case '@tracks/RESET_TRACK': {
        draft.selectedTrack = state;
        break;
      }

      default:
        return state;
    }
  });
}

