import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import chapter from './chapter/sagas';
import track from './track/sagas';
import endpoints from './cmsEndpoints/sagas';

export default function* rootSaga() {
  return yield all([auth, user, endpoints, chapter, track]);
}
