import mixpanel from 'mixpanel-browser';

export class Mixpanel {
  static track(name, props) {
    mixpanel.track(name, props);
  }

  static peopleSet(props) {
    mixpanel.people.set(props);
  }
  
  static time_event(name) {
    mixpanel.time_event(name);
  }

  static setUser(user) {
    mixpanel.identify(user.id);
    this.peopleSet({
      $name: user.name || '',
      $email: user.email,
      'ID do drops': user.id,
      'ID do usuário na plataforma': user.platform_user_id,
      Perfil: user.user_type,
    });
  }

  static setup(user) {
    if (user) {
      mixpanel.register({
        Usuário: `[${user.id}] ${user.name}`,
      });
      mixpanel.opt_in_tracking();
      this.setUser(user);
    }
  }
}
