import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function Checkbox({ checked, label, onChange }) {
  return (
    <Styled onClick={() => onChange(!checked)}>
      <input
        type="checkbox"
        name={label}
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      <label htmlFor={label}>{label}</label>
    </Styled>
  );
}
Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

Checkbox.defaultProps = {
  label: 'Checkbox',
  onChange: () => {},
  checked: false,
};
export default Checkbox;

const Styled = styled.div`
  display: flex;
  flex-direction: row;
  > input {
    opacity: 0;
    position: absolute;
  }
  > input + label {
    position: relative;
    padding-left: 35px;
    height: 15px;
    color: #767676;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 15px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      width: 21px;
      height: 21px;
      border: 1px solid #eee;
      background: #eee;
      border-radius: 5px;
    }
    &:after {
      content: '✔';
      position: absolute;
      left: 0;
      top: 1px;
      padding-top: 1px;
      width: 21px;
      height: 18px;
      font-size: 12px;
      color: #fff;
      border: 1px solid #3b3fb6;
      border-radius: 5px;
      background: #3b3fb6;
      transition: all 0.2s;
    }
  }
  label {
    padding-top: 5px;
  }
  > input:not(:checked) + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }
  > input:disabled:not(:checked) + label {
    &:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #ddd;
    }
  }
  > input:checked + label {
    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
  > input:disabled:checked + label {
    &:after {
      color: #999;
    }
  }
  > input:disabled + label {
    color: #aaa;
  }
  > input:checked:focus + label,
  input:not(:checked):focus + label {
    &:before {
      border: 1px dotted blue;
    }
  }
`;
