import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
  &:hover .tooltip-container {
    display: block;
  }
  .triangulo-para-direita {
    width: 0;
    height: 0;
    position: absolute;
    top: 23px;
    right: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #181a1ecc;
  }
  .tooltip-container {
    background: #181a1ecc;
    color: #fff;
    padding: 12px 16px;
    border-radius: 8px;
    width: 145px;
    position: absolute;
    left: -127px;
    top: 4px;
    font-size: 14px;
    font-weight: 600;
    display: none;
    transition: 0.3s;
  }
  .container {
    cursor: grab;
    width: 100%;
    display: flex;
    border: 2px solid #d1d7e1;
    border-radius: 8px;
    padding: 16px 28px;
    align-items: center;
    justify-content: space-between;
    transitions: 0.3s;
    :hover {
      box-shadow: 0px 0px 0px 6px ${(props) => props.shadow}20;
      border: 2px solid ${(props) => props.shadow};
    }
    .infos1 {
      display: flex;
      gap: 40px;
      align-items: center;
    }
    .infosIndex {
      display: flex;
      gap: 25px;
      align-items: center;
      width: 400px;
    }
    .item-remove {
      width: 105px;
    }
    .infos2 {
      display: flex;
      gap: 10px;
      align-items: center;
      color: #00205B;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
    }

      .remove {
        color: #01c3aa;
        font-weight: 600;
        cursor: pointer;
      }
      .remove:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const OrderOption = styled.span`
  color: #3d5582;
  font-size: 16px;
  font-weight: 600;
`;

export const NameOption = styled.span`
  color: ${(props) => props.colors.text};
  font-weight: 600;
  font-size: 16px;
`;

export const QuestionOption = styled.span`
  font-weight: 700;
  font-size: 11px;
  color: #747678;
`;

export const ValueOption = styled.input`
  border: 1px solid #cacccf;
  border-radius: 10px;
  width: 88px;
  padding: 8px 10px;
  background: #f0f2f5;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14.2px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #00205B;
  :focus-within {
    box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
    background-color: #fff;
    border: 3px solid #01cfb5;
`;
export const ButtonUp = styled.div`
  width: 14px;
  height: 14px;
`;

export const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  gap: 4px;
  width: 65px;
  height: 24px;
  background: ${(props) => props.color};
  border-radius: 1000px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const RangeText = styled.span`
  width: 36px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
`;

export const LbRange = styled.span`
  color: '#00205B';
`;
