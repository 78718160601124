import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { subscribe } from 'redux-subscriber';

import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';

import { store } from '~/store';
import { signOut } from '~/store/modules/auth/actions';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  routePermissions,
  ...rest
}) {
  const { signed, keepConnected, permissions } = store.getState().auth;
  const location = useLocation();
  const session = !!sessionStorage.getItem('email');
  const logged = (signed && keepConnected) || (signed && !keepConnected && session);
  const isSignUp = location.pathname === '/signup/';

  const hasPermission =
    !routePermissions ||
    permissions.some((permission: string) => routePermissions?.includes(permission));

  const unsubscribe = subscribe('auth', (state) => {
    if (!state.auth.signed) {
      unsubscribe();
      sessionStorage.removeItem('email');
      return <Redirect to="/" />;
    }
  });

  if (!signed && !isSignUp) {
    if (location.pathname !== '/') {
      window.localStorage.setItem('redirect_login', location.pathname);
    }
  }

  if (signed && isSignUp) {
    store.dispatch(signOut());
    return <Redirect to={`${location.pathname}${location.search}`} />;
  }

  if (!signed && isPrivate) {
    unsubscribe();
    return <Redirect to="/" />;
  }

  if (signed && !keepConnected && !session && isPrivate) {
    unsubscribe();
    return <Redirect to="/" />;
  }

  if (signed && keepConnected && (!isPrivate || !hasPermission)) {
    return <Redirect to="/dashboard" />;
  }

  if (signed && !keepConnected && session && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  const Layout = logged ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} title />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
