import React from 'react';
import CardComponent from '~/components/CardComponent';
import ProgressBar from '~/components/ProgressBar';
import SettingsIcon from '~/assets/drops-settings.svg';

import qs from 'qs';

import history from '~/services/history';
import { Mixpanel } from '~/services/analytics';

import * as S from './styles';

function ItemCard({
  title,
  item,
  speciality,
  parentTag,
  residencyDegree,
  searchText,
  searchId,
  institutions,
  published,
  pending,
  reported,
  getHeaderData,
}) {
  const calcPercentage = (value) => {
    if (item.total_flashcard === 0) {
      return 0;
    }
    return Math.floor((100 * value) / item.total_flashcard);
  };

  const handleEdit = () => {
    let status = null;
    if (published || pending) {
      status = !!published;
    }
    Mixpanel.track("Clicou 'Busca de flashcards - editar flashcards'", {});
    const timestamp = Date.now();
    const queryParams = {
      speciality: speciality,
      parentTag: parentTag,
      tag: item.id,
      residencyDegree: residencyDegree,
      search: searchText,
      institutions: institutions,
      enabled: status,
      reported: reported,
      id: searchId,
    };

    const queryString = qs.stringify(queryParams);

    window.open(`${process.env.REACT_APP_URL}/flashcards/edit?${queryString}`, '_blank');
  };

  const mock = [
    {
      label: 'Publicados',
      count: item.qtd_published,
      percentage: calcPercentage(item.qtd_published),
    },
    {
      label: 'Pendentes',
      count: item.qtd_pending,
      percentage: calcPercentage(item.qtd_pending),
    },
    {
      label: 'Reportados',
      count: item.qtd_report_error,
      percentage: calcPercentage(item.qtd_report_error),
    },
  ];

  return (
    <CardComponent
      type="default"
      background="#fff"
      style={{
        width: '100%',
        display: 'flex',
        marginBottom: 20,
        borderRadius: '8px',
      }}
    >
      <S.ViewCol>
        <S.RowCard>
          <S.ViewCol>
            <S.ContainerTitle>
              <S.ItemTitle>{item.name}</S.ItemTitle>
              <S.Chip>{item.residency_degree}</S.Chip>
            </S.ContainerTitle>
            <S.ItemSubtitle>
              <strong>{item.total_flashcard}</strong>{' '}
              {item.total_flashcard > 1 ? 'flashcard' : 'flashcards'}
            </S.ItemSubtitle>
          </S.ViewCol>
          <S.DivRow>
            <S.EditButton onClick={handleEdit}>
              <img src={SettingsIcon} alt="settings icon" /> Editar
            </S.EditButton>
          </S.DivRow>
        </S.RowCard>
        <S.Divisor />

        <S.RowCard style={{ flexWrap: 'wrap' }}>
          {mock.map((info, index) => {
            let color = { filled: '', unfilled: '', chipBackground: '', chipColor: '' };
            switch (info.label) {
              case 'Publicados':
                color.filled = '#01CFB5';
                color.unfilled = '#D1F6F2';
                color.chipBackground = '#E5FAF7';
                color.chipColor = '#01C3AA';
                break;
              case 'Pendentes':
                color.filled = '#00205B';
                color.unfilled = '#E5E8EE';
                color.chipBackground = '#E5E8EE';
                color.chipColor = '#00205B';
                break;
              case 'Reportados':
                color.filled = '#F3C404';
                color.unfilled = '#FDF6D7';
                color.chipBackground = '#FDF6D7';
                color.chipColor = '#F3C404';
                break;
              default:
            }
            return (
              <S.CardProgress key={index}>
                <S.ChipCount background={color.chipBackground} color={color.chipColor}>
                  {info.count}
                </S.ChipCount>
                <S.ViewCol style={{ gap: 6 }}>
                  <S.ItemSubtitle>{info.label}</S.ItemSubtitle>
                  <ProgressBar
                    unfilledColor={color.unfilled}
                    color={color.filled}
                    percentage={info.percentage}
                    height={4}
                    style={{ minWidth: '140px' }}
                  />
                </S.ViewCol>
              </S.CardProgress>
            );
          })}
        </S.RowCard>
      </S.ViewCol>
    </CardComponent>
  );
}

export default ItemCard;
