import { useQuery } from '@tanstack/react-query';

import { LessonSubjectAPICMS } from '~/services/apiCallsCMS';

const getLessonSubjectList = async (params) => {
  const response = await LessonSubjectAPICMS.list(params);
  return response.data;
};

export default function useLessonSubject(params, options) {
  return useQuery({
    queryKey: ['lessonSubjectList', params],
    queryFn: () => getLessonSubjectList(params),
    staleTime: 0,
    ...options?.config,
  });
}
