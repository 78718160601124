import React from 'react'
import {
  Container,
  // ColorBar,
  // ItemColorBar
} from './styles';
// import { optionsSpecialtiesMock } from '../mock'

const TotalQuestions = ({ totalQuestionsSelecteds }) => {
  return (
    <Container>
      <p className='title'>Total de questões</p> 
      <div className='container-total-questions'>
        <span className='text'>{totalQuestionsSelecteds}</span>
      </div>
      {/* <ColorBar style={{ display: 'flex' }}> 
        { optionsSpecialtiesMock.map(item => {
          return <ItemColorBar style={{ background: item.colors.text }} />
        }) }
      </ColorBar> */}
    </Container>
  )
}

export default TotalQuestions