import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as ArrowLeft } from '~/assets/drops-arrow-left.svg';

export const ModalWrap = styled.div`
  position: relative;
  background-color: white;
  width: 100%;
  max-width: 800px;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ModalTitle = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 22.8px;
  line-height: 30.4px;
  color: #00205b;
`;

export const ModalHeader = styled.div`
  padding: 32px 32px 24px;
  border-bottom: 1px solid #e5e8ee;
  width: 100%;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 26px;
  right: 26px;
  fill: #acacae;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const ModalContent = styled.div`
  padding: 32px 56px;
  border-bottom: 1px solid #e5e8ee;
  width: 100%;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 24px;
  width: 100%;
  justify-content: space-evenly;
`;

export const FillButton = styled.button`
  display: flex;
  border-radius: 320px;
  background: #0caa87;
  height: 40px;
  width: 100%;
  padding: 10px 16px;

  justify-content: center;
  align-items: center;
  color: #fff;

  font-size: 14px;
  font-weight: 600;
  border: none;
  gap: 10px;

  :hover {
    background: #01cfb5;
  }

  :disabled {
    color: #8f9db7;
    background: #e5e8ee;
    cursor: default;
  }
`;

export const BackButton = styled.button`
  padding: 10px 16px;
  gap: 10px;
  display: flex;
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #077859;
  background: #fff;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 320px;

  :hover {
    background: #daf5f1;
  }
`;

export const StyledArrowLeft = styled(ArrowLeft)`
  path {
    fill: #077859;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .fieldHeight {
    height: 20px;
  }
`;

export const ItemTitle = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;
`;
