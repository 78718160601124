import React from 'react';
import ModalWithHeader from '~/components/ModalWithHeader';
import {
  ModalContent,
  ModalText,
  ModalFooter,
  BackButton,
  FillButton,
  StyledArrowLeft,
} from '../../styles';

export default function ModalPublish(
  {
    showModal,
    handleClose,
    removeStatus,
  }) {
  return (
    <ModalWithHeader
      show={showModal}
      close={handleClose}
      title="Remover status de reportado"
    >
      <ModalContent>
        <ModalText>
          Eu estou ciente que estou retirando a marcação de reportado e estou publicando o flashcard para os alunos.
        </ModalText>
      </ModalContent>
      <ModalFooter>
        <BackButton onClick={handleClose}>
          <StyledArrowLeft />
          Voltar
        </BackButton>
        <FillButton onClick={removeStatus}>
          Remover status
        </FillButton>
      </ModalFooter>
    </ModalWithHeader>
  );
}


