import styled from 'styled-components';

export const CardModal = styled.div`
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  max-width: 808px;
  box-shadow:
    0px 0px 2px rgba(24, 26, 30, 0.16),
    0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  align-items: center;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Header = styled.div`
  flex-direction: column;
  width: 100%;
  padding: 36px 56px 24px;

  color: #00205b;
  font-size: 25.6px;
  font-style: normal;
  font-weight: 600;
  line-height: 34.2px;
  border-bottom: 1px solid #e5e8ee;
`;

export const Footer = styled.div`
  flex-direction: column;
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid #e5e8ee;
`;

export const ButtonAdd = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 16px;
  background: #019d8a;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    background: #088968;
  }
  text-align: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  padding: 24px 40px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  overflow-y: auto;
  max-height: 60vh;
`;

export const Label = styled.div`
  align-self: stretch;
  color: #00205b;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const RowContainer = styled.div`
  padding: 20px 0px 40px;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 40px;
  width: 100%;
  gap: 24px;
`;

export const ContainerSelects = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 21px;
`;

export const InputStyle = styled.div`
  width: 100%;
  height: 48px;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  :hover {
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    background-color: #fff;
    border: 3px solid #3d5582;
  }
  :focus-within {
    box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
    background-color: #fff;
    border: 3px solid #01cfb5;
  }
  input {
    width: 100%;
    height: 30px;
    border: none;
    background: none;
    color: #3d5582;
    font-size: 16px;

    ::placeholder {
      color: #3d5582;
      font-weight: 400;
    }
    ::-webkit-inner-spin-button {
      color: red;
    }
  }
`;

export const CustomInputFile = styled.div`
  display: flex;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  :hover {
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    background-color: #fff;
    border: 3px solid #3d5582;
  }
  :active {
    box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
    background-color: #fff;
    border: 3px solid #01cfb5;
    color: #01cfb5;
  }
`;

export const FileName = styled.div`
  background: #f0f2f5;
  border: transparent;
  border-radius: 6px 0 0 6px;
  height: 48px;
  width: 100%;
  padding-inline: 20px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #3d5582;
  :hover {
    background-color: #fff;
  }
`;

export const ButtonSelectFile = styled.div`
  border-left: 2px solid #e5e8ee;
  border-radius: 0 8px 8px 0;
  width: 127px;
  height: 48px;
  padding: 13px 20px;
  background-color: #fff;
  font-size: 16px;
`;

export const SubLabel = styled.div`
  color: #4f5154;
  font-size: 14.2px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.958px;
  width: 100%;
  padding-top: 4px;
`;
