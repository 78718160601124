import styled from 'styled-components';

export const Logo = styled.img`
  @media (max-height: 550px) {
    display: none;
  }
  width: 300px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
`;

export const InputDiv = styled.div`
  position: relative;

  input {
    width: 100%;
  }

  svg {
    color: #808080;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 34px;
    cursor: pointer;
  }

  span{
    width: 100%;
    color: #dc3545;
    display: block;
    text-align: left;
    line-height: 8px;
    margin-bottom: 8px;
  }
`;

export const RememberDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
`;

export const Separator = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 3px;
  background-color: #f5f5fb;
`;

export const SupportText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block: 15px;
  gap: 15px;

  span {
    color: #00205b;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
  }
`;
