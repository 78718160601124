import styled from 'styled-components';

const FlexRow = styled.div`
  display: flex;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled(FlexRow)`
  width: 100%;
  gap: 16px;
  background: #fff;
  padding: 16px 20px;
  max-width: 256px; //remover
  align-items: center;
  position: relative;
  border-bottom: 1px solid #D1D7E1;
  box-shadow: 0px 2px 4px 0px #181A1E14, 0px 0px 2px 0px #181A1E29;

  ${(props) => (props.selected && `
  &:after {
    content: "";
    height: 86%;
    width: 3px;
    position: absolute;
    top: 7%;
    left: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: #01C3AA
  }`)};
`;

export const Controller = styled(FlexColumn)`
  gap: 8px;
  align-items: center;
`;

export const NumberContainer = styled(FlexRow)`
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: ${(props) => (props.selected ? '2px solid #8F9DB7' : '2px solid transparent')};
  box-shadow: ${(props) => (props.selected ? '0px 0px 0px 4px #3D558233' : 'none')};
  font-size: 12.6px;
  font-weight: 700;
  color: #3D5582;
`;

export const CardContent = styled(FlexColumn)`
  width: 100%;
  min-height: 82px;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  border: ${(props) => (props.selected ? '2px solid #8F9DB7' : '1px solid #D1D7E1')};
  box-shadow: ${(props) => (props.selected ? '0px 0px 0px 4px #3D558233' : 'none')};
`;

export const Title = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #D1D7E1;
  padding: 12px 20px;
  height: 41px;
`;

export const Response = styled.div`
  background: #F0FCFB;
  padding: 12px 20px;
  height: 41px;
`;

export const IconContainer = styled.div`
  display: inline-block;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  color: #4F5154;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`