import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function DatePickerComponent({
  label,
  value,
  time,
  handleChange,
  small,
  fullWidth,
}) {
  const props = {
    ...(small && {
      '& .MuiInputBase-root': {
        height: '40px',
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 11px) scale(1)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    }),
    ...(fullWidth && { width: '100%' }),
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      {time ? (
        <DateTimePicker
          label={label}
          onChange={(newValue) => handleChange(newValue)}
          value={value && dayjs(value)}
          sx={props}
        />
      ) : (
        <DatePicker
          label={label}
          onChange={(newValue) => handleChange(newValue)}
          value={value && dayjs(value)}
          sx={props}
        />
      )}
    </LocalizationProvider>
  );
}
