import styled from 'styled-components';
import { ReactComponent as ExternalLinkComponent } from '~/assets/external-link.svg';
import { ReactComponent as ThrashIcon } from '~/assets/drops-removefile-logo.svg';

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
`;
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  background: #ffffff;
  padding: 16px 24px;
  border-radius: 8px;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 4px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const CardTitle = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #00205b;
`;

export const OrderDescription = styled.p`
  margin: 0;
  display: inline-flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2a2c30;
  gap: 4px;
`;

export const CardContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const TableHeader = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: flex-end;
`;

export const TableHeaderTitle = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #00205b;
  width: ${(props) => props.width || '100px'};
  text-align: center;
`;

export const TableItem = styled.div`
  display: flex;
  border-top: 1px solid #e5e8ee;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`;

export const TableTitle = styled.h1`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #00205b;
  margin: 0 0 0 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const TableText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(props) => (props.errorColor ? '#EE1F38' : '#2a2c30')};
  width: ${(props) => props.width || '100px'};
`;

export const TableRightInformations = styled.div`
  display: flex;
`;

export const IconContainer = styled.button`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const EmptySpace = styled.div`
  visibility: hidden;
  width: 80px;
  height: 1px;
`;

export const OrderButtonsContainer = styled.div`
  margin-right: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const PrimaryButton = styled.button`
  display: flex;
  height: 32px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 320px;
  background: #019d8a;
  color: #fff;
  border: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;

  &:hover {
    background: #01b69f;
  }
`;

export const StyledExternalLinkIcon = styled(ExternalLinkComponent)`
  width: 16px;
  height: 16px;
  path {
    fill: #fff;
  }
`;

export const SecondaryButton = styled.button`
  display: flex;
  min-height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 2px solid transparent;
  border-radius: 320px;
  color: #019d8a;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  background: #fff;

  &:hover {
    border: 2px solid #d1d7e1;
  }
`;

export const StyledThrashIcon = styled(ThrashIcon)`
  width: 16px;
  height: 16px;
  path {
    fill: #019d8a;
  }
`;
