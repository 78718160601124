import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiFilterAlt } from 'react-icons/bi';

import {
  useFilterSpecialitys,
  useFilterInstitutionStates,
  useFilterCourse,
} from '~/hooks-querys/filters';

import SelectWithPagination from '~/components/SelectWithPagination';
import SelectComponentMaterialUi from '~/components/SelectComponentMaterialUi';
import SearchInputMaterialUi from '~/components/SearchInputMaterialUi';
import MultiselectAutocomplete from '~/components/MultiselectAutocomplete';
import { FILTERS_INITIAL_STATE } from '~/store/modules/questionFilters/reducer';
import {
  saveShowMoreFilters,
  saveInstitutions,
  saveCourses,
} from '~/store/modules/questionFilters/actions';
import {
  yesOrNo,
  invertedYesOrNo,
  questionDifficulty,
  questionType,
  demandType,
  usedInChallenge,
  explanationLastUpdate,
  isNew,
} from './mocks';

import { deepEqual, checkIfArrayOfObjectsContainsAnother } from '~/utils/util';
import { loadTags } from '~/pages/Chapters/components/Settings/utils';
import * as S from './styles';
import { Title } from '../styles';
import { Mixpanel } from '~/services/analytics';
import { Institution } from '~/services/apiCalls/institution';
import { useResidencyDegree } from '~/hooks-querys/residencydegree';

function Filters({ handleFilter, tagValue, setTagValue }) {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [filters, setFilters] = useState({
    ...FILTERS_INITIAL_STATE,
    ...useSelector((state) => state.questionFilters.questions_filters),
  });
  const [moreFilters, setMoreFilters] = useState(
    useSelector((state) => state.questionFilters.show_more_filters) || false,
  );
  const [specialitys, setSpecialitys] = useState([]);
  const [institutions, setInstitutions] = useState(
    useSelector((state) => state.questionFilters.saved_institutions) || [],
  );
  const [institutionState, setInstitutionState] = useState([]);
  const [courses, setCourses] = useState(
    useSelector((state) => state.questionFilters.saved_courses) || [],
  );
  const [coursesPage, setCoursesPage] = useState(
    useSelector((state) => state.questionFilters.saved_institutions_page) || 1,
  );

  const [residencyDegrees, setResidencyDegrees] = useState([]);

  const [searchId, setSearchId] = useState(filters.id || '');
  const [searchTrack, setSearchTrack] = useState(filters.track || '');
  const [institutionsLoading, setInstitutionsLoading] = useState(false);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);

  const lastYear = 2015;
  const arrayOfYears = Array.from(
    {
      length: new Date().getFullYear() - (lastYear - 1),
    },
    (_, i) => new Date().getFullYear() - i,
  );
  const years = arrayOfYears.map((year, index) => {
    return {
      id: index,
      value: year,
      label: year,
    };
  });

  const { data: residencyDegreeData } = useResidencyDegree({
    no_page: 1,
    ordering: 'abbreviation',
  });

  const seeMoreFilters = () => {
    Mixpanel.track(
      `Clicou 'Busca de questões - ${moreFilters ? 'colapsar' : 'expandir'} filtros'`,
      {},
    );
    setMoreFilters(!moreFilters);
  };

  const filtersForMixpanel = (filters) => {
    const defaultFilters = {
      'ID da questão': filters.id,
      'ID da trilha': filters.track,
      Tags: filters.tag,
      Instituição: filters.institution__in,
      Ano: filters.year__in,
      Especialidade: filters.speciality,
    };
    const otherFilters = {
      'Nível de prova': filters.residency_degree,
      'Curso em que está disponível': filters.track__course__in,
      'Modo de cobrança': filters.demand_type,
      Estado: filters.institution__state,
      'Não foi utilizada em simulado': filters.used_in_challenge,
      'Última atualização dos comentários': filters.explanation_last_updated,
      'Tipo de questão': filters.question_type,
      'Dificuldade da questão': filters.difficulty,
      'É original': filters.is_original,
      'Tem vídeo comentário?': filters.explanation_video__isnull,
      'Questões novas': filters.is_new,
      'Tem imagem?': filters.questionimage__isnull,
    };
    if (moreFilters) {
      return { ...defaultFilters, ...otherFilters };
    }
    return defaultFilters;
  };

  const filterData = (filters) => {
    handleFilter(filters);
    Mixpanel.track(
      `Clicou 'Busca de questões - filtrar questões ${moreFilters ? 'expandido' : 'colapsado'}'`,
      filtersForMixpanel(filters),
    );

    dispatch(saveInstitutions({ institutions: institutions }));
    dispatch(saveCourses({ courses: courses, page: coursesPage + 1 }));
    dispatch(saveShowMoreFilters(moreFilters));
  };

  const resetData = () => {
    setSearchId('');
    setSearchTrack('');
    setTagValue('');
    setFilters(FILTERS_INITIAL_STATE);
    setSelectedInstitutions([]);
  };

  const transformOptions = (value) => {
    if (!value.id || !value.name) return;

    return {
      label: value.name,
      key: value.id,
      value: value.id,
    };
  };

  const transformInstitutionStates = (stateObject) => {
    let result = [];
    Object.keys(stateObject).map((key) =>
      result.push({
        id: key,
        value: key,
        label: stateObject[key],
      }),
    );

    return result;
  };

  useEffect(() => {
    if (!residencyDegreeData) return;
    setResidencyDegrees(residencyDegreeData.map(transformOptions));
  }, [residencyDegreeData]);

  const { data: specialitysData, isLoading: loadingSpecialities } = useFilterSpecialitys({
    no_page: true,
    ordering: 'name',
    main_speciality: true,
  });

  const { data: coursesData, isLoading: loadingCourses } = useFilterCourse(
    {
      ordering: 'name',
      page: coursesPage,
    },
    {
      config: {
        enabled: moreFilters,
      },
    },
  );

  const { data: institutionStatesData, isLoading: loadingStates } = useFilterInstitutionStates(
    {},
    {
      config: {
        enabled: moreFilters,
      },
    },
  );

  const loadInstitutions = async (search) => {
    setInstitutionsLoading(true);
    let params;
    if (search) {
      params = {
        search: search,
      };
    }

    const { data } = await Institution.list({
      ordering: 'name',
      no_page: 1,
      ...params,
    });
    setInstitutionsLoading(false);
    if (data) {
      return setInstitutions([...institutions, ...data.map(transformOptions)]);
    }
  };

  useEffect(() => {
    loadInstitutions();
  }, []);

  useEffect(() => {
    if (!specialitysData) return;
    setSpecialitys(specialitysData.map(transformOptions));
  }, [specialitysData]);

  useEffect(() => {
    if (!coursesData && !coursesData?.results) return;

    const mappedData = coursesData.results.map(transformOptions);

    if (courses.length === 0 || !checkIfArrayOfObjectsContainsAnother(courses, mappedData)) {
      setCourses((courses) => [...courses, ...mappedData]);
    }
  }, [coursesData]);

  useEffect(() => {
    if (!institutionStatesData) return;
    setInstitutionState(transformInstitutionStates(institutionStatesData));
  }, [institutionStatesData]);

  const handleSelectValue = (event, paramName) => {
    return setFilters((filters) => {
      return { ...filters, [paramName]: event.target.value };
    });
  };

  const fetchNextCoursesPage = () => {
    if (!coursesData && !coursesData?.next) return;
    return setCoursesPage(coursesPage + 1);
  };

  const handleTagValue = (tag) => {
    setTagValue(tag);
    setFilters((filters) => {
      return { ...filters, tag: tag.value };
    });
  };

  const handleBlurId = () => {
    setFilters((filters) => {
      return { ...filters, id: searchId };
    });
  };

  const handleBlurTrack = () => {
    setFilters((filters) => {
      return { ...filters, track: searchTrack };
    });
  };

  const handleSelectedOptions = (option) => {
    setSelectedInstitutions(option);
    const transformedoptions = option.map((item) => item.value);
    setFilters((filters) => {
      return { ...filters, institution__in: transformedoptions };
    });
  };

  const filterInstitutions = async (search) => {
    if (!search) {
      return institutions;
    }
    return institutions.filter((item) => search.includes(item.label));
  };

  const defaultSelects = [
    {
      label: 'Ano',
      placeholder: 'Ano',
      filterIdentifier: 'year__in',
      values: years,
      selectedValue: filters.year__in,
      multiple: true,
    },
    {
      label: loadingSpecialities ? 'Carregando...' : 'Especialidade',
      placeholder: 'Especialidade',
      filterIdentifier: 'speciality',
      values: specialitys,
      disabled: loadingSpecialities,
      selectedValue: filters.speciality,
    },
  ];

  const seeMoreSelects = [
    {
      label: 'Nível de residência',
      placeholder: 'Nível de residência',
      filterIdentifier: 'new_residency_degree',
      values: residencyDegrees,
      selectedValue: filters.new_residency_degree,
    },
    {
      label: loadingCourses ? 'Carregando...' : 'Curso em que está disponível',
      placeholder: 'Curso em que está disponível',
      filterIdentifier: 'track__course__in',
      values: courses,
      selectedValue: filters.track__course__in,
      disabled: loadingCourses,
      loadMore: fetchNextCoursesPage,
      loadingMore: coursesPage > 1 && loadingCourses,
      shouldLoadMore: coursesData?.next || false,
      multiple: true,
    },
    {
      label: 'Modo de cobrança',
      placeholder: 'Modo de cobrança',
      filterIdentifier: 'demand_type',
      values: demandType,
      selectedValue: filters.demand_type,
    },
    {
      label: loadingStates ? 'Carregando...' : 'Estado',
      placeholder: 'Estado',
      filterIdentifier: 'institution__state',
      values: institutionState,
      disabled: loadingStates,
      selectedValue: filters.institution__state,
    },
    {
      label: 'Não foi utilizada em simulado',
      placeholder: 'Não foi utilizada em simulado',
      filterIdentifier: 'used_in_challenge',
      values: usedInChallenge,
      selectedValue: filters.used_in_challenge,
    },
    {
      label: 'Última atualização dos comentários',
      placeholder: 'Última atualização dos comentários',
      filterIdentifier: 'explanation_last_updated',
      values: explanationLastUpdate,
      selectedValue: filters.explanation_last_updated,
    },
    {
      label: 'Tipo de questão',
      placeholder: 'Tipo de questão',
      filterIdentifier: 'question_type',
      values: questionType,
      selectedValue: filters.question_type,
    },
    {
      label: 'Dificuldade da questão',
      placeholder: 'Dificuldade da questão',
      filterIdentifier: 'difficulty',
      values: questionDifficulty,
      selectedValue: filters.difficulty,
    },
    {
      label: 'É original',
      placeholder: 'É original',
      filterIdentifier: 'is_original',
      values: yesOrNo,
      selectedValue: filters.is_original,
    },
    {
      label: 'Tem vídeo comentário?',
      placeholder: 'Tem vídeo comentário?',
      filterIdentifier: 'explanation_video__isnull',
      values: invertedYesOrNo,
      selectedValue: filters.explanation_video__isnull,
    },
    {
      label: 'Questões novas',
      placeholder: 'Questões novas',
      filterIdentifier: 'is_new',
      values: isNew,
      selectedValue: filters.is_new,
    },
    {
      label: 'Tem imagem?',
      placeholder: 'Tem imagem?',
      filterIdentifier: 'questionimage__isnull',
      values: invertedYesOrNo,
      selectedValue: filters.questionimage__isnull,
    },
  ];

  const textfieldStyle = {
    display: 'flex',
    flexBasis: '30%',
  };

  const searchInputStyle = {
    size: 'small',
    type: 'number',
    textfieldStyle: textfieldStyle,
    maxLength: 20,
  };

  const RenderSelect = (selectProps) => {
    return (
      <S.FieldContainer>
        <SelectComponentMaterialUi
          label={selectProps.label}
          placeholder={selectProps.placeholder}
          values={selectProps.values}
          selectedValue={selectProps.selectedValue}
          disabled={selectProps.disabled}
          loadMore={selectProps.loadMore}
          loadingMore={selectProps.loadingMore}
          shouldLoadMore={selectProps.shouldLoadMore}
          setSelectedValue={handleSelectValue}
          multiple={selectProps.multiple}
          multipleLimitNumber={5}
          textfieldStyle={textfieldStyle}
          size="small"
          id={selectProps.filterIdentifier}
          limitWidth={true}
        />
      </S.FieldContainer>
    );
  };

  return (
    <S.Container>
      <S.Header>
        <Title>Filtro de questões</Title>
        <S.MoreLessFilters onClick={seeMoreFilters}>
          <BiFilterAlt />
          {`${moreFilters ? 'Menos' : 'Mais'} filtros`}
        </S.MoreLessFilters>
      </S.Header>
      <S.Content>
        <S.InputRow>
          <S.FieldContainer>
            <SearchInputMaterialUi
              ref={inputRef}
              label="ID da questão"
              id="id"
              setSelectedValue={setSearchId}
              handleBlur={handleBlurId}
              value={searchId}
              {...searchInputStyle}
            />
          </S.FieldContainer>
          <S.FieldContainer>
            <SearchInputMaterialUi
              label="ID da trilha"
              id="track"
              setSelectedValue={setSearchTrack}
              value={searchTrack}
              handleBlur={handleBlurTrack}
              {...searchInputStyle}
            />
          </S.FieldContainer>

          <S.SearchWithPaginationContainer>
            <SelectWithPagination
              name="tags"
              height={40}
              value={tagValue}
              onChange={(selectedTag) => handleTagValue(selectedTag)}
              loadOptions={loadTags}
              placeholder="Tags"
              placeholderStyle={{
                display: 'flex',
                fontSize: '14px',
              }}
              valueContainerStyle={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                flexWrap: 'nowrap',
              }}
              controlStyle={{ background: '#fff' }}
              singleValueStyle={{ fontSize: '14px', position: 'absolute', background: '#fff' }}
              containerWidth={inputRef.current?.offsetWidth}
            />
          </S.SearchWithPaginationContainer>

          {defaultSelects.map((selectItem) => {
            return RenderSelect(selectItem);
          })}

          <S.InstitutionsFieldContainer>
            <MultiselectAutocomplete
              selectedOptions={selectedInstitutions}
              setSelectedOptions={handleSelectedOptions}
              label={institutionsLoading ? 'Carregando...' : 'Instituições'}
              placeholder="Selecione"
              options={institutions}
              fetchSuggestions={filterInstitutions}
              loading={institutionsLoading}
              disabled={institutionsLoading}
            />
          </S.InstitutionsFieldContainer>

          {moreFilters && (
            <>
              {seeMoreSelects.map((selectItem) => {
                return RenderSelect(selectItem);
              })}
            </>
          )}
        </S.InputRow>
      </S.Content>
      <S.Footer>
        <S.ResetButton onClick={resetData}>Limpar</S.ResetButton>
        <S.FilterButton
          onClick={() => filterData(filters)}
          disabled={
            deepEqual(filters, FILTERS_INITIAL_STATE) &&
            !searchId &&
            !searchTrack &&
            selectedInstitutions.length === 0
          }
        >
          Filtrar
        </S.FilterButton>
      </S.Footer>
    </S.Container>
  );
}

export default Filters;
