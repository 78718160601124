export const titles = [
  {
    number: 1,
    title: 'Dados principais',
    borderBottom: true,
  },
  {
    number: 2,
    title: 'Configuração',
    borderBottom: true,
  },
  {
    number: 3,
    title: 'Padrão de objetos de aula',
    borderBottom: false,
  },
];

export const INPUTS_INITIAL_STATE = {
  name: '',
  mentoring_access: '',
  certificate_minimum_percentage_required: undefined,
  course_features: [],
  residency_degree: '',
  //url_checkout: undefined,
  track_available: '',
  residency_tree: '',
  track_type_available: [],
  subject_available: '',
  subject_start_availability: null,
  lesson_available: '',
  lesson_start_availability: null,
};

export const YesOrNoRadio = [
  { key: 'yes', label: 'Sim', value: 'true' },
  { key: 'no', label: 'Não', value: 'false' },
];

export const TRACK_TYPES = [
  {
    label: 'Pessoal',
    value: 'a',
    key: 'a',
  },
  {
    label: 'Simulado',
    value: 'b',
    key: 'b',
  },
  {
    label: 'Exame',
    value: 'c',
    key: 'c',
  },
  {
    label: 'Capítulo',
    value: 'd',
    key: 'd',
  },
  {
    label: 'Revisão',
    value: 'e',
    key: 'e',
  },
  {
    label: 'Trilha inteligente',
    value: 'f',
    key: 'f',
  },
];

export const CONTENT_TYPES = [
  {
    content_type: 'pre_track',
    label: 'Trilha-Pré',
    object_type: 'a',
  },
  {
    content_type: 'video_lesson',
    label: 'Vídeo Aula',
    object_type: 'b',
  },
  {
    content_type: 'flash_video_lesson',
    label: 'Vídeo Aula - Flash',
    object_type: 'c',
  },
  {
    content_type: 'chapter',
    label: 'Capítulo',
    object_type: 'd',
  },
  {
    content_type: 'pos_track',
    label: 'Trilha-Pós',
    object_type: 'e',
  },
  {
    content_type: 'document',
    label: 'Documento de aula',
    object_type: 'f',
  },
  {
    content_type: 'challenge',
    label: 'Simulado',
    object_type: 'g',
  },
  {
    content_type: 'flashcarddeck',
    label: 'Flashcards',
    object_type: 'i',
  },
];
