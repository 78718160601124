import React from 'react';
import Modal from '@material-ui/core/Modal';
import { CardModal, CardLoadingModal, Icon, Title, Subtitle, Button } from './styles';
import NewLoader from '~/components/NewLoader';
import DropsSuccessIcon from '~/assets/drops-success-circle.svg';
import DropsErrorIcon from '~/assets/drops-error-circle.svg';
import MedwayIcon from '~/assets/drops-medway-m';
import CloseIcon from '@material-ui/icons/Close';
import { Mixpanel } from '~/services/analytics';

function WarningModal({
  show, 
  close,
  title,
  subtitle,
  textButton,
  linkButton,
  isSuccess,
  isLoading,
}) {
    const goToLink = () => {
        Mixpanel.track("Clicou 'Lista de trilhas - Gerar slide - sucesso'", {}); 
        window.open(linkButton, '_blank');
    }

    const closeModal = () => {
        Mixpanel.track("Clicou 'Lista de trilhas - Nova trilha - falha'", {});
        close();
    }

    return (
    <Modal
        open={show}
        onClose={close}
    >
        {isLoading ? (
            <CardLoadingModal>
                <NewLoader text="Carregando">
                    <MedwayIcon />
                </NewLoader>
            </CardLoadingModal>
        ) : (
            <CardModal>
                <CloseIcon 
                    onClick={close} 
                    style={{ 
                        position: 'absolute', 
                        top: '30px',
                        right: '30px',
                        fill: '#ACACAE',
                        cursor: 'pointer',
                        width: '24px',
                        height: '24px' 
                    }}/>
                <Icon src={isSuccess ? DropsSuccessIcon : DropsErrorIcon} />
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
                <Button onClick={isSuccess ? goToLink : closeModal}>{textButton}</Button>
            </CardModal>
            )}
    </Modal>
    );
}

export default WarningModal;
