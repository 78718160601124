import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as LogoutIcon } from '~/assets/sidebar-icons/logout.svg';
import { menuItems } from './menuItems';
import ModalConfirmation from '~/components/ModalConfirmation';
import GreenBorder from '../GreenBorder';
import { signOut } from '~/store/modules/auth/actions';
import { Mixpanel } from '~/services/analytics';
import history from '~/services/history';
import * as S from './styles';

const Sidebar = () => {
  const dispatch = useDispatch();
  const { location } = useHistory();
  const [activeMenu, setActiveMenu] = useState('');
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleMenu = (menu, label) => {
    Mixpanel.track("Clicou 'Submenu'", {
      'Item selecionado': label,
    });
    setActiveMenu(menu);
  };

  useEffect(() => {
    setActiveMenu(location.pathname.split('/')[1]);
  }, [location.pathname]);

  const handleLogout = () => {
    dispatch(signOut());
    history.push('/');
  };

  return (
    <>
      <S.Container>
        <S.LogoContainer>
          <S.StyledLogoSmall />
          <S.StyledLogo />
        </S.LogoContainer>
        <S.Menu>
          <S.MenuSuperiorItems>
            {menuItems.map((item) => (
              <Link
                key={item.key}
                to={{ pathname: `/${item.key}` }}
                onClick={() => handleMenu(item.key, item.name)}
              >
                <S.MenuItem>
                  {item.key === activeMenu && <GreenBorder />}
                  <S.IconContainer>{item.icon}</S.IconContainer>
                  <S.MenuItemText>{item.name}</S.MenuItemText>
                </S.MenuItem>
              </Link>
            ))}
          </S.MenuSuperiorItems>
          <S.MenuItem onClick={() => setShowLogoutModal(true)}>
            <S.IconContainer>
              <LogoutIcon />
            </S.IconContainer>
            <S.MenuItemText>Sair</S.MenuItemText>
          </S.MenuItem>
        </S.Menu>
      </S.Container>
      <S.BackgroundBackdrop />
      {showLogoutModal && (
        <ModalConfirmation
          title="Deseja sair?"
          message="Quer deslogar da sua conta?"
          actionText="Sair"
          showModal={showLogoutModal}
          handleClose={() => setShowLogoutModal(false)}
          handleAction={handleLogout}
        />
      )}
    </>
  );
};

export default Sidebar;
