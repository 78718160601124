import styled from 'styled-components';

export const CardModal = styled.div`
    position: relative;
    background-color: white;
    max-width: 470px;
    box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

`;

export const Title = styled.h1`
    margin: 0;
    font-size: 28.8px;
    line-height: 38.4px;
    font-weight: 600;
    color: #00205B;
    padding: 32px 56px 16px;
`

export const Divider = styled.hr`
    width: 100%;
    margin: 0;
    border-color: #E5E8EE;
`

export const LoaderContainer = styled.div`
    padding: 32px 0 24px;
`

export const Text = styled.p`
    margin: 0;
    font-size: 18px;
    line-height: 32.4px;
    color:#4F5154;
    padding: 0 56px 32px;
`