import { useQuery } from '@tanstack/react-query';

import { CourseAPICMS } from '~/services/apiCallsCMS';

const getFilterCourse = async (params) => {
  try {
    const response = await CourseAPICMS.list(params);
    if (response && response.data && response.data) {
      const data = response.data;
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    throw error;
  }
};

export default function useFilterCourse(params, options) {
  return useQuery({
    queryKey: ['filtersCourse', params],
    queryFn: () => getFilterCourse(params),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
