import styled from 'styled-components';

export const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  gap: 6px;
  height: 24px;
  border-radius: 8px;
  cursor: pointer;
  background: ${(props) => props.backgroundColor || '#e5e8ee'};
`;

export const DropdownButtonText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.color || '#00205b'};
`;

export const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(24, 26, 30, 0.16), 0px 8px 16px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 14px;
  height: 40px;
  border-bottom: 1px solid #e5e8ee;
  font-size: 14px;
  line-height: 20px;
  color: #00205b;
  cursor: pointer;
`;
