import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  background-color: #f5f5fb;
`;

export const MainContainer = styled.div`
  background: rgb(255, 255, 255);
  box-shadow: rgba(24, 26, 30, 0.16) 0px 0px 2px, rgba(24, 26, 30, 0.08) 0px 2px 4px;
  border-radius: 8px;
  padding: 40px 56px;
  width: 100%;
`;

export const NotFound = styled.div`
  margin-left: 5px;
  margin-top: 20px;
  height: 19px;
  color: #3b3fb6;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
`;

export const ContainerSpecialities = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ContainerQuestions = styled(ContainerSpecialities)`
  gap: 8px;
`;

export const SpecialityTitle = styled.h2`
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;
  margin-bottom: 0;
`;
