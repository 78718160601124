import styled from 'styled-components';
import { MdChevronRight } from 'react-icons/md';

export const Container = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  padding: 24px 0;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;
  transitions: 0.3s;
  border-bottom: ${(props) => (props.open ? '1px solid transparent' : '1px solid #E5E8EE')};
`;

export const NameOption = styled.span`
  color: #00205B;
  font-weight: 600;
  font-size: 20.3px;
  font-weight: 600;
  line-height: 27px;
`;

export const ButtonUp = styled.div`
  width: 14px;
  height: 14px;
`;

export const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  gap: 4px;
  width: 65px;
  height: 24px;
  background: ${(props) => props.color};
  border-radius: 1000px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const RangeText = styled.span`
  width: 36px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
`;

export const NameContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  width: 400px;
`;

export const RangeTextAndChipContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: #00205B;
  font-size: 16px;
  line-height: 21px;
`;

export const RangeAndExpandIcon = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;   
`;

export const ExpandIcon = styled(MdChevronRight)`
  transform: ${(props) => (props.open ? 'rotate(270deg)' : 'rotate(90deg)')};
`;

export const ExpandIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #E5E8EE;

  &:hover {
    border-color: #D1D7E1;
  }
`;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background: #E5E8EE;
  padding: 20px 28px;
  margin-bottom: 24px;
  border-radius: 8px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #E5E8EE;
`;