import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { toast } from 'react-toastify';
import {
  UploadFileS3,
  QuestionAPICMS,
  QuestionExplanationVideoAPICMS,
} from '~/services/apiCallsCMS';
import CloudLogo from '~/assets/drops-cloud-logo.svg';
import PlusLogo from '~/assets/drops-plus-logo.svg';
import SuccessMessage from '~/components/UploadVideoS3/SuccessMessage/index';
import Loader from '../Loader';
import * as S from './styles';
import ErrorInput from '~/components/UploadVideoS3/ErrorInput/index';
import ProgressCircle from '~/components/UploadVideoS3/ProgressCircle/index';
import VideoPlayer from '~/components/UploadVideoS3/VideoPlayer/index';

const VideoCommentUpload = ({
  question,
  setVideoCoPhysician,
  searchPhysicianById,
  setLoadingPhysician,
  setExternalVideoId,
}) => {
  const { question_id } = useParams();
  const [fileData, setFileData] = useState(null);
  const [fileDesc, setFileDesc] = useState('');
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState();
  const [percent, setPercent] = useState('');
  const [urlEmbed, setUrlEmbed] = useState();
  const [videoURL, setVideoURL] = useState('');
  const [videoId, setVideoId] = useState();
  const [questionExplanationId, setQuestionExplanationId] = useState(null);
  const [videoStatus, setVideoStatus] = useState(false);
  function onUploadProgress(progressEvent) {
    const { loaded, total } = progressEvent;
    setPercent(Math.floor((loaded * 100) / total));
  }

  const getVideo = async () => {
    setLoading(true);
    setLoadingPhysician(true);
    const data = await QuestionAPICMS.get(question);
    searchPhysicianById(data.data.video_co_physicians[0])
      .then((response) => {
        setVideoCoPhysician(response.options);
        setLoadingPhysician(false);
      })
      .catch(() => setLoadingPhysician(false));

    let institution_name = (data.data.institution_name ?? '').replace(' - ', '_');
    let question_type = data.question_type === 'o' ? 'Objetiva' : 'Discursiva';
    if (!question_id) {
      setFileDesc(
        `${question}-${institution_name.replaceAll(' ', '_')}-${data.data.year}-${question_type}-${
          data.data.question_number
        }.mp4`,
      );
    } else {
      setFileDesc(`${question}-comment.mp4`);
    }

    if (data.data.explanation_video !== null) {
      setQuestionExplanationId(data.data.explanation_video);
      setExternalVideoId(data.data.explanation_video);
      const url = await QuestionExplanationVideoAPICMS.get(data.data.explanation_video).then(
        (res) => {
          setVideoURL(res.data.video_url);
          setVideoId(res.data.video_url.replaceAll('https://vimeo.com/', ''));
          setVideoStatus(true);
        },
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    getVideo();
  }, [question]);

  useEffect(() => {
    if (videoId) {
      setUrlEmbed(`https://player.vimeo.com/video/${videoId}`);
    }
  }, [videoId]);

  const handleFileVideo = (file) => {
    if (file.size < process.env.REACT_APP_UPLOAD_VIDEO_SIZE) {
      setFileData(file);
      setLoadingUpload(true);
      const source = Axios.CancelToken.source();
      const data = {
        file_content: true,
        file_type: 'video',
        file_name: fileDesc,
      };
      const config = {
        onUploadProgress: onUploadProgress,
      };
      UploadFileS3.post(data, source.token).then((res) => {
        Axios.put(res.data.url, file, config, { credentials: 'include' })
          .then(() => {
            setLoadingUpload(false);
            toast.success('Upload realizado com sucesso!');
          })
          .catch(() => {
            setStatus('Error');
            setLoadingUpload(false);
            toast.error('Erro no upload!');
          });
      });
    } else {
      setStatus('Error');
      toast.error('Limite de upload ultrapassado!');
    }
  };
  const removeFile = () => {
    setFileDesc('Selecione');
    setFileData(null);
    setLoadingUpload(false);
  };
  const removeVideo = () => {
    if (questionExplanationId) {
      QuestionAPICMS.update(question, { explanation_video: null })
        .then((_) => {
          QuestionExplanationVideoAPICMS.delete(questionExplanationId)
            .then((res) => {
              toast.success('Vídeo removido com sucesso');
            })
            .catch(() => {
              toast.error('Não foi possível remover o vídeo');
            });
        })
        .catch(() => {
          toast.error('Não foi possível remover o vídeo');
        });
    }
    setVideoStatus(false);
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <>
          {loadingUpload === true ? (
            <ProgressCircle percent={percent} onClick={removeFile} />
          ) : (
            <>
              {status === 'Error' ? (
                <ErrorInput />
              ) : (
                <>
                  {videoStatus ? (
                    <VideoPlayer
                      urlEmbed={urlEmbed}
                      fileName={fileDesc}
                      url={videoURL}
                      onClick={removeVideo}
                    />
                  ) : (
                    <>
                      {fileData === null ? (
                        <S.MainContainer>
                          <S.IconGroup>
                            <S.PlusContainer>
                              <img src={PlusLogo} alt="Plus logo" />
                            </S.PlusContainer>
                            <S.Group>
                              <img src={CloudLogo} alt="Cloud logo" />
                            </S.Group>
                          </S.IconGroup>
                          <S.MainLb>Clique e selecione um vídeo</S.MainLb>
                          <S.InputHide>
                            <label htmlFor="file">
                              <input
                                type="file"
                                id="file"
                                accept="video/*"
                                onChange={(event) => handleFileVideo(event.target.files[0])}
                              />
                              <S.BtSelect>Selecionar vídeo</S.BtSelect>
                            </label>
                          </S.InputHide>
                        </S.MainContainer>
                      ) : (
                        <SuccessMessage
                          title="Enviado com sucesso"
                          subtitle="O vídeo será adicionado na página"
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default VideoCommentUpload;
