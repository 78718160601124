import React from 'react';
import Modal from '@material-ui/core/Modal';
import { CardModal, Title, Divider, LoaderContainer, Text } from './styles';
import NewLoader from '~/components/NewLoader';
import RobotIcon from '~/assets/dropsRobot';

function GeneratedCommentLoadingModal({
  show,
  close,
}) {
    return (
    <Modal open={show} onClose={close}>
        <CardModal>
            <Title>Gerando sugestão</Title>
            <Divider />
            <LoaderContainer>
                <NewLoader text="Carregando">
                    <RobotIcon />
                </NewLoader>
            </LoaderContainer>
            <Text>
            Por favor aguarde!
            <br />
            Estamos gerando a sua sugestão e estará disponível em breve
            </Text>
        </CardModal>
    </Modal>
    );
}
export default GeneratedCommentLoadingModal;