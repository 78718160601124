import React, { useState, useEffect, useRef, useCallback } from 'react';

import { loadTags } from '~/pages/Chapters/components/Settings/utils';
import SelectWithPagination from '~/components/SelectWithPagination';
import SelectComponent from '~/components/SelectComponent';
import { InstitutionAPICMS } from '~/services/apiCallsCMS';

import { useFlashcardsResidencyDegree, useFlashcardsSpecialitys } from '~/hooks-querys/flashcards';

import SearchIcon from '~/assets/drops-search.svg';
import * as S from './styles';

const flashcardStatus = [
  {
    value: 3,
    key: 3,
    label: 'Reportado',
  },
];

function Filters({ handleFilters, filters, setFilters }) {
  const cardRef = useRef();
  const [categorys, setCategorys] = useState([]);
  const [specialitys, setSpecialitys] = useState([]);
  const [smallWidth, setSmallWidth] = useState((cardRef.current?.offsetWidth - 128) / 4 || 0);

  const { data: residencyDegreeData, isLoading: loadingResidencyDegree } =
    useFlashcardsResidencyDegree({
      ordering: 'abbreviation',
    });

  const { data: specialitysData, isLoading: loadingSpecialities } = useFlashcardsSpecialitys({
    no_page: true,
    ordering: 'name',
    main_speciality: true,
  });

  const transformOptions = (value) => {
    if (!value.id || !value.name) return;

    return {
      label: value.name,
      key: value.id,
      value: value.id,
      abbreviation: value.abbreviation,
    };
  };

  const clearFilters = () => {
    const defaultCategory = categorys.find((category) => category.abbreviation.includes('R1'));
    setFilters({
      residency_degree: defaultCategory,
      search: '',
      id: '',
      speciality: null,
      institution: null,
      tag: null,
      status: null,
    });
  };

  useEffect(() => {
    if (!residencyDegreeData && !residencyDegreeData?.results) return;
    setCategorys((categorys) => [
      ...categorys,
      ...residencyDegreeData.results.map(transformOptions),
    ]);
  }, [residencyDegreeData]);

  useEffect(() => {
    if (categorys.length === 0) return;

    const defaultCategory = categorys.find((category) => category.abbreviation.includes('R1'));

    setFilters({ ...filters, residency_degree: defaultCategory });
  }, [categorys]);

  useEffect(() => {
    if (!specialitysData) return;
    setSpecialitys(specialitysData.map(transformOptions));
  }, [specialitysData]);

  useEffect(() => {
    const element = cardRef?.current;

    if (!element) return;

    const observer = new ResizeObserver(() => {
      setSmallWidth((element.offsetWidth - 346) / 3);
    });

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, []);

  const transformInstitutionOptions = (institution) => {
    if (!institution.id || !institution.name) return;

    return {
      label: institution.state ? `${institution.name} - ${institution.state}` : institution.name,
      key: institution.id,
      value: institution.id,
      institution,
    };
  };

  const loadInstitutions = useCallback(async (search, loadedOptions, { page }) => {
    const { data } = await InstitutionAPICMS.list({
      page,
      search,
      ordering: 'name',
      residency: true,
    });

    return {
      options: data.results.map(transformInstitutionOptions),
      hasMore: data.next,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  const handleSearch = (search) => {
    setFilters({ ...filters, title: search.target.value });
  };

  const handleTag = (tag) => {
    setFilters({ ...filters, tags: tag });
  };

  const handleIdSearch = (search) => {
    setFilters({ ...filters, id: search.target.value });
  };

  const handleResidencyDegree = (residencyDegree) => {
    let newFilters = {
      residency_degree: residencyDegree,
    };

    const differentResidencyDegrees = residencyDegree.value !== filters.residency_degree?.value;

    if (differentResidencyDegrees) {
      newFilters.tags = null;
    }

    setFilters({ ...filters, ...newFilters });
  };

  const handleSpeciality = (speciality) => {
    setFilters({ ...filters, speciality: speciality });
  };

  const handleInstitution = (institution) => {
    setFilters({ ...filters, institution: institution });
  };

  const handleFlashcardStatus = (status) => {
    return setFilters({ ...filters, status: status });
  };

  const selectValueStyle = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <S.Card ref={cardRef}>
      <S.FiltersTitle>Filtrar dados</S.FiltersTitle>
      <S.FiltersContainer>
        <S.FilterBox flexBasis={'24%'}>
          <S.Filter>
            <img src={SearchIcon} alt="Search icon" />
            <input
              className="textInput"
              name="search"
              type="text"
              value={filters.search}
              onChange={handleSearch}
              placeholder="Pesquisar"
            />
          </S.Filter>
        </S.FilterBox>
        <S.FilterBox flexBasis={'24%'}>
          <S.Filter>
            <img src={SearchIcon} alt="Search icon" />
            <input
              className="textInput"
              name="search"
              type="text"
              value={filters.id}
              onChange={handleIdSearch}
              placeholder="Buscar por ID"
            />
          </S.Filter>
        </S.FilterBox>
        <S.FilterBox flexBasis={'24%'}>
          <SelectComponent
            name="speciality"
            placeholder={loadingSpecialities ? 'Carregando...' : 'Especialidade'}
            value={filters.speciality}
            onChange={handleSpeciality}
            options={specialitys}
            width={'100%'}
            controlStyle={{ marginLeft: 0 }}
            singleValueStyle={selectValueStyle}
            placeholderStyle={selectValueStyle}
            valueContainerStyle={{ flexWrap: 'nowrap' }}
          />
        </S.FilterBox>
        <S.FilterBox flexBasis={'24%'}>
          <SelectWithPagination
            name="institutions"
            height={40}
            value={filters.institution}
            onChange={handleInstitution}
            loadOptions={loadInstitutions}
            placeholder="Instituição"
            placeholderStyle={{
              display: 'flex',
              fontSize: '14px',
            }}
            valueContainerStyle={{
              whiteSpace: 'nowrap',
              flexWrap: 'nowrap',
            }}
            singleValueStyle={selectValueStyle}
            width={'100%'}
          />
        </S.FilterBox>

        <S.FilterBox flexBasis={'25%'}>
          <SelectComponent
            name="residency-degree"
            placeholder={loadingResidencyDegree ? 'Carregando...' : 'Nível de residência'}
            value={filters.residency_degree}
            onChange={handleResidencyDegree}
            options={categorys}
            width={smallWidth || '100%'}
            controlStyle={{ marginLeft: 0 }}
            singleValueStyle={selectValueStyle}
            placeholderStyle={selectValueStyle}
            valueContainerStyle={{ flexWrap: 'nowrap' }}
          />
        </S.FilterBox>
        <S.FilterBox flexBasis={'25%'}>
          <SelectWithPagination
            name="tag"
            height={40}
            value={filters.tags}
            onChange={handleTag}
            loadOptions={(search, loadedOptions, page) =>
              loadTags(search, loadedOptions, {
                ...page,
                new_residency_degree: filters?.residency_degree?.value,
              })
            }
            placeholder="Tags"
            placeholderStyle={{
              display: 'flex',
              fontSize: '14px',
            }}
            valueContainerStyle={{
              whiteSpace: 'nowrap',
              flexWrap: 'nowrap',
            }}
            containerWidth={smallWidth || '100%'}
            singleValueStyle={selectValueStyle}
            asyncPaginateKey={`async-paginate-${filters?.residency_degree?.value || ''}`}
          />
        </S.FilterBox>
        <S.FilterBox flexBasis={'25%'}>
          <SelectComponent
            name="flashcard-status"
            placeholder="Status do flashcard"
            value={filters.status}
            onChange={handleFlashcardStatus}
            options={flashcardStatus}
            width={smallWidth || '100%'}
            controlStyle={{ marginLeft: 0 }}
            singleValueStyle={selectValueStyle}
            placeholderStyle={selectValueStyle}
            valueContainerStyle={{ flexWrap: 'nowrap' }}
          />
        </S.FilterBox>
        <S.FilterBox flexBasis={'5%'}>
          <S.ButtonFilter onClick={handleFilters} disabled={Object.keys(filters).length === 0}>
            Filtrar
          </S.ButtonFilter>
        </S.FilterBox>
        <S.FilterBox flexBasis={'5%'}>
          <S.ClearButton onClick={clearFilters}>Limpar</S.ClearButton>
        </S.FilterBox>
      </S.FiltersContainer>
    </S.Card>
  );
}

export default Filters;
