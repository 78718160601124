import { useQuery } from '@tanstack/react-query';

import { FormFieldAPICMS } from '~/services/apiCallsCMS';

const getFormField = async (params) => {
  try {
    const response = await FormFieldAPICMS.getFields(params);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useGetFormField(params, options) {
  return useQuery({
    queryKey: ['getFormField', params],
    queryFn: () => getFormField(params),
    staleTime: 2 * 60000,
    ...options?.config,
  });
}
