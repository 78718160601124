import produce from 'immer';

const INITIAL_STATE = {
  track: {
    requesting: false,
    error: false,
    track: [],
  },
};

export default function track(state = INITIAL_STATE, action = {}) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@track/REQUEST_TRACK': {
        draft.track.requesting = true;
        break;
      }
      case '@track/SET_LIST_TRACK': {
        draft.track = {
          requesting: false,
          error: false,
          track: action.payload.listTrack,
        };
        break;
      }
      case '@track/FETCH_LIST_TRACK': {
        draft.track.requesting = action.payload.requesting;
        draft.track.error = action.payload.error;
        break;
      }
      case '@track/RESET_TRACK': {
        draft = state;
        break;
      }
      
      default:
        return state;
    }
  });
}
