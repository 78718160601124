import React, { useState, useEffect } from 'react';
import { IoChevronDownSharp } from 'react-icons/io5';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { STATUS_OPTIONS } from '~/utils/variables';
import * as S from './styles';

function StatusDropdown({ name, activityId, status, changeStatus }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = anchorEl;
  const id = open ? name : undefined;

  const handleClick = (event) => {
    if (!anchorEl) {
      return setAnchorEl(event.currentTarget);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleClose, true);
    return () => {
      document.removeEventListener('scroll', handleClose, true);
    };
  }, []);

  return (
    <>
      {!STATUS_OPTIONS[status] ? (
        <>-</>
      ) : (
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <S.DropdownButton
              backgroundColor={STATUS_OPTIONS[status]?.bgColor}
              onClick={handleClick}
            >
              <IoChevronDownSharp size={16} color={STATUS_OPTIONS[status]?.color} />
              <S.DropdownButtonText color={STATUS_OPTIONS[status]?.color}>
                {STATUS_OPTIONS[status]?.label}
              </S.DropdownButtonText>
            </S.DropdownButton>

            <Popper
              id={id}
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ maxHeight: ' calc(100vh - 228px)', zIndex: '1201' }}
            >
              <S.DropdownMenu>
                {Object.keys(STATUS_OPTIONS).map(function (key) {
                  return (
                    <S.DropdownItem
                      onClick={() => {
                        changeStatus(STATUS_OPTIONS[key].key, activityId);
                        handleClose();
                      }}
                    >
                      {STATUS_OPTIONS[key].label}
                    </S.DropdownItem>
                  );
                })}
              </S.DropdownMenu>
            </Popper>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
}

export default StatusDropdown;
