import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  padding: 48px;
  max-width: 1388px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const FoldersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Folder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: ${(props) => (props.lastItem || props.open ? 'none' : '1px solid #d1d7e1')};
  padding-bottom: 24px;
`;

export const DivCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FolderTitle = styled.div`
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;
`;

export const DivRow = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const FolderCount = styled.span`
  font-weight: 400;
  font-size: 14.2px;
  color: #00205b;

  strong {
    font-weight: 600;
  }
`;

export const ContainerArrow = styled.div`
  border: 2px solid #e5e8ee;
  border-radius: 1000px;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  transform: ${(props) => (props.open ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: all 0.2s ease-in-out;
`;

export const ContainerLoader = styled.div`
  background-color: #fff;
  height: 120px;
  width: 100%;
  display: grid;
  place-items: center;
  box-shadow:
    0px 0px 2px rgba(24, 26, 30, 0.16),
    0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
`;

export const ExamsContainer = styled.div`
  width: 100%;
`;

export const InformationsLabel = styled.p`
  margin: 0;
  font-size: 12.6px;
  font-weight: 400;
  color: #00205B;
`;
