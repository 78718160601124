import styled from 'styled-components';
import { ReactComponent as ChevronRight } from '~/assets/drops-chevron-right-blue.svg';
import { ReactComponent as Plus } from '~/assets/drops-plus-logo.svg';

export const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const UpArrow = styled(ChevronRight)`
  width: 22px;
  height: 22px;
  transform: rotate(-90deg);
  path {
    fill: ${(props) => (props.disabled ? '#8B97BD' : '#077859')};
  }
`;

export const DownArrow = styled(ChevronRight)`
  width: 22px;
  height: 22px;
  transform: rotate(90deg);
  path {
    fill: ${(props) => (props.disabled ? '#8B97BD' : '#077859')};
  }
`;

const buttonProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  borderRadius: '50%',
};

export const ArrowContainer = styled.div`
  ${buttonProperties}
  border: 2px solid #E2E5EE;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  :hover {
    border-color: ${(props) => (props.disabled ? '#E2E5EE' : '#b7bed7')};
  }
`;

export const PlusIcon = styled(Plus)`
  width: 22px;
  height: 22px;
  path {
    fill: #fff;
  }
`;

export const PlusIconContainer = styled.div`
  ${buttonProperties}
  cursor: pointer;
  background: #0CAA87;

  :hover {
    background: #01cfb5;
  }
`;