import styled, { css } from 'styled-components';

const wrapperModifiers = {
  'color-CM': () => css`
    color: var(--color-CM);
    svg {
      path {
        fill: var(--color-CM);
      }
    }
    :hover {
      border: 2px solid #1862bc;
      box-shadow: 0px 0px 0px 6px rgba(110, 157, 214, 0.2);
    }
    .chip-video {
      background-color: var(--color-CM);
    }
    .chip-tag {
      border: 2px solid var(--color-CM);
    }
  `,
  'color-CIR': () => css`
    color: var(--color-CIR);
    svg {
      path {
        fill: var(--color-CIR);
      }
    }
    :hover {
      border: 2px solid var(--color-CIR);
      box-shadow: 0px 0px 0px 6px rgba(61, 243, 213, 0.2);
    }
    .chip-video {
      background-color: var(--color-CIR);
    }
    .chip-tag {
      border: 2px solid var(--color-CIR);
    }
  `,
  'color-PED': () => css`
    color: var(--color-PED);
    svg {
      path {
        fill: var(--color-PED);
      }
    }
    :hover {
      border: 2px solid var(--color-PED);
      box-shadow: 0px 0px 0px 6px rgba(255, 212, 117, 0.2);
    }
    .chip-video {
      background-color: var(--color-PED);
    }
    .chip-tag {
      border: 2px solid var(--color-PED);
    }
  `,
  'color-PREV': () => css`
    color: var(--color-PREV);
    svg {
      path {
        fill: var(--color-PREV);
      }
    }
    :hover {
      border: 2px solid var(--color-PREV);
      box-shadow: 0px 0px 0px 6px rgba(106, 109, 199, 0.2);
    }
    .chip-video {
      background-color: var(--color-PREV);
    }
    .chip-tag {
      border: 2px solid var(--color-PREV);
    }
  `,
  'color-GO': () => css`
    color: var(--color-GO);
    svg {
      path {
        fill: var(--color-GO);
      }
    }
    :hover {
      border: 2px solid var(--color-GO);
      box-shadow: 0px 0px 0px 6px rgba(192, 76, 129, 0.2);
    }
    .chip-video {
      background-color: var(--color-GO);
    }
    .chip-tag {
      border: 2px solid var(--color-GO);
    }
  `,
  'color-SEM-ESP': () => css`
    color: var(--color-SEM-ESP);
    svg {
      path {
        fill: var(--color-SEM-ESP);
      }
    }
    :hover {
      border: 2px solid var(--color-SEM-ESP);
      box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    }
    .chip-video {
      background-color: var(--color-SEM-ESP);
    }
    .chip-tag {
      border: 2px solid var(--color-SEM-ESP);
    }
  `,
};

export const Container = styled.div`
  ${({ colorSpeciality }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 2px solid #e5e8ee;
    border-radius: 8px;
    padding-inline: 24px;
    transition: all 0.2s ease-in-out;

    ${colorSpeciality && wrapperModifiers[colorSpeciality]};
  `}
`;

export const RowCard = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const QuestionNumber = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #00205b;
`;

export const ContainerOptions = styled.div`
  min-width: 216px;
  display: flex;
  justify-content: center;
  gap: 4px;
`;

export const Option = styled.div`
  display: flex;
  gap: 5px;
  width: 40px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12.6px;
  line-height: 17px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 2px solid #e5e8ee;
  color: #00205b;
  background-color: #fff;
  ${(props) =>
    props.correctOption &&
    css`
      border: 2px solid #01c3aa;
      color: #fff;
      background-color: #01c3aa;
    `}

  :hover {
    border: ${(props) => !props.correctOption && '2px solid #8f9db7'};
    background-color: ${(props) => props.correctOption && '#01CFB5'};
  }
`;

export const Check = styled.span`
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  width: 10px;
  height: 6px;
  margin-bottom: 2px;
  transform: rotate(-50deg);
`;

export const CanceledQuestion = styled.div`
  display: flex;
  align-items: center;
  background: #e5e8ee;
  border-radius: 1000px;
  height: 26px;
  padding-inline: 10px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #00205b;
`;

export const EditButton = styled.button`
  background-color: transparent;
  height: 32px;
  color: #01c3aa;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding-inline: 20px;
  border: none;
  border-radius: 1000px;
  transition: all 0.2s ease-in-out;

  :hover {
    background-color: #e5faf7;
  }
`;

export const ChipVideo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 24px;
  padding-inline: 8px;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  color: #ffffff;
`;

export const ChipTag = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  padding-inline: 12px;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
`;

export const NoContent = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #d1d7e1;
`;

export const PreviewContent = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
`;
