import styled from 'styled-components';
import { ReactComponent as LogoSmall } from '~/assets/ico-m.svg';
import { ReactComponent as Logo } from '~/assets/sidebar-icons/medway-logo.svg';

export const BackgroundBackdrop = styled.div`
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background: #00205b4d;
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
`;

export const LogoContainer = styled.div`
  display: flex;
  height: 84px;
  justify-content: center;
  align-items: center;
  padding: 36px 0 24px;
  position: relative;
`;

export const StyledLogo = styled(Logo)`
  height: 26px;
  opacity: 0;
  transform: scale(0.5);
  transition: 500ms ease-in;
  display: none;
`;

export const StyledLogoSmall = styled(LogoSmall)`
  height: 16px;
  opacity: 1;
  display: block;
  transition: opacity 500ms ease-in;
  path {
    fill: #00205b;
  }
`;

export const Menu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: containerAnimation 1s forwards;

  @keyframes containerAnimation {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const MenuSuperiorItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuItem = styled.div`
  display: flex;
  position: relative;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const MenuItemText = styled.p`
  opacity: 0;
  margin: 0;
  width: 0;
  pointer-events: none;
  margin-left: 0;
  color: #00205b;
  font-size: 12.6px;
  font-weight: 600;
  line-height: 16.9px;
  animation: fade_out_hide 700ms ease-in-out;
  transition: margin-left 700ms;
  white-space: nowrap;

  @keyframes fade_out_hide {
    0% {
      opacity: 1;
      width: 100%;
    }

    20% {
      opacity: 0;
    }

    100% {
      width: 0;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-shrink: 0;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 80px;
  min-height: 100vh;
  position: fixed;
  z-index: 1199;
  box-shadow: 0px 32px 64px 0px #17191c14, 0px 8px 32px 0px #87898f29;
  transition: width 400ms ease-in-out;

  @keyframes fade_in_show {
    0% {
      opacity: 0;
      width: 0;
    }

    57% {
      width: 100%;
    }

    100% {
      opacity: 1;
    }
  }

  &:hover {
    width: 210px;
  }

  &:hover + ${BackgroundBackdrop} {
    opacity: 0.3;
    display: block;
  }

  &:hover ${StyledLogoSmall} {
    opacity: 0;
    display: none;
  }

  &:hover ${StyledLogo} {
    opacity: 1;
    display: block;
    transform: scale(1);
  }

  &:hover ${MenuItemText} {
    opacity: 1;
    margin-left: 12px;
    pointer-events: unset;
    animation: fade_in_show 700ms ease-in;
    text-decoration: none;
  }

  &:hover ${MenuItem} {
    justify-content: flex-start;
  }
`;
