import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 16px 56px;
  border-bottom: 1px solid #e5e8ee;
  width: 100%;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 24px;
  width: 100%;
`;

export const FillButton = styled.button`
  display: flex;
  border-radius: 320px;
  background: #0caa87;
  height: 40px;
  width: 100%;
  padding: 10px 16px;

  justify-content: center;
  align-items: center;
  color: #fff;

  font-size: 14px;
  font-weight: 600;
  border: none;
  gap: 10px;

  :hover {
    background: #01cfb5;
  }

  :disabled {
    color: #8f9db7;
    background: #e5e8ee;
    cursor: default;
  }
`;

export const Rows = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;
  margin-bottom: 20px;

  label {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 21px;
  margin: 16px 0px;
`;

export const SelectContainer = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : '100%')};
  max-width: 544px;

  > div {
    width: 100%;
  }
`;

export const InputStyle = styled.div`
  max-width: 544px;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 48px;
  border: 3px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  :hover {
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    background-color: #fff;
    border: 3px solid #3d5582;
  }
  :focus-within {
    box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
    background-color: #fff;
    border: 3px solid #01cfb5;
  }
  input {
    width: 100%;
    height: 30px;
    border: none;
    background: none;
    color: #3d5582;
    font-size: 16px;

    ::placeholder {
      color: #3d5582;
      font-weight: 400;
    }
    ::-webkit-inner-spin-button {
      color: red;
    }
  }
`;
