import { useQuery } from '@tanstack/react-query';

import { DefaultUsersAPICMS } from '~/services/apiCallsCMS';

const getUser = async (id) => {
  try {
    const response = await DefaultUsersAPICMS.get(id);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useGetUserById(id, options) {
  return useQuery({
    queryKey: ['defaultUser', id],
    queryFn: () => getUser(id),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
