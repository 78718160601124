import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

export default function MultiselectAutocomplete({
  selectedOptions,
  setSelectedOptions,
  label,
  placeholder,
  options,
  fetchSuggestions,
  loading,
  multiSelectLimit = 5,
  disabled,
}) {
  const updateInput = (input) => {
    if (input) {
      fetchSuggestions(input, options);
    }
  };

  return (
    <Autocomplete
      value={selectedOptions}
      loading={loading}
      multiple
      clearText="Limpar"
      closeText="Fechar"
      loadingText="Carregando..."
      noOptionsText="Nenhuma opção encontrada"
      id="tags-outlined"
      options={options}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions
      onChange={(_, value) => setSelectedOptions(value)}
      onInputChange={(event) => updateInput(event?.target?.value)}
      getOptionDisabled={() => selectedOptions.length >= multiSelectLimit}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#0000008F',
              },
              '&:hover fieldset': {
                borderColor: '#0000008F',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#077859',
              },
            },
            '& .MuiInputLabel-root': {
              '&.Mui-focused': {
                color: '#077859',
              },
            },
          }}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option.label}
            sx={{
              backgroundColor: '#DAF5F1',
              color: '#077859',
            }}
          />
        ))
      }
    />
  );
}
MultiselectAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.object.isRequired,
};
MultiselectAutocomplete.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
};
