import produce from 'immer';

export const FILTERS_INITIAL_STATE = {
  id: '',
  track: '',
  tag: '',
  tag__parent_tag: '',
  institution__in: [],
  year__in: [],
  speciality: '',
  residency_degree: '',
  track__course__in: [],
  demand_type: '',
  institution__state: '',
  used_in_challenge:'',
  explanation_last_updated: '',
  question_type: '',
  difficulty: '',
  is_original: '',
  explanation_video__isnull: '',
  is_new: '',
  questionimage__isnull: '',
  page_size: 10,
  list_question: true,
};

export const INITIAL_STATE = {
  questions_filters: FILTERS_INITIAL_STATE,
  show_more_filters: null,
  filtered_questions: null,
  saved_institutions: null,
  saved_institutions_page: null,
  saved_courses: null,
  saved_courses_page: null,
};

export default function questionFilters(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@questionFilters/SAVE_FILTERS': {
        draft.questions_filters = action.payload.data;
        break;
      }
      case '@questionFilters/SHOW_MORE_FILTERS': {
        draft.show_more_filters = action.payload.data;
        break;
      }
      case '@questionFilters/SAVE_QUESTIONS': {
        draft.filtered_questions = action.payload.data;
        break;
      }
      case '@questionFilters/RESET_QUESTIONS': {
        draft.filtered_questions = null;
        break;
      }
      case '@questionFilters/SAVE_INSTITUTIONS': {
        draft.saved_institutions = action.payload.data.institutions;
        draft.saved_institutions_page = action.payload.data.page;
        break;
      }
      case '@questionFilters/SAVE_COURSES': {
        draft.saved_courses = action.payload.data.courses;
        draft.saved_courses_page = action.payload.data.page;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.questions_filters = null;
        draft.filtered_questions = null;
        draft.saved_institutions = null;
        draft.saved_courses = null;
        break;
      }
      default:
    }
  });
}
