import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import Axios from 'axios';
import { ActivityAPICMS } from '~/services/apiCallsCMS';
import { useGetTypeActivity } from '~/hooks-querys/activity';

import ModalWithHeader from '~/components/ModalWithHeader';
import Select from 'react-select';

import { SelectStyle } from '~/pages/_layouts/default/styles';
import { numericPrice, formatPrice } from '~/utils/util';
import * as S from './styles';

export default function CreateActivity({ showModal, handleClose }) {
  const [selectedTypeActivityNew, setSelectedTypeActitvityNew] = useState(null);
  const [nameActivity, setNameActivity] = useState(null);
  const [typeActivitiesNew, setTypeActivitiesNew] = useState([]);
  const [price, setPrice] = useState(null);
  const [priceFloat, setPriceFloat] = useState(0);

  const { data: dataTypeActivitiesNew } = useGetTypeActivity(
    {
      no_page: true,
      type_activity: selectedTypeActivityNew,
    },
    {
      config: { enabled: typeActivitiesNew.length === 0 },
    },
  );

  useEffect(() => {
    if (!dataTypeActivitiesNew) return;

    setTypeActivitiesNew(
      dataTypeActivitiesNew.map((element) => {
        return { label: element.name, value: element.id, key: element.id };
      }),
    );
  }, [dataTypeActivitiesNew]);

  const handleTypeActivity = (event) => {
    setSelectedTypeActitvityNew(event.value);
  };
  const handleNameTask = (event) => {
    setNameActivity(event.target.value);
  };
  const handlePrice = (event) => {
    let inputValue = event.target.value;
    setPriceFloat(numericPrice(inputValue));
    setPrice(formatPrice(inputValue, false));
  };

  const handleSave = () => {
    if (!selectedTypeActivityNew) {
      toast.error('Selecione o tipo da atividade');
      return;
    }

    if (!nameActivity) {
      toast.error('Digite o nome da atividade');
      return;
    }

    if (priceFloat === 0) {
      toast.error('Digite o preço');
      return;
    }

    const data = {
      type_activity: selectedTypeActivityNew,
      name: nameActivity,
      price: priceFloat,
    };

    const source = Axios.CancelToken.source();
    ActivityAPICMS.create(data, source.token).then(async (_result) => {
      toast.success('Atividade criada com sucesso');
      handleClose();
    });
  };
  return (
    <ModalWithHeader show={showModal} close={handleClose} title="Nova atividade">
      <S.ModalContent>
        <S.Container>
          <S.Rows>Tipo de atividade</S.Rows>

          <S.SelectContainer>
            <Select
              name="type-activity"
              placeholder="Selecione"
              onChange={handleTypeActivity}
              options={typeActivitiesNew}
              styles={SelectStyle}
            />
          </S.SelectContainer>
        </S.Container>
        <S.Container>
          <S.Rows>Atividade</S.Rows>

          <S.InputStyle>
            <input
              id="simulado"
              type="text"
              placeholder="Nome da atividade"
              onChange={handleNameTask}
              value={nameActivity || ''}
            />
          </S.InputStyle>
        </S.Container>
        <S.Container>
          <S.Rows>Preço</S.Rows>
          <S.InputStyle>
            <input
              id="price-override"
              type="text"
              placeholder="Preço"
              onChange={handlePrice}
              value={price && price}
            />
          </S.InputStyle>
        </S.Container>
      </S.ModalContent>
      <S.ModalFooter>
        <S.FillButton onClick={handleSave}>Salvar</S.FillButton>
      </S.ModalFooter>
    </ModalWithHeader>
  );
}
