import React from 'react';
import { ReactComponent as EmptyStateImage } from '~/assets/empty-state.svg';

import * as S from './styles';

export default function NewEmptyContent({ title, subtitle }) {
  return (
    <S.EmptyContentContainer>
      <S.EmptyContent>
        <EmptyStateImage />
        <S.EmptyContentText>
          <S.EmptyContentTitle>{title}</S.EmptyContentTitle>
          <S.EmptyContentSubtitle>{subtitle}</S.EmptyContentSubtitle>
        </S.EmptyContentText>
      </S.EmptyContent>
    </S.EmptyContentContainer>
  );
}
