import React from 'react';
import * as S from './styles';

function PageState({ icon, backgroundIcon = 'default', title, description }) {
  return (
    <S.Container>
      <S.ContainerIcon backgroundIcon={backgroundIcon}>
        <img src={icon} alt="icon" />
      </S.ContainerIcon>
      <S.ContainerText>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.ContainerText>
    </S.Container>
  );
}

export default PageState;
