import React from 'react';

export default function MedwayIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g id="medway">
      <path id="Vector" d="M16.1928 5.16094C14.513 5.16094 12.9975 5.80805 11.938 6.83738C10.9019 5.71417 9.36295 5 7.64292 5C4.86337 5 2.55323 6.86756 2.08382 9.32188C2.03018 9.62028 2 9.92875 2 10.2406V18.4317C2 18.7636 2.26823 19.0319 2.60017 19.0319H5.27242C5.60436 19.0319 5.87259 18.7636 5.87259 18.4317V11.1827C5.87259 10.1702 6.68734 9.18106 7.68986 9.05029C8.38055 8.95976 9.0109 9.20788 9.45013 9.64711C9.829 10.026 10.0637 10.549 10.0637 11.1291V18.4317C10.0637 18.7636 10.3319 19.0319 10.6639 19.0319H13.3361C13.6681 19.0319 13.9363 18.7636 13.9363 18.4317V11.1827C13.9363 10.1702 14.751 9.18106 15.7536 9.05029C16.4443 8.95976 17.0746 9.20788 17.5138 9.64711C17.8927 10.026 18.1274 10.549 18.1274 11.1291V18.4317C18.1274 18.7636 18.3956 19.0319 18.7276 19.0319H21.3998C21.7318 19.0319 22 18.7636 22 18.4317V10.4015C22 9.92204 21.9296 9.45935 21.7955 9.01676C21.1215 6.7938 18.8684 5.16094 16.1928 5.16094Z"/>
      </g>
    </svg>
  );
}
