import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.3px;
  color: #00205B;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 19px
  color: #4F5154;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-top: 1px solid #DADADB;
  padding-top: 24px;

  :first-child {
    border: none;
    padding-top: 0;
  }
`;

