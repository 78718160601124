import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '~/assets/drops-removefile-logo.svg';
import { ReactComponent as EditIcon } from '~/assets/drops-edit.svg';
import { ReactComponent as ViewIcon } from '~/assets/drops-eye.svg';
import { ReactComponent as SearchIcon } from '~/assets/drops-search.svg';
import { ReactComponent as ConfigurationIcon } from '~/assets/configuration-icon.svg';

export const StyledDeleteIcon = styled(DeleteIcon)`
  width: 16px;
  path {
    fill: #077859;
  }
`;

export const StyledEditIcon = styled(EditIcon)`
  width: 20px;
  path {
    fill: #077859;
  }
`;

export const StyledViewIcon = styled(ViewIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: #077859;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  width: 25px;
  height: 25px;
  path {
    fill: #077859;
  }
`;

export const StyledConfigurationIcon = styled(ConfigurationIcon)`
  width: 20px;
`;
