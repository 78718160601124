import React, { useState, memo, useEffect } from 'react';
import { TextField } from '@material-ui/core';

import { InputStyle } from './styles';

function InputMUI({
  label,
  value,
  placeholder,
  handleBlur,
  textfieldStyle,
  size,
  type,
  id,
  inputCustomStyles,
  inputProps,
  setFormsData,
}) {
  const [localValue, setLocalValue] = useState('');

  let initialValueChange = true;
  const handleChange = (event) => {
    setLocalValue(event.target.value);
  };

  useEffect(() => {
    if (!!value && initialValueChange) {
      setLocalValue(value);
      initialValueChange = false;
    }
  }, [value]);

  return (
    <InputStyle inputCustomStyles={inputCustomStyles}>
      <TextField
        type={type}
        id={`input-component-${id}`}
        label={label}
        value={localValue}
        variant="outlined"
        placeholder={placeholder}
        size={size}
        onBlur={() => handleBlur(id, localValue, setFormsData)}
        onChange={handleChange}
        style={textfieldStyle}
        inputProps={inputProps}
      />
    </InputStyle>
  );
}

export default memo(InputMUI);
