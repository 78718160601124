/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';

import { MdSort } from 'react-icons/md';

import {
  Container,
  QuestionsNavigation,
  QuestionNavContainer,
  // QuestionsTag,
  QuestionNavNumber,
  Scroll,
} from './styles';

const QuestionSelector = ({ selected, setSelected, questionsArray }) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <Container>
      <QuestionsNavigation>
        <Scroll>
          <QuestionNavContainer key={-1} style={{ marginTop: 20 }}>
            <QuestionNavNumber>
              <MdSort size={30} color="#fff" />
            </QuestionNavNumber>
          </QuestionNavContainer>
          {questionsArray.map((question, index) => (
            <div key={question.id} ref={index === selected ? scrollRef : null}>
              <div onClick={() => setSelected(index)}>
                <QuestionNavContainer key={question.id} selected={index === selected}>
                  {/*<QuestionsTag />*/}
                  <QuestionNavNumber selected={index === selected}>
                    {question.question_number}
                  </QuestionNavNumber>
                </QuestionNavContainer>
              </div>
            </div>
          ))}
        </Scroll>
      </QuestionsNavigation>
    </Container>
  );
};

export default QuestionSelector;
