export const transformOptions = (value) => {
  if (!value.id || !value.name) return;

  return {
    label: value.name,
    key: value.id,
    value: value.id,
    abbreviation: value.abbreviation,
  };
};
