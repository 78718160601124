import React from 'react';
import SpecialityLogo from '~/assets/drops-specialities-logo';
import { Container, ListOptions, ItemOptions, TextOptions, AddText } from './styles';

const AddSpecialties = ({ options, addedSpecialties }) => {
  return (
    <Container>
      <ListOptions>
        <AddText> Adicionar:</AddText>
        {options.map((item) => {
          return (
            <ItemOptions
              key={item.label}
              text={item.colors.text}
              onClick={() => addedSpecialties(item)}
            >
              <SpecialityLogo fill={item.colors.text} />

              <TextOptions text={item.colors.text}>{item.label}</TextOptions>
            </ItemOptions>
          );
        })}
      </ListOptions>
    </Container>
  );
};

export default AddSpecialties;
