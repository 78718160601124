import React, { useState, useEffect } from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SelectWithPagination from '~/components/SelectWithPagination';
import MultiselectInstitutions from '~/components/MultiselectInstitutions';
import DatePicker from '~/components/StyledDatePicker';
import StatusCheckbox from '../StatusCheckbox';
import { useFilterSpecialitys } from '~/hooks-querys/filters';
import { useResidencyDegree } from '~/hooks-querys/residencydegree';
import { loadTags } from '~/pages/Chapters/components/Settings/utils';
import { loadDefaultUsers, loadTypeActivities, loadInstitutions, transformOptions } from './utils';
import { FILTERS_INITIAL_STATE } from '../../variables';
import { Mixpanel } from '~/services/analytics';
import * as S from './styles';

function FilterDropdown({ formsData, setFormsData, handleFilter, clearFilters }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [institutions, setInstitutions] = useState([]);
  const [institutionsLoading, setInstitutionsLoading] = useState(false);
  const [selectedCheckboxItems, setSelectedCheckboxItems] = useState({
    p: false,
    i: false,
    c: false,
  });

  const open = anchorEl;
  const id = open ? 'filter-dropdown' : undefined;

  const { data: specialitysData } = useFilterSpecialitys({
    no_page: true,
    ordering: 'name',
    main_speciality: true,
  });

  const { data: residencyDegreeData } = useResidencyDegree({
    no_page: true,
  });

  const handleChange = (item, formProp) => {
    return setFormsData((formsData) => {
      return { ...formsData, [formProp]: item };
    });
  };

  const handleChangeInstitutions = (options) => {
    return setFormsData((formsData) => {
      return { ...formsData, selectedInstitutions: options };
    });
  };

  const filterInstitutions = async (search) => {
    if (!search) {
      return institutions;
    }
    return institutions.filter((item) => search.includes(item.label));
  };

  const loadSpecialities = () => {
    return {
      options: specialitysData.map(transformOptions),
      hasMore: false,
    };
  };

  const loadResidencyDegree = () => {
    return {
      options: residencyDegreeData.map(transformOptions),
      hasMore: false,
    };
  };

  const handleClick = (event) => {
    Mixpanel.track("Clicou 'Lista de atividades - Filtrar'");
    if (!anchorEl) {
      return setAnchorEl(event.currentTarget);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const firstLoadInstitutions = (search) => {
    setInstitutionsLoading(true);
    loadInstitutions(search).then((result) => {
      setInstitutions([...institutions, ...result]);
      setInstitutionsLoading(false);
    });
  };

  const handleClear = () => {
    Mixpanel.track("Clicou 'Lista de atividades - Filtrar - Limpar'");
    setFormsData(FILTERS_INITIAL_STATE);
    setSelectedCheckboxItems([]);
    clearFilters();
  };

  useEffect(() => {
    firstLoadInstitutions();
  }, []);

  useEffect(() => {
    if (!selectedCheckboxItems) return;

    return setFormsData((formsData) => {
      return { ...formsData, selectedStatus: selectedCheckboxItems };
    });
  }, [selectedCheckboxItems]);

  const singleValueStyle = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '95%',
  };

  const RenderSelect = (name, propName, loadOptions, placeholder) => {
    return (
      <SelectWithPagination
        height={40}
        name={name}
        value={formsData[propName]}
        onChange={(item) => handleChange(item, propName)}
        loadOptions={loadOptions}
        placeholder={placeholder}
        singleValueStyle={singleValueStyle}
        asyncPaginateKey={`async-paginate-${formsData[propName]?.label}`}
      />
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <S.ButtonStyle onClick={handleClick}>
          <S.ButtonText>Filtrar</S.ButtonText> <S.StyledDropdownIndicator />
        </S.ButtonStyle>

        <Popper
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ zIndex: 1190 }}
          disablePortal
        >
          <S.Content>
            {RenderSelect(
              'activity-type',
              'selectedActivity',
              loadTypeActivities,
              'Tipo de atividade',
            )}
            {RenderSelect('speciality', 'selectedSpeciality', loadSpecialities, 'Especialidade')}
            {RenderSelect('partner', 'selectedPartner', loadDefaultUsers, 'Parceiro')}
            {RenderSelect(
              'residencyDegree',
              'selectedResidencyDegree',
              loadResidencyDegree,
              'Nível de residência',
            )}
            <S.DateContainer>
              <DatePicker
                label="Prazo de"
                dateFormat="dd/MM/yyyy"
                value={formsData.selectedInitialDate}
                handleChange={(item) => handleChange(item, 'selectedInitialDate')}
              />
              <DatePicker
                label="Até"
                dateFormat="dd/MM/yyyy"
                value={formsData.selectedFinalDate}
                handleChange={(item) => handleChange(item, 'selectedFinalDate')}
              />
            </S.DateContainer>
            <MultiselectInstitutions
              selectedOptions={formsData.selectedInstitutions}
              setSelectedOptions={handleChangeInstitutions}
              label={institutionsLoading ? 'Carregando...' : ''}
              placeholder="Instituição de Ensino Superior"
              options={institutions}
              fetchSuggestions={filterInstitutions}
              loading={institutionsLoading}
              disabled={institutionsLoading}
              listMaxHeight={'16vh'}
            />

            <StatusCheckbox
              selectedItems={selectedCheckboxItems}
              setSelectedItems={setSelectedCheckboxItems}
            />
            {RenderSelect('tag', 'selectedTag', loadTags, 'Tags')}

            <S.FilterButtonsContainer>
              <S.ClearButton onClick={handleClear}>
                <S.StyledClearIcon /> Limpar
              </S.ClearButton>
              <S.FilterButton onClick={handleFilter}>
                <S.StyledSearchIcon /> Filtrar
              </S.FilterButton>
            </S.FilterButtonsContainer>
          </S.Content>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

export default FilterDropdown;
