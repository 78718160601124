import React from 'react';
import ModalWithHeader from '~/components/ModalWithHeader';
import {
  ModalContent,
  ModalText,
  ModalFooter,
  BackButton,
  FillButton,
  StyledArrowLeft,
} from './styles';
export default function ModalConfirmation({
  title,
  message,
  actionText,
  showModal,
  handleClose,
  handleAction,
}) {
  return (
    <ModalWithHeader show={showModal} close={handleClose} title={title}>
      <ModalContent>
        <ModalText>{message}</ModalText>
      </ModalContent>
      <ModalFooter>
        <BackButton onClick={handleClose}>
          <StyledArrowLeft />
          Voltar
        </BackButton>
        <FillButton onClick={handleAction}>{actionText}</FillButton>
      </ModalFooter>
    </ModalWithHeader>
  );
}
