import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components } from 'react-select';

import { RemoveButton, SelectContainer, SelectDescription, Value, ValuesContainer } from './styles';

export default function SelectWithPagination({
  value,
  defaultValue,
  name,
  text,
  placeholder,
  onChange,
  loadOptions,
  width,
  height,
  backgroundColor,
  disabled,
  marginLeft,
  marginTop,
  controlStyle,
  optionsStyle,
  singleValueStyle,
  placeholderStyle,
  valueContainerStyle,
  dropdownIndicatorStyle,
  clearIndicatorStyle,
  indicatorsContainerStyle,
  containerStyle,
  customDropdownIndicator,
  customNoOptionsMessage,
  isMulti,
  containerWidth,
  asyncPaginateKey,
  menuOnTop,
}) {
  const SelectStyle = {
    container: (styles) => ({
      ...styles,
      color: '#3D5582',
      width: width || '100%',
      ...containerStyle,
    }),
    control: (styles) => ({
      ...styles,
      background: backgroundColor,
      border: '2px solid #E5E8EE',
      borderRadius: 8,
      height: height,
      fontWeight: 400,
      fontSize: 14.2,
      letterSpacing: 0.15,
      cursor: 'pointer',
      paddingLeft: -10,
      marginLeft: marginLeft || 0,
      marginTop: marginTop || 0,
      ':hover': {
        backgroundColor: '#FFF',
        border: '3px solid #3D5582',
        fontWeight: 600,
        boxShadow: '0px 0px 0px 6px rgba(61, 85, 130, 0.2)',
      },
      ':focus-within': {
        backgroundColor: '#FFF',
        border: '3px solid #01cfb5',
        boxShadow: '0px 0px 0px 6px rgba(1, 207, 181, 0.2)',
      },
      ...controlStyle,
    }),
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: '#fff',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        cursor: 'pointer',
        letterSpacing: 0.15,
        color: '#3D5582',
        ':hover': {
          backgroundColor: '#F0F2F5',
        },
        ...optionsStyle,
      };
    },
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
      ...(menuOnTop && { bottom: '100%', top: 'unset' }),
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#3D5582',
      ':focus-within': {
        color: '#01cfb5',
      },
      ...dropdownIndicatorStyle,
    }),
    clearIndicator: (styles) => ({
      ...styles,
      display: 'none',
      ...clearIndicatorStyle,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      color: '#3D5582',
      svg: {
        border: '2px solid #3d5582',
        borderRadius: '100px',
        transition: 'border 0.2s ease-in-out',
      },
      ':hover': {
        svg: {
          border: '2px solid #01cfb5',
          color: '#01cfb5',
        },
      },
      ':active': {
        svg: {
          border: '2px solid #01cfb5',
          color: '#01cfb5',
        },
      },
      ...indicatorsContainerStyle,
    }),
    loadingIndicator: (styles) => ({
      ...styles,
      color: '#00205b',
      fontSize: 8,
      ...indicatorsContainerStyle,
    }),
    indicatorSeparator: () => ({
      backgroundColor: '#fff',
    }),
    singleValue: () => ({
      color: '#3D5582',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '21px',
      border: 0,
      backgroundColor: backgroundColor || '#f5f5fb',
      opacity: disabled ? 0.5 : 1,
      ...singleValueStyle,
    }),
    placeholder: (styles) => ({
      ...styles,
      border: 0,
      color: '#3D5582',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '21px',
      ...placeholderStyle,
    }),
    valueContainer: (styles) => ({
      ...styles,
      paddingTop: 0,
      ...valueContainerStyle,
    }),
    disabled: (styles) => ({
      ...styles,
      color: '#c5c5c5',
      fontSize: 12,
      border: 0,
    }),
  };

  const handleRemoveValue = (event) => {
    if (!event.currentTarget || !onChange) return;
    const { name: buttonIndex } = event.currentTarget;
    onChange(value.filter((val) => val.value !== parseInt(buttonIndex, 10)));
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {customDropdownIndicator}
      </components.DropdownIndicator>
    );
  };

  return (
    <div style={{ width: containerWidth || '100%' }}>
      <SelectContainer>
        {text && <SelectDescription>{text}</SelectDescription>}
        <AsyncPaginate
          key={asyncPaginateKey || 'async-paginate-key'}
          name={name}
          value={value}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
          loadOptions={loadOptions}
          styles={SelectStyle}
          isDisabled={disabled}
          isMulti={isMulti}
          controlShouldRenderValue={!isMulti}
          components={customDropdownIndicator ? { DropdownIndicator } : {}}
          noOptionsMessage={() => (
            <div>{customNoOptionsMessage || 'Nenhum resultado encontrado'}</div>
          )}
          additional={{
            page: 1,
          }}
        />
      </SelectContainer>
      {isMulti && value && (
        <ValuesContainer>
          {value.map((val) => (
            <Value key={val.value}>
              {val.label}
              <RemoveButton name={val.value} onClick={(event) => handleRemoveValue(event)}>
                ✕
              </RemoveButton>
            </Value>
          ))}
        </ValuesContainer>
      )}
    </div>
  );
}

SelectWithPagination.defaultProps = {
  name: 'order',
  placeholder: 'Mais recentes',
  text: false,
  width: '100%',
  height: '40px',
  backgroundColor: '#F0F2F5',
  disabled: false,
  controlStyle: {},
  optionsStyle: {},
  singleValueStyle: {},
  placeholderStyle: {},
  valueContainerStyle: {},
  dropdownIndicatorStyle: {},
  clearIndicatorStyle: {},
  indicatorsContainerStyle: {},
  containerStyleStyle: {},
  customDropdownIndicator: null,
  customNoOptionsMessage: 'Nenhuma opção encontrada',
  isMulti: null,
};
