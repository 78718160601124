import styled from 'styled-components';

export const RowContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 16px;
	line-height: 21px;
	margin: 16px 0px;
	padding-top: 20px;
`;

export const RowNested = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 16px;
	line-height: 21px;
`;

export const Rows = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 20.3px;
	line-height: 27px;
	color: #00205b;
	margin-bottom: 20px;

	label {
		width: 100%;
	}
`;

export const RowMinor = styled.div`
	margin-top: -15px;
	margin-bottom: 20px;
	color: #4f5154;

	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

export const InputStyle = styled.div`
	max-width: 544px;
	width: ${(props) => (props.width ? props.width : '100%')};
	height: 48px;
	border: 3px solid #e5e8ee;
	border-radius: 8px;
	background: #f0f2f5;
	padding: 7px 16px;
	display: flex;
	:hover {
		box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
		background-color: #fff;
		border: 3px solid #3d5582;
	}
	:focus-within {
		box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
		background-color: #fff;
		border: 3px solid #01cfb5;
	}
	input {
		width: 100%;
		height: 30px;
		border: none;
		background: none;
		color: #3d5582;
		font-size: 16px;

		::placeholder {
			color: #3d5582;
			font-weight: 400;
		}
		::-webkit-inner-spin-button {
			color: red;
		}
	}
`;

export const RadioContainer = styled.div`
	display: flex;
	gap: 24px;
	align-self: stretch;
`;

export const RowContainerInputs = styled.div`
	padding-top: 20px;
	display: flex;
	flex-direction: row;
	gap: 30px;
	width: 100%;
`;

export const SelectContainer = styled.div`
	display: flex;
	width: ${(props) => (props.width ? props.width : '100%')};
	max-width: 544px;

	> div {
		width: 100%;
	}
`;
