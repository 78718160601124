/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { format, isAfter } from 'date-fns';
import { BiLinkAlt, BiTime } from 'react-icons/bi';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { TextField } from '@material-ui/core';
import ButtonWithPopper from '~/components/ButtonWithPopper';
import CardComponent from '~/components/CardComponent';
import Loader from '~/components/Loader';
import { InputStyle } from '~/components/InputMUI/styles';
import StatusDropdown from '../StatusDropdown';
import { useGetOffcanvasData, useGetTaskNote } from '~/hooks-querys/tasks';
import { useSpecialityById } from '~/hooks-querys/speciality';
import { useTagById } from '~/hooks-querys/tag';
import { useGetInstitutionById } from '~/hooks-querys/institutions';
import { TaskNoteAPICMS } from '~/services/apiCallsCMS';

import * as S from './styles';

const emptyNote = {
  user: undefined,
  id: undefined,
  task: undefined,
  note: undefined,
};

export default function OffCanvas({ show, close, activityId, handleChangeStatus, user }) {
  const [observation, setObservation] = useState(emptyNote);
  const [emptyObservation, setEmptyObservation] = useState(true);
  const [observationValue, setObservationValue] = useState('');

  const {
    data: taskData,
    isLoading: loadingTaskData,
    refetch: refetchTask,
  } = useGetOffcanvasData(
    activityId,
    { with_answers: true },
    {
      config: {
        enabled: !!activityId,
      },
    },
  );

  const { data: tag, isFetching: fetchingTag } = useTagById(taskData?.tag_id, {
    config: {
      enabled: !!taskData?.tag_id,
      retry: false,
    },
  });

  const { data: parentTag, isFetching: fetchingParentTag } = useTagById(tag?.parent_tag, {
    config: {
      enabled: !!taskData?.tag_id && !!tag?.parent_tag,
      retry: false,
    },
  });

  const { data: speciality, isFetching: fetchingSpeciality } = useSpecialityById(
    taskData?.speciality_id,
    {
      config: {
        enabled: !!taskData?.speciality_id,
        retry: false,
      },
    },
  );

  const { data: institution, isFetching: fetchingInstitution } = useGetInstitutionById(
    taskData?.institution_id,
    {
      config: {
        enabled: !!taskData?.institution_id,
        retry: false,
      },
    },
  );

  const {
    data: taskNote,
    isFetching: fetchingTaskNote,
    refetch: refetchTaskNote,
  } = useGetTaskNote(
    {
      user: user.id,
      task: activityId,
    },
    {
      config: {
        enabled: !!user.id || !!activityId,
      },
    },
  );

  useEffect(() => {
    if (!taskNote) return;

    setObservation(taskNote.length > 0 ? taskNote[0] : emptyNote);
    setObservationValue(taskNote.length > 0 ? taskNote[0].note : '');
    setEmptyObservation(taskNote.length === 0);
  }, [taskNote]);

  const handleStatus = (status, activityId) => {
    handleChangeStatus(status, activityId);
    close();
  };

  const returnValue = (price) => {
    const stringPrice = price.toString();

    if (stringPrice.toString().includes('.')) {
      return `R$ ${stringPrice.replace('.', ',')}`;
    }
    return `R$ ${stringPrice},00`;
  };

  const handleObservation = (id, value) => {
    if (emptyObservation) {
      return TaskNoteAPICMS.create({ task: id, note: value }).then(() => refetchTaskNote());
    }
    return TaskNoteAPICMS.update(observation.id, { note: value }).then(
      () => setTimeout(() => refetchTaskNote()),
      100,
    );
  };

  const handleDeleteObservation = () => {
    if (!observation.note) return;
    TaskNoteAPICMS.delete(observation.id).then(() => refetchTaskNote());
  };

  const handleChangeObservationInput = (event) => {
    setObservationValue(event.target.value);
  };

  const renderItem = (title, description, extra, isQuestionId) => {
    const isLink = description.toUpperCase().includes('HTTP') || isQuestionId;

    return (
      <S.ContentItem>
        <S.ContentTitle>{title}</S.ContentTitle>
        {isLink ? (
          <S.LinkButtonContainer>
            <S.LinkButton onClick={() => window.open(description, '_blank')}>
              <BiLinkAlt size={16} color="#019D8A" />
            </S.LinkButton>
          </S.LinkButtonContainer>
        ) : (
          <S.ContentDescription>
            {description}
            {extra && (
              <>
                <br /> {extra}
              </>
            )}
          </S.ContentDescription>
        )}
      </S.ContentItem>
    );
  };

  const renderDeliveryDate = (deadline, endedAt) => {
    let delayedDate = false;

    if (endedAt) {
      delayedDate = isAfter(new Date(endedAt), new Date(deadline));
    } else {
      delayedDate = isAfter(new Date(), new Date(deadline));
    }

    return (
      <S.ContentItem>
        <S.ContentTitle>Prazo de entrega</S.ContentTitle>
        <S.ContentDescription error={delayedDate}>
          {delayedDate && <BiTime size={14} color={'#EE1F38'} />}
          {format(new Date(taskData?.deadline), 'dd/MM/yyyy')}
        </S.ContentDescription>
      </S.ContentItem>
    );
  };

  const renderFormsItems = () => {
    return (
      <>
        {taskData?.form_answers &&
          taskData?.form_answers.length > 0 &&
          taskData?.form_answers.map((answer) => {
            return renderItem(answer.label, answer.value);
          })}
      </>
    );
  };

  return (
    <Drawer
      open={show}
      onClose={close}
      anchor="right"
      sx={{
        '& .MuiDrawer-paper': {
          overflowY: 'hidden',
        },
      }}
    >
      <S.Container>
        {loadingTaskData ||
        fetchingTag ||
        fetchingParentTag ||
        fetchingSpeciality ||
        fetchingInstitution ? (
          <Loader />
        ) : (
          <CardComponent type="important" style={{ width: '100%' }} background="#fff">
            <S.Header>
              <CloseIcon
                onClick={close}
                style={{
                  position: 'absolute',
                  top: '24px',
                  right: '24px',
                  fill: '#ACACAE',
                  cursor: 'pointer',
                  width: '24px',
                  height: '24px',
                }}
              />
              <S.Title>{taskData?.name}</S.Title>
            </S.Header>
            <S.Content>
              <S.ContentBlock>
                {taskData && (
                  <>
                    {taskData?.status && (
                      <S.ContentItem>
                        <S.ContentTitle>status</S.ContentTitle>
                        <S.StatusContainer>
                          <StatusDropdown
                            name="offcanvas-status-dropdown"
                            activityId={activityId}
                            status={taskData?.status}
                            changeStatus={handleStatus}
                          />
                        </S.StatusContainer>
                      </S.ContentItem>
                    )}
                    {taskData?.deadline &&
                      renderDeliveryDate(taskData?.deadline, taskData?.ended_at)}
                    {taskData?.responsible_info &&
                      renderItem(
                        'parceiro',
                        taskData.responsible_info.name,
                        taskData.responsible_info.email,
                      )}
                    {taskData?.speciality_id &&
                      speciality &&
                      renderItem('grande área', speciality?.name)}
                    {taskData?.speciality_id &&
                      institution &&
                      renderItem('instituição', institution?.name)}
                    {taskData?.tag_id && tag && parentTag && (
                      <>
                        {renderItem('Tema', parentTag?.name)}
                        {renderItem('Foco', tag?.name)}
                      </>
                    )}
                    {taskData?.tag_id && tag && !parentTag && <>{renderItem('Tema', tag?.name)}</>}
                    {taskData?.task_price && (
                      <>{renderItem('Valor', returnValue(taskData.task_price))}</>
                    )}
                    {taskData?.question_id && (
                      <>
                        {renderItem(
                          'URL Da Questão',
                          `question/${taskData?.question_id}/text`,
                          '',
                          true,
                        )}
                      </>
                    )}

                    {renderFormsItems()}
                  </>
                )}
              </S.ContentBlock>
              <S.ObservationContainer>
                {fetchingTaskNote ? (
                  <Loader />
                ) : (
                  <>
                    <S.TitleContainer>
                      <S.ObservationTitle>Observações</S.ObservationTitle>
                      <S.PopperContainer>
                        <ButtonWithPopper minWidth="120px" popperStyle={{ zIndex: '1201' }}>
                          <S.ButtonPopper onClick={() => handleDeleteObservation()}>
                            Excluir
                          </S.ButtonPopper>
                        </ButtonWithPopper>
                      </S.PopperContainer>
                    </S.TitleContainer>
                    <InputStyle>
                      <TextField
                        type="text"
                        id={`input-component-${activityId}`}
                        label="Adicione alguma observação"
                        value={observationValue}
                        variant="outlined"
                        placeholder="Adicione alguma observação"
                        onBlur={() => handleObservation(activityId, observationValue)}
                        onChange={handleChangeObservationInput}
                        style={{ minHeight: '200px' }}
                      />
                    </InputStyle>
                  </>
                )}
              </S.ObservationContainer>
            </S.Content>
          </CardComponent>
        )}
      </S.Container>
    </Drawer>
  );
}
