import styled from 'styled-components';

export const MainContainerError = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px, 56px, 40px, 56px;
  gap: 16px;
  position: relative;
  width: 438px;
  height: 253px;
  left: 30%;
  background: #ffffff;
  border: 2px dashed #ee1f38;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 6px rgba(239, 35, 60, 0.2);
`;

export const IconGroupError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  isolation: isolate;
  width: 64px;
  height: 64px;
  background: #fcdbdf;
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const MainLbError = styled.span`
  width: 155px;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00205b;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const GroupError = styled.div`
  width: 32px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 1;
`;

export const ContainerError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 232px;
  height: 45px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const SubLbError = styled.span`
  width: 190px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12.6px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3d5582;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const PlusContainerError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  position: absolute;
  width: 18px;
  height: 18px;
  right: 185px;
  bottom: 125px;
  background: #ee1f38;
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
`;
