import React from 'react';
import RemoveFileLogo from '~/assets/drops-removefile-logo.svg';
import URLIcon from '~/assets/drops-url-icon.svg';
import IconFileLogo from '~/assets/drops-fileicon-logo.svg';
import * as S from './styles';

const VideoPlayer = (props) => {
  return (
    <S.VideoContainer>
      <S.PlayerContainer>
        <iframe
          src={props.urlEmbed}
          height="150px"
          width="300px"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title={props.title}
        />
      </S.PlayerContainer>
      <S.FileContainer>
        <S.IconFileContainer>
          <img src={IconFileLogo} alt="File logo" />
        </S.IconFileContainer>
        <S.FileName>{props.fileName}</S.FileName>
        <S.ContainerLogoRemove>
          <S.RemoveFileContainer onClick={props.onClick}>
            <img src={RemoveFileLogo} alt="Remove file logo" />
          </S.RemoveFileContainer>
        </S.ContainerLogoRemove>
      </S.FileContainer>
      <S.FileContainer>
        <S.UrlIconContainer>
          {' '}
          <img src={URLIcon} alt="URL Icon" />
        </S.UrlIconContainer>
        <S.VideoURL>
          <a href={props.url}> {props.url}</a>
        </S.VideoURL>
      </S.FileContainer>
    </S.VideoContainer>
  );
};
export default VideoPlayer;
