import React, { useEffect, useState, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import HeaderComponent from '~/components/HeaderComponent';
import Loader from '~/components/Loader';
import Tabs from '~/components/Tabs';
import { clearChapter, requestChapterSelected } from '~/store/modules/chapter/actions';

import Bibliography from '../components/Bibliography';
import EditContent from '../components/EditContent';
import Settings from '../components/Settings';

import * as S from './styles';

function EditChapter() {
  const { location } = useHistory();
  const dispatch = useDispatch();

  const {
    chapterSelected: { chapter, requesting },
  } = useSelector((state) => state.chapter);

  const [step, setStep] = useState(0);

  const getChapter = useCallback(
    (id) => {
      dispatch(requestChapterSelected(id));
    },
    [dispatch],
  );

  useEffect(() => {
    const id = location.pathname.split('/chapter/edit/')[1];
    getChapter(id);
    return () => {
      dispatch(clearChapter());
    };
  }, [location, step]);

  const changeStep = (actualStep) => {
    setStep(actualStep);
  };

  return (
    <>
      <HeaderComponent title={chapter?.name} />
      <S.Wrap>
        {requesting ? (
          <Loader />
        ) : (
          <S.Container>
            <Tabs selectedTab={step} changeStep={changeStep}>
              <div label="Configurações">
                <Settings changeStep={changeStep} chapter={chapter} editMode />
              </div>
              <div label="Edição de capítulo">
                <EditContent changeStep={changeStep} chapter={chapter} editMode />
              </div>
              <div label="Bibliografia">
                <Bibliography changeStep={changeStep} chapter={chapter} editMode />
              </div>
            </Tabs>
          </S.Container>
        )}
      </S.Wrap>
    </>
  );
}

export default EditChapter;
