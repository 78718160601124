import styled from 'styled-components';

export const InputHide = styled.div`
  input[type='file'] {
    display: none;
  }
`;

export const MainLb = styled.span`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 286px;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00205b;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const SubLb = styled.span`
  width: 190px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12.6px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3d5582;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const MainContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px, 56px, 40px, 56px;
  gap: 16px;
  position: relative;
  width: 438px;
  height: 253px;
  left: 30%;
  background: #ffffff;
  border: 2px dashed #e5e8ee;
  border-radius: 8px;
  :hover {
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    border-radius: 8px;
    border: 2px dashed #3d5582;
  }
`;

export const MainContainerVideo = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px, 56px, 40px, 56px;
  gap: 16px;
  position: relative;
  top: 20px;
  width: 438px;
  height: 240x;
  left: 30%;
  background: #ffffff;
  border: 2px dashed #e5e8ee;
  border-radius: 8px;
  :hover {
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    border-radius: 8px;
    border: 2px dashed #3d5582;
  }
`;

export const BtSelect = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  width: 145px;
  height: 32px;
  background: #01c3aa;
  border-radius: 1000px;
  flex: none;
  order: 2;
  flex-grow: 0;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 12px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  :hover {
    box-shadow: 0px 0px 0px 3px rgba(1, 195, 170, 0.2);
  }
`;

export const PlusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  position: absolute;
  width: 18px;
  height: 18px;
  right: 190px;
  bottom: 150px;
  background: #00205b;
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
`;

export const Group = styled.div`
  width: 32px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 1;
`;

export const IconGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 64px;
  height: 64px;
  background: #f0f2f5;
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ButtonCancel = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 12px;
  background: none;
  gap: 8px;
  width: 82px;
  height: 32px;
  border: 2px solid #e5e8ee;
  border-radius: 1000px;
  flex: none;
  order: 2;
  flex-grow: 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00205b;
  flex: none;
  order: 1;
  flex-grow: 0;
`;
