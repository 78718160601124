import React from 'react';
import Modal from '@material-ui/core/Modal';
import { CardModal, Title } from './styles';
import CloseIcon from '@material-ui/icons/Close';

function GenericModal({
  children,
  show, 
  close,
  title,
}) {
    return (
    <Modal
        open={show}
        onClose={close}
    >
        <CardModal>
            <CloseIcon 
                onClick={close} 
                style={{ 
                    position: 'absolute', 
                    top: '30px',
                    right: '30px',
                    fill: '#ACACAE',
                    cursor: 'pointer',
                    width: '24px',
                    height: '24px' 
                }}/>
            <Title>{title}</Title>
            {children}
        </CardModal>
    </Modal>
    );
}

export default GenericModal;
