import { useQuery } from '@tanstack/react-query';
import { GetSubjectTagsAPI } from '~/services/apiCallsCMS';

const listSubjectTags = async (params) => {
  try {
    const response = await GetSubjectTagsAPI.list(params);

    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useListSubjectTags(params, options) {
  return useQuery({
    queryKey: ['listSubjectTags', params],
    queryFn: () => listSubjectTags(params),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
