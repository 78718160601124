import { createStore, applyMiddleware } from 'redux';

export default (reducers, middlewares) => {
  const enhancer = applyMiddleware(...middlewares);
    // process.env.REACT_APP_ENV === 'development'
    //   ? compose(console.tron.createEnhancer(), applyMiddleware(...middlewares))
    //   : applyMiddleware(...middlewares);

  return createStore(reducers, enhancer);
};
