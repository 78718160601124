import styled from 'styled-components';

export const EmptyCommentIconContaner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-image: radial-gradient(#fff 0%, #E5E8EE 100%);
`;

export const EmptyCommentIcon = styled.img`
  width: 22.14px;
  height: 22.14px;
`;

export const EmptyCommentContainer = styled.div`
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyComment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;gap: 16px;
`;

export const EmptyCommentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-align: center;
`;

export const EmptyCommentTitle = styled.h3`
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205B;
  margin: 0;
`;

export const EmptyCommentSubtitle = styled.p`
  font-size: 16px;
  line-height: 21.3px;
  color: #3D5582;
  margin-bottom: 32px;
`;