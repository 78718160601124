import React from 'react';

export default function DropsCircleCheck() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99996 18.3332C5.39968 18.3281 1.67168 14.6001 1.66663 9.99985V9.83319C1.75823 5.25363 5.52878 1.60649 10.1089 1.66725C14.689 1.72803 18.3614 5.47393 18.3315 10.0543C18.3015 14.6347 14.5805 18.3323 9.99996 18.3332ZM6.17496 9.65819L4.99996 10.8332L8.33329 14.1665L15 7.49985L13.825 6.31652L8.33329 11.8082L6.17496 9.65819Z"
        fill="#407EC9"
      />
    </svg>
  );
}
