import React from 'react';
import {
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  createTheme,
  ThemeProvider,
} from '@material-ui/core';

function RadioButton(props) {
  const theme = createTheme({
    typography: {
      fontFamily: ['Montserrat'],
      fontWeight: 400,
      fontSize: 16,
      fontStyle: 'normal',
      color: '#00205B',
    },
    palette: {
      primary: {
        main: '#01CFB5',
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        <RadioGroup value={props.control} onChange={props.onChange} name={props.name} row>
          <FormControlLabel
            value={props.value}
            label={props.text}
            key={props.key}
            control={
              <Radio
                color="primary"
                style={props.radioStyles}
                disableRipple={props.disableRipple || false}
              />
            }
            style={{ ...theme.typography, ...props.formControlLabelStyles }}
          />
        </RadioGroup>
      </FormControl>
    </ThemeProvider>
  );
}

export default RadioButton;
