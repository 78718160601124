import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MdArrowBack } from 'react-icons/md';

import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

import { useSearchQuestionsModalGet } from '~/hooks-querys/searchQuestionsModal';
import { saveFilters, saveQuestions } from '~/store/modules/questionFilters/actions';

import Filters from './Filters';
import Questions from './Questions';

import { checkIfArrayContainsAnother } from '~/utils/util';

import * as S from './styles';

function SearchQuestionsModal({ show, close }) {
  const dispatch = useDispatch();
  const [questionsFilters, setQuestionsFilters] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [allQuestions, setAllQuestions] = useState([]);
  const [allCheckedQuestions, setAllCheckedQuestions] = useState([]);
  const [tagValue, setTagValue] = useState('');

  const {
    data: filteredQuestionsData,
    isLoading: loadingQuestions,
    isError: errorQuestions,
  } = useSearchQuestionsModalGet(
    { ...questionsFilters, page: currentPage },
    {
      config: {
        enabled: showResults && Object.keys(questionsFilters).length > 0,
        onSuccess: (response) => {
          if (!checkIfArrayContainsAnother(allQuestions, response.results)) {
            setAllQuestions((allQuestions) => [...allQuestions, ...response.results]);
          }
        },
      },
    },
  );

  const handleBack = () => {
    setShowResults(!showResults);
  };

  const handleFilter = (filters) => {
    Object.keys(filters).forEach((key) => {
      if (filters[key] === null || (Array.isArray(filters[key]) && filters[key].length === 0)) {
        return delete filters[key];
      }
      if (Array.isArray(filters[key]) && filters[key].length > 0) {
        transformArrayToQueryParam(filters[key], key);
      }
    });
    setCurrentPage(1);
    setTotalPages(1);
    setQuestionsFilters(filters);
    dispatch(saveFilters(filters));
    setShowResults(!showResults);
  };

  const handleSave = () => {
    const selectedQuestions = allQuestions.filter((question) => {
      return allCheckedQuestions.includes(question.id);
    });

    dispatch(saveQuestions(selectedQuestions));
    close();
  };

  const transformArrayToQueryParam = (array, filterKey) => {
    if (array.length <= 0) return;

    let query = '';
    array.forEach((item, index) => {
      if (index !== 1) {
        query = query.concat(',');
      }
      query = query.concat(item);
    });

    const transformedQuery = {
      [filterKey]: query,
    };

    setQuestionsFilters({ ...questionsFilters, transformedQuery });
  };

  useEffect(() => {
    if (!filteredQuestionsData) return;
    setTotalPages(Math.ceil(filteredQuestionsData.count / 10));
    setFilteredQuestions(filteredQuestionsData.results);
  }, [filteredQuestionsData]);

  return (
    <Modal open={show} onClose={close} style={{ maxHeight: '100vh' }}>
      <S.CardModal>
        {showResults ? (
          <>
            <S.OutlineButton onClick={handleBack}>
              <MdArrowBack size={20} />
              Voltar para filtro
            </S.OutlineButton>
            <Questions
              data={filteredQuestions}
              loadingData={loadingQuestions}
              errorData={errorQuestions}
              pagination={!!filteredQuestionsData?.next || !!filteredQuestionsData?.previous}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              allCheckedQuestions={allCheckedQuestions}
              setAllCheckedQuestions={setAllCheckedQuestions}
              save={handleSave}
              totalResults={filteredQuestionsData?.count}
            />
          </>
        ) : (
          <>
            <CloseIcon onClick={close} style={S.CloseButtonStyle} />
            <Filters handleFilter={handleFilter} tagValue={tagValue} setTagValue={setTagValue} />
          </>
        )}
      </S.CardModal>
    </Modal>
  );
}

export default SearchQuestionsModal;
