import { takeLatest, put, all } from 'redux-saga/effects';

import { updateEndpointsSuccess, updateEndpointsFailure } from './actions';

export function* setEndpoints({ payload }) {
  try {
    yield put(updateEndpointsSuccess(payload.data));
  } catch (err) {
    yield put(updateEndpointsFailure());
  }
}

export default all([
  takeLatest('@endpoints/UPDATE_ENDPOINTS', setEndpoints),
]);
