import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Button = styled.button`
  height: 48px;
  display: flex;
  gap: 10px;
  padding: 12px 20px;
  background: none;
  border-radius: 1000px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #077859;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  align-items: center;

  :hover {
    background: #e5faf7;
  }

  :disabled {
    color: #8B97BD;
    pointer-events: none;
    cursor: default;
  }
`;

export const Pages = styled.p`
  margin: 0;
  font-size: 12.6px;
  font-weight: 600;
  text-align: center;
  color: #00205B;
  flex-shrink: 0;
`;
