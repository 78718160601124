import React from 'react';
import { STATUS_OPTIONS } from '../../variables';
import * as S from './styles';

function StatusTag({ status }) {
  const selectedStatus = STATUS_OPTIONS.find((statusItem) => statusItem.value === status);

  return (
    <>
      {selectedStatus && (
        <S.StatusTag backgroundColor={selectedStatus.bgColor}>
          <S.StatusTagText color={selectedStatus.color}>
            {selectedStatus.icon}
            {selectedStatus.label}
          </S.StatusTagText>
        </S.StatusTag>
      )}
    </>
  );
}

export default StatusTag;
