import React from 'react';
import PropTypes from 'prop-types';
import 'react-circular-progressbar/dist/styles.css';

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

export default function ProgressCicle({
  percentage,
  radius,
  strokeWidth,
  background,
  bgColor,
  trailColor,
  customText,
  showText,
  showPercentage,
  percentageStyle,
  percentageSimbolStyle,
  pathColor,
  textStyle,
  bgStyle,
  differentPercentage,
}) {
  return (
    <div style={{ width: radius }}>
      <CircularProgressbarWithChildren
        value={percentage || 0}
        background={background}
        styles={buildStyles({
          trailColor,
          backgroundColor: bgColor,
          pathColor,
        })}
        strokeWidth={strokeWidth}
      >
        <div style={bgStyle}>
          {showText && (
            <>
              <div style={percentageSimbolStyle}>
                {showPercentage && (
                  <>
                    {differentPercentage !== null ? (
                      <>
                        <strong style={percentageStyle}>
                          {differentPercentage || 0}
                        </strong>{' '}
                        %
                      </>
                    ) : (
                      <>
                        <strong style={percentageStyle}>
                          {percentage || 0}
                        </strong>{' '}
                        %
                      </>
                    )}
                  </>
                )}
              </div>
              <div style={textStyle}>{customText || 'CONCLUÍDO'}</div>
            </>
          )}
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}

ProgressCicle.propTypes = {
  percentage: PropTypes.number,
  radius: PropTypes.number,
  strokeWidth: PropTypes.number,
  background: PropTypes.bool,
  bgColor: PropTypes.string,
  trailColor: PropTypes.string,
  customText: PropTypes.string,
  showText: PropTypes.bool,
  showPercentage: PropTypes.bool,
  percentageStyle: PropTypes.shape(),
  percentageSimbolStyle: PropTypes.shape(),
  pathColor: PropTypes.string,
  textStyle: PropTypes.shape(),
  bgStyle: PropTypes.shape(),
  differentPercentage: PropTypes.number,
};

ProgressCicle.defaultProps = {
  percentage: 0,
  radius: 150,
  strokeWidth: 8,
  background: null,
  bgColor: '#fff',
  trailColor: '#fff',
  customText: null,
  showText: true,
  showPercentage: true,
  percentageStyle: {
    color: '#343434',
    fontSize: 22,
    fontWeight: 700,
  },
  percentageSimbolStyle: {
    fontSize: 13,
    color: '#343434',
    marginTop: -5,
  },
  pathColor: '#00cfb4',
  textStyle: {
    fontSize: 8,
    fontWeight: 700,
    color: '#343434',
    marginTop: -2,
  },
  bgStyle: {},
  differentPercentage: null,
};
