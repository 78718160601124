import React from 'react';

export default function DropsPrevious() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.18875 13.5077L4.68121 9.00017L9.18875 4.49268L10.25 5.55318L6.79996 9.00317L10.25 12.4532L9.1895 13.5077H9.18875ZM13.2575 13.5077L8.74925 9.00017L13.2575 4.49268L14.318 5.55318L10.868 9.00317L14.318 12.4532L13.2583 13.5077H13.2575Z"
        fill="#01C3AA"
      />
    </svg>
  );
}
