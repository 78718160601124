import React, { useState, useEffect } from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ReactComponent as PopperButton } from '~/assets/popper-button.svg';
import * as S from './styles';

export default function ButtonWithPopper({ children, minWidth, popperStyle }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = anchorEl;
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    document.addEventListener('scroll', handleClose, true);
    return () => {
      document.removeEventListener('scroll', handleClose, true);
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <PopperButton type="button" onClick={handleClick} />
        <Popper
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          placement="bottom-end"
          style={popperStyle}
        >
          <S.Content minWidth={minWidth}>{children}</S.Content>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
