import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 48px 48px 48px;
  position: relative;

  div:nth-child(2) {
    padding-top: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 48px;
  max-width: 1300px;
`;

export const MainContainer = styled.div`
  background-color: #fff;
  padding: 60px;
  padding-bottom: 100px;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
`;

export const TitleChapter = styled.div`
  color: #00205b;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 34px;
  margin-top: 30px;
`;

export const Bibliography = styled(TitleChapter)`
  margin-bottom: 24px;
`;

export const ContentBibliography = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #181a1e;
`;

export const SectionsContainer = styled.div`
  max-width: 100%;
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const SectionTitle = styled.div`
  color: #666;
  font-weight: bold;
  font-size: ${(props) => (props.subTitle ? '14px' : '17px')};
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const HTMLContainer = styled.div`
  margin-top: 15px;
  color: #767676;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  user-select: none;
  width: 100%;
`;

export const FooterActions = styled.div`
  width: 100%;
  height: 72px;
  border-top: 1px solid #e5e8ee;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  box-shadow: 0px 8px 32px rgba(24, 26, 30, 0.16), 0px 32px 64px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  position: sticky;
  bottom: 20px;
  margin: 0 32px;
  z-index: 1000;
`;

export const ButtonSave = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }
`;

export const ButtonBack = styled(ButtonSave)`
  background: unset;
  color: #01c3aa;

  :hover {
    background: #e5faf7;
  }
`;
