import React from 'react';

import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

import * as S from './styles';

function ModalFilter({
	show,
	close,
	title,
	buttonTitle,
	showFooter,
	childrenFilter,
	childrenList,
	onCleanFilter,
	onHandleFilter,
	onAddItems,
}) {
	return (
		<Modal open={show} onClose={close}>
			<S.CardModal>
				<S.Header>{title}</S.Header>
				<S.Container>{childrenFilter}</S.Container>
				<S.Container>
					<S.ButtonSecondary onClick={() => onCleanFilter()}>Limpar</S.ButtonSecondary>
					<S.ButtonPrimary onClick={() => onHandleFilter()}>Buscar</S.ButtonPrimary>
				</S.Container>
				<CloseIcon onClick={close} style={S.CloseButtonStyle} />
				<S.ContainerResult showFooter={showFooter}>{childrenList}</S.ContainerResult>
				{showFooter && (
					<S.Footer>
						<S.ContainerButton>
							<S.ButtonPrimary onClick={() => onAddItems()}>{buttonTitle}</S.ButtonPrimary>
						</S.ContainerButton>
					</S.Footer>
				)}
			</S.CardModal>
		</Modal>
	);
}

export default ModalFilter;
