import { useQuery } from '@tanstack/react-query';

import { SpecialityAPICMS } from '~/services/apiCallsCMS';

const getFlashcardsSpecialitys = async (params) => {
  try {
    const response = await SpecialityAPICMS.list(
      params,
    );
    if (response && response.data) {
      const data = response.data;
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    throw error;
  }
};

export default function useFlashcardsSpecialitys(params, options) {
  return useQuery({
    queryKey: ['flashcardsSpecialitys', params],
    queryFn: () => getFlashcardsSpecialitys(params),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
