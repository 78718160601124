import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '~/components/Checkbox';

import * as S from './styles';

function TitleCheckbox({ parentId, selectedItems, setSelectedItems, id, title, disabled }) {
  const handleChange = (id, checked) => {
    if (checked) {
      const newList = selectedItems?.[parentId] ? [...selectedItems?.[parentId], id] : [id];
      return setSelectedItems({ ...selectedItems, [parentId]: newList });
    } else {
      const newList = selectedItems[parentId].filter((item) => item !== id);
      return setSelectedItems({ ...selectedItems, [parentId]: newList });
    }
  };

  return (
    <FormControlLabel
      style={{ margin: '0' }}
      control={
        <Checkbox
          label=""
          checked={selectedItems[parentId]?.includes(id)}
          onChange={(checked) => handleChange(id, checked)}
          name={`chackbox-${title}`}
          id={`checkbox-${id}`}
          value={id}
          styleContainer={{ margin: '5px 10px 0', width: '20px' }}
          styleLabel={{ marginBottom: 0 }}
          checkboxOutineColor="#ccc"
          disabled={disabled}
        />
      }
      label={
        <S.Title id={`label-${id}`} for={`checkbox-${id}`}>
          {title}
        </S.Title>
      }
    />
  );
}

export default TitleCheckbox;
