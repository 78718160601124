import { useQuery } from '@tanstack/react-query';

import { PaymentOrderAPICMS } from '~/services/apiCallsCMS';

const getPaymentOrder = async (params) => {
  try {
    const response = await PaymentOrderAPICMS.getPaymentOrders(params);

    if (!response && !response.data && !response.data.results) {
      throw new Error('No results found');
    }

    return response.data.results;
  } catch (error) {
    throw error;
  }
};

export default function useGetPaymentOrder(params, options) {
  return useQuery({
    queryKey: ['getPaymentOrder', params],
    queryFn: () => getPaymentOrder(params),
    staleTime: 0,
    ...options?.config,
  });
}
