/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import TextEditorComponent from '~/components/TextEditorComponent';

import { Container, EditTitle, EditSubtitle, EditorContainer } from './styles';

export default function QuestionEditor({
  question,
  commentStorageNameRef,
  data,
  setData,
  questionExplanation,
  notFound,
  saveOnFocusOut,
}) {
  const QUESTIONS_OPTIONS = ['option_a', 'option_b', 'option_c', 'option_d', 'option_e'];

  useEffect(() => {
    if (data.introduction !== '') {
      data.question = question.id;
      localStorage.setItem(commentStorageNameRef.current, JSON.stringify(data));
    }
  }, [data]);

  return (
    <Container>
      {notFound && (
        <EditSubtitle>Comentário na versão antiga ou não encontrado, criando um novo:</EditSubtitle>
      )}
      <EditorContainer>
        <EditTitle>Introdução</EditTitle>
        <TextEditorComponent
          textHTML={questionExplanation.introduction}
          onEdit={(text) => setData({ ...data, introduction: text })}
          showWarning
          onFocusOut={saveOnFocusOut}
        />
      </EditorContainer>
      {question &&
        question.options.map((option, index) => (
          <EditorContainer key={index}>
            <EditTitle>Alternativa {option.letter}</EditTitle>
            <TextEditorComponent
              textHTML={questionExplanation[QUESTIONS_OPTIONS[index]]}
              onEdit={(text) => setData({ ...data, [QUESTIONS_OPTIONS[index]]: text })}
              onFocusOut={saveOnFocusOut}
            />
          </EditorContainer>
        ))}
      <EditorContainer>
        <EditTitle>Visão do aprovado</EditTitle>
        <TextEditorComponent
          textHTML={questionExplanation.conclusion}
          onEdit={(text) => setData({ ...data, conclusion: text })}
          onFocusOut={saveOnFocusOut}
        />
      </EditorContainer>
    </Container>
  );
}
