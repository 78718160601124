import styled, { css } from 'styled-components';

const FlexRow = styled.div`
	display: flex;
`;

const FlexColumn = styled(FlexRow)`
	flex-direction: column;
`;

export const ItemContainer = styled(FlexColumn)`
	border-radius: 8px;
	border: ${(props) => (props.selected ? '2px solid #01CFB5' : '2px solid #E5E8EE')};
	box-shadow: ${(props) => (props.selected ? '0px 0px 0px 6px #01CFB533' : 'none')};
`;

export const ItemHeader = styled(FlexRow)`
	padding: 16px 24px;
	gap: 24px;
	align-items: center;
`;

export const ItemLabel = styled.p`
	margin: 0;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px;
	color: #00205b;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-basis: 50%;
	flex-grow: 1;
	cursor: pointer;
`;

export const OutlineButton = styled.button`
	position: absolute;
	top: 32px;
	left: 56px;
	padding: 10px 16px;
	display: flex;
	gap: 12px;
	border: 2px solid #e5e8ee;
	color: #00205b;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px;
	background: #fff;
	border-radius: 320px;
	align-items: center;

	:hover {
		border-color: #d1d7e1;
	}
`;

export const SeeItem = styled(OutlineButton)`
	position: initial;
`;

export const ItemFooter = styled(ItemHeader)`
	border-top: 1px solid #e5e8ee;
	overflow: hidden;
`;

export const CardProgress = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;

export const ChipCount = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 26px;
	background: #fcdbdf;
	color: #ee1f38;
	border-radius: 1000px;
	font-weight: 600;
	font-size: 14.2px;
	line-height: 19px;
	${(props) =>
		props.complete &&
		css`
			background: #d8f4e6;
			color: #0cba66;
		`}
`;

export const ProgressBarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
`;

export const ItemSubtitle = styled.div`
	font-size: 14px;
	line-height: 18.96px;
	color: #3d5582;
`;

export const ViewCol = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: flex-start;
`;
