import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';
import { ReactComponent as ClearIcon } from '~/assets/dismiss_logo.svg';
import { ReactComponent as SearchIcon } from '~/assets/drops-search.svg';

export const ButtonStyle = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  gap: 10px;
  height: 40px;
  min-height: 40px;
  background: #ffffff;
  border: 2px solid #d1d7e1;
  border-radius: 8px;
  min-width: 165px;

  :hover {
    border: 2px solid #3d5582;
    fontweight: 600;
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
  width: 100%;
  min-width: 422px;
  max-width: 422px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(24, 26, 30, 0.16), 0px 16px 32px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  overflow: hidden;
`;

export const ButtonText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: #00205b;
`;

export const StyledDropdownIndicator = styled(FaChevronDown)`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 2px solid #00205b;
  transition: border 0.2s ease-in-out;
  stroke-width: 0;
  padding: 2px;
  min-width: 165px;
  flex-grow: 0;
  min-width: 0;

  path {
    fill: #00205b;
  }

  :hover {
    border: 2px solid #01cfb5;
    path {
      fill: #01cfb5;
    }
  }
`;

export const FilterButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  gap: 10px;
  height: 40px;
  background: #019d8a;
  border-radius: 320px;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  :hover {
    background: #01b69f;
  }
`;

export const ClearButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  gap: 10px;
  height: 40px;
  border-radius: 320px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #019d8a;
  background: #fff;

  :hover {
    background: #e5faf7;
  }
`;

export const StyledClearIcon = styled(ClearIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: #019d8a;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: #fff;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const StatusTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  gap: 6px;
  height: 24px;
  border-radius: 8px;
  color: ${(props) => props.color || '#001C50'};
  background: ${(props) => props.background || '#E5E8EE'};
  font-size: 12px;
  font-weight: 600;
`;
