import { useQuery } from '@tanstack/react-query';

import { AnswerFormAPICMS } from '~/services/apiCallsCMS';

const listAnswers = async (params) => {
	try {
		const response = await AnswerFormAPICMS.getAnswers(params);
		if (!response && !response.data) {
			throw new Error('No results found');
		}

		return response.data;
	} catch (error) {
		throw error;
	}
};

export default function useListAnswers(params, options) {
	return useQuery({
		queryKey: ['listAnswers', params],
		queryFn: () => listAnswers(params),
		staleTime: 0,
		...options?.config,
	});
}
