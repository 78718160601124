/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { toast } from 'react-toastify';
import { useParams, useLocation } from 'react-router-dom';
import HeaderComponent from '~/components/HeaderComponent';
import Loader from '~/components/Loader';
import ListAndOrder from '~/components/ListAndOrder';
import ModalConfirmation from '~/components/ModalConfirmation';
import AddClassObjects from '../components/AddClassObjects';
import DocumentOffCanvas from '../components/DocumentOffCanvas';
import VideoLessonOffCanvas from '../components/VideoLessonOffCanvas';
import ChapterSearch from '~/components/ChapterSearch';
import DocumentLessonSearch from '~/components/DocumentLessonSearch';
import TrackSearch from '~/components/TrackSearch';
import ChallengeSearch from '~/components/ChallengeSearch';
import FlashcardSearch from '~/components/FlashcardSearch';
import GreenBorder from '~/components/GreenBorder';
import { CONTENT_TYPES } from '../mocks/mock';
import { useGetLessonModule, useGetLessonModuleItems, useGetCourses } from '~/hooks-querys/course';
import { useLessonModuleById } from '~/hooks-querys/lessonModule';
import {
  LessonModuleTemplateItemsAPICMS,
  LessonModuleItemsAPICMS,
  TrackAPICMS,
  LessonAPICMS,
  LessonDocumentAPICMS,
  ChapterAPICMS,
  CourseAPICMS,
  FlashcardDeckAPICMS,
} from '~/services/apiCallsCMS';
import history from '~/services/history';
import { Mixpanel } from '~/services/analytics';
import { objectIsEmpty, groupArrayByProp } from '~/utils/util';

import * as S from './styles';

const INITIAL_CONTENT = {
  a: [],
  b: [],
  c: [],
  d: [],
  e: [],
  f: [],
  g: [],
  i: [],
};

function EditLessonModule() {
  const { id } = useParams();
  const location = useLocation();
  const [courseId] = useState(location.state?.course);

  const [lessonModuleData, setLessonModuleData] = useState([]);
  const [parentSelectedFolder, setParentSelectedFolder] = useState(null);
  const [addItemObjectType, setAddItemObjectType] = useState({});
  const [searchItemObjectType, setSearchItemObjectType] = useState({});
  const [idDelete, setIdDelete] = useState(null);
  const [typeDelete, setTypeDelete] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [idEdit, setIdEdit] = useState(null);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [loadingModules, setLoadingModules] = useState(false);
  const [titleConfirmation, setTitleConfirmation] = useState('');
  const [messageConfirmation, setMessageConfirmation] = useState('');
  const [isLessonObject, setIsLessonObject] = useState(false);
  const [openLessonDocumentCanvas, setOpenLessonDocumentCanvas] = useState(false);
  const [openVideoLessonCanvas, setOpenVideoLessonCanvas] = useState(false);
  const [openChapterSearch, setOpenChapterSearch] = useState(false);
  const [openTrackSearch, setOpenTrackSearch] = useState(false);
  const [openChallengeSearch, setOpenChallengeSearch] = useState(false);
  const [openDocumentSearch, setOpenDocumentSearch] = useState(false);
  const [openFlashcardSearch, setOpenFlashcardSearch] = useState(false);
  const [content, setContent] = useState(INITIAL_CONTENT);
  const [openTrackType, setOpenTrackType] = useState('');
  const [updateOrder, setUpdateOrder] = useState(false);
  const [buttons, setButtons] = useState(CONTENT_TYPES);
  const [addClicked, setAddClicked] = useState(false);
  const deleteActionObject = {
    hasBorder: false,
    text: 'Excluir',
    icon: 'delete',
    permission: 'delete_course',
  };

  const trackChildButtons = [
    {
      hasBorder: false,
      text: 'Configuração',
      icon: 'configuration',
      action: (id) => () => handleTrackConfiguration(id),
    },
    { ...deleteActionObject, action: (id) => () => handleDeleteItem(id, 'track') },
  ];

  const childButtons = [
    {
      hasBorder: false,
      text: 'Editar',
      icon: 'edit',
      disabled: true,
      action: () => () => {
        return false;
      },
    },
    { ...deleteActionObject, action: (id) => () => handleDeleteItem(id) },
  ];

  const childButtonsChapter = [
    {
      hasBorder: false,
      text: 'Editar',
      icon: 'edit',
      action: (id) => () => handleChapterConfiguration(id),
    },
    { ...deleteActionObject, action: (id) => () => handleDeleteItem(id, 'chapter') },
  ];

  const childButtonsLessonDocument = [
    {
      hasBorder: false,
      text: 'Editar',
      icon: 'edit',
      action: (id) => () => handleOpenLessonDocumentOffCanvas(id),
    },
    { ...deleteActionObject, action: (id) => () => handleDeleteItem(id, 'document') },
  ];

  const childButtonsVideoLesson = [
    {
      hasBorder: false,
      text: 'Editar',
      icon: 'edit',
      action: (id) => () => handleOpenVideoLessonOffCanvas(id),
    },
    { ...deleteActionObject, action: (id) => () => handleDeleteItem(id, 'lesson') },
  ];

  const childFlascardButtons = [
    {
      hasBorder: false,
      text: 'Ver flashcards',
      icon: 'view',
      action: (id) => () => handleOpenFlashcards(id),
    },
    {
      hasBorder: false,
      text: 'Editar',
      icon: 'edit',
      action: (id) => () => handleEditFlashcardsDecks(id),
    },
    { ...deleteActionObject, action: (id) => () => handleDeleteItem(id, 'flashcarddeck') },
  ];

  const { data: lessonModule } = useLessonModuleById(id, {
    config: {
      enabled: !!id,
    },
  });

  const { data: course, isLoading: loadingCourse } = useGetCourses(courseId, {
    config: {
      enabled: !!courseId,
    },
  });

  const {
    data: getLessonModuleItems,
    isLoading: loadingLessonModuleItems,
    refetch: refetchLessonModuleItems,
  } = useGetLessonModuleItems(id, {
    config: {
      enabled: !!id,
    },
  });

  const {
    data: getLessonModule,
    isLoading: loadingLessonModule,
    refetch: refetchLessonModule,
  } = useGetLessonModule(
    id,
    { no_page: 1 },
    {
      config: {
        enabled: !!id,
      },
    },
  );

  useEffect(() => {
    const isLoading = loadingLessonModule || loadingLessonModuleItems || loadingLessonModule;
    setIsLoadingData(isLoading);
  }, [loadingLessonModule, loadingLessonModuleItems, loadingCourse, loadingLessonModule]);

  useEffect(() => {
    if (
      loadingLessonModule ||
      loadingLessonModuleItems ||
      (getLessonModule && getLessonModule.length === 0)
    )
      return;

    setLoadingModules(false);
    let createdTypes = [];
    const groupedLessonModuleItems = groupArrayByProp(getLessonModuleItems, 'object_type');

    Object.keys(groupedLessonModuleItems).forEach(function (key) {
      setContent((content) => {
        return { ...content, [key]: groupedLessonModuleItems[key] };
      });
      groupedLessonModuleItems[key].forEach((item) => {
        const auxId = item?.module_id || item.id;
        const auxName = item.name;
        item.module_id = auxId;
        item.module_name = auxName;
        item.id = item.object_id;
        item.name = item.object_name;
        switch (key) {
          case 'a':
          case 'e':
          case 'g':
            item.buttons = trackChildButtons;
            break;

          case 'f':
            item.buttons = childButtonsLessonDocument;
            break;
          case 'b':
          case 'c':
            item.buttons = childButtonsVideoLesson;
            break;
          case 'd':
            item.buttons = childButtonsChapter;
            break;
          case 'i':
            item.buttons = childFlascardButtons;
            break;
          default:
            item.buttons = childButtons;
            break;
        }
      });
    });

    const transformedDataMock = getLessonModule.map((data) => {
      createdTypes.push(data.object_type);
      return {
        id: data.id,
        lesson_module_id: data.lesson_module_id,
        order_number: data.order,
        object_type: data.object_type,
        name: CONTENT_TYPES.find((element) => element.object_type == data.object_type).label,
        content: groupedLessonModuleItems[data.object_type] ?? [],
        filled: data.is_filled,
        buttons: buttonsMock[data.object_type],
      };
    });

    setLessonModuleData(transformedDataMock);

    const transformedContentType = CONTENT_TYPES.filter((type) => {
      return !createdTypes.includes(type.object_type);
    });

    setButtons(transformedContentType);
  }, [getLessonModule, getLessonModuleItems]);

  useEffect(() => {
    if (objectIsEmpty(addItemObjectType)) return;

    let lastOrder = 0;
    for (let i = 0; i < lessonModuleData.length; i++) {
      lastOrder = lastOrder + lessonModuleData[i].content.length;
      if (lessonModuleData[i].object_type === addItemObjectType.object_type) {
        break;
      }
    }

    switch (addItemObjectType.object_type) {
      case 'a':
      case 'e':
      case 'g': {
        const lessonModuleItemName = CONTENT_TYPES.find(
          (element) => element.object_type == addItemObjectType.object_type,
        ).label;

        let payloadTrack = {
          name: '%%NOME%%',
          track_type: addItemObjectType.object_type === 'g' && 'b',
        };

        if (addItemObjectType.object_type === 'e') {
          payloadTrack.track_type = 'i';
        }

        if (addItemObjectType.object_type === 'a') {
          payloadTrack.track_type = 'h';
        }

        TrackAPICMS.create(payloadTrack).then((response) => {
          const payload = {
            name: lessonModuleItemName,
            object_type: addItemObjectType.object_type,
            object_id: response.data.id,
            last_order: lastOrder,
            lesson_module: id,
          };
          TrackAPICMS.addCourses(response.data.id, { courses: [course?.id] });
          LessonModuleItemsAPICMS.create(payload).then((response) => {
            handleUpdateDataChild(response.data.id, lessonModuleItemName);
            LessonModuleTemplateItemsAPICMS.update(addItemObjectType.id, {
              is_filled: true,
            })
              .then(() => refetchLessonModuleItems().then(() => refetchLessonModule()))
              .catch(() => setLoadingModules(false));
          });
        });

        Mixpanel.track("Clicou 'Configuração de aulas - Botão Criar nova trilha'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });
        break;
      }
      case 'b':
      case 'c': {
        const lessonModuleItemName = CONTENT_TYPES.find(
          (element) => element.object_type == addItemObjectType.object_type,
        ).label;
        let lessonPayload = {
          name: '%%NOME%%',
          description: '%%DESCRIÇÃO%%',
        };
        if (addItemObjectType.object_type === 'c') {
          lessonPayload.lesson_type = 'FLASH';
        }
        LessonAPICMS.create(lessonPayload).then((response) => {
          const payload = {
            name: lessonModuleItemName,
            object_type: addItemObjectType.object_type,
            object_id: response.data.id,
            last_order: lastOrder,
            lesson_module: id,
          };
          LessonModuleItemsAPICMS.create(payload).then(() => {
            LessonModuleTemplateItemsAPICMS.update(addItemObjectType.id, {
              is_filled: true,
            })
              .then(() => refetchLessonModuleItems().then(() => refetchLessonModule()))
              .catch(() => setLoadingModules(false));
          });
        });

        Mixpanel.track("Clicou 'Configuração de aulas - Botão Adicionar videoaula'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });
        break;
      }
      case 'd': {
        Mixpanel.track("Clicou 'Configuração de aulas - Botão Criar novo capítulo'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });
        window.open(`${process.env.REACT_APP_URL}/chapter/new`, '_blank');
        break;
      }
      case 'f': {
        const lessonModuleItemName = CONTENT_TYPES.find(
          (element) => element.object_type == addItemObjectType.object_type,
        ).label;
        LessonDocumentAPICMS.create({ name: '%%NOME%%', description: '%%DESCRIÇÃO%%' }).then(
          (response) => {
            const payload = {
              name: lessonModuleItemName,
              object_type: addItemObjectType.object_type,
              object_id: response.data.id,
              last_order: lastOrder,
              lesson_module: id,
            };
            LessonModuleItemsAPICMS.create(payload).then(() => {
              LessonModuleTemplateItemsAPICMS.update(addItemObjectType.id, {
                is_filled: true,
              })
                .then(() => refetchLessonModuleItems().then(() => refetchLessonModule()))
                .catch(() => setLoadingModules(false));
            });
          },
        );
        Mixpanel.track("Clicou 'Configuração de aulas - Botão Adicionar documento'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });
        break;
      }
      case 'i': {
        Mixpanel.track("Clicou 'Configuração de aulas - Botão Adicionar decks de flashcards'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });

        window.open(`${process.env.REACT_APP_URL}/flashcards-decks/new`, '_blank');
        break;
      }
    }

    const handleStorage = () => {
      const newChapterId = window.localStorage.getItem('newChapter');
      const newFlashcardDeckId = window.localStorage.getItem('newFlashcardDeck');

      if (newChapterId) {
        ChapterAPICMS.get(newChapterId).then((response) => {
          const subjectId = response.data.subject;

          const payload = {
            name: 'Capítulo',
            object_type: 'd',
            object_id: response.data.id,
            last_order: lastOrder,
            lesson_module: id,
          };
          CourseAPICMS.addSubject(course.id, { subject: subjectId });
          LessonModuleItemsAPICMS.create(payload).then(() => {
            LessonModuleTemplateItemsAPICMS.update(addItemObjectType.id, {
              is_filled: true,
            })
              .then(() => refetchLessonModuleItems().then(() => refetchLessonModule()))
              .catch(() => setLoadingModules(false));
          });
        });
      } else if (newFlashcardDeckId) {
        FlashcardDeckAPICMS.getFlashcardDeckById(newFlashcardDeckId).then((response) => {
          const payload = {
            name: response.data.title,
            object_type: 'i',
            object_id: response.data.id,
            last_order: lastOrder,
            lesson_module: id,
          };

          LessonModuleItemsAPICMS.create(payload).then(() => {
            LessonModuleTemplateItemsAPICMS.update(addItemObjectType.id, {
              is_filled: true,
            })
              .then(() => refetchLessonModuleItems().then(() => refetchLessonModule()))
              .catch(() => setLoadingModules(false));
          });
        });
      }
      setAddItemObjectType({});
      window.localStorage.removeItem('newChapter');
      window.localStorage.removeItem('newFlashcardDeck');
    };

    window.addEventListener('storage', handleStorage);
    setAddItemObjectType({});
  }, [addItemObjectType]);

  useEffect(() => {
    if (!updateOrder) return;

    setUpdateOrder(false);
    let childIds = [];

    lessonModuleData.forEach((lessonModuleItem) => {
      if (lessonModuleItem.content.length === 0) return;
      lessonModuleItem.content.forEach((contentItem) => {
        childIds.push(contentItem.module_id);
      });
    });

    if (childIds.length > 0) {
      LessonModuleItemsAPICMS.updateOrder({ ids: childIds }).then(() => refetchLessonModule());
    }
  }, [lessonModuleData, updateOrder]);

  function handleOpenLessonDocumentOffCanvas(id) {
    Mixpanel.track("Clicou 'Configuração de aulas - Botão Editar documento'", {
      Curso: course?.id,
      'Nome do curso': course?.name,
    });
    setIdEdit(id);
    setOpenLessonDocumentCanvas(true);
  }

  function handleCloseLessonDocument() {
    setOpenLessonDocumentCanvas(false);
    refetchLessonModuleItems();
  }

  function handleOpenVideoLessonOffCanvas(id) {
    setIdEdit(id);
    setOpenVideoLessonCanvas(true);
    Mixpanel.track("Clicou 'Configuração de aulas - Botão Editar videoaula'", {
      Curso: course?.id,
      'Nome do curso': course?.name,
    });
  }

  function handleCloseVideoLessonOffCanvas() {
    setOpenVideoLessonCanvas(false);
    refetchLessonModuleItems();
  }

  const handleUpdateDataChild = (id, name) => {
    let objectType;

    Object.keys(content).forEach(function (key) {
      const selectedObject = content[key].find((object) => object.id === id);
      if (!objectIsEmpty(selectedObject)) {
        objectType = selectedObject.object_type;
      }
    });

    switch (objectType) {
      case 'a':
      case 'e':
      case 'g': {
        TrackAPICMS.update(id, { name: name }).then(() => refetchLessonModuleItems());
        break;
      }
      case 'b':
      case 'c': {
        LessonAPICMS.update(id, { name: name }).then(() => refetchLessonModuleItems());
        break;
      }
      case 'd': {
        ChapterAPICMS.update(id, { name: name }).then(() => refetchLessonModuleItems());
        break;
      }
      case 'f': {
        LessonDocumentAPICMS.update(id, { name: name }).then(() => refetchLessonModuleItems());
        break;
      }
      case 'i': {
        FlashcardDeckAPICMS.update(id, { title: name }).then(() => refetchLessonModuleItems());
        break;
      }
    }
  };

  const handleAddItem = (object_type, id, order) => {
    setLoadingModules(true);
    setParentSelectedFolder(id);
    setAddItemObjectType({ object_type: object_type, id: id, order: order });
  };

  const handleAdd = async (content_type) => {
    if (addClicked) return;
    setAddClicked(true);
    const newItemObject = CONTENT_TYPES.find((content) => {
      return content.content_type === content_type;
    });

    const hasObject = lessonModuleData.find(
      (lessonModule) => lessonModule.object_type === newItemObject.object_type,
    );

    if (hasObject) {
      setAddClicked(false);
      toast.error(`Já existe um objeto do tipo ${newItemObject.label}.`);
      return;
    }

    LessonModuleTemplateItemsAPICMS.create({
      lesson_module_id: id,
      object_type: newItemObject.object_type,
    })
      .then(() => {
        refetchLessonModule();
      })
      .catch(() => setLoadingModules(false))
      .finally(() => setAddClicked(false));
  };

  const updateOrderListSubject = (ids) => {
    LessonModuleTemplateItemsAPICMS.updateOrder({ ids }).then(() => {
      setUpdateOrder(true);
    });
  };

  function handleDeleteObject(id, type) {
    switch (type) {
      case 'track': {
        Mixpanel.track(
          "Clicou 'Configuração de aulas - Botão Excluir trilha (elemento consolidador)'",
          {
            Curso: course?.id,
            'Nome do curso': course?.name,
          },
        );
        break;
      }
      case 'lesson': {
        Mixpanel.track(
          "Clicou 'Configuração de aulas - Botão Excluir videoaula' (elemento consolidador)",
          {
            Curso: course?.id,
            'Nome do curso': course?.name,
          },
        );
        break;
      }
      case 'chapter': {
        Mixpanel.track(
          "Clicou 'Configuração de aulas - Botão Excluir capítulo (elemento consolidador)'",
          {
            Curso: course?.id,
            'Nome do curso': course?.name,
          },
        );
        break;
      }
      case 'document': {
        Mixpanel.track(
          "Clicou 'Configuração de aulas - Botão Excluir documento (elemento consolidador)'",
          {
            Curso: course?.id,
            'Nome do curso': course?.name,
          },
        );
        break;
      }
      case 'flashcarddeck': {
        Mixpanel.track(
          "Clicou 'Configuração de aulas - Botão Excluir deck de flashcards (elemento consolidador)'",
          {
            Curso: course?.id,
            'Nome do curso': course?.name,
          },
        );
        break;
      }
    }
    setShowModalConfirmation(true);
    setIdDelete(id);
    setTitleConfirmation('Excluir objeto de aula');
    setIsLessonObject(true);
    setMessageConfirmation(
      'Você deseja remover esse objeto de aula? Após confirmar não será possivel recuperá-lo.',
    );
    setTypeDelete(type);
  }

  function handleDeleteItem(id, type) {
    switch (type) {
      case 'track': {
        Mixpanel.track("Clicou 'Configuração de aulas - Botão Excluir trilha (objeto de aula)'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });
        break;
      }
      case 'lesson': {
        Mixpanel.track(
          "Clicou 'Configuração de aulas - Botão Excluir videoaula (objeto de aula)'",
          {
            Curso: course?.id,
            'Nome do curso': course?.name,
          },
        );
        break;
      }
      case 'chapter': {
        Mixpanel.track("Clicou 'Configuração de aulas - Botão Excluir capítulo (objeto de aula)'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });
        break;
      }
      case 'document': {
        Mixpanel.track(
          "Clicou 'Configuração de aulas - Botão Excluir documento (objeto de aula)'",
          {
            Curso: course?.id,
            'Nome do curso': course?.name,
          },
        );
        break;
      }
      case 'flashcarddeck': {
        Mixpanel.track(
          "Clicou 'Configuração de aulas - Botão Excluir deck de flashcards (objeto de aula)'",
          {
            Curso: course?.id,
            'Nome do curso': course?.name,
          },
        );
        break;
      }
      default: {
        break;
      }
    }

    setShowModalConfirmation(true);
    setIdDelete(id);
    setTypeDelete(type);
    setTitleConfirmation('Excluir item');
    setIsLessonObject(false);
    setMessageConfirmation('Você deseja excluir esse item?');
  }

  function deleteObject(id) {
    setShowModalConfirmation(false);

    switch (typeDelete) {
      case 'track': {
        Mixpanel.track("Clicou 'Configuração de aulas - Confirmar botão Excluir trilha'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });
        break;
      }
      case 'lesson': {
        Mixpanel.track("Clicou 'Configuração de aulas - Confirmar botão Excluir videoaula'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });
        break;
      }
      case 'chapter': {
        Mixpanel.track("Clicou 'Configuração de aulas - Confirmar botão Excluir capítulo'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });
        break;
      }
      case 'document': {
        Mixpanel.track("Clicou 'Configuração de aulas - Confirmar botão Excluir documento'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });
        break;
      }
      case 'flashcarddeck': {
        Mixpanel.track("Clicou 'Configuração de aulas - Confirmar botão Excluir flashcards'", {
          Curso: course?.id,
          'Nome do curso': course?.name,
        });
        break;
      }
    }

    const parentToDelete = lessonModuleData.find((data) => data.id === id);
    LessonModuleTemplateItemsAPICMS.delete(id)
      .then(() => {
        parentToDelete.content.length > 0 &&
          parentToDelete.content.forEach((child) => {
            const idToDelete = child.module_id || child.id;
            deleteObjectItem(idToDelete, true);
          });
        refetchLessonModule();
      })
      .finally(() => {
        const newOrderList = getLessonModule.map((item) => item.id);
        const index = newOrderList.indexOf(id);
        newOrderList.splice(index, 1);
        LessonModuleTemplateItemsAPICMS.updateOrder({ ids: newOrderList });
        setTypeDelete(null);
      })
      .catch(() => setLoadingModules(false));
  }

  function deleteObjectItem(id, deleteFromParent) {
    setShowModalConfirmation(false);
    setLoadingModules(true);

    let selectedModuleId;
    let selectedObjectType;
    let selectedObjectId;

    Object.keys(content).forEach(function (key) {
      const selectedObject = content[key].find((object) => {
        if (deleteFromParent) {
          return object.module_id === id;
        }
        return object.id === id;
      });
      if (!objectIsEmpty(selectedObject)) {
        selectedModuleId = selectedObject.module_id;
        selectedObjectType = selectedObject.object_type;
        selectedObjectId = selectedObject.object_id;
      }
    });

    const filteredContent = content[selectedObjectType]?.filter((item) => item.id !== id);
    setContent((content) => {
      return { ...content, [selectedObjectType]: filteredContent };
    });

    LessonModuleItemsAPICMS.delete(selectedModuleId)
      .then(() => {
        refetchLessonModuleItems().then((response) => {
          setUpdateOrder(false);
          if (!deleteFromParent) {
            const newOrderList = response.data.map((item) => item.id);
            LessonModuleItemsAPICMS.updateOrder({ ids: newOrderList }).then(() =>
              refetchLessonModuleItems(),
            );

            const hasChildren = response.data.filter(
              (item) => item.object_type === selectedObjectType,
            );
            if (hasChildren.length === 0) {
              const parentObject = lessonModuleData.find((data) => {
                return data.object_type === selectedObjectType;
              });
              LessonModuleTemplateItemsAPICMS.update(parentObject.id, {
                is_filled: false,
              }).then(() => refetchLessonModule());
            }
          }
        });
      })
      .catch(() => setLoadingModules(false));

    if (typeDelete && typeDelete === 'track') {
      TrackAPICMS.deleteTrackCourse(selectedObjectId, { course: course?.id });
      setTypeDelete(null);
      return;
    }

    if (typeDelete && typeDelete === 'flashcarddeck') {
      setTypeDelete(null);
      return;
    }
  }

  const deleteAction = () => {
    if (isLessonObject) {
      deleteObject(idDelete);
    } else {
      deleteObjectItem(idDelete);
    }
  };

  const handleTrackConfiguration = (id) => {
    Mixpanel.track("Clicou 'Configuração de aulas - Botão Editar trilha'", {
      Curso: course?.id,
      'Nome do curso': course?.name,
    });
    window.open(`${process.env.REACT_APP_URL}/tracks/${id}`, '_blank');
  };

  const handleChapterConfiguration = (id) => {
    Mixpanel.track("Clicou 'Configuração de aulas - Botão Editar capítulo'", {
      Curso: course?.id,
      'Nome do curso': course?.name,
    });
    window.open(`${process.env.REACT_APP_URL}/chapter/edit/${id}`, '_blank');
  };

  const handleOpenChapterSearch = (object_type, id, order) => {
    setParentSelectedFolder(id);
    setSearchItemObjectType({ object_type: object_type, id: id, order: order });
    setOpenChapterSearch(true);
    Mixpanel.track("Clicou 'Configuração de aulas - Botão Buscar capítulo existente'", {
      Curso: course?.id,
      'Nome do curso': course?.name,
    });
  };

  const handleOpenTrackSearch = (object_type, id, order) => {
    setParentSelectedFolder(id);
    setSearchItemObjectType({ object_type: object_type, id: id, order: order });
    setOpenTrackSearch(true);
    setOpenTrackType(object_type);
    Mixpanel.track("Clicou 'Configuração de aulas - Botão Buscar trilha existente'", {
      Curso: course?.id,
      'Nome do curso': course?.name,
    });
  };

  const handleOpenDocumentSearch = (object_type, id, order) => {
    setParentSelectedFolder(id);
    setSearchItemObjectType({ object_type: object_type, id: id, order: order });
    setOpenDocumentSearch(true);
    Mixpanel.track("Clicou 'Configuração de aulas - Botão Buscar documento/vídeo-aula existente'", {
      Curso: course?.id,
      'Nome do curso': course?.name,
    });
  };

  const handleOpenFlashcardsSearch = (object_type, id, order) => {
    setParentSelectedFolder(id);
    setSearchItemObjectType({ object_type: object_type, id: id, order: order });
    setOpenFlashcardSearch(true);
    setOpenTrackType(object_type);
    Mixpanel.track("Clicou 'Configuração de aulas - Botão Buscar flashcards existentes'", {
      Curso: course?.id,
      'Nome do curso': course?.name,
    });
  };

  const handleOpenChallengeSearch = (object_type, id, order) => {
    setParentSelectedFolder(id);
    setSearchItemObjectType({ object_type: object_type, id: id, order: order });
    setOpenChallengeSearch(true);
  };

  const handleOpenFlashcards = (id) => {
    Mixpanel.track("Clicou 'Configuração de aulas - Botão Ver flashcards'", {
      Curso: course?.id,
      'Nome do curso': course?.name,
    });

    const queryParams = {
      flashcard_decks: id,
    };

    const queryString = qs.stringify(queryParams);

    window.open(`${process.env.REACT_APP_URL}/flashcards/edit?${queryString}`, '_blank');
  };

  const handleEditFlashcardsDecks = (id) => {
    return window.open(`${process.env.REACT_APP_URL}/flashcards-decks/edit?id=${id}`, '_blank');
  };

  const handleCloseSearch = (selectedItems) => {
    setLoadingModules(true);
    const lessonModuleItemName = CONTENT_TYPES.find(
      (element) => element.object_type == searchItemObjectType.object_type,
    ).label;

    let lastOrder = 0;
    for (let i = 0; i < lessonModuleData.length; i++) {
      lastOrder = lastOrder + lessonModuleData[i].content.length;
      if (lessonModuleData[i].object_type === searchItemObjectType.object_type) {
        break;
      }
    }

    selectedItems.forEach(function (objectId) {
      const payload = {
        name: lessonModuleItemName,
        object_type: searchItemObjectType.object_type,
        object_id: objectId,
        last_order: lastOrder,
        lesson_module: id,
      };
      LessonModuleItemsAPICMS.create(payload).then(() => {
        LessonModuleTemplateItemsAPICMS.update(searchItemObjectType.id, {
          is_filled: true,
        })
          .then(() => refetchLessonModuleItems().then(() => refetchLessonModule()))
          .catch(() => setLoadingModules(false));
      });
    });
  };

  const handleCloseTrackSearch = (selectedItems) => {
    const isArray = Array.prototype.isPrototypeOf(selectedItems);
    if (!isArray) return;
    selectedItems.forEach((item) => TrackAPICMS.addCourses(item, { courses: [course?.id] }));
    handleCloseSearch(selectedItems);
  };

  const handleCloseFlashcardSearch = (selectedItems) => {
    const isArray = Array.prototype.isPrototypeOf(selectedItems);
    if (!isArray) return;
    handleCloseSearch(selectedItems);
  };

  const handleCloseChapterSearch = (selectedItems) => {
    const isArray = Array.prototype.isPrototypeOf(selectedItems);
    if (!isArray) return;

    let subjectList = [];
    ChapterAPICMS.list({ id__in: selectedItems }).then((response) => {
      if (response && response.data && response.data.results) {
        subjectList = response.data.results.map((item) => item.subject_id);

        subjectList.forEach((subject) => {
          CourseAPICMS.addSubject(course?.id, { subject: subject });
        });
      }
    });
    handleCloseSearch(selectedItems);
  };

  const handleCloseLessonDocumentSearch = (selectedItems) => {
    const isArray = Array.prototype.isPrototypeOf(selectedItems);
    if (!isArray) return;
    handleCloseSearch(selectedItems);
  };

  const buttonsMock = {
    a: [
      {
        hasBorder: true,
        text: 'Criar nova',
        icon: 'add',
        action: (id, order) => () => handleAddItem('a', id, order),
      },
      {
        hasBorder: true,
        text: 'Buscar existente',
        icon: 'search',
        action: (id, order) => () => handleOpenTrackSearch('a', id, order),
      },
      {
        hasBorder: false,
        text: 'Excluir',
        icon: 'delete',
        action: (id) => () => handleDeleteObject(id, 'track'),
        permission: 'delete_course',
      },
    ],
    b: [
      {
        hasBorder: true,
        text: 'Adicionar video aula',
        icon: 'add',
        action: (id, order) => () => handleAddItem('b', id, order),
      },
      {
        hasBorder: true,
        text: 'Buscar existente',
        icon: 'search',
        action: (id, order) => () => handleOpenDocumentSearch('b', id, order),
      },
      {
        hasBorder: false,
        text: 'Excluir',
        icon: 'delete',
        action: (id) => () => handleDeleteObject(id, 'lesson'),
        permission: 'delete_course',
      },
    ],
    c: [
      {
        hasBorder: true,
        text: 'Adicionar vídeo aula',
        icon: 'add',
        action: (id, order) => () => handleAddItem('c', id, order),
      },
      {
        hasBorder: true,
        text: 'Buscar existente',
        icon: 'search',
        action: (id, order) => () => handleOpenDocumentSearch('c', id, order),
      },
      {
        hasBorder: false,
        text: 'Excluir',
        icon: 'delete',
        action: (id) => () => handleDeleteObject(id, 'lesson'),
        permission: 'delete_course',
      },
    ],
    d: [
      {
        hasBorder: true,
        text: 'Criar novo',
        icon: 'add',
        action: (id, order) => () => handleAddItem('d', id, order),
      },
      {
        hasBorder: true,
        text: 'Buscar existente',
        icon: 'search',
        action: (id, order) => () => handleOpenChapterSearch('d', id, order),
      },
      {
        hasBorder: false,
        text: 'Excluir',
        icon: 'delete',
        action: (id) => () => handleDeleteObject(id, 'chapter'),
        permission: 'delete_course',
      },
    ],
    e: [
      {
        hasBorder: true,
        text: 'Criar novo',
        icon: 'add',
        action: (id, order) => () => handleAddItem('e', id, order),
      },
      {
        hasBorder: true,
        text: 'Buscar existente',
        icon: 'search',
        action: (id, order) => () => handleOpenTrackSearch('e', id, order),
      },
      {
        hasBorder: false,
        text: 'Excluir',
        icon: 'delete',
        action: (id) => () => handleDeleteObject(id, 'track'),
        permission: 'delete_course',
      },
    ],
    f: [
      {
        hasBorder: true,
        text: 'Adicionar documento',
        icon: 'add',
        action: (id, order) => () => handleAddItem('f', id, order),
      },
      {
        hasBorder: true,
        text: 'Buscar existente',
        icon: 'search',
        action: (id, order) => () => handleOpenDocumentSearch('f', id, order),
      },
      {
        hasBorder: false,
        text: 'Excluir',
        icon: 'delete',
        action: (id) => () => handleDeleteObject(id, 'document'),
        permission: 'delete_course',
      },
    ],
    g: [
      {
        hasBorder: true,
        text: 'Criar novo',
        icon: 'add',
        action: (id, order) => () => handleAddItem('g', id, order),
      },
      {
        hasBorder: true,
        text: 'Buscar simulado',
        icon: 'search',
        action: (id, order) => () => handleOpenChallengeSearch('g', id, order),
      },
      {
        hasBorder: false,
        text: 'Excluir',
        icon: 'delete',
        action: (id) => () => handleDeleteObject(id, 'track'),
        permission: 'delete_course',
      },
    ],
    i: [
      {
        hasBorder: true,
        text: 'Criar novo',
        icon: 'add',
        action: (id, order) => () => handleAddItem('i', id, order),
      },
      {
        hasBorder: true,
        text: 'Buscar existente',
        icon: 'search',
        action: (id, order) => () => handleOpenFlashcardsSearch('i', id, order),
      },
      {
        hasBorder: false,
        text: 'Excluir',
        icon: 'delete',
        action: (id) => () => handleDeleteObject(id, 'flashcarddeck'),
        permission: 'delete_course',
      },
    ],
  };

  return (
    <>
      {isLoadingData ? (
        <Loader text="Carregando" />
      ) : (
        <>
          <HeaderComponent title={course?.name} />
          <S.Wrap>
            <S.BackButtonContainer>
              <S.BackButton onClick={() => history.goBack()}>
                <S.StyledArrowLeft /> Voltar
              </S.BackButton>
            </S.BackButtonContainer>
            <S.Content>
              <S.ItemsContainer>
                <GreenBorder />
                <S.TitleContainer>
                  <S.NumberIndicator>1</S.NumberIndicator>
                  <S.Title>{lessonModule?.name}</S.Title>
                </S.TitleContainer>
                <ListAndOrder
                  parentData={lessonModuleData}
                  setParentData={setLessonModuleData}
                  parentAllowDrag={true}
                  childAllowDrag={false}
                  selectedParent={parentSelectedFolder}
                  setSelectedParent={setParentSelectedFolder}
                  hasChild
                  allowEditChildLabel
                  updateDataChildName={handleUpdateDataChild}
                  updateOrderListParent={updateOrderListSubject}
                  childLoading={loadingModules}
                />
                <S.AddClassObjectsContainer>
                  {buttons.length > 0 && (
                    <AddClassObjects buttonsData={buttons} onAdd={handleAdd} />
                  )}
                </S.AddClassObjectsContainer>
              </S.ItemsContainer>
            </S.Content>
          </S.Wrap>
          {showModalConfirmation && (
            <ModalConfirmation
              title={titleConfirmation}
              message={messageConfirmation}
              actionText="Excluir"
              showModal={showModalConfirmation}
              handleClose={() => setShowModalConfirmation(false)}
              handleAction={() => deleteAction()}
            />
          )}
          {openLessonDocumentCanvas && (
            <DocumentOffCanvas
              id={idEdit}
              show={openLessonDocumentCanvas}
              close={handleCloseLessonDocument}
            />
          )}
          {openVideoLessonCanvas && (
            <VideoLessonOffCanvas
              id={idEdit}
              show={openVideoLessonCanvas}
              close={handleCloseVideoLessonOffCanvas}
            />
          )}
          {openChapterSearch && (
            <ChapterSearch
              show={openChapterSearch}
              close={(search) => {
                handleCloseChapterSearch(search);
                setOpenChapterSearch(false);
              }}
            />
          )}
          {openTrackSearch && (
            <TrackSearch
              show={openTrackSearch}
              close={(search) => {
                handleCloseTrackSearch(search);
                setOpenTrackSearch(false);
              }}
              showTrackType={openTrackType}
            />
          )}
          {openChallengeSearch && (
            <ChallengeSearch
              show={openChallengeSearch}
              close={(search) => {
                handleCloseTrackSearch(search);
                setOpenChallengeSearch(false);
              }}
            />
          )}
          {openDocumentSearch && (
            <DocumentLessonSearch
              show={openDocumentSearch}
              isDocument={searchItemObjectType.object_type === 'f'}
              close={(search) => {
                handleCloseLessonDocumentSearch(search);
                setOpenDocumentSearch(false);
              }}
            />
          )}
          {openFlashcardSearch && (
            <FlashcardSearch
              show={openFlashcardSearch}
              close={(search) => {
                handleCloseFlashcardSearch(search);
                setOpenFlashcardSearch(false);
              }}
              showTrackType={openTrackType}
            />
          )}
        </>
      )}
    </>
  );
}

export default EditLessonModule;
