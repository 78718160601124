import React from 'react';
import * as S from './styles';

export default function Controls({
  goToPrevious,
  goToNext,
  hasPrevious,
  hasNext,
  createNew,
  loading,
}) {
  return (
    <>
      {!loading && (
        <S.ControlContainer>
          <S.ArrowContainer onClick={goToPrevious} disabled={!hasPrevious}>
            <S.UpArrow disabled={!hasPrevious} />
          </S.ArrowContainer>
          <S.ArrowContainer onClick={goToNext} disabled={!hasNext}>
            <S.DownArrow disabled={!hasNext} />
          </S.ArrowContainer>
          <S.PlusIconContainer onClick={createNew}>
            <S.PlusIcon />
          </S.PlusIconContainer>
        </S.ControlContainer>
      )}
    </>
  );
}
