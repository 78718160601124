import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: {
    transition: '0.3s',

    '&:hover': {
      boxShadow: '0px 10px 13px -7px #000000, 2px 6px 20px 1px rgba(0,0,0,0)',
      transform: 'scale(1.005)',
    },
  },
}));

function CardComponent({ background, type, children, style, withAnimation, ...rest }) {
  function getElevation() {
    switch (type) {
      case 'minimal':
        return 0;
      case 'default':
        return 3;
      case 'important':
        return 5;

      default:
        return 3;
    }
  }

  const classes = useStyles();
  const elevation = getElevation();

  return (
    <Paper
      className={withAnimation ? classes.paper : null}
      elevation={elevation}
      style={{
        ...style,
        background: background || 'none',
      }}
      {...rest}
    >
      {children}
    </Paper>
  );
}
CardComponent.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.shape(),
  withAnimation: PropTypes.bool,
};

CardComponent.defaultProps = {
  type: 'default',
  style: {},
  withAnimation: false,
};

export default CardComponent;
