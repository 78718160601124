import React from 'react';

export default function SpecialityLogo(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      {' '}
      <path
        d="M8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.4954 12.1402 12.1402 15.4954 8 15.5ZM2 8.129C2.03549 11.4299 4.73083 14.0821 8.03187 14.0645C11.333 14.0466 13.9996 11.3656 13.9996 8.0645C13.9996 4.76338 11.333 2.08233 8.03187 2.0645C4.73083 2.04684 2.03549 4.69907 2 8V8.129ZM8.75 11.75H7.25V8.75H4.25V7.25H7.25V4.25H8.75V7.25H11.75V8.75H8.75V11.75Z"
        fill={props.fill}
      />
    </svg>
  );
}
