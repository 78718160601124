import styled from 'styled-components';

export const Styled = styled.div`
  width: 100%;
  .tab-list {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin: 0 0 14px 0;
    cursor: pointer;
    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }
  }

  .tab-content {
    margin-top: 8px;
  }
`;

export const ContainerTab = styled.div`
  height: 64px;
  padding-inline: 40px;
  margin-bottom: 40px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgb(24 26 30 / 16%), 0px 2px 4px rgb(24 26 30 / 8%);

  .tab-list {
    margin: 0;
    align-items: center;
    height: 100%;
  }
`;
