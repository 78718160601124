import React, { useState, useEffect, useRef } from 'react';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { ReactComponent as ConfiogurationIcon } from '~/assets/configuration-icon.svg';
import { Chapter } from '~/services';
import SelectWithPagination from '~/components/SelectWithPagination';
import Loader from '~/components/Loader';
import ApostilleModal from '../ApostilleModal';
import GreenBorder from '~/components/GreenBorder';
import history from '~/services/history';

import { MdArrowBack, MdArrowForward, MdSave } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Mixpanel } from '~/services/analytics';
import { SubjectsAPI, CourseAPICMS } from '~/services/apiCallsCMS';

import * as S from './styles';
import { getTags, loadPhysicians, loadSubjects, loadTags, loadTracks } from './utils';
import { TRACK_TYPE } from '~/utils/variables';
import { EMPTY_DATA } from './constants';

function Settings({ changeStep, chapter, changeChapterId, editMode = false }) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState('no');
  const [chapterName, setChapterName] = useState(null);
  const [order, setOrder] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedPreTrack, setSelectedPreTrack] = useState(null);
  const [selectedPosTrack, setSelectedPosTrack] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedPhysicians, setSelectedPhysicians] = useState([]);
  const [selectedCoPhysicians, setSelectedCoPhysicians] = useState([]);
  const [fileName, setFileName] = useState('Imagem de capa');
  const [pdf, setPdf] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [showApostilleModal, setShowApostilleModal] = useState(false);
  const [apostilleModalAction, setApostilleModalAction] = useState(null);
  const [loadingApostilleModalAction, setLoadingApostilleModalAction] = useState(false);
  const [selectedData, setSelectedData] = useState(EMPTY_DATA);
  const [subjectKey, setSubjectKey] = useState(1);
  const [initialData, setInitialData] = useState({
    name: '',
  });

  const previewRef = useRef();

  useEffect(() => {
    if (editMode) {
      Mixpanel.track("Clicou 'Tela do capítulo - Opções de edição de capítulo'", {
        'Item selecionado': 'Configuração',
      });
    }
  }, []);

  const transformOptions = (item) => {
    if (!item?.id || !item?.name) return;

    return {
      label:
        (item.subject_name && `${item.subject} - ${item.subject_name}`) ||
        (item.track_type && `${item.id} - ${item.name}`) ||
        item.verbose ||
        `${item.name} - ${item.email}`,
      key: item.subject || item.id,
      value: item.subject || item.id,
    };
  };

  useEffect(() => {
    if (editMode || chapter?.id) {
      setOrder(chapter.order);
      setSelectedSubject(transformOptions(chapter));
      setSelectedPreTrack(transformOptions(chapter.pre_track));
      setSelectedPosTrack(transformOptions(chapter.pos_track));
      getTags(chapter.tag).then((res) => {
        if (res) {
          setSelectedTags(res[0]);
        }
      });
      setSelectedPhysicians(chapter.physicians.map((physician) => transformOptions(physician)));
      setSelectedCoPhysicians(
        chapter.co_physicians.map((coPhysician) => transformOptions(coPhysician)),
      );

      if (chapter.cover_image) {
        const split = chapter.cover_image.split('?')[0];
        const imageName = split.split('chapter/')[1];
        setFileName(imageName);
      }
      setVisible(chapter.visible ? 'yes' : 'no');
      setInitialData({ ...initialData, name: chapter?.name });
      setChapterName(chapter?.name);
      setPdf(chapter?.pdf);
    }
  }, [chapter]);

  const schema = Yup.object().shape({
    name: Yup.string().required('Título é obrigatório'),
  });

  function handleSelectFile(event) {
    if (!event.target?.files || event.target?.files.length === 0) {
      return;
    }

    const file = event.target?.files[0];

    setCoverImage(file);
    setFileName(file.name);
  }

  const handleSubmit = async (obj) => {
    if (!selectedSubject) {
      toast.error('Selecione a Apostila destino!');
      return;
    }
    const params = {
      name: obj.name,
      order: order || null,
      subject: selectedSubject?.value || null,
      pre_track: selectedPreTrack?.value || null,
      pos_track: selectedPosTrack?.value || null,
      visible: visible === 'yes',
      tag: selectedTags?.value ? [selectedTags?.value] : [],
      physicians: selectedPhysicians.map((physician) => physician?.value),
      co_physicians: selectedCoPhysicians.map((physician) => physician?.value),
    };

    let urlCoverImage;
    let title;

    setLoading(true);
    try {
      if (editMode || chapter?.id) {
        await Chapter.update(chapter?.id, params);
        if (coverImage) {
          const { data } = await Chapter.updateFile(chapter?.id, { cover_image: coverImage });
          urlCoverImage = data.cover_image;
        }
      } else {
        const { data } = await Chapter.create(params);
        changeChapterId(data.id);
        if (coverImage) {
          const result = await Chapter.updateFile(data.id, { cover_image: coverImage });
          urlCoverImage = result.data.cover_image;
        }
        window.localStorage.setItem('newChapter', data.id);
        window.dispatchEvent(new Event('storage'));
      }

      if (previewRef.current) {
        title = "Clicou 'Tela do capítulo - Configuração - Botão revisar o capítulo'";
      } else if (editMode) {
        title = "Clicou 'Tela do capítulo - Configuração - Botão salvar o capítulo'";
      } else {
        title = "Clicou 'Novo capítulo - Configuração - Botão continuar'";
      }

      Mixpanel.track(title, {
        'Nome do capítulo': params.name,
        'Nome da apostila': selectedSubject?.label.split(' - ')[1],
        'Trilha pré': params.pre_track
          ? `${selectedPreTrack.label.split(' - ')[1]} (${selectedPreTrack.label.split(' - ')[0]})`
          : 'Não especificado',
        'Trilha pós': params.pos_track
          ? `${selectedPosTrack.label.split(' - ')[1]} (${selectedPosTrack.label.split(' - ')[0]})`
          : 'Não especificado',
        Visível: visible === 'yes' ? 'Sim' : 'Não',
        'Tag Tema':
          selectedTags.length > 0
            ? selectedTags.label.split(' >> ')[0].split('[Tema] ')[1]
            : 'Não especificado',
        'Tag Foco':
          selectedTags.length > 0
            ? selectedTags.label.split(' >> ')[1].split('[Foco] ')[1]
            : 'Não especificado',
        'Comentarista revisor em texto':
          selectedPhysicians.length > 0
            ? selectedPhysicians.map((physician) => physician?.label.split(' - ')[0]).join('; ')
            : 'não informado',
        'Comentarista colaborador em texto':
          selectedPhysicians.length > 0
            ? selectedCoPhysicians.map((physician) => physician?.label.split(' - ')[0]).join('; ')
            : 'não informado',
        'Imagem da capa': urlCoverImage ?? 'não informado',
      });

      if (editMode) {
        changeStep(1);
      } else {
        changeStep({ first: false, second: true });
      }
      toast.success(
        `Sucesso ao ${editMode || chapter?.id ? 'editar' : 'cadastrar'} as configurações.`,
      );
    } catch (error) {
      toast.error(
        `Erro ao ${
          editMode || chapter?.id ? 'editar' : 'cadastrar'
        } as configurações. Tente novamente.`,
      );
    } finally {
      setLoading(false);
      previewRef.current = false;
    }
  };

  async function handlePreview() {
    previewRef.current = true;
    await handleSubmit({ name: chapterName });
    history.push({
      pathname: `/chapter/preview/${chapter?.id}`,
      state: { removeFooter: true, chapter, backTo: `/chapter/edit/${chapter?.id}` },
    });
  }

  function openLink(link) {
    const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  const handleAddNewApostille = () => {
    setApostilleModalAction('new');
    setShowApostilleModal(true);
  };

  const handleEditApostille = () => {
    setApostilleModalAction('edit');
    setShowApostilleModal(true);
  };

  const handleCloseApostilleModal = () => {
    setShowApostilleModal(false);
  };

  const addCourses = async (id, data) => {
    await CourseAPICMS.addSubject(id, { subject: data });
  };

  const handleActionApostilleModal = async () => {
    if (!selectedData?.name) {
      return toast.error('É obrigatório preencher o campo de nome.');
    }

    setLoadingApostilleModalAction(true);
    const params = {
      name: selectedData.name,
      ...(selectedData?.subject_tag && { subject_tag: selectedData.subject_tag.value }),
      ...(selectedData?.institution && { institution: selectedData.institution.value }),
      ...(selectedData?.speciality && { speciality: selectedData.speciality.value }),
    };

    switch (apostilleModalAction) {
      case 'new':
        try {
          const { data } = await SubjectsAPI.create(params);

          if (selectedData?.courses && selectedData?.courses.length > 0) {
            await Promise.all(
              selectedData?.courses.map(async (course) => {
                addCourses(course.value, data.id);
              }),
            );
          }

          toast.success('Apostila criada com sucesso!');
          setLoadingApostilleModalAction(false);
          setShowApostilleModal(false);
          setSubjectKey(subjectKey + 1);
          setSelectedData(EMPTY_DATA);
        } catch {
          toast.error('Erro ao criar apostila, tente novamente mais tarde.');
        }
        break;
      case 'edit':
        try {
          await SubjectsAPI.update(selectedSubject.value, params);
          toast.success('Apostila atualizada com sucesso!');
          setLoadingApostilleModalAction(false);
          setShowApostilleModal(false);
          setSelectedData(EMPTY_DATA);
        } catch {
          toast.error('Erro ao atualizar apostila, tente novamente mais tarde.');
        }
        break;
      default:
        break;
    }
  };

  const returnActionText = () => {
    if (apostilleModalAction === 'new') {
      return 'Criar nova apostila';
    }
    if (apostilleModalAction === 'new') {
      return 'Editar nova apostila';
    }

    return '';
  };

  return (
    <>
      <S.Container>
        {!editMode && (
          <S.Header>
            <S.TitleHeader>Configurações</S.TitleHeader>
            <S.Chip>Novo Capítulo</S.Chip>
          </S.Header>
        )}
        <Form initialData={initialData} onSubmit={handleSubmit} schema={schema}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <S.FormContainer>
                <S.ContainerFields>
                  <GreenBorder />
                  <S.Field className="errorMessage">
                    <S.Label>Título</S.Label>
                    <Input
                      className="fieldHeight"
                      name="name"
                      placeholder="Título"
                      onChange={(event) => setChapterName(event.target.value)}
                      style={{ marginRight: 20, backgroundColor: '#eee', width: '100%' }}
                    />
                  </S.Field>
                  <S.Field width="100%">
                    <S.Label>Apostila</S.Label>
                    <S.Row>
                      <S.SelectContainer>
                        <SelectWithPagination
                          height={48}
                          name="subject"
                          value={selectedSubject}
                          onChange={(subject) => setSelectedSubject(subject)}
                          loadOptions={loadSubjects}
                          placeholder={loading ? 'Carregando...' : 'Apostila'}
                          asyncPaginateKey={`subject-select-key-${subjectKey}`}
                        />
                      </S.SelectContainer>

                      <S.ButtonAdd type="button" onClick={handleAddNewApostille}>
                        Adicionar nova
                      </S.ButtonAdd>
                      <S.ConfigurationButton type="button" onClick={handleEditApostille}>
                        <ConfiogurationIcon />
                        Configuração
                      </S.ConfigurationButton>
                    </S.Row>
                  </S.Field>
                  <S.Field>
                    <S.Label>Ordem</S.Label>
                    <Input
                      className="fieldHeight"
                      type="number"
                      name="order"
                      value={order}
                      placeholder="Ordem"
                      onChange={(event) => setOrder(event.target.value)}
                    />
                  </S.Field>
                  <S.FieldRow>
                    <S.Label>Trilha</S.Label>
                    <S.Row>
                      <S.ContainerSelect>
                        <SelectWithPagination
                          height={48}
                          name="pre_track"
                          value={selectedPreTrack}
                          onChange={(track) => setSelectedPreTrack(track)}
                          loadOptions={(search, loadedOptions, page) =>
                            loadTracks(search, loadedOptions, {
                              ...page,
                              track_type: TRACK_TYPE.PRETRILHA,
                            })
                          }
                          placeholder={loading ? 'Carregando...' : 'Pré-trilha'}
                          text={selectedPreTrack && 'Pré-trilha'}
                        />
                      </S.ContainerSelect>
                      <S.ButtonAdd
                        type="button"
                        hasLabel={selectedPreTrack}
                        onClick={() => {
                          window.open(`${process.env.REACT_APP_BASE_URL}/admin/track/track/add/`);
                        }}
                      >
                        Adicionar nova
                      </S.ButtonAdd>
                    </S.Row>
                    <S.Row>
                      <S.ContainerSelect>
                        <SelectWithPagination
                          height={48}
                          name="pos_track"
                          value={selectedPosTrack}
                          onChange={(track) => setSelectedPosTrack(track)}
                          loadOptions={(search, loadedOptions, page) =>
                            loadTracks(search, loadedOptions, {
                              ...page,
                              track_type: TRACK_TYPE.POSTRILHA,
                            })
                          }
                          placeholder={loading ? 'Carregando...' : 'Pós-trilha'}
                          text={selectedPosTrack && 'Pós-trilha'}
                        />
                      </S.ContainerSelect>
                      <S.ButtonAdd
                        hasLabel={selectedPosTrack}
                        type="button"
                        onClick={() => {
                          window.open(`${process.env.REACT_APP_BASE_URL}/admin/track/track/add/`);
                        }}
                      >
                        Adicionar nova
                      </S.ButtonAdd>
                    </S.Row>
                  </S.FieldRow>
                  {(editMode || chapter?.id) && (
                    <S.FieldRow>
                      <S.Label>Visível</S.Label>
                      <S.Row>
                        <label htmlFor="no">
                          <input
                            id="no"
                            type="radio"
                            name="option"
                            value="no"
                            checked={visible === 'no'}
                            onChange={(event) => setVisible(event.target.value)}
                          />
                          <S.CustomRadio checked={visible === 'no'}>
                            <span />
                          </S.CustomRadio>
                          <span className="valueRadio">Não</span>
                        </label>
                        <label htmlFor="yes">
                          <input
                            id="yes"
                            type="radio"
                            name="option"
                            value="yes"
                            checked={visible === 'yes'}
                            onChange={(event) => setVisible(event.target.value)}
                          />
                          <S.CustomRadio checked={visible === 'yes'}>
                            <span />
                          </S.CustomRadio>
                          <span className="valueRadio">Sim</span>
                        </label>
                      </S.Row>
                    </S.FieldRow>
                  )}
                  <S.Field>
                    <S.Label>Tags</S.Label>
                    <SelectWithPagination
                      height={48}
                      name="tags"
                      value={selectedTags}
                      onChange={(tag) => setSelectedTags(tag)}
                      loadOptions={loadTags}
                      placeholder={loading ? 'Carregando...' : 'Tags'}
                    />
                  </S.Field>
                  <S.FieldRow>
                    <S.Label>Comentaristas</S.Label>
                    <S.Row>
                      <S.ContainerSelect>
                        <SelectWithPagination
                          height={48}
                          isMulti
                          name="physicians"
                          value={selectedPhysicians}
                          onChange={(physician) => setSelectedPhysicians(physician)}
                          loadOptions={loadPhysicians}
                          placeholder={loading ? 'Carregando...' : 'Comentarista revisor em texto'}
                        />
                      </S.ContainerSelect>
                      <S.ButtonAdd
                        type="button"
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}/admin/users/physician/add/`,
                          );
                        }}
                      >
                        Adicionar novo
                      </S.ButtonAdd>
                    </S.Row>
                    <S.Row>
                      <S.ContainerSelect>
                        <SelectWithPagination
                          height={48}
                          isMulti
                          name="co_physicians"
                          value={selectedCoPhysicians}
                          onChange={(physician) => setSelectedCoPhysicians(physician)}
                          loadOptions={loadPhysicians}
                          placeholder={
                            loading ? 'Carregando...' : 'Comentarista colaborador em texto'
                          }
                        />
                      </S.ContainerSelect>
                      <S.ButtonAdd
                        type="button"
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}/admin/users/physician/add/`,
                          );
                        }}
                      >
                        Adicionar novo
                      </S.ButtonAdd>
                    </S.Row>
                  </S.FieldRow>
                  <S.Field>
                    <S.Label>Imagem da Capa</S.Label>
                    <label htmlFor="file">
                      <input
                        id="file"
                        type="file"
                        accept="image/*"
                        onChange={(event) => handleSelectFile(event)}
                      />
                      <S.CustomInputFile>
                        <S.FileName>{fileName}</S.FileName>
                        <S.ButtonSelectFile>Selecionar</S.ButtonSelectFile>
                      </S.CustomInputFile>
                    </label>
                  </S.Field>
                  {chapter?.pdf && (
                    <S.Field>
                      <S.Label>PDF</S.Label>
                      <S.CustomInputFile>
                        <S.FileName>{chapter.pdf.substring(0, 60)}...</S.FileName>
                        <S.ButtonSelectFile onClick={() => openLink(chapter.pdf)}>
                          Abrir
                        </S.ButtonSelectFile>
                      </S.CustomInputFile>
                    </S.Field>
                  )}
                </S.ContainerFields>
              </S.FormContainer>
              <S.FooterActions>
                <S.ButtonBack type="button" onClick={() => history.push('/chapter')}>
                  <MdArrowBack size={18} color="#01c3aa" />
                  Voltar
                </S.ButtonBack>
                <S.BoxButtons>
                  {editMode && (
                    <S.ButtonPreview onClick={() => handlePreview()}>
                      Revisão
                      <MdArrowForward size={18} color="#01c3aa" />
                    </S.ButtonPreview>
                  )}
                  <S.ButtonSave>
                    {`${editMode ? 'Salvar' : 'Continuar'}`}
                    {editMode ? (
                      <MdSave size={18} color="#FFF" />
                    ) : (
                      <MdArrowForward size={18} color="#fff" />
                    )}
                  </S.ButtonSave>
                </S.BoxButtons>
              </S.FooterActions>
            </>
          )}
        </Form>
      </S.Container>
      {showApostilleModal && (
        <ApostilleModal
          id={selectedSubject.value}
          show={showApostilleModal}
          close={handleCloseApostilleModal}
          action={handleActionApostilleModal}
          actionText={returnActionText()}
          loading={loadingApostilleModalAction}
          data={selectedData}
          setData={setSelectedData}
          modalAction={apostilleModalAction}
        />
      )}
    </>
  );
}

export default Settings;
