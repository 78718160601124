import { useQuery } from '@tanstack/react-query';

import { CourseAPICMS } from '~/services/apiCallsCMS';

const getCourse = async (params) => {
  try {
    const response = await CourseAPICMS.getCourseDetail(params);
    if (response && response.data && response.data) {
      const data = response.data;
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    throw error;
  }
};

export default function useGetCourses(params, options) {
  return useQuery({
    queryKey: ['getCourses', params],
    queryFn: () => getCourse(params),
    staleTime: 0,
    ...options?.config,
  });
}
