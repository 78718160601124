import { Physician } from '~/services';

const transformOptions = (item) => {
  if (!item?.id || !item?.name) return;

  let returnValues = {
    label: item.verbose || item.name,
    key: item.id,
    value: item.id,
  };

  if (item.parent_tag) {
    returnValues.parentTag = item.parent_tag;
  }

  return returnValues;
};

const renderOptions = (data, page) => {
  return {
    options: data.results.map(transformOptions),
    hasMore: data.next,
    additional: {
      page: page + 1,
    },
  };
};

export const loadPhysicians = async (search, loadedOptions, { page }) => {
  const { data } = await Physician.list({ page, search, ordering: 'user__name' });
  data.results.forEach((physician) => {
    physician.name = `${physician.name} - ${physician.email}`;
  });
  return renderOptions(data, page);
};

export const searchPhysicianById = async (id) => {
  if (!id) return;

  const { data } = await Physician.list({ id: id });
  return renderOptions(data);
};
