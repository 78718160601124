import React, { useState, useCallback, useEffect } from 'react';

import ModalFilter from '~/components/ModalFilter';

import { useTrack } from '~/hooks-querys/tracks';

import Pagination from '~/components/Pagination';
import Loader from '~/components/Loader';

import Filter from './Filter';
import TrackItem from './TrackItem';
import * as S from './styles';

let isFirstRender = true;

function TrackSearch({ show, close, showTrackType }) {
  const [dispatchFilter, setDispatchFilter] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPagination, setHasPagination] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
  });

  const params = {
    search: filter.search,
    course: filter.course,
    track_category: filter.category,
    speciality: filter.speciality,
    created_at__gte: filter.created_at__gte,
    created_at__lt: filter.created_at__lt,
    track_type__in: 'i, h',
    enabled: filter.enabled,
    page: currentPage,
    pre_pos_track: true,
  };

  const { data: trackData, isFetching: fetchingTrackData } = useTrack(params, {
    config: {
      enabled: dispatchFilter,
    },
  });

  useEffect(() => {
    if (!trackData) return;

    setSearchResults(trackData.results);
    setTotalPages(Math.ceil(trackData.count / 30));
    setHasPagination(!!trackData.next || !!trackData.previous);
    setDispatchFilter(false);
  }, [trackData]);

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const onCleanFilter = () => {
    setFilter({ search: '', course: null, category: null, page: 1 });
  };

  const onHandleFilter = () => {
    setDispatchFilter(true);
  };

  const onAddItems = () => {
    close(selectedItems);
  };

  useEffect(() => {
    if (isFirstRender) {
      isFirstRender = false;
      return;
    }
    setDispatchFilter(true);
  }, [currentPage]);

  useEffect(() => {
    setDispatchFilter(false);
  }, [filter?.search]);

  function renderList() {
    return (
      <S.Container>
        {fetchingTrackData ? (
          <Loader />
        ) : (
          <>
            {searchResults?.length > 0 && (
              <>
                <S.ContainerItems>
                  {searchResults.map((item, key) => (
                    <TrackItem
                      track={item}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                    />
                  ))}
                </S.ContainerItems>
                <S.ContainerPagination>
                  {searchResults?.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      backPage={goToPreviousPage}
                      nextPage={goToNextPage}
                      setPage={setCurrentPage}
                    />
                  )}
                </S.ContainerPagination>
              </>
            )}
          </>
        )}
      </S.Container>
    );
  }

  return (
    <ModalFilter
      show={show}
      close={close}
      title="Buscar trilhas existentes"
      buttonTitle="Adicionar trilha"
      showFooter={searchResults?.length > 0}
      onCleanFilter={onCleanFilter}
      onHandleFilter={onHandleFilter}
      onAddItems={onAddItems}
      childrenFilter={<Filter filter={filter} setFilter={setFilter} />}
      childrenList={renderList()}
    />
  );
}

export default TrackSearch;
