import React, { useState, useRef, useEffect } from 'react';
import Rating from '@mui/material/Rating';

import * as S from './styles';

function RatingComponent({ initialValue, setRating, size }) {
  const [value, setValue] = useState(null);
  const starsRef = useRef();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChangeValue = (newValue) => {
    setValue(newValue);
    setRating(newValue);
  };

  return (
    <S.Container>
      <S.Label>Essa sugestão foi útil?</S.Label>
      <S.RatingContainer>
        <Rating
          ref={starsRef}
          name="simple-controlled"
          size={size || 'medium'}
          value={value || null}
          onChange={(event, newValue) => {
            handleChangeValue(newValue);
          }}
        />
        <S.BottomLabelContainer width={starsRef.current?.offsetWidth}>
          <S.BottomLabel>Ruim</S.BottomLabel>
          <S.BottomLabel>Ótimo</S.BottomLabel>
        </S.BottomLabelContainer>
      </S.RatingContainer>
    </S.Container>
  );
}

export default RatingComponent;
