import { useQuery } from '@tanstack/react-query';
import { TAG_TYPE } from '~/utils/variables';
import { Tag } from '~/services';

const transformOptions = (item) => {
  if (!item?.id || !item?.name) return;

  let returnValues = {
    label: item.verbose || item.name,
    key: item.id,
    value: item.id,
  };

  if (item.parent_tag) {
    returnValues.parentTag = item.parent_tag;
  }

  return returnValues;
};

const fetchCfas = async (parentTag) => {
  try {
    const response = await Tag.list({
      no_page: 1,
      ordering: 'name',
      tag_type: TAG_TYPE.CFA,
      ...(!!parentTag && { parent_tag: parentTag }),
    });

    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data.map(transformOptions);
  } catch (error) {
    throw error;
  }
};

export default function useGetCfas(parentTag, options) {
  return useQuery({
    queryKey: ['parentTag', parentTag],
    queryFn: () => fetchCfas(parentTag),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
