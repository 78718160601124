import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  height: calc(100vh - 120px);
  position: relative;
`;

export const HeaderContainer = styled.div`
  height: 34px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #3b3fb6;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 3px 3px 0px 0px;
`;
export const CloseButton = styled.div`
  align-items: center;
  justify-content: center;
  margin-right: 1%;
  cursor: pointer;
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  color: #ffffff;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-right: 4%;
  padding-left: 4%;
`;
