import React from 'react';
import Select, { components } from 'react-select';

import * as S from './styles';

export default function SelectComponent({
  value,
  defaultValue,
  name,
  text,
  placeholder,
  onChange,
  options,
  width,
  height,
  backgroundColor,
  disabled,
  marginLeft,
  marginTop,
  controlStyle,
  optionsStyle,
  singleValueStyle,
  placeholderStyle,
  valueContainerStyle,
  dropdownIndicatorStyle,
  indicatorsContainerStyle,
  containerStyle,
  customDropdownIndicator,
  isSearchable,
  filterOption,
  customNoOptionsMessage,
  isMulti,
  paddingLeft,
}) {
  const SelectStyle = {
    container: (styles) => ({
      ...styles,
      color: '#3D5582',
      width: width || 140,
      ...containerStyle,
    }),
    control: (styles) => ({
      ...styles,
      background: backgroundColor,
      border: '2px solid #E5E8EE',
      borderRadius: 8,
      height,
      fontWeight: 400,
      fontSize: 14.2,
      letterSpacing: 0.15,
      cursor: 'pointer',
      paddingLeft: paddingLeft || -10,
      marginLeft: marginLeft || -10,
      marginTop: marginTop || 0,
      ':hover': {
        backgroundColor: '#FFF',
        border: '3px solid #3D5582',
        fontWeight: 600,
        boxShadow: '0px 0px 0px 6px rgba(61, 85, 130, 0.2)',
      },
      ':focus-within': {
        backgroundColor: '#FFF',
        border: '3px solid #01cfb5',
        boxShadow: '0px 0px 0px 6px rgba(1, 207, 181, 0.2)',
      },
      ...controlStyle,
    }),
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: '#fff',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        cursor: 'pointer',
        letterSpacing: 0.15,
        color: '#3D5582',
        ':hover': {
          backgroundColor: '#F0F2F5',
        },
        ...optionsStyle,
      };
    },
    menu: (styles) => ({
      ...styles,
      zIndex: 9999,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#3D5582',
      ...dropdownIndicatorStyle,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      color: '#3D5582',
      svg: {
        border: '2px solid #3d5582',
        borderRadius: '100px',
        transition: 'border 0.2s ease-in-out',
      },
      '&:hover, &:focus': {
        svg: {
          border: '2px solid #01cfb5',
          color: '#01cfb5',
        },
      },
      ...indicatorsContainerStyle,
    }),
    indicatorSeparator: () => ({
      backgroundColor: '#fff',
    }),
    multiValue: (styles) => ({
      ...styles,
      '.css-12jo7m5': {
        marginRight: '22px',
        color: '#00205b',
        fontWeight: '600',
        fontSize: '12.6px',
        lineHeight: '17px',
      },
      backgroundColor: '#fff',
      border: '2px solid #00205B',
      borderRadius: '100px',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      paddingInline: '12px',
      position: 'relative',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      position: 'absolute',
      right: '-2px',
      svg: {
        background: '#F0F2F5',
        width: '24px',
        height: '24px',
        color: '#8f9db7',
      },
      ':hover': {
        path: {
          fill: '#00205ba6',
        },
      },
    }),
    singleValue: () => ({
      color: '#3D5582',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '21px',
      border: 0,
      backgroundColor: backgroundColor || '#f5f5fb',
      opacity: disabled ? 0.5 : 1,
      ...singleValueStyle,
    }),
    placeholder: (styles) => ({
      ...styles,
      border: 0,
      color: '#3D5582',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '21px',
      ...placeholderStyle,
    }),
    valueContainer: (styles) => ({
      ...styles,
      ...valueContainerStyle,
    }),
    disabled: (styles) => ({
      ...styles,
      color: '#c5c5c5',
      fontSize: 12,
      border: 0,
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {customDropdownIndicator}
      </components.DropdownIndicator>
    );
  };
  const handleRemoveValue = (event) => {
    if (!event.currentTarget || !onChange) return;
    const { name: buttonIndex } = event.currentTarget;
    onChange(value.filter((val) => val.value !== parseInt(buttonIndex, 10)));
  };

  return (
    <S.SelectContainer styles={{ height }}>
      {text && <S.SelectDescription>{text}</S.SelectDescription>}
      <Select
        name={name}
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        styles={SelectStyle}
        isSearchable={isSearchable}
        filterOption={filterOption}
        isDisabled={disabled}
        isMulti={isMulti}
        controlShouldRenderValue={!isMulti}
        components={customDropdownIndicator ? { DropdownIndicator } : {}}
        noOptionsMessage={() => (
          <div>{customNoOptionsMessage || 'Nenhum resultado encontrado'}</div>
        )}
      />
      {isMulti && value && (
        <S.ValuesContainer>
          {value.map((val) => (
            <S.Value key={val.value}>
              {val.label}
              <S.RemoveButton name={val.value} onClick={(event) => handleRemoveValue(event)}>
                ✕
              </S.RemoveButton>
            </S.Value>
          ))}
        </S.ValuesContainer>
      )}
    </S.SelectContainer>
  );
}

SelectComponent.defaultProps = {
  name: 'order',
  placeholder: 'Mais recentes',
  text: '',
  width: '130px',
  height: '40px',
  backgroundColor: '#F0F2F5',
  disabled: false,
  isSearchable: false,
  controlStyle: {},
  optionsStyle: {},
  singleValueStyle: {},
  placeholderStyle: {},
  valueContainerStyle: {},
  dropdownIndicatorStyle: {},
  indicatorsContainerStyle: {},
  containerStyleStyle: {},
  customDropdownIndicator: null,
  customNoOptionsMessage: null,
  isMulti: null,
  filterOption: (candidate, input) => {
    if (!input) return true;
    const search = input.toLowerCase().trim();
    if (candidate.label && typeof candidate.label === 'string') {
      const value = candidate.label.toLowerCase().trim();
      return value.indexOf(search.toLowerCase()) !== -1;
    }
    if (candidate.value && typeof candidate.value === 'string') {
      const value = candidate.value.toLowerCase().trim();
      return value.indexOf(search.toLowerCase()) !== -1;
    }
    return false;
  },
};
