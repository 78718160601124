import React from 'react';

import { TagContainer } from './styles';

function Tags({ text, type, textColor, backgroundColor, textUppercase}) {
	return (
		<TagContainer 
			type={type || 'primary'}
			textColor={textColor}
			backgroundColor={backgroundColor}
			textUppercase={textUppercase}>
      {text}
		</TagContainer>
	);
}

export default Tags;
