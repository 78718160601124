import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

function WhiteTooltip({ text, children }) {
  const StyledTooltip = withStyles({
    tooltipPlacementTop: {
      right: '0px',
      top: '12px',
    },
    tooltip: {
      padding: '10px 14px',
      color: '#00205B',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: 600,
      borderRadius: '8px',
      boxShadow: '0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08)',
      background: '#fff',
      maxWidth: 'none',
    },
    arrow: {
      color: '#fff',
      '&:before': {
        boxShadow: '0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08)',
      },
    },
  })(Tooltip);

  return (
    <StyledTooltip title={text} placement="top-end" arrow>
      <span>{children}</span>
    </StyledTooltip>
  );
}

export default WhiteTooltip;
