/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import RadioButton from '~/components/RadioButton';

import { Container } from './styles';

function RadioGroup({ data, handleRadio, radioValue, formProp, setFormsData }) {
  return (
    <Container>
      {data &&
        data.length > 0 &&
        data.map((radio, index) => {
          return (
            <div key={`radio_${index}`}>
              <RadioButton
                control={radioValue}
                onChange={(event) => handleRadio(event, formProp, setFormsData)}
                text={radio.label}
                value={radio.value}
              />
            </div>
          );
        })}
    </Container>
  );
}

export default RadioGroup;
