import React from 'react';
import { Container, LoaderContainer, Loader, CenterIcon, Text } from './styles';

const NewLoader =({
  children,
  text, 
  customStyles,
}) => {
    return (
        <Container style={{...customStyles}}>
            <LoaderContainer>
                <Loader />
                <CenterIcon>
                    {children}
                </CenterIcon>
            </LoaderContainer>
            <Text>
                {text}
            </Text>
        </Container>
    );
}

export default NewLoader;
