import { useQuery } from '@tanstack/react-query';

import { TaskFinancialAPICMS } from '~/services/apiCallsCMS';

const getFinancialTask = async (params) => {
  try {
    const response = await TaskFinancialAPICMS.getFinancialTasks(params);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useGetFinancialTask(params, options) {
  return useQuery({
    queryKey: ['getFinancialTask', params],
    queryFn: () => getFinancialTask(params),
    staleTime: 0,
    ...options?.config,
  });
}
