import { useQuery } from '@tanstack/react-query';

import { SpecialityAPICMS } from '~/services/apiCallsCMS';

const fetchSpecialityById = async (id) => {
  try {
    const response = await SpecialityAPICMS.get(id);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useSpecialityById(id, options) {
  return useQuery({
    queryKey: ['specialityId', id],
    queryFn: () => fetchSpecialityById(id),
    staleTime: 10 * 60000,
    ...options?.config,
  });
}
