import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Axios from 'axios';

import Loader from '~/components/Loader';
import DatePicker from '~/components/DatePickerComponent';
import TextEditorComponent from '~/components/TextEditorComponent';

import SelectComponent from '~/components/SelectComponent';
import SelectWithPagination from '~/components/SelectWithPagination';
import SelectComponentMaterialUi from '~/components/SelectComponentMaterialUi';
import Modal from '@material-ui/core/Modal';

import { LessonModuleAPICMS, LessonModulePreRequiredItemsAPICMS } from '~/services/apiCallsCMS';
import { useFilterSpecialitys } from '~/hooks-querys/filters';
import { useGetCourses } from '~/hooks-querys/course';
import { useLessonSubject } from '~/hooks-querys/lessonSubject';
import { useLessonModulePreReqItems } from '~/hooks-querys/lessonModulePreReqItems';
import { useLessonModuleBySubject, useLessonModuleById } from '~/hooks-querys/lessonModule';
import { useModuleItems } from '~/hooks-querys/lessonModuleItems';
import { getTags, loadTags, loadCfas } from '~/pages/Chapters/components/Settings/utils';
import * as S from './styles';

const CreateLesson = ({
  showModal,
  handleClose,
  course,
  courseName,
  lessonSubject,
  lessonModule,
}) => {
  const [nameLesson, setNameLesson] = useState(null);
  const [orderMatriz, setOrderMatrix] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [fileName, setFileName] = useState('Imagem de capa');
  const [fileLesson, setFileLesson] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [selectedLessonSubject, setSelectedLessonSubject] = useState(null);
  const [selectedLessonModule, setSelectedLessonModule] = useState(null);
  const [selectedLessonObject, setSelectedLessonObject] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedCFA, setSelectedCFA] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [modules, setModules] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [objects, setObjects] = useState([]);
  const [thumbLesson, setThumbLesson] = useState(null);
  const [fetch, setFetch] = useState(true);
  const [thumbName, setThumbName] = useState('Thumbnail');
  const [description, setDescription] = useState(null);

  const templateDescription = `Fala, galera! <br/>
    Seja muito bem-vindos à mais uma aula do ${courseName}! <br/>
    E vamos a mais um assunto quem precisam estudar com atenção, pois é bastante frequente nas provas: ${nameLesson}! <br/>
    Aproveitem e bons estudos! <br/>
    #PRACIMA`;

  const { data: courseDetail, isLoading: loadingCourse } = useGetCourses(course, {
    config: {
      enabled: !!course,
    },
  });

  const { data: specialitysData, isLoading: loadingSpecialities } = useFilterSpecialitys({
    no_page: true,
    ordering: 'name',
    main_speciality: true,
  });

  const { data: lessonSubjects, isLoading: loadingLessonSubjects } = useLessonSubject({
    ordering: 'order',
    course,
    no_page: true,
  });

  const { data: lessonModules, isLoading: loadingLessonItems } = useLessonModuleBySubject(
    { lesson_subject: selectedLessonSubject, no_page: true },
    {
      config: {
        enabled: !!selectedLessonSubject,
      },
    },
  );

  const { data: lessonItems } = useModuleItems(
    { lesson_module: selectedLessonModule, no_page: true, is_track: true },
    {
      config: {
        enabled: !!selectedLessonModule,
      },
    },
  );

  const { data: updateLessonModule, isLoading: loadingLessonModule } = useLessonModuleById(
    lessonModule,
    {
      config: {
        enabled: !!lessonModule && fetch,
      },
    },
  );

  const { data: preReqItems, isLoading: loadingPreReqItems } = useLessonModulePreReqItems(
    { lesson_module: lessonModule, no_page: true },
    {
      config: {
        enabled: !!lessonModule && fetch,
      },
    },
  );

  function handleImageFile(event, isLesson) {
    if (!event.target?.files || event.target?.files.length === 0) {
      return;
    }
    const file = event.target?.files[0];
    if (isLesson) {
      setFileLesson(file);
      setFileName(file.name);
    } else {
      setThumbName(file.name);
      setThumbLesson(file);
    }
  }

  const transformPreRequired = (value) => {
    if (!value.key || !value.item.content_type) return;

    return {
      object_id: value.key,
      content_type: value.item.content_type,
    };
  };

  function handleCreateTemplate(id) {
    const source = Axios.CancelToken.source();
    LessonModuleAPICMS.createTemplateByCourse(id, {}, source.token)
      .then(function () {
        handleClose();
      })
      .catch(function () {
        toast.error(`Não foi possível criar os templates.`);
      });
  }

  function handlePrerequiredItems(id, isUpdate) {
    if (selectedLessonObject?.length > 0) {
      const data = {
        lesson_module: id,
        objects: selectedLessonObject.map(transformPreRequired),
      };
      const source = Axios.CancelToken.source();
      LessonModulePreRequiredItemsAPICMS.updateItems(data, source.token)
        .then(async (_result) => {
          if (isUpdate) {
            handleClose();
          } else {
            handleCreateTemplate(id);
          }
        })
        .catch(() => {
          toast.error(`Não foi possível salvar os itens pré-requisito.`);
        });
    } else {
      if (isUpdate) {
        handleClose();
      } else {
        handleCreateTemplate(id);
      }
    }
  }

  function handleSaveFiles(id, isUpdate) {
    const source = Axios.CancelToken.source();
    if (!fileLesson && !thumbLesson) {
      toast.success(`Aula ${isUpdate ? 'atualizada' : 'criada'} com sucesso.`);
      handlePrerequiredItems(id, isUpdate);
      return;
    }

    if (fileLesson) {
      LessonModuleAPICMS.updateFile(id, { cover_image: fileLesson }, source.token)
        .then(async (_result) => {
          toast.success(`Aula ${isUpdate ? 'atualizada' : 'criada'} com sucesso.`);
          handlePrerequiredItems(id, isUpdate);
        })
        .catch(() => {
          toast.error(`Erro no vínculo da capa`);
        });
    }

    if (thumbLesson) {
      LessonModuleAPICMS.updateFile(id, { thumbnail_mobile: thumbLesson }, source.token)
        .then(async (_result) => {
          toast.success(`Aula ${isUpdate ? 'atualizada' : 'criada'} com sucesso.`);
          if (!fileLesson) handlePrerequiredItems(id, isUpdate);
          else handleClose();
        })
        .catch(() => {
          toast.error(`Erro no vínculo do thumbnail`);
        });
    }
  }

  function handleSave() {
    // if (!selectedSpeciality) {
    //   toast.error('Especialidade é obrigatória!');
    //   return;
    // }

    // if (!selectedTag) {
    //   toast.error('Foco é obrigatório!');
    //   return;
    // }

    if (!nameLesson) {
      toast.error('Nome da aula é obrigatório!');
      return;
    }
    if (!description) {
      toast.error('Descrição é obrigatória!');
      return;
    }

    const data = {
      name: nameLesson,
      description: description?.text,
      speciality: selectedSpeciality ? [selectedSpeciality?.value] : [],
      tag: [
        ...(selectedTag ? [selectedTag.value] : []),
        ...(selectedCFA && selectedCFA.length > 0 ? selectedCFA.map((spec) => spec.value) : []),
      ],
      start_availability: startTime && new Date(startTime).toISOString(),
      instructional_order: orderMatriz || 1,
    };

    const source = Axios.CancelToken.source();
    if (!lessonModule) {
      data.lesson_subject = lessonSubject;

      LessonModuleAPICMS.create(data, source.token)
        .then(async (result) => {
          const { id } = result.data;
          handleSaveFiles(id, false);
        })
        .catch(() => {
          toast.error(`Erro na criação da Aula.`);
        });
    } else {
      LessonModuleAPICMS.update(lessonModule, data, source.token)
        .then(async (_result) => {
          handleSaveFiles(lessonModule, true);
        })
        .catch(() => {
          toast.error(`Erro na atualização da aula`);
        });
    }
  }

  const transformOptions = (item) => {
    if (!item.id || !item.name) return;

    return {
      label: item.name,
      key: item.id,
      value: item.id,
      item,
    };
  };

  const transformOptionsObject = (item) => {
    if (!item.object_id || !item.name) return;

    return {
      label: `${item.object_id} - ${item.name}`,
      key: item.object_id,
      value: item.object_id,
      item,
    };
  };

  const transformOptionsObjectPreReq = (item) => {
    if (!item.object_id) return;

    return {
      label: `${item.object_id} - Trilha`,
      key: item.object_id,
      value: item.object_id,
      item,
    };
  };

  const handleChangeTag = (item) => {
    setSelectedTag(item);
    setSelectedCFA([]);
  };

  useEffect(() => {
    if (nameLesson && !updateLessonModule) {
      setDescription(templateDescription);
    }
  }, [nameLesson]);

  useEffect(() => {
    if (!lessonModules) return;
    setLessons(lessonModules.lessonModules.map(transformOptions));
  }, [lessonModules]);

  useEffect(() => {
    if (!lessonItems) return;
    setObjects(lessonItems.map(transformOptionsObject));
  }, [lessonItems]);

  useEffect(() => {
    if (!specialitysData) return;
    setSpecialities(specialitysData.map(transformOptions));
  }, [specialitysData]);

  useEffect(() => {
    if (!lessonSubjects) return;
    setModules(lessonSubjects.map(transformOptions));
  }, [lessonSubjects]);

  useEffect(() => {
    if (selectedTag && !lessonModule) {
      setNameLesson(selectedTag.label.split('[Foco] ')[1] || selectedTag.label);
    }
  }, [selectedTag]);

  useEffect(() => {
    if (updateLessonModule) {
      setFetch(false);
      setDescription(updateLessonModule.description);
      setNameLesson(updateLessonModule.name);
      setStartTime(updateLessonModule.start_availability);
      setOrderMatrix(updateLessonModule.instructional_order);
      setFileName(
        updateLessonModule.cover_image
          ? updateLessonModule.cover_image.split('/lesson/')[1].split('?')[0].substring(0, 70)
          : '',
      );
      setThumbName(
        updateLessonModule.thumbnail_mobile
          ? updateLessonModule.thumbnail_mobile.split('/lesson/')[1].split('?')[0].substring(0, 70)
          : '',
      );
      if (updateLessonModule.focus.length > 0) {
        getTags(updateLessonModule.focus).then((res) => {
          if (res.length > 0) {
            setSelectedTag(res[0]);
          }
        });
      }
    }
  }, [updateLessonModule]);

  useEffect(() => {
    if (updateLessonModule && selectedTag) {
      if (updateLessonModule.cfas.length > 0) {
        getTags(updateLessonModule.cfas).then((res) => {
          if (res.length > 0) {
            setSelectedCFA(res);
          }
        });
      }
    }
  }, [selectedTag, updateLessonModule]);

  useEffect(() => {
    if (!preReqItems) return;
    setSelectedLessonObject(preReqItems.map(transformOptionsObjectPreReq));
  }, [preReqItems]);

  useEffect(() => {
    if (specialities.length > 0 && updateLessonModule?.speciality?.length > 0) {
      setSelectedSpeciality(
        specialities.find((element) => element.value === updateLessonModule.speciality[0]),
      );
    }
  }, [specialities, updateLessonModule]);

  const textfieldStyle = {
    display: 'flex',
    flexBasis: '30%',
    width: '350px',
  };

  const textfieldStyleBigger = {
    flexBasis: '30%',
    width: '100%',
  };

  return (
    <Modal open={showModal} onClose={handleClose}>
      <S.CardModal>
        <S.Header>Configuração de aula</S.Header>
        {(loadingLessonModule && lessonModule) || loadingSpecialities ? (
          <Loader />
        ) : (
          <S.Content>
            <S.Label>Especialidade e foco</S.Label>
            <S.Container>
              <SelectComponent
                value={selectedSpeciality}
                options={specialities}
                onChange={(item) => setSelectedSpeciality(item)}
                placeholder="Especialidade"
                height="40px"
                width="100%"
                marginLeft="0"
                paddingLeft="0"
                controlStyle={{ background: '#fff' }}
                singleValueStyle={{ fontSize: '14px', position: 'absolute', background: '#fff' }}
              />

              <SelectWithPagination
                name="tags"
                height={40}
                value={selectedTag}
                disabled={!courseDetail}
                onChange={(item) => handleChangeTag(item)}
                loadOptions={(search, loadedOptions, page) =>
                  loadTags(search, loadedOptions, {
                    ...page,
                    new_residency_degree: courseDetail?.residency_degree,
                  })
                }
                placeholder="Focos"
                placeholderStyle={{
                  display: 'flex',
                  fontSize: '14px',
                }}
                valueContainerStyle={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flexWrap: 'nowrap',
                }}
                controlStyle={{ background: '#fff' }}
                singleValueStyle={{ fontSize: '14px', position: 'absolute', background: '#fff' }}
              />
              <SelectWithPagination
                name="cfas"
                height={40}
                value={selectedCFA}
                disabled={!selectedTag}
                onChange={(item) => setSelectedCFA(item)}
                loadOptions={(search, loadedOptions, page) =>
                  loadCfas(search, loadedOptions, page, { parent_tag: selectedTag.value })
                }
                isMulti
                placeholder="CFA"
                placeholderStyle={{
                  display: 'flex',
                  fontSize: '14px',
                }}
                valueContainerStyle={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flexWrap: 'nowrap',
                }}
                controlStyle={{ background: '#fff' }}
                singleValueStyle={{ fontSize: '14px', position: 'absolute', background: '#fff' }}
              />
            </S.Container>
            <S.Label>Nome</S.Label>
            <S.RowContainer>
              <S.InputStyle>
                <input
                  id="name"
                  type="text"
                  placeholder="Nome da aula"
                  onChange={(event) => {
                    setNameLesson(event.target.value);
                  }}
                  value={nameLesson}
                />
              </S.InputStyle>
            </S.RowContainer>
            <S.Label>Descrição</S.Label>
            <S.RowContainer>
              <TextEditorComponent
                textHTML={description}
                onEdit={(text) => setDescription({ ...description, text })}
                simplify
              />
            </S.RowContainer>
            <S.Label>Imagem de Capa</S.Label>
            <S.RowContainer>
              <form>
                <label htmlFor="image" style={{ width: '100%' }}>
                  <input
                    id="image"
                    type="file"
                    accept="image/*"
                    onChange={(event) => handleImageFile(event, true)}
                    style={{ display: 'none' }}
                  />
                  <S.CustomInputFile>
                    <S.FileName>{fileName}</S.FileName>
                    <S.ButtonSelectFile type="button">Selecionar</S.ButtonSelectFile>
                  </S.CustomInputFile>
                </label>
              </form>
            </S.RowContainer>
            <S.Label>Thumbnail</S.Label>
            <S.RowContainer>
              <form>
                <label htmlFor="thumb" style={{ width: '100%' }}>
                  <input
                    id="thumb"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(event) => handleImageFile(event, false)}
                  />
                  <S.CustomInputFile>
                    <S.FileName>{thumbName}</S.FileName>
                    <S.ButtonSelectFile type="button">Selecionar</S.ButtonSelectFile>
                  </S.CustomInputFile>
                </label>
              </form>
            </S.RowContainer>
            <S.Label>Início da disponibilidade</S.Label>
            <S.RowContainer>
              <DatePicker
                label="Início"
                time
                value={startTime}
                handleChange={(event) => setStartTime(event)}
              />
            </S.RowContainer>
            <S.Label>Ordem da matriz</S.Label>
            <S.SubLabel>Disponibilidade somente para Mapa de Estudos</S.SubLabel>
            <S.RowContainer>
              <S.InputStyle>
                <input
                  id="order"
                  type="number"
                  placeholder="Ordem"
                  onChange={(event) => {
                    setOrderMatrix(event.target.value);
                  }}
                  value={orderMatriz}
                />
              </S.InputStyle>
            </S.RowContainer>

            <S.Label>Liberação da aula</S.Label>
            <S.SubLabel>
              Preencher somente caso a aula for liberada após conclusão de certo conteúdo
            </S.SubLabel>
            <S.RowContainer>
              <S.ContainerSelects>
                <SelectComponentMaterialUi
                  label="Módulo"
                  placeholder="Módulo"
                  values={modules}
                  selectedValue={selectedLessonSubject}
                  disabled={loadingLessonSubjects}
                  loadMore={false}
                  loadingMore={false}
                  shouldLoadMore={false}
                  setSelectedValue={(event) => {
                    setSelectedLessonSubject(event.target.value);
                  }}
                  multiple={false}
                  textfieldStyle={textfieldStyle}
                  size="small"
                  id="module"
                  limitWidth
                />
                <SelectComponentMaterialUi
                  label="Aula"
                  placeholder="Aula"
                  values={lessons}
                  selectedValue={selectedLessonModule}
                  loadMore={false}
                  loadingMore={false}
                  shouldLoadMore={false}
                  setSelectedValue={(event) => {
                    setSelectedLessonModule(event.target.value);
                  }}
                  multiple={false}
                  textfieldStyle={textfieldStyle}
                  size="small"
                  id="lesson"
                  limitWidth
                  disabled={loadingLessonSubjects || lessons.length === 0}
                />
              </S.ContainerSelects>

              <SelectComponent
                value={selectedLessonObject}
                options={objects}
                onChange={(item) => setSelectedLessonObject(item)}
                placeholder="Objeto"
                height="40px"
                width="100%"
                isMulti
                marginLeft="0"
                paddingLeft="0"
                controlStyle={{ background: '#fff' }}
                singleValueStyle={{ fontSize: '14px', position: 'absolute', background: '#fff' }}
              />
            </S.RowContainer>
          </S.Content>
        )}
        <S.Footer>
          <S.ButtonAdd
            onClick={() => {
              handleSave();
            }}
          >
            {lessonModule ? 'Atualizar' : 'Adicionar'}
          </S.ButtonAdd>
        </S.Footer>
      </S.CardModal>
    </Modal>
  );
};

export default CreateLesson;
