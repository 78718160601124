import { useQuery } from '@tanstack/react-query';
import { GetQuestionsAPICMS } from '~/services/apiCallsCMS';

const getSearchQuestionsModal = async (params) => {
  try {
    const response = await GetQuestionsAPICMS.list(params);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    if (response && response.data) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export default function useSearchQuestionsModalGet(params, options) {
  return useQuery({
    queryKey: ['searchQuestionsModalGet', params],
    queryFn: () => getSearchQuestionsModal(params),
    staleTime: 0,
    retry: 1,
    ...options?.config,
  });
}
