import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const QuestionCommentContainer = styled.div`
  padding-top: 3px;
  width: 100%;
  background-color: rgba(242, 242, 242, 0.4);
`;

export const ExplanationContainer = styled.div`
  width: 98%;
  margin: 16px 1%;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  align-self: flex-start;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 28px;
  justify-content: space-between;
  border-bottom: 1px solid #dadadb;
  margin-bottom: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  color: #00205b;
  gap: 24px;
  width: 100%;
`;

export const Title = styled.h1`
  font-family: Montserrat;
  color: #00205b;
  font-size: 22.8px;
  font-weight: 600;
  line-height: 30.4px;
  letter-spacing: 0.25px;
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 0 24px;
`;

export const ContentQuestionContainer = styled(ContentContainer)`
  @media (min-width: 1550px) {
    max-height: 776px;
    overflow-y: scroll;
  }
`;

export const MarginContainer = styled.div`
  margin-bottom: 5px;
`;

export const CommentTextContainer = styled.div`
  margin-top: 5px;
`;

export const CommentText = styled.div`
  margin-top: 15px;
  color: #767676;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 22px;
  user-select: none;
  width: 100%;
`;

export const CommentUpdatedText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #343434;
  margin-top: 33px;
`;

export const OpinionContainer = styled.div`
  border-top-width: 2px;
  padding-top: 25px;
  border-top-color: #f5f5fb;
`;

export const OpinionText = styled.div`
  height: 20px;
  color: #343434;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`;

export const OpinionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-top: 15px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 35px;
  width: 90px;

  border: 1px solid #3b3fb6;
  border-radius: 17.5px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#3b3fb6' : '#fff')};
  color: ${(props) => (props.selected ? '#fff' : '#3b3fb6')};

  font-size: 10px;
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  position: relative;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  height: 40px;
  gap: 8px;
  padding: 10px 16px;
  background: ${(props) => (props.disabled ? '#F0F2F5' : '#01B69F')};
  color: ${(props) => (props.disabled ? '#D1D7E1' : '#FFF')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  border: none;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: ${(props) => (props.disabled ? '#F0F2F5' : '#01cfb5')};
  }
  path {
    fill: ${(props) => (props.disabled ? '#D1D7E1' : '#FFF')};
  }
`;

export const EmptyContainer = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  padding: 48px;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const FooterActions = styled.div`
  position: fixed;
  bottom: 0;
  left: 32px;
  z-index: 100;
  width: calc(100% - 64px);
  border-top: 1px solid #e5e8ee;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  box-shadow: 0px 8px 32px rgba(24, 26, 30, 0.16), 0px 32px 64px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
`;

export const ButtonFill = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }
`;

export const ButtonBack = styled(ButtonFill)`
  background: unset;
  color: #01c3aa;

  :hover {
    background: #e5faf7;
  }
`;

export const ButtonEdit = styled(ButtonFill)`
  background: unset;
  color: #00205b;
  border: 2px solid #e5e8ee;

  :hover {
    border-color: #d1d7e1;
    background: unset;
  }
`;

export const ButtonGenerateNew = styled(ButtonFill)`
  flex-shrink: 0;
  height: 48px;
  background: #0caa87;
  font-size: 16px;
  line-height: 19.2px;
  align-items: center;

  :hover {
    background: #098968;
  }

  :disabled {
    background: #e2e5ee;
    color: #8b97bd;

    path {
      fill: #8b97bd;
    }
  }

  path {
    fill: #fff;
  }
`;

export const LoaderContainer = styled.div`
  margin-bottom: 24px;
`;

export const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  border: 2px solid #e5e8ee;

  :hover {
    border-color: #d1d7e1;
  }
`;

export const ControlButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const NoCommentContainer = styled.div`
  margin: 24px 0 70px;
`;

export const CommentsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 1550px) {
    flex-direction: column;
  }
`;

export const NumberContainer = styled.div`
  width: 40px;
  height: 40px;
  padding: 14px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background: #01c3aa;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyStateContainer = styled.div`
  height: 776px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;

  @media (max-width: 1550px) {
    height: 300px;
  }
`;

export const EmptyIALabel = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  color: #00205b;
`;

export const GenerateIASuggestionButton = styled.button`
  color: #077859;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  padding: 12px 20px;
  border: 2px solid #e5e8ee;
  border-radius: 1000px;
  background-color: #fff;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;

  :hover {
    border-color: #d1d7e1;
  }

  path {
    fill: #077859;
  }
`;

export const HeaderControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const IAControls = styled.div`
  display: flex;
  gap: 24px;
`;

export const GeneratedSuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const GeneratedSuggestionText = styled.div`
  max-height: 535px;
  overflow-y: auto;
  padding-bottom: 24px;
  white-space: pre-line;
`;

export const NoCommentsFound = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 24px;
`;

export const LoadingSuggestion = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
`;

export const FeedbackContainer = styled.div`
  border-top: 1px solid #dadadb;
  padding: 24px 0 30px;
`;

export const ButtonPopper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  min-height: 48px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00205b;
  background: #fff;
  border: none;
`;

export const ModalText = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.3px;
  color: #00205b;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`;

export const ModalButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  gap: 18px;
  height: 48px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.3px;
  background: #01c3aa;
  border-radius: 100px;
  border: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }
`;

export const ButtonLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  min-height: 48px;
`;
