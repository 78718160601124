import axios from 'axios';

import { store } from '~/store';
import history from '~/services/history';

import { signOut, saveNewToken } from '~/store/modules/auth/actions';

function addInterceptors(api) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const state = store.getState();
        if (error.response.status === 403 || error.response.status === 401) {
          if (error.response.data) {
            const { detail } = error.response.data;
            if (
              detail === 'User not found for email' ||
              detail === 'INVALID_ID_TOKEN' ||
              !state.auth.refreshToken
            ) {
              const currentLocation = window.location.pathname;
              if (currentLocation !== '/') {
                store.dispatch(signOut());
                history.push('/');
              }

              return Promise.reject(error);
            }
          }

          axios
            .post(
              `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_API_KEY}`,
              {
                grant_type: 'refresh_token',
                refresh_token: state.auth.refreshToken,
              },
            )
            .then((response) => {
              const { id_token } = response.data;
              api.defaults.headers.Authorization = `Bearer ${id_token}`;
              store.dispatch(saveNewToken(id_token));
            });
        }
      }
      return Promise.reject(error);
    },
  );
}

const apiCMS = axios.create({
  baseURL: `${process.env.REACT_APP_CMS_URL}/api/`,
});
addInterceptors(apiCMS);

export { apiCMS };
