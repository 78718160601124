import styled from 'styled-components';

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 40px 0 20px 0;
  font-weight: 600;
  font-size: 18px;
  color: #00205b;
`;
