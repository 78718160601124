import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { TrackAPICMS } from '~/services/apiCallsCMS';
import { setListTrack } from './actions';

export function* sagaRequestTrack({ params }) {
  try {
    const { data } = yield call(() => TrackAPICMS.get(params));
    yield put(setListTrack({ listTrack: data }));
  } catch (error) {
    yield put({ type: '@track/FETCH_LIST_TRACK', payload: { requesting: false, error: true } });
    toast.error(error?.message || error?.data?.message);
  }
}

export function* sagaUpdateTrack({ params }) {
  try {
    const { data } = yield call(() => TrackAPICMS.get(params));
    yield put(setListTrack({ listTrack: data }));
  } catch (error) {
    yield put({ type: '@track/FETCH_LIST_TRACK', payload: { requesting: false, error: true } });
    toast.error(error?.message || error?.data?.message);
  }
}

export default all([
  takeLatest('@track/REQUEST_TRACK', sagaRequestTrack),
  takeLatest('@track/UPDATE_TRACK', sagaUpdateTrack),
]);
