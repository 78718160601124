import React from 'react';

import { GoEye } from 'react-icons/go';
import Tags from '~/components/Tags';
import Checkbox from '~/components/Checkbox';

import * as S from './styles';

function ChapterItem({ chapter, selectedItems, setSelectedItems }) {
	const getTags = (item) => {
		const coursesNamesItems = item.courses_names.slice(0, 4).map(function (item) {
			return {
				textColor: '#00205B',
				backgroundColor: '#E5E8EE',
				text: `${item}`,
			};
		});

		return [
			{
				type: 'secondary',
				textColor: '#00205B',
				text: item.subject_name,
			},
			...coursesNamesItems,
		];
	};

	const handleCheck = (id) => {
		if (selectedItems.includes(id)) {
			const selectedWithRemovedId = selectedItems.filter((item) => item !== id);

			setSelectedItems(selectedWithRemovedId);
			return;
		}

		setSelectedItems((checkedQuestions) => [...selectedItems, id]);
		return;
	};

	const openChapter = (id) => {
		window.open(`${process.env.REACT_APP_URL}/chapter/preview/${id}`, '_blank');
	};

	return (
		<S.ItemContainer>
			<S.ItemHeader>
				<Checkbox
					id={chapter.id}
					label=""
					checked={selectedItems.includes(chapter.id)}
					onChange={() => handleCheck(chapter.id)}
					value={chapter.id}
					styleContainer={{ margin: '5px 0 0', width: '21px' }}
					styleLabel={{ marginBottom: 0 }}
					checkboxOutineColor="#ccc"
				/>
				<S.ItemLabel onClick={() => handleCheck(chapter.id)}>
					{`[${chapter.id}] - ${chapter.name}`}
				</S.ItemLabel>
				<S.SeeItem onClick={() => openChapter(chapter.id)}>
					<GoEye />
					{'Visualizar'}
				</S.SeeItem>
			</S.ItemHeader>
			<S.ItemFooter>
				{getTags(chapter).map((tag) => {
					if (!tag.text) return <></>;
					return (
						<Tags
							type={tag.type}
							textColor={tag.textColor}
							backgroundColor={tag.backgroundColor}
							text={tag.text}
							textUppercase={tag.isUppercase}
						/>
					);
				})}
			</S.ItemFooter>
		</S.ItemContainer>
	);
}

export default ChapterItem;
