import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 40px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #181a1e14, 0px 0px 2px 0px #181a1e29;
`;

export const Text = styled.h1`
  margin: 0;
  color: #00205b;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

export const Filter = styled.div`
  width: 100%;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  min-height: 32px;

  &:focus-within {
    background-color: #ffffff;
    box-shadow: 0 3px 8px 0 rgba(0, 32, 91, 0.15);
    color: #00205b;
    letter-spacing: 0.5px;
    line-height: 15px;
  }

  input {
    width: 100%;
    border: none;
    background: none;

    &::placeholder {
      font-size: 14.2px;
      line-height: 18.96px;
      color: #3d5582;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Button = styled.button`
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }

  :disabled {
    color: #8f9db7;
    background: #e5e8ee;
    cursor: default;
  }
`;

export const InputAndButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;
