import React from 'react';
import PropTypes from 'prop-types';

import { Container, Progress, LinearProgressBar } from './styles';

export default function ProgressBar({
  percentage,
  color,
  linearColor,
  unfilledColor,
  height,
  style,
  ...rest
}) {
  let styleProgressBar = { height };

  if (color) {
    styleProgressBar = { ...styleProgressBar, backgroundColor: color };
  }
  if (percentage) {
    styleProgressBar = { ...styleProgressBar, width: `${percentage}%` };
  }

  return (
    <Container
      {...rest}
      style={{ ...style, backgroundColor: unfilledColor, height }}
    >
      {color.length > 0 && <Progress style={styleProgressBar} />}
      {color.length === 0 && linearColor.length > 0 && (
        <LinearProgressBar
          colors={linearColor}
          start={[0, 1]}
          end={[1, 0]}
          style={styleProgressBar}
        />
      )}
    </Container>
  );
}

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  color: PropTypes.string,
  linearColor: PropTypes.arrayOf(PropTypes.string),
  unfilledColor: PropTypes.string,
  height: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ProgressBar.defaultProps = {
  percentage: 0,
  color: '',
  linearColor: [],
  unfilledColor: '#f2f2f2',
  height: 6,
  style: {},
};
