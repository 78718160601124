import React from 'react';
import PropTypes from 'prop-types';

import { Content, HeaderDiv, FooterDiv } from './styles';

export default function AuthLayout({ children }) {
  return (
    <Content>
      <HeaderDiv />
      {children}
      <FooterDiv />
    </Content>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
