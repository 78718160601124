import React from 'react';
import IconLogo from '~/assets/drops-check-logo.svg';
import * as S from './styles';

const SuccessMessage = (props) => (
  <S.SuccessContainer>
    <S.LogoContainer>
      <img src={IconLogo} alt="check logo" />
    </S.LogoContainer>
    <S.SuccessMainLb>{props.title}</S.SuccessMainLb>
    <S.SuccessSubLb>{props.subtitle}</S.SuccessSubLb>
  </S.SuccessContainer>
);
export default SuccessMessage;
