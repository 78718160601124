import styled from 'styled-components';

export const FilterInput = styled.div`
  max-width: 604px;
  width: 100%;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  gap: 10px;

  input {
    width: 100%;
    height: 19px;
    border: none;
    background: none;

    ::placeholder {
      color: #3d5582;
      font-weight: 400;
      font-size: 14.2px;
      line-height: 19px;
    }
  }
`;

export const FlexRow = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`;
