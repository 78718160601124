import PropTypes from 'prop-types';

export function isEqualProfile(obj1, obj2) {
  if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
    return false;
  }
  return true;
}

isEqualProfile.propTypes = {
  obj1: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    student: PropTypes.shape({
      id: PropTypes.number.isRequired,
      user: PropTypes.number.isRequired,
      avatar: PropTypes.string,
      birthday: PropTypes.string,
      current_track: PropTypes.number,
      speciality: PropTypes.number,
      speciality_name: PropTypes.string,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    }),
  }),
  obj2: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    student: PropTypes.shape({
      id: PropTypes.number.isRequired,
      user: PropTypes.number.isRequired,
      avatar: PropTypes.string,
      birthday: PropTypes.string,
      current_track: PropTypes.number,
      speciality: PropTypes.number,
      speciality_name: PropTypes.string,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    }),
  }),
};
