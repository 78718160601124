import React, { useState, useCallback, useEffect } from 'react';

import { CourseAPICMS } from '~/services/apiCallsCMS';

import ModalFilter from '~/components/ModalFilter';
import SelectWithPagination from '~/components/SelectWithPagination';
import * as S from './styles';

import SearchIcon from '~/assets/drops-search.svg';

function Filter({ filter, setFilter }) {
	const [selectedCourse, setSelectedCourse] = useState(null);

	const [loading, setLoading] = useState(false);

	const transformOptions = (course) => {
		if (!course.id || !course.name) return;

		return {
			label: course.name,
			key: course.id,
			value: course.id,
			course,
		};
	};

	const loadCourses = useCallback(async (search, loadedOptions, { page }) => {
		setLoading(true);
		const { data } = await CourseAPICMS.list({
			page,
			search,
			ordering: 'name',
			residency: true,
		});
		setLoading(false);
		return {
			options: data.results.map(transformOptions),
			hasMore: data.next,
			additional: {
				page: page + 1,
			},
		};
	}, []);

	const changeFilter = (value) => {
		setFilter({ ...filter, search: value, page: 1 });
	};

	return (
		<>
			<S.FilterInput>
				<img src={SearchIcon} alt="Search icon" />
				<input
					type="text"
					value={filter.search}
					onChange={(e) => changeFilter(e.target.value)}
					placeholder="Pesquisar capítulo"
				/>
			</S.FilterInput>
			<SelectWithPagination
				name="course"
				height={40}
				width="100%"
				value={selectedCourse}
				onChange={(e) => {
					setSelectedCourse(e);
					setFilter({ ...filter, course: e.value, page: 1 });
				}}
				loadOptions={loadCourses}
				placeholder={loading ? 'Carregando...' : 'Curso'}
			/>
		</>
	);
}

export default Filter;
