import React from 'react';
import {ReactComponent as SearchIcon} from '~/assets/drops-search.svg';

import * as S from './styles';

export default function EmptyContent({title, subtitle, isError = false, icon}) {

  return (
    <S.EmptyContentContainer>
    <S.EmptyContent>
      <S.EmptyContentIconContaner isError={isError}>
        <S.EmptyContentIcon isError={isError}>
          {icon ? icon : <SearchIcon />}
        </S.EmptyContentIcon>
      </S.EmptyContentIconContaner>
      <S.EmptyContentText>
        <S.EmptyContentTitle>
          {title}
        </S.EmptyContentTitle>
        <S.EmptyContentSubtitle>
          {subtitle}
        </S.EmptyContentSubtitle>
      </S.EmptyContentText>
    </S.EmptyContent>
  </S.EmptyContentContainer>
  );
}
