import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Ring } from 'react-spinners-css';
import Axios from 'axios';

import { TrackCategoryAPICMS, TrackAPICMS, TrackAppealPDF } from '~/services/apiCallsCMS';
import { updateSearchExamRequest } from '~/store/modules/search/actions';
import { Mixpanel } from '~/services/analytics';
import history from '~/services/history';

import { downloadPDF } from '~/utils/util';

import ButtonWithPopper from '~/components/ButtonWithPopper';
import SelectComponent from '~/components/SelectComponent';
import CardComponent from '~/components/CardComponent';
import ProgressBar from '~/components/ProgressBar';
import ModalScreen from '~/components/ModalScreen';
import Button from '~/components/ButtonComponent';
import PageState from '~/components/PageState';
import Loader from '~/components/Loader';

import ChevronRightIcon from '~/assets/drops-chevron-right-blue.svg';
import SearchIcon from '~/assets/drops-search.svg';
import SettingsIcon from '~/assets/drops-settings.svg';
import Collapse from '@material-ui/core/Collapse';
import ErrorIcon from '~/assets/drops-error.svg';
import WarningIcon from '~/assets/drops-warning.svg';
import CheckIcon from '~/assets/drops-check.svg';

import * as S from './styles';

export default function Exams({ statusTab }) {
  const rowRef = useRef(null);

  const dispatch = useDispatch();
  const exam_search = useSelector((state) => state.search.searchTermExams);
  const search = useSelector((state) => state.search.searchTermExams);

  const [categorys, setCategorys] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [institutions, setInstitutions] = useState([]);
  const [exams, setExams] = useState([]);
  const [order, setOrder] = useState();
  const [selectedInstitution, setSelectedInstitution] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingSplit, setLoadingSplit] = useState(false);
  const [loadingTagIa, setLoadingTagIa] = useState(false);
  const [loadingSuggestionIA, setLoadingSuggestionIA] = useState(false);
  const [loadingAppeal, setLoadingAppeal] = useState(false);
  const [loadingExams, setLoadingExams] = useState(false);
  const [searchTerm, setSearchTerm] = useState(search);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [idExame, setIdExam] = useState();
  const [enabledExam, setEnabledExam] = useState();

  const itemsOrder = [
    {
      label: 'Mais recentes',
      value: '-created_at',
      key: '-created_at',
    },
    { label: 'Por ano da prova', value: '-year', key: '-year' },
    { label: 'Por nome', value: 'name', key: 'name' },
  ];

  const loadCategorys = useCallback(async (cancelToken) => {
    setLoading(true);
    const { data } = await TrackCategoryAPICMS.list({ ordering: 'abbreviation' }, cancelToken);
    if (!data?.results) {
      setLoading(false);
      return;
    }

    const dataCategory = data.results.map((category) => {
      return {
        label: category.name,
        value: category.id,
        key: category.id,
        abreviation: category.abreviation,
      };
    });

    setCategorys(dataCategory);
    setSelectedCategory(dataCategory[0]);
    setLoading(false);
  }, []);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    if (exam_search && selectedInstitution) {
      setSelectedInstitution({});
    }

    loadCategorys(source.token);

    Mixpanel.track("Clicou 'Lista de provas'", {
      'Item selecionado': `${statusTab === 'creation' ? 'Criação' : 'Publicado'}`,
    });

    return () => {
      source.cancel();
    };
  }, []);

  const loadExams = useCallback(
    async (cancelToken) => {
      setLoadingExams(true);
      const { data } = await TrackAPICMS.list(
        {
          exam_search,
          ordering: order?.value ?? '-year',
          institution: selectedInstitution.id,
          track_type: 'c',
          track_category: selectedCategory?.value ?? null,
          enabled: statusTab === 'published',
        },
        cancelToken,
      );
      if (data && data.results) {
        setExams(data.results);
      }
      setLoadingExams(false);
    },
    [selectedInstitution, selectedCategory],
  );

  useEffect(() => {
    const source = Axios.CancelToken.source();
    if (!selectedInstitution || !selectedInstitution.id) {
      return;
    }

    loadExams(source.token);

    return () => {
      source.cancel();
    };
  }, [selectedInstitution]);

  function handleOrder(orderSelected) {
    setOrder(orderSelected);
  }

  function handleCategory(categorySelected) {
    setSelectedCategory(categorySelected);
  }

  function handleShowExam(exam) {
    Mixpanel.track("Clicou 'Lista de provas - Botão de editar a prova'", {
      'Nome da prova': exam?.name,
      Instituição: selectedInstitution?.name,
      'Ano da prova': exam?.year ?? 'Não especificado',
      'Nível de residência':
        categorys
          .filter((category) => category?.value === exam?.track_category)
          .map((res) => res.label) ?? 'Não especificado',
      'Tipo de prova': exam?.question_objective_count > 0 ? 'Objetiva' : 'Discursiva',
    });
    history.push({
      pathname: `/exam/${exam.id}`,
      state: {
        name: 'voltar para Banco de questões',
        backTo: '/exam',
        removeFooter: true,
      },
    });
  }

  const handleEnable = (enabled, id, status) => {
    if (status === 'complete') {
      setEnabledExam(enabled);
      setOpenPublishModal(true);
      setIdExam(id);
    } else {
      toast.error('Esta prova está pendente. Termine de preencher para poder publicá-la!');
    }
  };

  function handleRequestSearch() {
    dispatch(updateSearchExamRequest(searchTerm));

    if (searchTerm || selectedInstitution) {
      setSelectedInstitution({});
    }

    setLoading(true);
    const source = Axios.CancelToken.source();

    Mixpanel.track("Clicou 'Lista de provas - Botão de filtrar'", {
      Pesquisar: searchTerm !== '' ? searchTerm : 'Não especificado',
      'Nível de residência': selectedCategory?.label ?? 'Não especificado',
      'Ordenar por': order?.label ?? 'Não especificado',
    });
    TrackAPICMS.by_institution(
      {
        search: searchTerm,
        track_type: 'c',
        track_category: selectedCategory?.value ?? null,
        enabled: statusTab === 'published',
        ordering: order?.value ?? '-year',
      },
      source.token,
    )
      .then((result) => {
        if (result && result.data) {
          setInstitutions(result.data);
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (selectedCategory) {
      handleRequestSearch();
    }
  }, [selectedCategory]);

  const handlePublish = () => {
    TrackAPICMS.update(idExame, { enabled: !enabledExam })
      .then(({ data }) => {
        toast.success(`${enabledExam ? 'Despublicado' : 'Publicado'} com sucesso`);
        Mixpanel.track("Clicou 'Lista de provas - Botão de editar a prova'", {
          'Nome da prova': data?.name,
          Instituição: selectedInstitution?.name,
          'Ano da prova': data?.year ?? 'Não especificado',
          'Nível de residência':
            categorys
              .filter((category) => category?.value === data?.track_category)
              .map((res) => res.label) ?? 'Não especificado',
          'Tipo de prova': data.question_objective_count > 0 ? 'Objetiva' : 'Discursiva',
          'Publicar a prova': `${enabledExam ? 'Não' : 'Sim'}`,
        });
        loadExams();
      })
      .catch(() => {
        toast.error(`Não foi possível ${enabledExam ? 'despublicar' : 'publicar'}`);
      })
      .finally(() => {
        setOpenPublishModal(false);
      });
  };

  function hasPendencies(question) {
    if (question.questions_count > question.question_with_comments) return true;
    if (question.questions_count > question.questions_with_tags) return true;
    if (question.questions_count > question.questions_with_content) return true;
    if (question.question_objective_count > question.questions_with_correct_answer) return true;
    return false;
  }

  function renderItem(item) {
    const infos = [
      {
        label: 'Enunciadas',
        value: item.questions_with_content,
      },
      {
        label: 'Tagueadas',
        value: item.questions_with_tags,
      },
      {
        label: 'Comentadas',
        value: item.question_with_comments,
      },
      item.question_objective_count > 0 && {
        label: 'Alt. correta',
        value: item.questions_with_correct_answer,
      },
      {
        label: 'Videos',
        value: item.questions_with_video,
      },
    ].filter(Boolean);

    let status;

    if (item.questions_count !== 0 && !hasPendencies(item)) {
      status = 'complete';
    } else {
      status = 'pending';
    }

    function calcPercentage(value) {
      if (item.questions_count === 0) {
        return 0;
      }
      return Math.floor((100 * value) / item.questions_count);
    }

    const splitTrack = async (cancelToken, id) => {
      setLoadingSplit(true);
      const { data } = await TrackAPICMS.split_track(id, cancelToken);

      if (data) {
        toast.success('Trilhas de revisão criadas.');
      } else {
        toast.error('Não foi possível criar as trilhas de revisão');
      }
      setLoadingSplit(false);
    };

    const handleSplitTrack = (track) => {
      if (!loadingSplit) {
        const source = Axios.CancelToken.source();
        splitTrack(source.token, track.id);
      }
    };

    const handleGenerateTagIA = (trackId) => {
      setLoadingTagIa(true);
      const source = Axios.CancelToken.source();
      TrackAPICMS.suggestTagIA(trackId, source.token)
        .then(() => {
          toast.success(
            'A sugestão foi iniciada com sucesso. Em breve, a sugestão de tags para as questões será concluída.',
          );
          setLoadingTagIa(false);
        })
        .catch(() => {
          toast.error('Erro ao iniciar sugestão de tags.');
        });
    };

    const handleGenerateSugestionIA = (trackId) => {
      setLoadingSuggestionIA(true);
      const source = Axios.CancelToken.source();
      TrackAPICMS.suggestCommentIA(trackId, source.token)
        .then(() => {
          toast.success(
            'A sugestão foi iniciada com sucesso. Em breve, a sugestão de comentários para as questões será concluída.',
          );
          setLoadingSuggestionIA(false);
        })
        .catch(() => {
          toast.error('Erro ao iniciar sugestão de comentários.');
        });
    };

    const handleGetPDFAppeal = (trackId) => {
      setLoadingAppeal(true);
      const source = Axios.CancelToken.source();
      TrackAppealPDF.list({ track_id: trackId }, source.token)
        .then(({ data }) => {
          downloadPDF(
            data.results[0].pdf_file,
            data.results[0].pdf_file.split('no_institution/')[1].split('.pdf')[0],
          );
        })
        .catch((error) => {
          toast.error('Não foi encontrado PDF do recursos. Tente novamente mais tarde');
        });
    };

    return (
      <CardComponent
        type="default"
        background="#fff"
        style={{
          width: '100%',
          display: 'flex',
          marginBottom: 20,
          borderRadius: '8px',
        }}
        key={`${item.id}${item.name}`}
      >
        <S.ViewCol>
          <S.RowCard>
            <S.ViewCol>
              <S.ContainerTitle>
                <S.ItemTitle>{item.name}</S.ItemTitle>
                <S.Chip>
                  {
                    categorys.filter((category) => category.value === item.track_category)[0]
                      ?.abreviation
                  }
                </S.Chip>
              </S.ContainerTitle>
              <S.ItemSubtitle>
                <strong>{item.questions_count}</strong>{' '}
                {item.questions_count > 1 ? 'questões' : 'questão'}
              </S.ItemSubtitle>
            </S.ViewCol>
            <S.DivRow>
              <S.ButtonSplit onClick={() => handleSplitTrack(item)}>Split</S.ButtonSplit>
              <S.EditButton onClick={() => handleShowExam(item)}>
                <img src={SettingsIcon} alt="settings icon" /> Editar
              </S.EditButton>
              <S.ContainerSwitch>
                <S.SwitchButton
                  checked={item.enabled}
                  onClick={() => handleEnable(item.enabled, item.id, status)}
                >
                  <S.CircleButton checked={item.enabled} />
                </S.SwitchButton>
                <S.Label>Publicado</S.Label>
              </S.ContainerSwitch>
              <ButtonWithPopper>
                <S.ButtonPopper onClick={() => handleGenerateTagIA(item.id)}>
                  Gerar tags via IA
                  {loadingTagIa && <Ring size={20} color="#019d8a" />}
                </S.ButtonPopper>
                <S.ButtonPopper onClick={() => handleGenerateSugestionIA(item.id)}>
                  Sugerir Comentário via IA
                  {loadingSuggestionIA && <Ring size={20} color="#019d8a" />}
                </S.ButtonPopper>
                <S.ButtonPopper onClick={() => handleGetPDFAppeal(item.id)}>
                  Baixar PDF de recursos
                  {loadingAppeal && <Ring size={20} color="#019d8a" />}
                </S.ButtonPopper>
              </ButtonWithPopper>
              <S.ChipStatus status={status}>
                {status === 'pending' ? 'Pendente' : 'Completo'}{' '}
                <img src={status === 'pending' ? WarningIcon : CheckIcon} alt="icon status" />
              </S.ChipStatus>
            </S.DivRow>
          </S.RowCard>
          <S.Divisor />
          <S.RowCard style={{ flexWrap: 'wrap' }}>
            {infos.map((info, index) => {
              const complete = item.questions_count !== 0 && info.value === item.questions_count;
              return (
                <S.CardProgress key={index}>
                  <S.ChipCount complete={complete}>{info.value}</S.ChipCount>
                  <S.ViewCol style={{ gap: 6 }}>
                    <S.ItemSubtitle>
                      {info.label} <strong>{calcPercentage(info.value)}%</strong>
                    </S.ItemSubtitle>
                    <ProgressBar
                      unfilledColor={complete ? '#D8F4E6' : '#FCDBDF'}
                      color={complete ? '#0CBA66' : '#EE1F38'}
                      percentage={calcPercentage(info.value)}
                      height={4}
                      style={{ minWidth: '140px' }}
                    />
                  </S.ViewCol>
                </S.CardProgress>
              );
            })}
          </S.RowCard>
        </S.ViewCol>
      </CardComponent>
    );
  }

  function handleCollapseInstitution(institution, open) {
    if (institution.id === selectedInstitution.id) {
      setSelectedInstitution({});
      institution = {};
    } else {
      setSelectedInstitution(institution);
    }
    Mixpanel.track("Clicou 'Lista de provas - Visualização da lista das provas'", {
      'Visualização da lista de provas': `${open ? 'Recolher' : 'Expandir'}`,
      Instituição: institution?.name,
    });
    window.localStorage.setItem('selectedExamInstitution', JSON.stringify(institution));
  }

  function renderFoldersInstitutions(institution, index, lastItem) {
    const open = institution.id === selectedInstitution.id;
    return (
      <Collapse
        collapsedSize={70}
        in={open}
        style={{ marginBottom: 32 }}
        key={`${index}_{institution}`}
      >
        <CardComponent type="minimal">
          <S.Folder
            style={{ cursor: 'pointer' }}
            onClick={() => handleCollapseInstitution(institution, open)}
            open={open}
            lastItem={lastItem === index}
          >
            <S.DivCol style={{ justifyContent: 'center', alignItems: 'flex-start' }}>
              <S.FolderOrder>Instituição</S.FolderOrder>
              <S.FolderTitle>{institution.name}</S.FolderTitle>
            </S.DivCol>
            <S.DivRow>
              <S.FolderCount>
                <strong>{institution.count} </strong> {institution.count > 1 ? 'itens' : 'item'}
              </S.FolderCount>
              <S.ContainerArrow open={open}>
                <img src={ChevronRightIcon} alt="arrow rigth" />
              </S.ContainerArrow>
            </S.DivRow>
          </S.Folder>
          {loadingExams && (
            <S.ContainerLoader>
              <Loader spinner="ring" text="" />
            </S.ContainerLoader>
          )}
          {open && institution.id === selectedInstitution.id && !loadingExams && (
            <S.ExamsContainer ref={rowRef} key={institution.id}>
              {exams.map((exam) => renderItem(exam))}
            </S.ExamsContainer>
          )}
        </CardComponent>
      </Collapse>
    );
  }

  return (
    <S.Container>
      <S.FilterContainer gap={!selectedCategory && !order}>
        <S.Header>Filtrar dados</S.Header>
        <S.Content>
          <S.Filter>
            <img src={SearchIcon} alt="Search icon" />
            <input
              type="text"
              placeholder="Pesquisar"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleRequestSearch();
                }
              }}
            />
          </S.Filter>
          <SelectComponent
            name="residency-degree"
            placeholder="Nível de residência"
            text={selectedCategory && 'Nível de residência'}
            value={selectedCategory}
            onChange={(e) => handleCategory(e)}
            options={categorys}
            width="100%"
            controlStyle={{ marginLeft: 0 }}
          />
          <SelectComponent
            name="order"
            placeholder="Ordenar por"
            text={order && 'Ordenar por'}
            value={order}
            onChange={(e) => handleOrder(e)}
            options={itemsOrder}
            width="100%"
            controlStyle={{ marginLeft: 0 }}
          />
          <S.ButtonFilter onClick={() => handleRequestSearch()}>Filtrar</S.ButtonFilter>
        </S.Content>
      </S.FilterContainer>
      <S.ListExams>
        <S.MainContainer>
          {loading ? (
            <Loader />
          ) : (
            <>
              {!loading && institutions.length === 0 && (
                <S.ContainerPageState>
                  {!exam_search ? (
                    <PageState
                      icon={SearchIcon}
                      title="Utilize o filtro para encontrar uma prova"
                      description="Selecione os critérios acima e clique em ”Filtrar”"
                    />
                  ) : (
                    <PageState
                      icon={ErrorIcon}
                      backgroundIcon="error"
                      title="Nenhuma prova encontrada"
                      description="Altere os critérios de filtro acima e clique em ”Filtrar”"
                    />
                  )}
                </S.ContainerPageState>
              )}
              <div style={{ marginTop: 40 }}>
                {institutions.map((institution, index) => {
                  const lastItem = institutions.length - 1;
                  return renderFoldersInstitutions(institution, index, lastItem);
                })}
              </div>
              <ModalScreen
                show={openPublishModal}
                close={() => setOpenPublishModal(false)}
                name={`Você tem certeza que quer ${enabledExam ? 'despublicar' : 'publicar'}?`}
              >
                <S.GroupButtonModal>
                  <Button
                    type="important"
                    text="Não"
                    primaryColor="#01cfb5"
                    textColor="#fff"
                    onClick={() => setOpenPublishModal(false)}
                  />
                  <Button
                    type="important"
                    text="Sim"
                    primaryColor="#01cfb5"
                    textColor="#fff"
                    onClick={() => handlePublish()}
                  />
                </S.GroupButtonModal>
              </ModalScreen>
            </>
          )}
        </S.MainContainer>
      </S.ListExams>
    </S.Container>
  );
}
