export function requestTrack(params) {
  return {
    type: '@track/REQUEST_TRACK',
    params,
  };
}

export function setListTrack({ listTrack }) {
  return {
    type: '@track/SET_LIST_TRACK',
    payload: { listTrack },
  };
}

export function updateTrack(params) {
  return {
    type: '@track/UPDATE_TRACK',
    params,
  };
}

export function resetTrack() {
  return {
    type: '@track/RESET_TRACK',
  };
}
