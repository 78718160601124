import React from 'react';
import DragIcon from '~/assets/drag-indicator.svg';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

import * as S from './styles';

const ItemQuestion = ({
  id,
  name,
  number,
  index,
  onDragStart,
  onDragEnter,
  onDragEnd,
  deleteQuestion,
  canAction,
}) => {
  const handleVisualize = () => {
    const path = `/question/${id}/comment`;
    const newWindow = window.open(path, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <S.Container
      draggable={canAction}
      onDragStart={() => onDragStart(index)}
      onDragEnter={() => onDragEnter(index)}
      onDragEnd={() => onDragEnd(index)}
      key={`question_id_${id}`}
    >
      <S.DragIconAndName>
        <S.NumberText>{number}</S.NumberText>
        {canAction && <img src={DragIcon} alt="drag icon" />}
        <S.NameOption>{name}</S.NameOption>
      </S.DragIconAndName>

      <S.ButtonsContainer>
        <S.Button onClick={handleVisualize}>
          <MdOutlineRemoveRedEye size={18} color="#077859" />
          Visualizar
        </S.Button>
        {canAction && (
          <S.Button onClick={() => deleteQuestion(id)}>
            <S.RemoveIcon size={18} color="#077859" />
            Remover
          </S.Button>
        )}
      </S.ButtonsContainer>
    </S.Container>
  );
};

export default ItemQuestion;
