import React, { useEffect, useState, useRef } from 'react';
import Axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';
import { UploadFileS3 } from '~/services/apiCallsCMS';
import Loader from '~/components/Loader';
import * as S from './styles';

export default function TextEditorComponent({
  textHTML,
  onEdit,
  config,
  onFocusOut,
  headerBgColor = '#fff',
  textAreaBgColor = '#fff',
  showWarning = false,
  simplify = false,
}) {
  const [value, setValue] = useState(textHTML ?? '');
  const [loading, setLoading] = useState(false);
  useEffect(() => setValue(textHTML ?? ''), [textHTML]);

  const editorRef = useRef(null);
  const source = Axios.CancelToken.source();

  const uploadFileS3 = (file, callback) => {
    const formData = new FormData();
    formData.append('files', file);
    UploadFileS3.post(
      {
        file_content: file,
        file_name: file.name,
        file_type: 'image',
      },
      source.token,
      true,
    )
      .then((res) => {
        callback(res.data.url);
      })
      .catch(() => {
        toast.error('Não foi possível fazer o upload da imagem.');
      });
  };

  function getRandomFileName() {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
    const random = Math.random().toString().substring(2, 8);
    const random_number = timestamp + random;
    return random_number;
  }

  function resolveFetchImages(urlImage, img, isRetry = false) {
    const imageName = `image${getRandomFileName()}.png`;
    return new Promise((resolve, reject) => {
      fetch(urlImage)
        .then((res) => {
          if (!res.ok) {
            throw new Error('Network response was not ok');
          }
          return res.blob();
        })
        .then((resBlob) => {
          const newFile = new File([resBlob], imageName, { type: resBlob.type });
          uploadFileS3(newFile, (url) => {
            const newUrl = process.env.REACT_APP_PREFFIX_CDN + url.split('media/')[1];
            const pTag = img.closest('p');
            if (pTag) pTag.style.textAlign = 'center';
            img.setAttribute('src', newUrl);
            resolve(img);
          });
        })
        .catch((error) => {
          if (!isRetry) {
            const fetchUrl = process.env.REACT_APP_PROXY_URL + urlImage;
            resolveFetchImages(fetchUrl, img, true).then(resolve).catch(reject);
          } else {
            reject(error);
          }
        });
    });
  }

  function changeSrcImage(img) {
    const imgUrl = img.getAttribute('src');
    if (imgUrl.includes(process.env.REACT_APP_PREFFIX_STATIC)) {
      const newUrl = process.env.REACT_APP_PREFFIX_CDN + imgUrl.split('media/')[1];
      const pTag = img.closest('p');
      if (pTag) pTag.style.textAlign = 'center';
      img.setAttribute('src', newUrl);

      return img;
    }

    return resolveFetchImages(imgUrl, img);
  }

  async function onChangeEditor(content) {
    const htmlContent = document.createElement('div');
    htmlContent.innerHTML = content;
    const prefixS3 = process.env.REACT_APP_PREFFIX_CDN;

    const imgTags = htmlContent.querySelectorAll(`img:not([src*="${prefixS3}"])`);

    if (imgTags.length > 0) {
      Promise.all(Array.from(imgTags).map(changeSrcImage)).then((_allImgs) => {
        setValue(htmlContent.innerHTML);
        onEdit(htmlContent.innerHTML);
        setLoading(false);
      });
    } else {
      setValue(content);
      onEdit(content);
      setLoading(false);
    }
  }

  return (
    <>
      {showWarning && (
        <S.WarningBox>
          <S.WarningLogoContainer>
            <S.WarningLogo />
          </S.WarningLogoContainer>
          <S.TextContainer>
            <S.WarningMainText>Indisponibilidade de tabelas</S.WarningMainText>
            <S.WarningSubText>
              Não é mais possível colar tabelas no editor. Por favor, substitua por imagem.
            </S.WarningSubText>
          </S.TextContainer>
        </S.WarningBox>
      )}
      <div style={{ position: 'relative' }}>
        {loading && (
          <S.Loading>
            {' '}
            <Loader text="Carregando" />
          </S.Loading>
        )}
        <input id="my-file" type="file" name="my-file" style={{ display: 'none' }} />
        <S.Styled headerBgColor={headerBgColor} textAreaBgColor={textAreaBgColor}>
          <Editor
            tinymceScriptSrc={`https://cdn.tiny.cloud/1/${process.env.REACT_APP_TINYMCE_APIKEY}/tinymce/6/tinymce.min.js`}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(content) => onChangeEditor(content)}
            initialValue={textHTML}
            value={value}
            onFocusOut={onFocusOut}
            onSkinLoadError={(error) => console.log(error)}
            init={{
              allow_script_urls: true,
              height: 500,
              menubar: true,
              plugins: [
                'advlist',
                'advcode',
                'advtable',
                'autolink',
                'checklist',
                'export',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'powerpaste',
                'fullscreen',
                'formatpainter',
                'insertdatetime',
                'media',
                'wordcount',
                'tinymcespellchecker',
              ],
              spellchecker_languages: 'Brazilian Portuguese=pt',
              content_langs: [{ title: 'Portuguese', code: 'pt' }],
              spellchecker_select_languages: 'pt',
              spellchecker_language: 'pt',
              spellchecker_on_load: true,
              spellchecker_active: true,
              toolbar:
                'undo redo blocks fontsize bold italic underline ' +
                (!simplify
                  ? 'alignleft aligncenter alignright alignjustify ' +
                    'bullist numlist outdent indent image fullscreen'
                  : ''),
              file_picker_callback(callback, _, meta) {
                if (meta.filetype === 'image') {
                  const input = document.getElementById('my-file');
                  input.click();
                  input.onchange = () => {
                    const file = input.files[0];
                    uploadFileS3(file, callback);
                  };
                }
              },
              paste_preprocess: (_editor, _args) => {
                if (_args.content.includes('table')) {
                  toast.error('Utilize uma imagem ao invés da tabela');
                }
                setLoading(true);
              },
              paste_data_images: true,
              content_style:
                'body { font-family: Montserrat,sans-serif; font-size:11pt; color:rgb(102, 102, 102); }',
              block_formats: 'Parágrafo=p;Seção=h2; Subseção=h3;',
              font_family_formats: 'Montserrat=montserrat',
              font_size_formats: '11pt 15pt 20pt',
              invalid_elements: 'table,thread,colgroup,col,th,tbody,tr,td,tfoot',
              ...config,
            }}
          />
        </S.Styled>
      </div>
    </>
  );
}
