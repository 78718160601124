import styled from 'styled-components';

export const CardModal = styled.div`
position: relative;
    background-color: white;
    max-width: 468px;
    box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
    border-radius: 8px;
    padding: 48px 40px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const Title = styled.h1`
    margin: 0;
    font-weight: 600;
    font-size: 22.8px;
    line-height: 30.4px;
    color: #00205B;
    margin-bottom: 24px;
`;
