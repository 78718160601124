import styled, { css } from 'styled-components';

const flexRow = styled.div`
  display: flex;
`;

export const Container = styled(flexRow)`
  gap: 8px;
`;

export const PaginationDot = styled(flexRow)`
  padding: 10px;
  border-radius: 320px;
  font-size: 14px;
  font-weight: 600;
  height: 38px;
  min-width: 38px;
  justify-content: center;
  align-items: center;
  color: #019d8a;
  background: #fff;
  border: 2px solid #e5e8ee;
  cursor: pointer;
  pointer-events: initial;
  :hover {
    border-color: #d1d7e1;
  }
  ${(props) =>
    props.selected &&
    css`
      color: #fff;
      background: #019d8a;
      border: 2px solid transparent;
      cursor: default;
      pointer-events: none;
      :hover {
        border-color: none;
      }
    `}
`;

export const ThreeDotsContainer = styled(flexRow)`
  height: 40px;
  width: 40px;
  padding-bottom: 6px;
  justify-content: center;
  align-items: flex-end;
  font-size: 14px;
  font-weight: 600;
  color: #8f9db7;
`;

export const BackFowardButton = styled.button`
  padding: 10px 16px;
  gap: 10px;
  color: ${(props) => (props.disabled ? '#8F9DB7' : '#019D8A')};
  font-size: 14px;
  line-height: 19px;
  display: flex;
  font-weight: 600;
  text-align: center;
  background: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 320px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  :hover {
    background: ${(props) => (props.disabled ? 'transparent' : '#E5FAF7')};
  }
`;

export const DoubleArrowContainer = styled.div`
  svg > path {
    fill: ${(props) => (props.disabled ? '#8F9DB7' : '#019D8A')};
  }

  transform: ${(props) => (props.backwards ? 'rotate(180deg)' : 'none')};
`;
