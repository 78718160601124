import styled from 'styled-components';

export const Container = styled.div`
  width: 240px;
  .title {
    font-weight: 400;
    font-size: 20px;
    color: #747678;
  }
  .container-total-questions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 60px;
    border: 2px dashed #DADADB;
    border-radius: 8px;
    .text {
      font-weight: 500;
      font-size: 48px;
      color: #747678;
    }
  }
`

export const ColorBar = styled.div`
  display: flex;
  margin: 24px 0 0 0;
`

export const ItemColorBar = styled.div`
  height: 8px; 
  width: 40px;
  :first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  :last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
`