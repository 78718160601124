import styled, { css } from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  padding: 48px;
  max-width: 1388px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #f5f5fb;
`;

export const GroupButtonModal = styled.div`
  display: flex;
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  gap: 24px;
`;

export const ProgressContainer = styled.div`
  width: 50vw;
  align-items: baseline;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
  column-gap: 24px;
  row-gap: 16px;
  max-width: ${(props) => (props.small ? '450px' : '650px')};
`;

export const CardProgress = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ChipCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 50px;
  height: 26px;
  background: #fcdbdf;
  color: #ee1f38;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  ${(props) =>
    props.complete &&
    css`
      background: #d8f4e6;
      color: #0cba66;
    `}
`;

export const ViewCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`;

export const ItemSubtitle = styled.span`
  font-weight: 400;
  font-size: 14.2px;
  line-height: 19px;
  color: #3d5582;

  strong {
    font-weight: 600;
  }
`;

export const ContainerFooter = styled.div`
  width: 100%;
  padding: 0 48px 48px;
`;

export const FooterTrack = styled.div`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(24, 26, 30, 0.16), 0px 32px 64px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  padding: 20px 24px;
`;

export const ButtonSave = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    background: #01cfb5;
  }
`;

export const ButtonBack = styled(ButtonSave)`
  background: unset;
  color: #01c3aa;
  :hover {
    background: #e5faf7;
  }
`;
