import React, { useState, useEffect } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import Loader from '~/components/Loader';
import Tags from '~/components/Tags';
import * as S from './styles';

function IncludedTracks({ trackList, loading, close }) {
  const [transformedTrackList, setTransformedTrackList] = useState(null);
  const [totalTracks, setTotalTracks] = useState(0);

  const transformData = (data) => {
    const insertedCourses = [];
    let results = [];
    let trackCount = 0;

    data.forEach((item) => {
      item.courses_names.forEach((course) => {
        if (insertedCourses.includes(course)) {
          const sameCourseName = results.find(({ courseName }) => courseName === course);
          trackCount++;
          sameCourseName.trackInfo.push(item.track);
          return;
        }
        trackCount++;
        insertedCourses.push(course);
        results.push({courseName: course, trackInfo: [item.track]});
      });
    });

    setTotalTracks(trackCount);
    return results;
  }

  useEffect(() => {
    if (!trackList) return;
    setTransformedTrackList(transformData(trackList));
  }, [trackList]);

	return (
		<>
      <S.Container>
        {loading ? 
          <S.LoaderContainer>
            <Loader />
          </S.LoaderContainer>
          : 
          <>
            <S.Header>
              <CloseIcon onClick={close} style={S.CloseButtonStyle}/>
              <S.Title>
                Trilhas inclusas
              </S.Title>
              <S.Subtitle>
                <Tags textColor="#00205B" backgroundColor="#E5E8EE" text={totalTracks} />
                Trilhas utilizam essa questão
              </S.Subtitle>
            </S.Header>

            <S.Content>
              {transformedTrackList && transformedTrackList.map((track) => {
                return (
                  <S.TextContainer>
                    <S.ContentTitle>{track.courseName}</S.ContentTitle>
                    <S.SubtitleContainer>
                      {track.trackInfo.map((childTrack) => {
                        return (
                        <S.ContentSubtitle>
                          {`[${childTrack.id}] ${childTrack.name}`}
                        </S.ContentSubtitle>)
                      })}
                    </S.SubtitleContainer>
                  </S.TextContainer>
                );
              })}

            </S.Content>
          </>
        }
      </S.Container>
    </>			
	);
};

export default IncludedTracks;
