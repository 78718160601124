import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { Chapter } from '~/services';
import { setListChapter, setListChapterSelected } from './actions';

export function* sagaRequestChapter({ params }) {
  try {
    const { data } = yield call(() => Chapter.list(params));
    yield put(setListChapter({ listChapter: data }));
  } catch (error) {
    yield put({ type: '@chapter/FETCH_LIST_CHAPTER', payload: { requesting: false, error: true } });
    toast.error(error?.message || error?.data?.message);
  }
}
export function* sagaRequestChapterSelected({ id }) {
  try {
    const { data } = yield call(() => Chapter.get(id));
    yield put(setListChapterSelected({ listChapterSelected: data }));
  } catch (error) {
    yield put({
      type: '@chapter/FETCH_LIST_CHAPTER_SELECTED',
      payload: { requesting: false, error: true },
    });
    toast.error(error?.message || error?.data?.message);
  }
}

export default all([
  takeLatest('@chapter/REQUEST_CHAPTER', sagaRequestChapter),
  takeLatest('@chapter/REQUEST_CHAPTER_SELECTED', sagaRequestChapterSelected),
]);
