import styled from 'styled-components';
import { ReactComponent as ArrowLeft } from '~/assets/drops-arrow-left.svg';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  padding: 0px 48px;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1388px;
`;

export const ItemsContainer = styled.div`
  position: relative;
  background: #fff;
  padding: 32px 56px 32px 52px;
`;

export const BackButtonContainer = styled.div`
  width: 100%;
  max-width: 1388px;
`;

export const BackButton = styled.button`
  align-self: flex-start;
  border: none;
  height: 48px;
  padding: 0px 20px;
  gap: 12px;
  border-radius: 320px;
  color: #077859;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  margin-top: 48px;

  :hover {
    background: #daf5f1;
  }
`;

export const StyledArrowLeft = styled(ArrowLeft)`
  path {
    fill: #077859;
  }
`;

export const AddClassObjectsContainer = styled.div`
  margin-top: 24px;
  background: #fff;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 22.8px;
  font-weight: 600;
  line-height: 30.4px;
  color: #00205b;
`;

export const NumberIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 1000px;
  background: #01c3aa;
  color: #fff;
  font-size: 20.3px;
  font-weight: 600;
  line-height: 36.5px;
`;
