import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const customStyles = {
  '& .MuiFormLabel-root': {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '21px',
    top: '-6px',
    color: '#3D5582',
  },

  '& .MuiInputLabel-shrink': {
    display: 'none',
  },

  '& .MuiInputBase-root': {
    display: 'flex',
    flexWrap: 'nowrap',
    height: '40px',
    border: '2px solid #E5E8EE',
    borderRadius: '8px',
    background: '#F0F2F5',
    padding: '0 9px',

    '& .MuiInputBase-input': {
      fontFamily: 'Montserrat',
      color: '#3D5582',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '21px',
    },
    '&:hover': {
      background: '#fff',
      border: '3px solid #3D5582',
      fontWeight: 600,
      boxShadow: '0px 0px 0px 6px rgba(61, 85, 130, 0.2)',
    },
    '&:focus-within': {
      border: '3px solid #01cfb5',
      boxShadow: '0px 0px 0px 6px rgba(1, 207, 181, 0.2)',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:focus-within fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: '#077859',
    },
  },

  '& .MuiSvgIcon-root path': { fill: '#3D5582' },
};

export default function StyledDatePickerComponent({ label, value, time, handleChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      {time ? (
        <DateTimePicker
          label={label}
          placeholder="placeholder"
          onChange={(newValue) => handleChange(newValue)}
          value={value && dayjs(value)}
          sx={customStyles}
        />
      ) : (
        <DatePicker
          label={label}
          placeholder="placeholder"
          onChange={(newValue) => handleChange(newValue)}
          value={value && dayjs(value)}
          sx={customStyles}
        />
      )}
    </LocalizationProvider>
  );
}
