import React, { useEffect, useRef } from 'react';

import {ReactComponent as ImageIcon} from '~/assets/drops-image.svg';
import {ReactComponent as WarningIcon} from '~/assets/flashcards-warning.svg';
import {ReactComponent as ConcludedIcon} from '~/assets/flashcards-concluded.svg';
import {ReactComponent as NeutralIcon} from '~/assets/flashcards-neutral.svg';

import { removeHtmlTags } from '~/utils/util';

import * as S from './styles';

function SidebarItem(
  {
    number = 0,
    isSelected = false,
    hasImage = false,
    status = 'neutral',
    title,
    response,
  }) {
  const scrollRef = useRef(null);
  
  const getIcon = () => {
    switch (status) {
      case 'warning':
        return <WarningIcon />;
      case 'concluded':
        return <ConcludedIcon />;
      case 'neutral':
        return <NeutralIcon />;
      default: 
        return <NeutralIcon />
    }
  };

  const goToScrolling = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  useEffect(() => {
    if (!isSelected) return;
    goToScrolling();
  }, [isSelected]);

  return (
    <S.Container selected={isSelected} ref={scrollRef}>
      <S.Controller>
        <S.NumberContainer selected={isSelected}>{number}</S.NumberContainer>
        {getIcon()}
      </S.Controller>
      <S.CardContent selected={isSelected}>
        <S.Title>
          {hasImage && (<S.IconContainer><ImageIcon /></S.IconContainer>)}
          <S.Text dangerouslySetInnerHTML={{ __html: removeHtmlTags(title) }} />
        </S.Title>
        <S.Response>
          <S.Text dangerouslySetInnerHTML={{ __html: removeHtmlTags(response) }} />
         </S.Response>
      </S.CardContent>
    </S.Container>
  );
}

export default SidebarItem;
