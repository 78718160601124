const optionsSpecialtiesMock = [
  {
    name: 'Clínica Médica',
    label: 'CM',
    colors: {
      text: '#407EC9',
      background: '#D9E5F4',
    },
  },
  {
    name: 'Cirurgia Geral',
    label: 'CIR',
    colors: {
      text: '#01C3AA',
      background: '#CCFCF4',
    },
  },
  {
    name: 'Pediatria',
    label: 'PED',
    colors: {
      text: '#FFC649',
      background: '#FFF4DB',
    },
  },
  {
    name: 'Ginecologia e Obstetrícia',
    label: 'GO',
    colors: {
      text: '#AC145A',
      background: '#EED0DE',
    },
  },
  {
    name: 'Medicina Preventiva e Social',
    label: 'PREV',
    colors: {
      text: '#3B3FB6',
      background: '#D8D9F0',
    },
  },
];

export { optionsSpecialtiesMock };
