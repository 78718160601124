import styled from 'styled-components';

export const EmptyContentContainer = styled.div`
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const EmptyContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const EmptyContentTitle = styled.h3`
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;
  margin: 0;
`;

export const EmptyContentSubtitle = styled.p`
  font-size: 16px;
  line-height: 21.3px;
  color: #3d5582;
  margin: 0;
`;
