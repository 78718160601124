/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import 'react-datepicker/dist/react-datepicker.css';

import { UserAPICMS } from '~/services/apiCallsCMS';
import history from '~/services/history';
import { signOut } from '~/store/modules/auth/actions';

import AvatarInput from './AvatarInput';
import ModalComponent from '~/components/ModalComponent';
import Loader from '~/components/Loader';

import DefaultAvatar from '~/assets/default_user.png';

import {
  Container,
  HeaderContainer,
  AvatarContainer,
  InfoContainer,
  Name,
  ViewRow,
  SecundaryButton,
  SubmitButton,
} from './styles';

export default function ProfileComponent({ show, close }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(profile.name);
  const [email, setEmail] = useState(profile.email);
  const urlAvatar = profile.physician?.avatar || DefaultAvatar;
  const [avatar, setAvatar] = useState(urlAvatar);
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    UserAPICMS.get(profile.id).then((result) => {
      const { data } = result;
      setLoading(false);
      if (profile.name !== data.name) {
        setName(data.name);
      }
      if (profile.email !== data.email) {
        setEmail(data.email);
      }
    });
  }, [profile]);

  useEffect(() => {
    setAvatar(profile.physician?.avatar || DefaultAvatar);
  }, [profile]);

  function handleSignOut() {
    dispatch(signOut());
    history.push('/');
  }

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <ModalComponent show={show} close={close} name="Meu perfil" background="#fff">
      <Container>
        <HeaderContainer>
          <AvatarContainer>
            <AvatarInput
              name="avatar_id"
              urlAvatar={avatar}
              width={95}
              height={95}
            />
          </AvatarContainer>
          {loading ? (
            <Loader />
          ) : (
            <InfoContainer>
              <Name>{profile.name}</Name>
              <ViewRow>
                <SecundaryButton
                  type="button"
                  onClick={handleClickOpen}
                  style={{ width: 78, marginRight: 15 }}
                >
                  sair
                </SecundaryButton>
              </ViewRow>
            </InfoContainer>
          )}
        </HeaderContainer>
        <Form initialData={profile}>
          <div className="input-container">
            <Input
              className="textInput"
              name="name"
              defaultValue={name}
              onChange={(evt) => setName(evt.target.value)}
              style={{ width: '100%' }}
              disabled
            />
            <label className="labelDisabled" htmlFor="name">
              Nome
            </label>
          </div>

          <div className="input-container">
            <Input
              name="email"
              type="email"
              className="textInput"
              placeholder="E-mail"
              defaultValue={email}
              disabled
              style={{ marginRight: 20, backgroundColor: '#eee' }}
            />
            <label className="labelDisabled" htmlFor="email">
              E-mail
            </label>
          </div>
        </Form>
      </Container>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deseja sair?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Quer deslogar da sua conta?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecundaryButton
            onClick={handleClose}
            color="primary"
            style={{ marginTop: 20, width: 80 }}
          >
            VOLTAR
          </SecundaryButton>
          <SubmitButton onClick={handleSignOut} color="primary" autoFocus>
            SAIR
          </SubmitButton>
        </DialogActions>
      </Dialog>
    </ModalComponent>
  );
}
