import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  justify-content: center;
  height: 6px;
  width: 100%;
  border-radius: 13px;
`;

export const Progress = styled.div`
  flex-direction: row;
  height: 6px;
  width: 0%;
  background-color: #000;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
`;

export const LinearProgressBar = styled.div`
  flex-direction: row;
  height: 6px;
  width: 0%;
  border-radius: 3px;
  background: linear-gradient(90deg, #00205b 0%, #00efc8 100%);
`;
