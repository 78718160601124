import styled from 'styled-components';

export const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  gap: 8px;
  height: 32px;
  background: #e5e8ee;
  border-radius: 8px;
  cursor: pointer;
`;

export const DropdownButtonText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #00205b;
`;
