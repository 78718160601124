/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import Axios from 'axios';
import { format, add, sub } from 'date-fns';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@mui/material/Typography';

import Loader from '~/components/Loader';
import ProgressBar from '~/components/ProgressBar';
import SelectComponent from '~/components/SelectComponent';
import ProtectedContent from '~/components/ProtectedContent';
import WarningModal from '~/components/WarningModal';
import GenericModal from '~/components/GenericModal';
import HeaderComponent from '~/components/HeaderComponent';
import EmptyContent from '~/components/EmptyContent';

// 5. Project-specific assets
import SettingsIcon from '~/assets/drops-settings.svg';
import EditIcon from '~/assets/drops-edit.svg';
import WarningIcon from '~/assets/drops-warning-soft.svg';
import FileIcon from '~assets/drops-fileicon.svg';
import DropsPrevious from '~/assets/dropsPrevious';
import DropsNext from '~/assets/dropsNext';
import SearchIcon from '~/assets/drops-search.svg';

import history from '~/services/history';
import { Mixpanel } from '~/services/analytics';
import {
  TrackAPICMS,
  CourseAPICMS,
  TrackCategoryAPICMS,
  SpecialityAPICMS,
} from '~/services/apiCallsCMS';

import { setTrack } from '~/store/modules/tracks/actions';
import { TRACK_TYPE } from '~/utils/variables';

import * as S from './styles';

export default function ListTracks() {
  const location = useLocation();
  const searchToday = location.state?.searchToday;
  const dispatch = useDispatch();
  const today = format(new Date(), 'yyyy-MM-dd');
  const tomorrow = format(
    add(new Date(), {
      days: 1,
    }),
    'yyyy-MM-dd',
  );
  const sevenDaysAgo = format(
    sub(new Date(), {
      days: 7,
    }),
    'yyyy-MM-dd',
  );
  const aMonthAgo = format(
    sub(new Date(), {
      months: 1,
    }),
    'yyyy-MM-dd',
  );
  const aYearAgo = format(
    sub(new Date(), {
      years: 1,
    }),
    'yyyy-MM-dd',
  );

  const [tracks, setTracks] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseLoading, setCourseLoading] = useState(true);
  const [categorys, setCategorys] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [itemsSpecialty, setItemsSpecialty] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [selectedTrackType, setSelectedTrackType] = useState(null);
  const [specialtyLoading, setSpecialtyLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterReady, setFilterReady] = useState(false);
  const [containerLoading, setContainerLoading] = useState(false);
  const [enabledTrack, setEnabledTrack] = useState();
  const [noContent, setNoContent] = useState(true);
  const [noTracks, setNoTracks] = useState(false);
  const [openCoursesModal, setOpenCoursesModal] = useState(false);
  const [modalCoursesItem, setModalCoursesItem] = useState(null);
  const [openSlidesModal, setOpenSlidesModal] = useState(false);
  const [isSuccessSlides, setIsSuccessSlides] = useState(true);
  const [loadingSlides, setLoadingSlides] = useState(false);
  const [clickedId, setClickedId] = useState(0);
  const [slidesLink, setSlidesLink] = useState('');
  const source = Axios.CancelToken.source();
  const [filter, setFilter] = useState({
    track_type__in: [TRACK_TYPE.PRETRILHA, TRACK_TYPE.POSTRILHA],
    page: 1,
    ordering: '-created_at',
    pre_pos_track: true,
  });

  const enabledItems = [
    { label: 'Sim', value: true, key: 'sim' },
    { label: 'Não', value: false, key: 'não' },
  ];

  const trackTypeItems = [
    { label: 'Pré-trilha', value: 'i', key: 'i' },
    { label: 'Pós-Trilha', value: 'h', key: 'h' },
    { label: 'Revisão', value: 'e', key: 'e' },
  ];

  const dataItems = [
    { label: 'Hoje', value: today, key: 1 },
    { label: 'Últimos 7 dias', value: sevenDaysAgo, key: 2 },
    { label: 'Este mês', value: aMonthAgo, key: 3 },
    { label: 'Este ano', value: aYearAgo, key: 4 },
  ];

  const [selectedDate, setSelectedDate] = useState(null);

  const selectProps = [
    {
      basis: '30%',
      content: {
        key: 'category',
        name: 'category',
        value: selectedCategory,
        options: categorys,
        onChange: (category) => {
          setSelectedCategory(category);
          setFilter({ ...filter, track_category: category.value, page: 1 });
        },
        noOptionsMessage: () => 'Nenhum encontrado',
        placeholder: courseLoading ? 'Carregando...' : 'Categoria de prova',
      },
    },
    {
      basis: '30%',
      content: {
        key: 'course',
        name: 'course',
        value: selectedCourse,
        options: courses.map((course) => {
          return { key: course.id, value: course.id, label: course.name };
        }),
        onChange: (course) => {
          setSelectedCourse(course);
          setFilter({ ...filter, course: course.key, page: 1 });
        },
        noOptionsMessage: () => 'Nenhum encontrado',
        placeholder: courseLoading ? 'Carregando...' : 'Curso',
        isSearchable: true,
      },
    },
    {
      basis: '15%',
      content: {
        key: 'specialty',
        name: 'specialty',
        value: selectedSpecialty,
        options: itemsSpecialty,
        onChange: (specialty) => {
          setSelectedSpecialty(specialty);
          setFilter({ ...filter, speciality: specialty.key, page: 1 });
        },
        noOptionsMessage: () => 'Nenhum encontrado',
        placeholder: specialtyLoading ? 'Carregando...' : 'Especialidade',
        isSearchable: true,
      },
    },
    {
      basis: '15%',
      content: {
        key: 'enabled',
        name: 'enabled',
        value: enabledTrack,
        options: enabledItems,
        onChange: (enabled) => {
          setEnabledTrack(enabled);
          setFilter({ ...filter, enabled: enabled.value, page: 1 });
        },
        placeholder: 'Disponível',
      },
    },
    {
      basis: '15%',
      content: {
        key: 'date',
        name: 'date',
        value: selectedDate,
        options: dataItems,
        onChange: (date) => {
          setSelectedDate(date);
          setFilter({ ...filter, created_at__gte: date.value, created_at__lt: tomorrow, page: 1 });
        },
        placeholder: 'Data de criação',
      },
    },
    {
      basis: '15%',
      content: {
        key: 'track_type__in',
        name: 'track_type__in',
        value: selectedTrackType,
        options: trackTypeItems,
        onChange: (item) => {
          setSelectedTrackType(item);
          setFilter({ ...filter, track_type__in: [item.value] });
        },
        placeholder: 'Tipo da trilha',
      },
    },
  ];

  const loadData = useCallback(async (cancelToken) => {
    loadCourses(cancelToken);
    loadCategorys(cancelToken);
    loadSpecialties();
  });

  const loadCourses = useCallback(async (cancelToken) => {
    setCourseLoading(true);
    const { data } = await CourseAPICMS.list({ no_page: true, ordering: 'name' }, cancelToken);

    if (!data) {
      setCourseLoading(false);
      return;
    }

    setCourses(data);
    setCourseLoading(false);
  });

  const loadSpecialties = useCallback(async () => {
    SpecialityAPICMS.list({ no_page: true, main_speciality: true }).then((result) => {
      let item = {};
      const specialties = result.data.map((element) => {
        item = { label: element.name, value: element.id, key: element.id };
        return item;
      });
      setSpecialtyLoading(false);
      setItemsSpecialty(specialties);
    });
  });

  const loadCategorys = useCallback(async (cancelToken) => {
    const { data } = await TrackCategoryAPICMS.list({ ordering: 'abreviation' }, cancelToken);
    if (data && data.results) {
      setCategorys(
        data.results.map((element) => {
          return { label: element.name, value: element.id, key: element.id };
        }),
      );
    }
  });

  const loadTracks = useCallback(async (cancelToken) => {
    const { data } = await TrackAPICMS.list(filter, cancelToken);

    if (data && data.results.length === 0) {
      setNoTracks(true);
      setTracks([]);
      setNoContent(false);
      setCounter(0);
      return;
    }

    if (data) {
      setTracks(data);
      setNoTracks(false);
      setNoContent(false);
      setCounter(data.count);
    }
  });

  const generateSlides = useCallback(async (cancelToken, id) => {
    let result = null;

    result = await TrackAPICMS.generate_slide_deck(id, cancelToken);

    if (!result.data) {
      setIsSuccessSlides(false);
    }
    if (result.data && result.data.url) {
      setIsSuccessSlides(true);
      setSlidesLink(result.data.url);
    }
    setLoadingSlides(false);
  });

  const handleFilter = () => {
    if (loading) {
      return;
    }

    Mixpanel.track("Clicou 'Lista de trilhas - Botão de filtrar'", {
      Pesquisar: !searchTerm ? 'Não especificado' : searchTerm,
      'Categoria de prova': !selectedCategory ? 'Não especificado' : selectedCategory?.label,
      Curso: !selectedCourse ? 'Não especificado' : selectedCourse?.label,
      Especialidade: !selectedSpecialty ? 'Não especificado' : selectedSpecialty?.label,
      Disponivel: !enabledTrack ? 'Não especificado' : enabledTrack?.label,
      'Data de criação': !selectedDate ? 'Não especificado' : selectedDate?.label,
    });
    setNoTracks(false);
    setContainerLoading(true);
    setLoading(true);
    loadTracks(source.token).then(() => {
      setContainerLoading(false);
      setLoading(false);
    });

    return () => {
      source.cancel();
    };
  };

  const handleClear = () => {
    resetFilters();
    Mixpanel.track("Clicou 'Lista de trilhas - Botão de limpar'", {});

    if (tracks.length === 0) {
      return;
    }
    resetTracks();
  };

  const getTag = (questionCount, questionObjectiveCount) => {
    if (questionCount > questionObjectiveCount) {
      return 'Objetiva + Discursiva';
    }
    if (questionCount === questionObjectiveCount) {
      return 'Objetiva';
    }
    return 'Discursiva';
  };

  const getPrePosTag = (type) => {
    if (type === 'pre_track') {
      return 'Trilha Pré';
    }

    if (type === 'pos_track') {
      return 'Trilha Pós';
    }

    return '';
  };

  const extraCourses = (item) => {
    if (item.courses.length <= 4) {
      return 0;
    }
    return item.courses.length - 4;
  };

  const handleEdit = (track) => {
    dispatch(setTrack(track));

    history.push(`exam/${track.id}`);
  };

  const handleConfig = (track) => {
    history.push(`tracks/${track.id}`);
  };

  const handleGenerateSlide = (item) => {
    if (!loadingSlides) {
      setOpenSlidesModal(true);
      setClickedId(item.id);
      setLoadingSlides(true);
      Mixpanel.track("Clicou 'Lista de trilhas - Gerar slide'", {});
      generateSlides(source.token, item.id);
      return;
    }
  };

  const handleNext = () => {
    setFilter({ ...filter, page: filter.page + 1 });
  };

  const handlePrevious = () => {
    setFilter({ ...filter, page: filter.page - 1 });
  };

  const handleTrackType = (type) => {
    if (type === filter.track_type__in || loading || noContent) {
      return;
    }
    Mixpanel.track("Clicou 'Lista de trilhas - Tipo de trilha'", {});
    setCounter(0);
    setNoTracks(false);

    setFilter({ ...filter, track_type__in: type, page: 1 });
  };

  const resetTracks = () => {
    setNoContent(true);
    setTracks([]);
  };

  const resetFilters = () => {
    setSelectedCategory(null);
    setSearchTerm('');
    setSelectedCategory(null);
    setSelectedCourse(null);
    setSelectedDate(null);
    setSelectedSpecialty(null);
    setEnabledTrack(null);
    setCounter(0);
    setFilter({
      track_type__in: [TRACK_TYPE.PRETRILHA, TRACK_TYPE.POSTRILHA],
      page: 1,
      ordering: '-created_at',
    });
  };

  const handleClose = () => {
    setOpenSlidesModal(false);
    setOpenCoursesModal(false);
    setSlidesLink('');
  };

  const clickCourseCount = (item) => {
    Mixpanel.track("Clicou 'Lista de trilhas - Expandir cursos'", {});
    setModalCoursesItem(item);
    setOpenCoursesModal(true);
  };

  const goToCreateTrack = () => {
    Mixpanel.time_event("Clicou 'Criação de trilhas novas - salvar'");
    Mixpanel.track("Clicou 'Lista de trilhas - Nova trilha");
    history.push({ pathname: '/tracks/new', state: { newTrack: true } });
  };

  useEffect(() => {
    loadData(source.token);
    setLoading(false);
    if (searchToday) {
      setSelectedDate(dataItems[0]);
      setFilter({
        ...filter,
        created_at__gte: dataItems[0].value,
        created_at__lt: tomorrow,
        page: 1,
      });
      setFilterReady(true);
    }
  }, []);

  useEffect(() => {
    if (filterReady) handleFilter();
  }, [filterReady]);

  useEffect(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    window.scrollTo(0, 100);
    loadTracks(source.token).then(() => setLoading(false));

    return () => {
      source.cancel();
    };
  }, [filter.track_type__in, filter.page]);

  function hasPendencies(question) {
    if (question.questions_count > question.question_with_comments) return true;
    if (question.questions_count > question.questions_with_tags) return true;
    if (question.questions_count > question.questions_with_content) return true;
    if (question.question_objective_count > question.questions_with_correct_answer) return true;
    return false;
  }

  const mainContent = () => {
    return (
      <>
        {noTracks ? (
          <EmptyContent
            title="Nenhum resultado encontrado"
            subtitle="Selecione os critérios acima e clique em ”Filtrar”"
          />
        ) : (
          <>
            <S.ListExams>
              <S.MainContainer>
                {loading ? (
                  <Loader />
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    {tracks?.results?.map((track) => renderItem(track))}
                  </div>
                )}
              </S.MainContainer>
            </S.ListExams>
            {!loading && (
              <S.ContainerPagination>
                <span>
                  Mostrando <strong>{tracks?.results?.length}</strong> itens
                </span>
                <S.Pagination>
                  <S.ButtonPagination onClick={() => handlePrevious()} disabled={!tracks?.previous}>
                    <DropsPrevious /> Anterior
                  </S.ButtonPagination>
                  <S.ButtonPagination onClick={() => handleNext()} disabled={!tracks?.next}>
                    Próximo <DropsNext />
                  </S.ButtonPagination>
                </S.Pagination>
                <span>
                  <strong>{tracks?.count}</strong> trilhas
                </span>
              </S.ContainerPagination>
            )}
          </>
        )}
      </>
    );
  };

  const renderItem = (item) => {
    const infos = [
      {
        label: 'Enunciadas',
        value: item.questions_with_content,
      },
      {
        label: 'Tagueadas',
        value: item.questions_with_tags,
      },
      {
        label: 'Comentadas',
        value: item.question_with_comments,
      },
      item.question_objective_count > 0 && {
        label: 'Alt. correta',
        value: item.questions_with_correct_answer,
      },
      {
        label: 'Videos',
        value: item.questions_with_video,
      },
    ].filter(Boolean);

    let status;

    if (item.questions_count !== 0 && !hasPendencies(item)) {
      status = 'complete';
    } else {
      status = 'pending';
    }

    function calcPercentage(value) {
      if (item.questions_count === 0) {
        return 0;
      }
      return Math.floor((100 * value) / item.questions_count);
    }

    function truncateString(str, maxLength = 50) {
      if (str.length <= maxLength) {
        return str;
      }

      const truncatedStr = str.slice(0, maxLength - 3);
      return `${truncatedStr}...`;
    }

    function hasPendenciesExtra() {
      if (
        item.all_questions_have_same_tags &&
        item.all_questions_have_same_speciality &&
        item.all_questions_have_same_residency
      )
        return false;
      else return true;
    }

    function getTooltip() {
      let tooltip = '';
      if (!item.all_questions_have_same_tags) tooltip += 'Tem mais de um foco \n';
      if (!item.all_questions_have_same_speciality) tooltip += 'Tem mais de uma especialidade \n';
      if (!item.all_questions_have_same_residency)
        tooltip += 'Tem mais de um nível de residência \n';

      return tooltip;
    }

    return (
      <S.CardBook key={item?.id}>
        <S.HeaderCardBook>
          <S.InfoBook>
            <S.TitleContainer>
              {hasPendenciesExtra(item) && (
                <S.TooltipDiv>
                  <Tooltip
                    title={
                      <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                        {getTooltip(item)}
                      </Typography>
                    }
                    placement="top-start"
                    enterDelay={300}
                    arrow
                  >
                    <img src={WarningIcon} alt="Warning icon" style={{ cursor: 'pointer' }} />
                  </Tooltip>
                </S.TooltipDiv>
              )}
              <S.TitleChapter>{`[${item.id}] ${truncateString(item.name)}`}</S.TitleChapter>
              {item.chapter_pre_pos_track !== '' && (
                <S.PrePosTag>{getPrePosTag(item.chapter_pre_pos_track)}</S.PrePosTag>
              )}
            </S.TitleContainer>
            <S.QuestionsContainer>
              <b>{item.questions_count}</b> {item.questions_count === 1 ? 'questão' : 'questões'}
            </S.QuestionsContainer>
          </S.InfoBook>
          <S.Controls>
            <ProtectedContent permissions={['add_track']}>
              <S.ButtonGenerateSlide onClick={() => handleGenerateSlide(item)}>
                Gerar Deck
                <img src={FileIcon} alt="File icon" />
              </S.ButtonGenerateSlide>
              <S.ButtonEdit onClick={() => handleConfig(item)}>
                <img src={SettingsIcon} alt="Settings icon" />
                Configuração
              </S.ButtonEdit>
              <S.ButtonEdit onClick={() => handleEdit(item)}>
                <img src={EditIcon} alt="Settings icon" />
                Editar
              </S.ButtonEdit>
            </ProtectedContent>
          </S.Controls>
        </S.HeaderCardBook>
        <S.FooterCardBook>
          <S.QuestionsTag>
            {getTag(item.questions_count, item.question_objective_count)}
          </S.QuestionsTag>
          {item.courses.slice(0, 4)?.map((course, index) => (
            <S.CouserName key={index}>{course.name}</S.CouserName>
          ))}
          {!!extraCourses(item) && (
            <S.CouserNameClickable onClick={() => clickCourseCount(item)}>
              +{extraCourses(item)}
            </S.CouserNameClickable>
          )}
        </S.FooterCardBook>
        <S.FooterCardBook>
          <S.RowCard>
            {infos.map((info, index) => {
              const complete = item.questions_count !== 0 && info.value === item.questions_count;
              return (
                <S.CardProgress key={index}>
                  <S.ChipCount complete={complete}>{info.value}</S.ChipCount>
                  <S.ViewCol style={{ gap: 6 }}>
                    <S.ItemSubtitle>
                      {info.label} <strong>{calcPercentage(info.value)}%</strong>
                    </S.ItemSubtitle>
                    <ProgressBar
                      unfilledColor={complete ? '#D8F4E6' : '#FCDBDF'}
                      color={complete ? '#0CBA66' : '#EE1F38'}
                      percentage={calcPercentage(info.value)}
                      height={4}
                      style={{ minWidth: '140px' }}
                    />
                  </S.ViewCol>
                </S.CardProgress>
              );
            })}
          </S.RowCard>
        </S.FooterCardBook>
      </S.CardBook>
    );
  };

  const RenderSelect = ({ ...props }) => (
    <SelectComponent
      {...props}
      width="100%"
      placeholderStyle={{ fontSize: 14.2 }}
      controlStyle={{ marginLeft: 0 }}
      singleValueStyle={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
      valueContainerStyle={{ flexWrap: 'nowrap' }}
    />
  );

  return (
    <>
      <HeaderComponent
        title="Trilhas"
        action={goToCreateTrack}
        actionText="Adicionar nova"
        permissions={['add_track']}
      />
      <S.Wrapper>
        <S.Container>
          <S.FiltersContainer>
            <S.FiltesTitle>Filtrar dados</S.FiltesTitle>
            <S.FilterComponents>
              <S.FilterBox flexBasis={'30%'}>
                <S.Filter>
                  <img src={SearchIcon} alt="Search icon" />
                  <input
                    className="textInput"
                    name="search"
                    type="text"
                    value={searchTerm}
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                      setFilter({ ...filter, search: event.target.value, page: 1 });
                    }}
                    placeholder="Pesquisar"
                  />
                </S.Filter>
              </S.FilterBox>

              {selectProps.map((select) => (
                <S.FilterBox flexBasis={select.basis} key={select.content.name}>
                  <RenderSelect {...select.content} />
                </S.FilterBox>
              ))}
              <S.ButtonsContainer>
                <S.ClearButton onClick={() => handleClear()}>Limpar</S.ClearButton>
              </S.ButtonsContainer>
              <S.ButtonsContainer>
                <S.ButtonFilter onClick={() => handleFilter()}>Filtrar</S.ButtonFilter>
              </S.ButtonsContainer>
            </S.FilterComponents>
          </S.FiltersContainer>
          <S.Tabs>
            <S.TabButton
              active={[TRACK_TYPE.PRETRILHA, TRACK_TYPE.POSTRILHA].some((r) =>
                filter.track_type__in.includes(r),
              )}
              onClick={() => handleTrackType([TRACK_TYPE.PRETRILHA, TRACK_TYPE.POSTRILHA])}
            >
              Pré/Pós trilhas{' '}
              {!!counter &&
                [TRACK_TYPE.PRETRILHA, TRACK_TYPE.POSTRILHA].some((r) =>
                  filter.track_type__in.includes(r),
                ) && <S.TabTag>{counter}</S.TabTag>}
            </S.TabButton>
            <S.TabButton
              active={[TRACK_TYPE.REVISAO].every((r) => filter.track_type__in.includes(r))}
              onClick={() => handleTrackType([TRACK_TYPE.REVISAO])}
            >
              Revisão{' '}
              {!!counter && [TRACK_TYPE.REVISAO].includes(filter.track_type__in) && (
                <S.TabTag>{counter}</S.TabTag>
              )}
            </S.TabButton>
          </S.Tabs>
          {containerLoading ? (
            <Loader />
          ) : noContent ? (
            <EmptyContent
              title="Utilize o filtro para encontrar uma trilha"
              subtitle="Selecione os critérios acima e clique em ”Filtrar”"
            />
          ) : (
            mainContent()
          )}
        </S.Container>
      </S.Wrapper>
      <WarningModal
        show={openSlidesModal}
        close={handleClose}
        title={isSuccessSlides ? 'Solicitação com sucesso' : 'Ocorreu um erro ao gerar slides'}
        subtitle={
          isSuccessSlides
            ? 'O arquivo está em processamento'
            : 'Tente fazer a solicitação novamente'
        }
        textButton={isSuccessSlides ? 'Abrir slide em nova aba' : 'Fechar'}
        linkButton={isSuccessSlides ? slidesLink : null}
        isSuccess={isSuccessSlides}
        isLoading={loadingSlides}
      ></WarningModal>
      <GenericModal title="Cursos vinculados" show={openCoursesModal} close={handleClose}>
        <S.CoursesList>
          {modalCoursesItem &&
            modalCoursesItem.courses?.map((course, index) => (
              <S.CouserName key={index}>{course.name}</S.CouserName>
            ))}
        </S.CoursesList>
      </GenericModal>
    </>
  );
}
