import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';

import Axios from 'axios';

import ImageZoomModal from '~/components/ImageZoomModal';
import ImageSlider from '~/components/ImageSlider';
import MultiImageUploader from '~/components/MultiImageUploader';

import { SelectStyle } from '~/pages/_layouts/default/styles';

import { ImageKindAPICMS } from '~/services/apiCallsCMS';

import { InteractiveContainer, ImageTagContainer } from './styles';

export default function QuestionImages({
  question,
  questionImgs,
  setQuestionImgs,
  editContent,
  action,
}) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState({});

  const [imageKinds, setImageKinds] = useState([]);

  const handleGoToImage = (arraySize, isPrev) => {
    if (isPrev && currentImageIndex < 0) {
      return null;
    }
    if (!isPrev && arraySize < currentImageIndex) {
      return null;
    }
    const step = isPrev ? -1 : 1;
    setCurrentImageIndex(currentImageIndex + step);
    return true;
  };

  const handleImage = (selectedImage) => {
    setCurrentImageIndex(selectedImage);
    setIsFullScreen(true);
  };

  useEffect(() => {
    if (
      !question.imagesUri ||
      !Array.isArray(question.imagesUri) ||
      question.imagesUri.length === 0
    ) {
      setQuestionImgs([]);
    } else {
      setQuestionImgs(
        question.imagesUri.map((im) => {
          return { id: im.id, src: im.url, kind: im.kind };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  useEffect(() => {
    if (!Array.isArray(questionImgs) || questionImgs.length === 0) return;
    setCurrentImage(questionImgs[currentImageIndex]);
  }, [question, currentImageIndex, questionImgs]);

  const loadImageKinds = useCallback(async (cancelToken) => {
    const { data } = await ImageKindAPICMS.list({ no_page: true, ordering: 'kind' }, cancelToken);

    if (data) {
      setImageKinds(
        data.map((imk) => {
          return { key: imk.id, value: imk.id, label: imk.kind };
        }),
      );
    }
  }, []);

  useEffect(() => {
    const source = Axios.CancelToken.source();

    loadImageKinds(source.token);

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <>
      {!editContent || action !== 'text' ? (
        <InteractiveContainer>
          {question.imagesUri && (
            <>
              {action === 'tags' && imageKinds && questionImgs.length > 0 && (
                <ImageTagContainer>
                  <Select
                    name="number-of-options"
                    placeholder="Selecione..."
                    value={imageKinds.find((imk) => imk.key === currentImage.kind)}
                    onChange={(v) => {
                      setCurrentImage({ ...currentImage, kind: v.key });
                      questionImgs[currentImageIndex].kind = v.key;
                    }}
                    options={imageKinds}
                    styles={SelectStyle}
                  />
                </ImageTagContainer>
              )}
              <ImageSlider
                images={question.imagesUri}
                currentPosition={currentImageIndex}
                setPosition={setCurrentImageIndex}
                onPressImage={(index) => handleImage(index)}
              />
            </>
          )}
        </InteractiveContainer>
      ) : (
        <MultiImageUploader
          headerText="Adicione ou arraste as imagens aqui"
          fileImgs={questionImgs}
          setFileImgs={setQuestionImgs}
        />
      )}
      {question.imagesUri && Array.isArray(question.imagesUri) && (
        <ImageZoomModal
          image={question.imagesUri[currentImageIndex]}
          visible={isFullScreen}
          close={() => setIsFullScreen(false)}
          goToPrev={
            currentImageIndex > 0
              ? () => handleGoToImage(question.imagesUri.length - 1, true)
              : null
          }
          goToNext={
            currentImageIndex < question.imagesUri.length - 1
              ? () => handleGoToImage(question.imagesUri.length - 1, false)
              : null
          }
        />
      )}
    </>
  );
}
