/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toast } from 'react-toastify';
import { ReactComponent as SaveIcon } from '~/assets/drops-save.svg';
import RadioButton from '~/components/RadioButton';
import SelectComponentMaterialUi from '~/components/SelectComponentMaterialUi';
import SelectWithPagination from '~/components/SelectWithPagination';
import TextEditorComponent from '~/components/TextEditorComponent';
import Loader from '~/components/Loader';
import { useGetQuestionAppeal } from '~/hooks-querys/appeal';
import { loadDefaultUsers } from '~/pages/Activities/components/FilterDropdown/utils';
import { APPEAL_STATUS, CHANGED_STATUS } from './variables';
import { QuestionAppealAPICMS } from '~/services/apiCallsCMS';
import { objectIsEmpty } from '~/utils/util';
import * as S from './styles';

export default function Appeal({ questionId }) {
  const [appealStatus, setAppealStatus] = useState('');
  const [changedStatus, setChangedStatus] = useState('');
  const [selectedPhysicians, setSelectedPhysicians] = useState(null);
  const [explanation, setExplanation] = useState({ text: '' });
  const [hasData, setHasData] = useState(false);
  const [savedFetchedData, setSavedFetchedData] = useState(null);
  const [isFirstFetch, setIsFirstFetch] = useState(true);

  const {
    data: questionAppealData,
    isFetching: fetchingChallengeData,
    refetch: refetchQuestionAppeal,
  } = useGetQuestionAppeal(
    {
      question_id: questionId,
    },
    {
      config: {
        enabled: !!questionId && isFirstFetch,
      },
    },
  );

  function handleChangeStatus(event) {
    setAppealStatus(event.target.value);

    if (event.target.value !== 'c') {
      setChangedStatus('');
    }
  }

  const handleChangePhysician = (physician) => {
    setSelectedPhysicians(physician);
  };

  const validateParams = () => {
    if (!appealStatus) {
      toast.error('Selecione um status de questão');
      return false;
    }
    if (appealStatus === 'c' && !changedStatus) {
      toast.error('Selecione um motivo para a alteração da banca');
      return false;
    }
    if (!selectedPhysicians) {
      toast.error('Selecione um professor responsável');
      return false;
    }
    if (!explanation?.text) {
      toast.error('O texto não pode estar vazio');
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (validateParams()) {
      if (hasData) {
        let params = {
          ...(savedFetchedData?.status !== appealStatus && { status: appealStatus }),
          ...(!!changedStatus && { board_status: changedStatus }),
          ...(savedFetchedData?.description !== explanation.text && {
            description: explanation.text,
          }),
          ...(savedFetchedData?.responsible !== selectedPhysicians.value && {
            responsible: selectedPhysicians.value,
          }),
        };

        if (objectIsEmpty(params)) {
          return toast.error('Faça modificações para atualizar o recurso.');
        }
        QuestionAppealAPICMS.update(savedFetchedData?.id, params)
          .then(() => {
            refetchQuestionAppeal();
            return toast.success('Recurso atualizado com sucesso!');
          })
          .catch(() => toast.error('Erro ao atualizar recurso. Tente novamente mais tarde'));
      } else {
        let params = {
          status: appealStatus,
          responsible: selectedPhysicians.value,
          description: explanation.text,
          question_id: questionId,
        };

        if (appealStatus === 'c') {
          params['board_status'] = changedStatus;
        }
        QuestionAppealAPICMS.create(params)
          .then(() => {
            refetchQuestionAppeal();
            return toast.success('Recurso criado com sucesso!');
          })
          .catch(() => toast.error('Erro ao criar recurso. Tente novamente mais tarde'));
      }
    }
  };

  useEffect(() => {
    if (!questionAppealData) return;

    if (questionAppealData.length === 0) {
      setIsFirstFetch(false);
      return;
    }

    setHasData(true);
    const fetchedData = questionAppealData[0];
    if (!fetchedData) return;

    setIsFirstFetch(false);
    setAppealStatus(fetchedData?.status || '');
    setChangedStatus(fetchedData?.board_status || '');
    setSelectedPhysicians({
      key: fetchedData?.responsible,
      value: fetchedData?.responsible,
      label: fetchedData?.responsible_name || '',
    });
    setExplanation({ text: fetchedData?.description || '' });
    setSavedFetchedData(fetchedData);
  }, [questionAppealData]);

  return (
    <S.Container>
      {fetchingChallengeData ? (
        <Loader />
      ) : (
        <S.Content>
          <S.AccordionStyle>
            <Accordion defaultExpanded={true}>
              <S.HeaderContainer>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <S.TitleContainer>
                    <S.NumberContainer>3</S.NumberContainer>
                    <S.Title>Recursos</S.Title>
                  </S.TitleContainer>
                </AccordionSummary>
              </S.HeaderContainer>

              <AccordionDetails>
                <S.MainContent>
                  <S.ContentText>Status</S.ContentText>
                  <S.ContentControls>
                    <S.RadioContainer>
                      {APPEAL_STATUS.map((resource) => {
                        return (
                          <S.RadioItemContainer>
                            <RadioButton
                              key={resource.key}
                              control={appealStatus}
                              onChange={handleChangeStatus}
                              text={resource.label}
                              value={resource.value}
                              formControlLabelStyles={{ margin: 0 }}
                              radioStyles={{ padding: '0 8px 0 0' }}
                            />
                          </S.RadioItemContainer>
                        );
                      })}
                      <S.SelectContainer>
                        <SelectComponentMaterialUi
                          label="Selecionar"
                          placeholder="Selecionar"
                          values={CHANGED_STATUS}
                          selectedValue={changedStatus}
                          disabled={appealStatus !== 'c'}
                          loadMore={false}
                          loadingMore={false}
                          shouldLoadMore={false}
                          setSelectedValue={(event) => {
                            setChangedStatus(event.target.value);
                          }}
                          multiple={false}
                          size="small"
                          id="changed-status"
                          limitWidth
                        />
                      </S.SelectContainer>
                    </S.RadioContainer>
                    <S.Button onClick={handleSave}>
                      <SaveIcon /> Salvar
                    </S.Button>
                  </S.ContentControls>

                  <S.ProfessorAndTinyContent>
                    <SelectWithPagination
                      height={40}
                      name="physicians"
                      value={selectedPhysicians}
                      onChange={handleChangePhysician}
                      loadOptions={loadDefaultUsers}
                      placeholder={'Professor responsável'}
                      ismUlti={false}
                    />
                    <TextEditorComponent
                      textHTML={savedFetchedData?.description || ''}
                      onEdit={(text) => setExplanation({ ...explanation, text: text })}
                    />
                  </S.ProfessorAndTinyContent>
                </S.MainContent>
              </AccordionDetails>
            </Accordion>
          </S.AccordionStyle>
        </S.Content>
      )}
    </S.Container>
  );
}
