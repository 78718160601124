import { useQuery } from '@tanstack/react-query';

import { LessonDocumentAPICMS } from '~/services/apiCallsCMS';

const fetchLessonDocumentId = async (id) => {
  try {
    const response = await LessonDocumentAPICMS.get(id);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useLessonDocument(id, options) {
  return useQuery({
    queryKey: ['lessonDocument', id],
    queryFn: () => fetchLessonDocumentId(id),
    staleTime: 0,
    ...options?.config,
  });
}
