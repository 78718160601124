import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-left: auto;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  padding: 0px 56px 24px;
  border-bottom: 1px solid #e5e8ee;
`;

export const Title = styled.span`
  color: #00205b;

  font-size: 28.8px;
  font-style: normal;
  font-weight: 600;
  line-height: 38.4px; /* 133.333% */
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid #e5e8ee;
  bottom: 0px;
  position: absolute;
  background-color: #fff;
`;

export const ButtonSecondary = styled.button`
  display: flex;
  width: 100px;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 320px;
  border: none;
  color: #01c3aa;
  background: #fff;

  font-size: 14.2px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  :hover {
    background-color: #e5f9f7;
  }
`;

export const ButtonPrimary = styled.button`
  display: flex;
  height: 40px;
  width: 100px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 320px;
  border: none;
  background: #0caa87;

  color: #fff;

  font-size: 14.2px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 48px;
`;
