import React from 'react';
import * as S from './styles';

function TextAreaComponent(props) {
  return (
    <S.InputStyle height={props.height}>
      <textarea
        onChange={props.onChange}
        disabled={props.disabled}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
      />
    </S.InputStyle>
  );
}

export default TextAreaComponent;
