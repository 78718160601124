import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '~/components/Checkbox';

import * as S from './styles';

function SelectAllCheckbox({
  id,
  selectedItems,
  setSelectedItems,
  title,
  activities,
  checked,
  disabled,
}) {
  const listOfAllIds = activities.map((activity) => activity.id);

  const handleChange = (checked) => {
    if (checked) {
      return setSelectedItems({ ...selectedItems, [id]: listOfAllIds });
    } else {
      return setSelectedItems({ ...selectedItems, [id]: [] });
    }
  };

  return (
    <FormControlLabel
      style={{ margin: '0' }}
      control={
        <Checkbox
          label=""
          checked={checked}
          onChange={(checked) => handleChange(checked)}
          name={`chackbox-${title}`}
          id={`checkbox-${id}`}
          value={id}
          styleContainer={{ margin: '5px 10px 0', width: '20px' }}
          styleLabel={{ marginBottom: 0 }}
          checkboxOutineColor="#ccc"
          disabled={disabled}
        />
      }
      label={
        <S.Title id={`label-${id}`} for={`checkbox-${title}`}>
          {title}
        </S.Title>
      }
    />
  );
}

export default SelectAllCheckbox;
