import React, { useState, useEffect, useRef, useCallback } from 'react';

import { loadTags, loadCfas } from '~/pages/Chapters/components/Settings/utils';
import SelectWithPagination from '~/components/SelectWithPagination';
import SelectComponent from '~/components/SelectComponent';
import { InstitutionAPICMS } from '~/services/apiCallsCMS';

import { useFlashcardsResidencyDegree, useFlashcardsSpecialitys } from '~/hooks-querys/flashcards';

import { TAG_TYPE } from '~/utils/variables';

import SearchIcon from '~/assets/drops-search.svg';
import * as S from './styles';

const flashcardStatus = [
  {
    value: 1,
    key: 1,
    label: 'Publicado',
  },
  {
    value: 2,
    key: 2,
    label: 'Pendente',
  },
  {
    value: 3,
    key: 3,
    label: 'Reportado',
  },
];

function Filters({
  handleFilters,
  handleClearAll,
  loading,
  setSavedSpecialities,
  setSavedResidencyDegrees,
}) {
  const cardRef = useRef();
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [selectedResidencyDegree, setSelectedResidencyDegree] = useState(null);
  const [residencyDegrees, setResidencyDegrees] = useState([]);
  const [specialitys, setSpecialitys] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [selectedFlashcardStatus, setSelectedFlashcardStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [idSearchTerm, setIdSearchTerm] = useState('');
  const [filters, setFilters] = useState({});
  const [tagWidth, setTagWidth] = useState((cardRef.current?.offsetWidth - 98) / 3 || 0);
  const [smallInputsWidth, setSmallInputsWidth] = useState(
    (cardRef.current?.offsetWidth - 98) / 5 || 0,
  );
  const [selectedCfaOption, setSelectedCfaOption] = useState(null);

  const { data: residencyDegreeData, isLoading: loadingResidencyDegree } =
    useFlashcardsResidencyDegree({
      ordering: 'abbreviation',
    });

  const { data: specialitysData, isLoading: loadingSpecialities } = useFlashcardsSpecialitys({
    no_page: true,
    ordering: 'name',
    main_speciality: true,
  });

  const transformOptions = (value) => {
    if (!value.id || !value.name) return;

    return {
      label: value.name,
      key: value.id,
      value: value.id,
      abbreviation: value.abbreviation,
    };
  };

  const handleClear = () => {
    const defaultResidencyDegree = residencyDegrees?.filter((residencyDegree) =>
      residencyDegree.label.includes('R1'),
    )[0];
    setSelectedTag(null);
    setSelectedResidencyDegree(defaultResidencyDegree);
    setSelectedSpeciality(null);
    setSelectedFlashcardStatus(null);
    setSelectedInstitution(null);
    setSearchTerm('');
    setIdSearchTerm('');
    setFilters({ residency_degree: defaultResidencyDegree.key });
    handleClearAll();
    setSelectedCfaOption(null);
  };

  useEffect(() => {
    if (!residencyDegreeData && !residencyDegreeData?.results) return;
    setResidencyDegrees((residencyDegrees) => [
      ...residencyDegrees,
      ...residencyDegreeData.results.map(transformOptions),
    ]);
    setSavedResidencyDegrees(residencyDegreeData.results);
  }, [residencyDegreeData]);

  useEffect(() => {
    if (residencyDegrees.length === 0) return;

    const defaultresidencyDegree = residencyDegrees.filter((residencyDegree) =>
      residencyDegree.label.includes('R1'),
    )[0];
    setSelectedResidencyDegree(defaultresidencyDegree);
    setFilters({ ...filters, residency_degree: defaultresidencyDegree.key });
  }, [residencyDegrees]);

  useEffect(() => {
    if (!specialitysData) return;
    setSpecialitys(specialitysData.map(transformOptions));
    setSavedSpecialities(specialitysData);
  }, [specialitysData]);

  useEffect(() => {
    const element = cardRef?.current;

    if (!element) return;

    const observer = new ResizeObserver(() => {
      setTagWidth((element.offsetWidth - 98) / 2);
      setSmallInputsWidth((element.offsetWidth - 128) / 5);
    });

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, []);

  const transformInstitutionOptions = (institution) => {
    if (!institution.id || !institution.name) return;

    return {
      label: institution.state ? `${institution.name} - ${institution.state}` : institution.name,
      key: institution.id,
      value: institution.id,
      institution,
    };
  };

  const loadInstitutions = useCallback(async (search, loadedOptions, { page }) => {
    const { data } = await InstitutionAPICMS.list({
      page,
      search,
      ordering: 'name',
      residency: true,
    });

    return {
      options: data.results.map(transformInstitutionOptions),
      hasMore: data.next,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  const handleSearch = (search) => {
    setSearchTerm(search.target.value);
    setFilters({ ...filters, search: search.target.value });
  };

  const handleTag = (tag) => {
    setSelectedTag(tag);
    setFilters({ ...filters, tag: tag.value, parent_tag: tag.parentTag });
    setSelectedCfaOption(null);
  };

  const handleCfa = (cfa) => {
    setSelectedCfaOption(cfa);
    setFilters({
      ...filters,
      tag: cfa?.value,
      tag_type: TAG_TYPE.CFA,
      parent_tag: selectedTag?.value || cfa?.parentTag,
    });
  };

  const handleIdSearch = (search) => {
    setIdSearchTerm(search.target.value);
    setFilters({ ...filters, flashcard: search.target.value });
  };

  const handleResidencyDegree = (residencyDegree) => {
    setSelectedResidencyDegree(residencyDegree);
    setFilters({ ...filters, residency_degree: residencyDegree.value });

    const differentResidencyDegrees = residencyDegree.value !== filters.residency_degree?.value;

    if (differentResidencyDegrees) {
      setSelectedTag(null);
    }
  };

  const handleSpeciality = (speciality) => {
    setSelectedSpeciality(speciality);
    setFilters({ ...filters, speciality: speciality.value });
  };

  const handleInstitution = (institution) => {
    setSelectedInstitution(institution);
    setFilters({ ...filters, institution: institution.value });
  };

  const handleFlashcardStatus = (status) => {
    setSelectedFlashcardStatus(status);

    let newValue = {
      published: false,
      pending: false,
      reported: false,
    };

    switch (status.label) {
      case 'Publicado':
        newValue.published = true;
        break;
      case 'Pendente':
        newValue.pending = true;
        break;
      case 'Reportado':
        newValue.reported = true;
        break;
    }

    return setFilters({ ...filters, ...newValue });
  };

  const filterFlashcards = () => {
    const extraData = {
      specialityName: selectedSpeciality?.label,
      tagName: selectedTag?.label,
      status: selectedFlashcardStatus?.label,
      institution: selectedInstitution?.label,
    };
    return handleFilters(filters, extraData);
  };

  const selectValueStyle = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <S.Card ref={cardRef}>
      <S.FiltersTitle>Filtrar dados</S.FiltersTitle>
      <S.FiltersContainer>
        <S.FilterBox flexBasis={'50%'}>
          <S.Filter>
            <img src={SearchIcon} alt="Search icon" />
            <input
              className="textInput"
              name="search"
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Pesquisar"
            />
          </S.Filter>
        </S.FilterBox>
        <S.FilterBox flexBasis={'50%'}>
          <SelectComponent
            name="residency-degree"
            placeholder={loadingResidencyDegree ? 'Carregando...' : 'Nível de residência'}
            value={selectedResidencyDegree}
            onChange={handleResidencyDegree}
            options={residencyDegrees}
            width={'100%'}
            controlStyle={{ marginLeft: 0 }}
            singleValueStyle={selectValueStyle}
            placeholderStyle={selectValueStyle}
            valueContainerStyle={{ flexWrap: 'nowrap' }}
          />
        </S.FilterBox>
        <S.FilterBox flexBasis={'50%'}>
          <div style={{ width: '100%' }}>
            <SelectWithPagination
              name="tag"
              height={40}
              value={selectedTag}
              onChange={handleTag}
              loadOptions={(search, loadedOptions, page) =>
                loadTags(search, loadedOptions, {
                  ...page,
                  new_residency_degree: selectedResidencyDegree.value,
                })
              }
              placeholder="Tags"
              placeholderStyle={{
                display: 'flex',
                fontSize: '14px',
              }}
              valueContainerStyle={{
                whiteSpace: 'nowrap',
                flexWrap: 'nowrap',
              }}
              containerWidth={tagWidth}
              singleValueStyle={selectValueStyle}
              asyncPaginateKey={`async-paginate-${selectedResidencyDegree?.value || ''}`}
            />
          </div>
        </S.FilterBox>
        <S.FilterBox flexBasis={'50%'}>
          <div style={{ width: '100%' }}>
            <SelectWithPagination
              name="cfa"
              height={40}
              width={'100%'}
              value={selectedCfaOption}
              onChange={handleCfa}
              loadOptions={(search, loadedOptions, page) =>
                loadCfas(search, loadedOptions, page, {
                  parent_tag: selectedTag?.value,
                  new_residency_degree: selectedResidencyDegree.value,
                })
              }
              placeholder="CFA"
              placeholderStyle={{
                display: 'flex',
                fontSize: '14px',
              }}
              valueContainerStyle={{
                whiteSpace: 'nowrap',
                flexWrap: 'nowrap',
              }}
              containerWidth={tagWidth}
              singleValueStyle={selectValueStyle}
              asyncPaginateKey={`async-paginate-cfa${selectedTag?.value || 'no-tag'}`}
            />
          </div>
        </S.FilterBox>
        <S.FilterBox flexBasis={'10%'}>
          <S.Filter>
            <img src={SearchIcon} alt="Search icon" />
            <input
              className="textInput"
              name="search"
              type="number"
              value={idSearchTerm}
              onChange={handleIdSearch}
              placeholder="Buscar por ID"
            />
          </S.Filter>
        </S.FilterBox>

        <S.FilterBox flexBasis={'10%'}>
          <SelectComponent
            name="speciality"
            placeholder={loadingSpecialities ? 'Carregando...' : 'Especialidade'}
            value={selectedSpeciality}
            onChange={handleSpeciality}
            options={specialitys}
            width={smallInputsWidth || '100%'}
            controlStyle={{ marginLeft: 0 }}
            singleValueStyle={selectValueStyle}
            placeholderStyle={selectValueStyle}
            valueContainerStyle={{ flexWrap: 'nowrap' }}
          />
        </S.FilterBox>
        <S.FilterBox flexBasis={'10%'}>
          <SelectComponent
            name="flashcard-status"
            placeholder="Status do flashcard"
            value={selectedFlashcardStatus}
            onChange={handleFlashcardStatus}
            options={flashcardStatus}
            width={smallInputsWidth || '100%'}
            controlStyle={{ marginLeft: 0 }}
            singleValueStyle={selectValueStyle}
            placeholderStyle={selectValueStyle}
            valueContainerStyle={{ flexWrap: 'nowrap' }}
          />
        </S.FilterBox>
        <S.FilterBox flexBasis={'10%'}>
          <SelectWithPagination
            name="institutions"
            height={40}
            value={selectedInstitution}
            onChange={handleInstitution}
            loadOptions={loadInstitutions}
            placeholder="Instituição"
            placeholderStyle={{
              display: 'flex',
              fontSize: '14px',
            }}
            valueContainerStyle={{
              whiteSpace: 'nowrap',
              flexWrap: 'nowrap',
            }}
            singleValueStyle={selectValueStyle}
          />
        </S.FilterBox>
        <S.FilterBox flexBasis={'5%'}>
          <S.ButtonFilter
            onClick={() => filterFlashcards()}
            disabled={loading || Object.keys(filters).length === 0}
          >
            Filtrar
          </S.ButtonFilter>
        </S.FilterBox>
        <S.FilterBox flexBasis={'5%'}>
          <S.ClearButton onClick={() => handleClear()}>Limpar</S.ClearButton>
        </S.FilterBox>
      </S.FiltersContainer>
    </S.Card>
  );
}

export default Filters;
