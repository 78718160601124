import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  padding: 48px;
  flex-direction: column;
  width: 100%;
  max-width: 1388px;
  justify-content: flex-start;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(24, 26, 30, 0.08), 0px 0px 2px 0px rgba(24, 26, 30, 0.16);
  position: relative;
`;

export const Step = styled.div`
  display: flex;
  padding: 32px 56px;
  border-left: ${(props) => props.active && '4px solid #01CFB5'};
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid #e5e8ee;
`;

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  align-self: stretch;
  justify-content: flex-start;
`;

export const StepText = styled.span`
  cursor: default;
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  color: #00205b;
  font-family: Montserrat;
  font-size: 22.8px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.4px; /* 133.333% */
`;

export const StepOrder = styled.div`
  cursor: default;
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background: #01c3aa;
  color: #fff;
  font-family: Montserrat;
  font-size: 20.3px;
  font-style: normal;
  font-weight: 600;
  line-height: 36.5px;
`;

export const SectionText = styled.div`
  color: #00205b;
  cursor: default;
  font-family: Montserrat;
  font-size: 20.3px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  align-self: stretch;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const SectionSubText = styled.span`
  color: #3d5582;
  font-family: Montserrat;
  font-size: 14.2px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.958px; /* 133.507% */
  cursor: default;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
export const GroupContainer = styled.div`
  display: flex;
  padding-top: 24px;
  flex-direction: row;
  width: 100%;
  gap: 62px;
`;

export const AddButton = styled.button`
  border-radius: 320px;
  background: #01b69f;
  height: 48px;
  width: 250px;
  padding: 0px 30px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px;
  border: none;
  :hover {
    background: #01cfb5;
  }
`;

export const EditButton = styled.button`
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: var(--pill, 320px);
  border: 2px solid var(--primary-020, #e5e8ee);
  color: var(--interaction-neutral-02, #00205b);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px;
  background: #fff;
  :hover {
    border: 2px solid var(--primary-020, #00205b);
  }
`;

export const QuestionsCount = styled.div`
  display: flex;
  width: 50px;
  height: 26px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 1000px;
  background: var(--primary-020, #e5e8ee);
  color: var(--primary-accent, #00205b);
  text-align: center;
  font-family: Montserrat;
  font-size: 14.2px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.958px; /* 133.507% */
`;

export const QuestionsText = styled.span`
  color: var(--primary-accent, #00205b);
  font-family: Montserrat;
  font-size: 12.6px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.9px; /* 134.127% */
`;

export const ImportContainer = styled.div`
  input[type='file'] {
    display: none;
  }
  display: flex;
  padding: 40px 56px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const ImportButton = styled.button`
  cursor: pointer;
  display: flex;
  height: var(--3-x, 48px);
  padding: 0px var(--125-x, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--075-x, 12px);
  color: var(--interaction-secondary-default, #01b69f);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px; /* 133.125% */
  border: none;
  background: #fff;
  :hover {
    background: #e5faf7;
    border-radius: var(--pill, 320px);
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  width: calc(100% - 64px);
  margin: 0 32px;
  gap: 24px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(24, 26, 30, 0.16), 0px 32px 64px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  justify-content: space-between;
  position: sticky;
  bottom: 20px;
  z-index: 1000;
`;

export const FooterModalContainer = styled.div`
  display: flex;
  width: 468px;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 24px;
  border-radius: 0px 0px 8px 8px;
  background: var(--main-white, #fff);
`;

export const ModalSubmitButton = styled.button`
  display: flex;
  height: var(--3-x, 48px);
  padding: 0px var(--125-x, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--075-x, 12px);
  flex: 1 0 0;
  border-radius: var(--pill, 320px);
  background: ${(props) => (props.disabled ? '#f0f2f5' : '#01B69F')};
  color: ${(props) => (props.disabled ? '#d1d7e1' : '#fff')};
  text-align: center;
  border: none;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px; /* 133.125% */
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid var(--primary-020, #e5e8ee);
  background: var(--main-white, #fff);
  width: 100%;
`;

export const QuestionNumber = styled.div`
  color: var(--primary-accent, #00205b);
  gap: 30px;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
`;

export const QuestionContent = styled.div`
  color: var(--primary-accent, #00205b);
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 24px;
  line-height: 24px; /* 133.333% */
  whitespace: nowrap;
  align-self: stretch;
`;

export const QuestionHeader = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  cursor: default;
`;

export const QuestionInfoContent = styled.div`
  display: flex;
  gap: 24px;
`;

export const QuestionButtonsContent = styled.div`
  display: flex;
  gap: 24px;
`;

export const QuestionButton = styled.button`
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  border-radius: 1000px;
  border: none;
  background: #fff;
  color: var(--secondary-100, #01c3aa);
  text-align: center;
  font-family: Montserrat;
  font-size: 14.2px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.958px;
  :hover {
    background: #e5faf7;
  }
  :disabled {
    color: #8f9db7;
    background: #e5e8ee;
    cursor: default;
    svg {
      path {
        fill: #d1d7e1;
      }
    }
  }
`;

export const ReviewText = styled.span`
  cursor: default;
  margin-top: 10px;
  color: var(--main-090, #2a2c30);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.3px; /* 133.125% */
  align-self: stretch;
`;

export const ReviewWarning = styled.div`
  display: flex;
  padding: var(--1-x, 16px) var(--15-x, 24px);
  align-items: center;
  gap: var(--15-x, 24px);
  align-self: stretch;
  border-radius: 8px;
  background: ${(props) => (props.warning ? '#FDF6D7' : '#DAE6F4')};
`;

export const ReviewWarningLogo = styled.div`
  display: flex;
  width: var(--3-x, 48px);
  height: var(--3-x, 48px);
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background: ${(props) => (props.warning ? '#F3C404' : '#1862BC')};
`;

export const ReviewMainText = styled.span`
  align-self: stretch;
  color: var(--text-title, #181a1e);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px; /* 133.125% */
`;

export const ReviewSubText = styled.span`
  align-self: stretch;
  color: var(--text-paragraph, #4f5154);
  font-family: Montserrat;
  font-size: 14.2px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.958px; /* 133.507% */
`;

export const ReviewTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--025-x, 4px);
  flex: 1 0 0;
`;

export const SearchQuestionsButton = styled.button`
  padding: 13px 20px;
  display: flex;
  gap: 12px;
  border: 2px solid #e5e8ee;
  color: #00205b;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  background: #fff;
  border-radius: 320px;
  align-items: center;

  :hover {
    border-color: #d1d7e1;
  }
`;

export const SelectComponentContainer = styled.div`
  width: 100%;
  max-width: 544px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const DetailLabel = styled.span`
  padding-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 12.2px;
  line-height: 0px;
  color: #3d5582;
`;
