import React from 'react';

export default function DropsNext() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.8105 13.5077L8.74925 12.4479L12.1993 8.99792L8.74925 5.54793L9.8105 4.49268L14.318 9.00017L9.81125 13.5077H9.8105ZM5.74171 13.5077L4.68121 12.4479L8.13125 8.99792L4.68121 5.55318L5.74171 4.49268L10.25 9.00017L5.74246 13.5077H5.74171Z"
        fill="#01C3AA"
      />
    </svg>
  );
}
