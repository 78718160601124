export const routeName = {
  '/': 'Início',
  '/exam': 'Provas',
  '/exam/new': 'Nova Prova',
  '/challenge': 'Simulados',
  '/challenge/new': 'Novo Simulado',
  '/book': 'Apostilas',
  '/book/new': 'Nova Apostila',
  '/chapter': 'Capítulos',
  '/chapter/new': 'Novo Capítulo',
  '/chapter/edit': 'Editar Capítulo',
  '/chapter/preview': 'Visualização de Capítulo',
  '/question': 'Questões',
  '/tracks': 'Trilhas',
  '/flashcards': 'Flashcards',
  '/courses': 'Cursos',
  '/lesson-module': 'Módulo de aula',
  '/activities': 'Atividades',
  '/create-activity': 'Adicionar tarefa',
  '/edit-activity': 'Editar tarefa',
  '/flashcards-decks': 'Decks Flashcards',
};
