import React from 'react';

import { GoEye } from 'react-icons/go';
import ProgressBar from '~/components/ProgressBar';
import Tags from '~/components/Tags';
import Checkbox from '~/components/Checkbox';

import * as S from './styles';

function ChallengeItem({ challenge, selectedItems, setSelectedItems }) {
	const hasPendencies = (challenge) => {
		if (challenge.questions_count > challenge.question_with_comments) return true;
		if (challenge.questions_count > challenge.questions_with_tags) return true;
		if (challenge.questions_count > challenge.questions_with_content) return true;
		if (challenge.question_objective_count > challenge.questions_with_correct_answer) return true;
		return false;
	};

	const infos = [
		{
			label: 'Enunciadas',
			value: challenge.questions_with_content,
		},
		{
			label: 'Tagueadas',
			value: challenge.questions_with_tags,
		},
		{
			label: 'Comentadas',
			value: challenge.question_with_comments,
		},
		challenge.question_objective_count > 0 && {
			label: 'Alt. correta',
			value: challenge.questions_with_correct_answer,
		},
		{
			label: 'Videos',
			value: challenge.questions_with_video,
		},
	].filter(Boolean);

	let status;

	if (challenge.questions_count !== 0 && !hasPendencies(challenge)) {
		status = 'complete';
	} else {
		status = 'pending';
	}

	function calcPercentage(value) {
		if (challenge.questions_count === 0) {
			return 0;
		}
		return Math.floor((100 * value) / challenge.questions_count);
	}

	const handleCheck = (id) => {
		if (selectedItems.includes(id)) {
			const selectedWithRemovedId = selectedItems.filter((item) => item !== id);

			setSelectedItems(selectedWithRemovedId);
			return;
		}

		setSelectedItems((checkedQuestions) => [...selectedItems, id]);
		return;
	};

	const openChallenge = (id) => {
		window.open(`${process.env.REACT_APP_URL}/exam/${id}`, '_blank');
	};

	return (
		<S.ItemContainer>
			<S.ItemHeader>
				<Checkbox
					id={challenge.id}
					label=""
					checked={selectedItems.includes(challenge.id)}
					onChange={() => handleCheck(challenge.id)}
					value={challenge.id}
					styleContainer={{ margin: '5px 0 0', width: '21px' }}
					styleLabel={{ marginBottom: 0 }}
					checkboxOutineColor="#ccc"
				/>
				<S.ItemLabel onClick={() => handleCheck(challenge.id)}>
					{`[${challenge.id}] - ${challenge.name}`}
				</S.ItemLabel>
				<S.SeeItem onClick={() => openChallenge(challenge.id)}>
					<GoEye />
					{'Visualizar'}
				</S.SeeItem>
			</S.ItemHeader>
			<S.ItemFooter>
				<S.ProgressBarContainer style={{ flexWrap: 'wrap' }}>
					{infos.map((info, index) => {
						const complete =
							challenge.questions_count !== 0 && info.value === challenge.questions_count;
						return (
							<S.CardProgress key={index}>
								<S.ChipCount complete={complete}>{info.value}</S.ChipCount>
								<S.ViewCol style={{ gap: 6 }}>
									<S.ItemSubtitle>
										{info.label} <strong>{calcPercentage(info.value)}%</strong>
									</S.ItemSubtitle>
									<ProgressBar
										unfilledColor={complete ? '#D8F4E6' : '#FCDBDF'}
										color={complete ? '#0CBA66' : '#EE1F38'}
										percentage={calcPercentage(info.value)}
										height={4}
										style={{ minWidth: '140px' }}
									/>
								</S.ViewCol>
							</S.CardProgress>
						);
					})}
				</S.ProgressBarContainer>
			</S.ItemFooter>
		</S.ItemContainer>
	);
}

export default ChallengeItem;
