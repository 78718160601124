import styled from 'styled-components';

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const Card = styled.div`
  display: flex;
  flex-basis: 33%;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(24, 26, 30, 0.16), 0px 4px 8px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  gap: ${(props) => (props.small ? '6px' : '8px')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};

  .price-value {
    font-size: ${(props) => (props.small ? '20px' : '28px')};
  }

  .number {
    font-weight: ${(props) => (props.small ? '700' : '800')};
    font-size: ${(props) => (props.small ? '28px' : '46px')};
  }

  .selected-label {
    height: ${(props) => (props.small ? '12px' : '16px')};
    font-size: ${(props) => (props.small ? '10px' : '12px')};
  }
`;

export const IconAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CardHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const Title = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  color: #00205b;
`;

export const NumberContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const PriceValue = styled.p`
  margin: 0;
  line-height: 28px;
  color: #00205b;
`;

export const Number = styled.p`
  margin: 0;
  line-height: 62px;
  color: #00205b;
`;

export const CardButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  gap: 8px;
  height: 32px;
  background: #019d8a;
  border-radius: 320px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  min-width: 98px;

  :hover {
    background: #01b69f;
  }
`;

export const NumberAndLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectedLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  line-height: 16px;
  color: #4f5154;
  gap: 4px;
  align-items: center;
`;
