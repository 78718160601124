import React, { useState, useEffect, useCallback, useRef } from 'react';

import { MdArrowBack, MdArrowForward, MdSave } from 'react-icons/md';
import { toast } from 'react-toastify';
import Loader from '~/components/Loader';
import history from '~/services/history';
import Axios from 'axios';

import TextEditorComponent from '~/components/TextEditorComponent';
import { Section } from '~/services/apiCalls/section';
import { prepareSections, validateSections } from '~/utils/prepareSections';

import { Chapter } from '~/services';
import { Mixpanel } from '~/services/analytics';
import * as S from './styles';

function EditContent({ changeStep, chapter, editMode = false }) {
  const [content, setContent] = useState({ text: '' });
  const [defaultValue, setDefaultValue] = useState('');
  const [chapterHasContent, setChapterHasContent] = useState(false);
  const [fromLocalStorage, setFromLocalStorage] = useState(false);
  const [topics, setTopics] = useState([]);
  const [sections, setSections] = useState([]);
  const [sectionsParams, setSectionsParams] = useState([]);
  const [loading, setLoading] = useState(false);

  const parser = new DOMParser();
  const parsedDocument = parser.parseFromString(content.text, 'text/html');
  const sectionsStorageNameRef = useRef();
  const previewRef = useRef();

  function preparingChapter(sectionsData) {
    setChapterHasContent(true);
    const prepareChapter = sectionsData?.map((section) => {
      const prepareSection = [];
      prepareSection.push(`<h2 id="${section.id}">${section.name}</h2>${section.content}`);

      if (section?.sub_sections?.length > 0) {
        section.sub_sections.forEach((sub_sections) => {
          prepareSection.push(
            `<h3 id="${sub_sections.id}">${sub_sections.name}</h3>${sub_sections.content}`,
          );
        });
      }
      return prepareSection.join('');
    });
    const joinChapter = prepareChapter?.join('');
    setDefaultValue(joinChapter);
  }

  const getSections = useCallback(async (cancelToken, id) => {
    setLoading(true);
    try {
      const { data } = await Section.list({ chapter: id }, cancelToken);
      if (data?.results?.length > 0) {
        preparingChapter(data?.results);
      } else {
        setChapterHasContent(false);
      }
    } catch (error) {
      toast.error('Erro ao carregar o conteúdo do capítulo. Tente novamente.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    sectionsStorageNameRef.current = `sections${chapter?.id}`;
    const source = Axios.CancelToken.source();
    const contentLocalObj = JSON.parse(localStorage.getItem(sectionsStorageNameRef.current));
    if (contentLocalObj?.text) {
      setContent(contentLocalObj);
      setDefaultValue(contentLocalObj.text);
      setFromLocalStorage(true);
    } else {
      getSections(source.token, chapter?.id);
      setFromLocalStorage(false);
    }
    return () => {
      source.cancel();
    };
  }, [chapter?.id]);

  useEffect(() => {
    const contentLocalObj = JSON.parse(localStorage.getItem(sectionsStorageNameRef.current));
    const contentLocalText = contentLocalObj?.text;

    if (
      content?.text !== '' &&
      contentLocalText !== content.text &&
      defaultValue !== content.text
    ) {
      localStorage.setItem(sectionsStorageNameRef.current, JSON.stringify(content));
    }

    const parse = [...parsedDocument?.documentElement?.childNodes[1]?.children];
    const filtered = parse?.filter((res) => res.localName === 'h2' || res.localName === 'h3');
    setTopics(filtered);
    setSections(prepareSections(parse, chapter?.id));
  }, [content]);

  useEffect(() => {
    const sectionsId = [];

    sections.forEach((section) => {
      if (section.id) {
        sectionsId.push(section.id);
      }
    });

    setSectionsParams(sectionsId);
  }, [sections]);

  async function handleClick() {
    setLoading(true);
    const parse = [...parsedDocument?.documentElement?.childNodes[1]?.children];
    if (!validateSections(parse)) {
      toast.error(
        'Valide as seções e subseções. Não podem ter nomes vazios ou estar fora de ordem. ',
      );
      setLoading(false);
      return;
    }

    const newSections = sectionsParams;
    try {
      if (editMode || chapterHasContent) {
        await Promise.all(
          sections.map(async (section) => {
            if (section.id) {
              await Section.update(section.id, section);
            } else {
              const { data } = await Section.create(section);
              newSections.push(data.id);
            }
          }),
        );
        await Chapter.update(chapter?.id, { sections: newSections });
      } else {
        sections.forEach(async (section) => {
          await Section.create(section);
        });
      }

      let title;

      if (previewRef.current) {
        title = "Clicou 'Tela do capítulo - Edição do capítulo - Botão revisar o capítulo'";
      } else if (editMode) {
        title = "Clicou 'Tela do capítulo - Edição do capítulo - Botão salvar o capítulo'";
      } else {
        title = "Clicou 'Novo capítulo - Edição do capítulo - Botão continuar'";
      }

      Mixpanel.track(title, {
        'Nome do capítulo': chapter.name,
        'Conteúdo preenchido': content.text !== '' ? 'Sim' : 'Não',
      });
      if (editMode) {
        changeStep(2);
      } else {
        changeStep({ second: false, third: true });
      }
      toast.success(
        `Sucesso ao ${
          editMode || chapterHasContent ? 'editar' : 'cadastrar'
        } o conteúdo do capítulo.`,
      );
      localStorage.removeItem(sectionsStorageNameRef.current);
    } catch (error) {
      toast.error(
        `Erro ao ${
          editMode || chapterHasContent ? 'editar' : 'cadastrar'
        } o conteúdo do capítulo. Tente novamente.`,
      );
    } finally {
      previewRef.current = false;
      setLoading(false);
    }
  }

  async function handlePreview() {
    previewRef.current = true;
    await handleClick();
    history.push({
      pathname: `/chapter/preview/${chapter?.id}`,
      state: { removeFooter: true, chapter, backTo: `/chapter/edit/${chapter?.id}` },
    });
  }

  const handleTabClose = (event) => {
    event.preventDefault();
    return (event.returnValue = '');
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <S.Content>
          {!editMode && (
            <S.Header>
              <S.TitleHeader>{`Edição do capítulo - ${chapter.name}`}</S.TitleHeader>
            </S.Header>
          )}
          {fromLocalStorage && (
            <S.WarningSave>
              Há conteúdos não salvos. Por favor, clique no botão abaixo para salvá-los.
            </S.WarningSave>
          )}
          <S.Container>
            <S.Index>
              <S.Scroll>
                <S.Title>ESTRUTURA DE TÓPICOS</S.Title>
                {topics.map((topic, index) => {
                  return (
                    <S.TopicContainer topic={topic.localName} key={index}>
                      <S.Tag>{topic.localName === 'h2' ? 'T1' : 'T2'}</S.Tag>
                      <S.Topic>{topic.innerText}</S.Topic>
                    </S.TopicContainer>
                  );
                })}
              </S.Scroll>
            </S.Index>
            <S.Editor>
              <TextEditorComponent
                textHTML={defaultValue}
                onEdit={(text) => setContent({ ...content, text })}
                showWarning
              />
            </S.Editor>
          </S.Container>
          <S.FooterActions>
            <S.ButtonBack
              type="button"
              onClick={() => {
                if (editMode) {
                  history.push('/chapter');
                } else {
                  changeStep({ first: true, second: false });
                }
              }}
            >
              <MdArrowBack size={18} color="#01c3aa" />
              Voltar
            </S.ButtonBack>
            <S.BoxButtons>
              {editMode && (
                <S.ButtonPreview onClick={() => handlePreview()}>
                  Revisão
                  <MdArrowForward size={18} color="#01c3aa" />
                </S.ButtonPreview>
              )}
              <S.ButtonSave onClick={() => handleClick()}>
                {`${editMode ? 'Salvar' : 'Continuar'}`}
                {editMode ? (
                  <MdSave size={18} color="#FFF" />
                ) : (
                  <MdArrowForward size={18} color="#fff" />
                )}
              </S.ButtonSave>
            </S.BoxButtons>
          </S.FooterActions>
        </S.Content>
      )}
    </>
  );
}

export default EditContent;
