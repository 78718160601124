import React from 'react';
import PropTypes from 'prop-types';

import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import {
  Container,
  LeftArrow,
  Indicator,
  IndicatorSelected,
  IndicatorDeselected,
  RightArrow,
} from './styles';

export default function ImageSlider({
  images,
  currentPosition,
  setPosition,
  onPressImage,
  width,
  height,
}) {
  function handleChangePosition(value) {
    setPosition(value);
  }

  return (
    <>
      {images && images[currentPosition] && (
        <Container style={{ height }} key={images[currentPosition].url}>
          {currentPosition - 1 >= 0 && (
            <LeftArrow>
              <div onClick={() => handleChangePosition(currentPosition - 1)}>
                <MdChevronLeft size={40} color="#00CFB4" />
              </div>
            </LeftArrow>
          )}
          <div
            style={{ width, height }}
            onClick={() => onPressImage(currentPosition)}
          >
            <img
              src={images[currentPosition].url}
              style={{
                flex: 1,
                height,
                width: '100%',
                objectFit: 'contain',
                cursor: 'pointer',
              }}
              alt="Medway"
            />
          </div>
          {images.length > 1 && (
            <Indicator>
              {images.map((_, index) => {
                return (
                  <div key={`indicator${index}`}>
                    {index === currentPosition ? (
                      <IndicatorSelected />
                    ) : (
                      <IndicatorDeselected />
                    )}
                  </div>
                );
              })}
            </Indicator>
          )}
          {currentPosition + 1 < images.length && (
            <RightArrow>
              <div onClick={() => handleChangePosition(currentPosition + 1)}>
                <MdChevronRight size={40} color="#00CFB4" />
              </div>
            </RightArrow>
          )}
        </Container>
      )}
    </>
  );
}

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      uri: PropTypes.string,
    })
  ).isRequired,
  currentPosition: PropTypes.number,
  setPosition: PropTypes.func,
  onPressImage: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

ImageSlider.defaultProps = {
  currentPosition: 0,
  setPosition: () => {},
  onPressImage: () => {},
  width: 200,
  height: 200,
};
