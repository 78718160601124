export const yesOrNo = [
  {
    value: true,
    label: 'Sim',
  },
  {
    value: false,
    label: 'Não',
  },
];

export const invertedYesOrNo = [
  {
    value: false,
    label: 'Sim',
  },
  {
    value: true,
    label: 'Não',
  },
];

export const isNew = [
  {
    value: 365,
    label: 'Sim',
  },
  {
    value: 0,
    label: 'Não',
  },
];

export const questionDifficulty = [
  {
    value: 'e',
    label: 'Fácil',
  },
  {
    value: 'm',
    label: 'Médio',
  },
  {
    value: 'h',
    label: 'Difícil',
  },
];

export const questionType = [
    {
      value: 'd',
      label: 'Discursiva',
    },
    {
      value: 'o',
      label: 'Objetiva',
    }
];

export const demandType = [
  {
    value: 'mz',
    label: 'Memorização',
  },
  {
    value: 'rc',
    label: 'Raciocínio Clínico',
  },
  {
    value: 'ct',
    label: 'Conceitualização',
  },
];

export const usedInChallenge = [
  {
    value: 30,
    label: 'No último mês',
  },
  {
    value: 365,
    label: 'No último ano',
  },
  {
    value: 730,
    label: 'Nos últimos dois anos',
  },
];

export const explanationLastUpdate = [
  {
    id: 0,
    value: 0,
    label: 'Hoje'
  },
  {
    id: 7,
    value: 7,
    label: 'Nos últimos 7 dias',
  },
  {
    id: 30,
    value: 30,
    label: 'Nos últimos 30 dias',
  },
  {
    id: 365,
    value: 365,
    label: 'No último ano',
  },
];