import React, { useState, useCallback, useEffect } from 'react';

import ModalFilter from '~/components/ModalFilter';

import { useChapter } from '~/hooks-querys/chapter';

import Pagination from '~/components/Pagination';
import Loader from '~/components/Loader';
import Filter from './Filter';
import ChapterItem from './ChapterItem';
import * as S from './styles';

let isFirstRender = true;

function ChapterSearch({ show, close }) {
	const [dispatchFilter, setDispatchFilter] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [hasPagination, setHasPagination] = useState(false);
	const [filter, setFilter] = useState({
		page: 1,
	});

	const { data: chapterData, isFetching: fetchingChapterData } = useChapter(
		{
			ordering: 'name',
			...(filter?.search && { search: filter.search }),
			...(filter?.course && { course: filter.course }),
			page: currentPage,
		},
		{
			config: {
				enabled: dispatchFilter,
			},
		},
	);

	useEffect(() => {
		if (!chapterData) return;

		setSearchResults(chapterData);
		setTotalPages(Math.ceil(chapterData.count / 30));
		setHasPagination(!!chapterData.next || !!chapterData.previous);
		setDispatchFilter(false);
	}, [chapterData]);

	const goToPreviousPage = () => {
		setCurrentPage(currentPage - 1);
	};

	const goToNextPage = () => {
		setCurrentPage(currentPage + 1);
	};

	const onCleanFilter = () => {
		setFilter({ search: '', course: null, page: 1 });
	};

	const onHandleFilter = () => {
		setDispatchFilter(true);
	};

	const onAddItems = () => {
		close(selectedItems);
	};

	useEffect(() => {
		if (isFirstRender) {
			isFirstRender = false;
			return;
		}
		setDispatchFilter(true);
	}, [currentPage]);

	useEffect(() => {
		setDispatchFilter(false);
	}, [filter?.search]);

	function renderList() {
		return (
			<S.Container>
				{fetchingChapterData ? (
					<Loader />
				) : (
					<>
						{searchResults?.length > 0 && (
							<>
								<S.ContainerItems>
									{searchResults.map((item, key) => (
										<ChapterItem
											chapter={item}
											selectedItems={selectedItems}
											setSelectedItems={setSelectedItems}
										/>
									))}
								</S.ContainerItems>
								<S.ContainerPagination>
									{searchResults?.length > 0 && (
										<Pagination
											currentPage={currentPage}
											totalPages={totalPages}
											backPage={goToPreviousPage}
											nextPage={goToNextPage}
											setPage={setCurrentPage}
										/>
									)}
								</S.ContainerPagination>
							</>
						)}
					</>
				)}
			</S.Container>
		);
	}

	return (
		<ModalFilter
			show={show}
			close={close}
			title="Buscar capítulos"
			buttonTitle="Adicionar capítulo"
			showFooter={searchResults?.length > 0}
			onCleanFilter={onCleanFilter}
			onHandleFilter={onHandleFilter}
			onAddItems={onAddItems}
			childrenFilter={<Filter filter={filter} setFilter={setFilter} />}
			childrenList={renderList()}
		/>
	);
}

export default ChapterSearch;
