import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;

  background-color: #fff;
`;
export const LeftArrow = styled.div`
  display: flex;
  position: absolute;
  left: -50px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  z-index: 5;
`;

export const Indicator = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  bottom: -25px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  z-index: 5;
`;

export const IndicatorSelected = styled.div`
  width: 10px;
  height: 10px;
  margin: 3px;
  border-radius: 5px;
  background-color: #00cfb4;
`;

export const IndicatorDeselected = styled.div`
  width: 8px;
  height: 8px;
  margin: 3px;
  border-radius: 4px;
  background-color: #00a38e;
`;

export const RightArrow = styled.div`
  display: flex;
  position: absolute;
  right: -50px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  z-index: 5;
`;
