import { useQuery } from '@tanstack/react-query';

import { ErrorReportAPI } from '~/services/apiCallsCMS';

const getReports = async (params) => {
  const response = await ErrorReportAPI.list(params);
  return response.data;
};

export default function useReports(params) {
  return useQuery({
    queryKey: ['reportslist', params],
    queryFn: () => getReports(params),
    staleTime: 60 * 1000,
  });
}
