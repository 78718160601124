import styled from 'styled-components';
import { FillButton, TextButton, OutlineButton } from '../styles';

const FlexRow = styled.div`
  display: flex;
`;

export const Container = styled.div`
  padding: 48px 54px 40px;
  width: 100%;
  overflow-y: auto;
`;

export const Header = styled(FlexRow)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
`;

export const Content = styled(FlexRow)`
  display: flex;
  gap: 24px;
  width: 100%;
`;

export const MoreLessFilters = TextButton;

export const InputRow = styled(FlexRow)`
  width: 100%;
  gap: 24px;
  flex-flow: wrap;
`;

export const Footer = styled(FlexRow)`
  padding-top: 24px;
  width: 100%;
  gap: 24px;
`;

export const FilterButton = FillButton;

export const ResetButton = styled(OutlineButton)`
  position: unset;
`;

export const SearchWithPaginationContainer = styled(FlexRow)`
  flex-grow: 1;
  flex-basis: 31%;
  width: 100%;
`;

export const FieldContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  flex-basis: 31%;
  flex-grow: 1;
`;

export const InstitutionsFieldContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  flex-basis: 100%;
  flex-grow: 1;
`;
