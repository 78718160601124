import styled from 'styled-components';
import { FillButton, TextButton, OutlineButton } from '../styles';

const FlexRow = styled.div`
	display: flex;
`;

const FlexColumn = styled(FlexRow)`
	flex-direction: column;
`;

export const Container = styled(FlexRow)`
	width: 100%;
`;

export const Questions = styled(FlexColumn)`
	min-width: 0;
	flex-basis: ${(props) => (props.showList ? '60%' : '100%')};
	max-height: 100vh;
`;

export const ListTracks = styled.div`
	flex-basis: 40%;
	max-height: 100vh;
`;

export const Header = styled(FlexColumn)`
	width: 100%;
	padding: 112px 56px 20px;
`;

export const Content = styled(FlexColumn)`
	gap: 16px;
	width: 100%;
  overflow: hidden;
  overflow-y: scroll;
	padding: 0 56px 32px;
	max-width: 100vw;
`;

export const ItemContainer = styled(FlexColumn)`
  border-radius: 8px;
	border: ${(props) => (props.selected ? '2px solid #01CFB5' : '2px solid #E5E8EE')};
	box-shadow: ${(props) => (props.selected ? '0px 0px 0px 6px #01CFB533' : 'none')};
`;

export const ItemHeader = styled(FlexRow)`
  padding: 16px 24px;
  gap: 24px;
  align-items: center;
`;

export const ItemLabel = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 21px;
  color: #00205B;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: 50%;
  flex-grow: 1;
	cursor: pointer;
`;

export const IncludedTracksButton = styled(TextButton)`
	padding: 10px 16px;
	gap: 10px;
  font-size: 14px;
  line-height: 19px;
`;

export const FlagContainer = styled.div`
  svg > path {
    fill: #01C3AA;
  }
`;

export const SeeQuestionButton = styled(OutlineButton)`
	position: initial;
`;

export const ItemFooter = styled(ItemHeader)`
  border-top: 1px solid #E5E8EE;
	overflow: hidden;
`;

export const Footer = styled(FlexRow)`
	padding: 16px 24px;
	gap: 24px;
	justify-content: space-between;
	width: 100%;
	border-top: 1px solid #E5E8EE;
	align-items: center;
`;

export const RightItemsFooter = styled(FlexRow)`
	width: 100%;
	justify-content: flex-end;
	gap: 24px;
`;

export const SelectedItems = styled(FlexRow)`
	align-items: center;
	gap: 12px;
	font-size: 13px;
	font-weight: 400;
	line-height: 17px;
	color: #00205B;
`;

export const SaveButton = styled(FillButton)`
	width: auto;
	display: flex;
	gap: 12px;
	align-items: center
`;

export const EndContent = styled(FlexRow)`
	justify-content: center;
	padding-top: 16px;
	position: relative;
`;

export const TotalQuestions = styled.p`
	position: absolute;
	top: 22px;
	right: 0;
	margin: 0;
	font-size: 12.6px;
	font-weight: 400;
	color: #00205B;
	@media (max-height: 1224px) {
		top: ${(props) => (props.smallScreen ? '5px' : '22px')};
	}
`;

export const PaginationContainer = styled.div`
	@media (max-height: 1224px) {
		margin-top: ${(props) => (props.smallScreen ? '28px' : '0')};
	}
`;