import styled from 'styled-components';

export const StyledBorderColor = styled.div`
  position: absolute;
  top: 8px;
  left: 0;
  width: 4px;
  height: calc(100% - 16px);
  background: #01cfb5;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;
