import React, { useState, useEffect } from 'react';
import { BiPlusCircle } from 'react-icons/bi';
import * as S from './styles';

function AddClassObjects({ buttonsData, onAdd }) {
  return (
    <S.Container>
      <S.Label>Adicionar</S.Label>
      <S.ButtonsContainer>
        {buttonsData.map((button, index) => {
          return (
            <S.Button
              key={`button_${button.label}_${index}`}
              onClick={() => onAdd(button.content_type)}
            >
              <BiPlusCircle size={20} color="#077859" />
              <S.ButtonText>{button.label}</S.ButtonText>
            </S.Button>
          );
        })}
      </S.ButtonsContainer>
    </S.Container>
  );
}

export default AddClassObjects;
