import styled from 'styled-components';

export const OptionsContainer = styled.div`
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #3d5582;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400 !important;
`;

export const SelectContainer = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ValuesContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 16px;
  align-items: flex-start;
  overflow-x: auto;
`;

export const Value = styled.div`
  color: #00205b;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  border: 2px solid #00205b;
  color: #00205b;
  border-radius: 100px;
  background-color: #fff;
  user-select: none;
  height: 32px;
  display: flex;
  align-items: center;
  padding-inline: 12px 3px;
  position: relative;
`;

export const RemoveValueButton = styled.button`
  margin-left: 0.3rem;
  color: black;
  transition: border 0.2s ease-in-out;
  border-radius: 100px;
  background: #f0f2f5;
  border: 2px;
  height: 24px;
  align-items: center;
  width: 24px;
  cursor: pointer;
  color: #8f9db7;
  &:hover {
    color: #bb392d;
  }
`;
