import { useQuery } from '@tanstack/react-query';

import { TrackAPICMS } from '~/services/apiCallsCMS';

const getTracks = async (params) => {
  try {
    const response = await TrackAPICMS.list(params);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useTrack(params, options) {
  return useQuery({
    queryKey: ['tracks', params],
    queryFn: () => getTracks(params),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
