import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

export const CardModal = styled.div`
	position: relative;
	background-color: white;
	max-width: 468px;
	box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const Title = styled.h1`
	margin: 0;
	font-weight: 600;
	font-size: 22.8px;
	line-height: 30.4px;
	color: #00205B;
`;

export const Header = styled.div`
	padding: 32px 56px 16px;
	border-bottom: 1px solid #E5E8EE;
	width: 100%;
`;

export const StyledCloseIcon = styled(CloseIcon)`
	position: absolute;
	top: 26px;
	right: 26px;
	fill: #ACACAE;
	cursor: pointer;
	width: 20px;
	height: 20px;
`;