/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HeaderComponent from '~/components/HeaderComponent';
import Tabs from '~/components/Tabs';
import EmptyContent from '~/components/EmptyContent';
import Loader from '~/components/Loader';
import Filter from './components/Filter';
import CourseItem from './components/CourseItem';
import Pagination from '~/components/Pagination';
import { useFilterCourse } from '~/hooks-querys/filters';
import { Mixpanel } from '~/services/analytics';
import history from '~/services/history';
import { saveCreatedCourseName, saveCourseStatus } from '~/store/modules/courses/actions';
import * as S from './styles';

let isFirstRender = true;

function Courses() {
  const dispatch = useDispatch();
  const courseName = useSelector((state) => state.courses.name);
  const activeStatus = useSelector((state) => state.courses.activeStatus);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [clickFilter, setClickFilter] = useState(false);
  const [hasPagination, setHasPagination] = useState(false);
  const [active, setActive] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [filtered, setFiltered] = useState(false);

  const { data: coursesData, isFetching: fetchingCourses } = useFilterCourse(
    {
      ordering: 'name',
      search: searchTerm,
      page: currentPage,
      active: active,
    },
    {
      config: {
        enabled: clickFilter,
      },
    },
  );

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleFilter = () => {
    setClickFilter(true);
    setCurrentPage(1);
    Mixpanel.track("Clicou 'Lista de cursos - Botão de filtrar'", {
      Pesquisar: searchTerm,
    });
  };

  const handleAddNew = () => {
    history.push('courses/new');
    Mixpanel.track("Clicou 'Lista de cursos - Novo curso'", {});
  };

  const handleActive = (isActive) => {
    setCurrentPage(1);
    setClickFilter(true);
    setActive(isActive);
    Mixpanel.track(`Clicou 'Lista de cursos - Aba de ${isActive ? 'Ativos' : 'Inativos'}'`, {});
  };

  useEffect(() => {
    if (!coursesData && !coursesData?.results) return;

    setSearchResults(coursesData.results);
    setFiltered(true);
    setTotalPages(Math.ceil(coursesData.count / 30));
    setHasPagination(!!coursesData.next || !!coursesData.previous);
    setClickFilter(false);
  }, [coursesData]);

  useEffect(() => {
    if (isFirstRender) {
      isFirstRender = false;
      return;
    }
    setClickFilter(true);
  }, [currentPage]);

  useEffect(() => {
    setClickFilter(false);
  }, [searchTerm]);

  useEffect(() => {
    if (courseName) {
      setSearchTerm(courseName);
      setClickFilter(true);
      setSelectedTab(activeStatus !== null ? activeStatus : 0);
      setActive(activeStatus !== null && activeStatus === 0);
      dispatch(saveCourseStatus(null));
      dispatch(saveCreatedCourseName(null));
    }
  }, []);

  const ResultsList = () => {
    return (
      <>
        <S.CourseList>
          {searchResults.map((course) => {
            return (
              <CourseItem courseName={course.name} id={course.id} activeStatus={selectedTab} />
            );
          })}
        </S.CourseList>
        <S.FooterContainer>
          <S.FooterLabel>
            Mostrando <strong>{searchResults.length}</strong>{' '}
            {searchResults.length === 1 ? 'item' : 'itens'}
          </S.FooterLabel>
          {hasPagination && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              backPage={goToPreviousPage}
              nextPage={goToNextPage}
              setPage={setCurrentPage}
            />
          )}
          <S.FooterLabel></S.FooterLabel>
        </S.FooterContainer>
      </>
    );
  };

  const ResultComponent = () => {
    return (
      <>
        {fetchingCourses ? (
          <Loader />
        ) : (
          <>
            {searchResults.length === 0 ? (
              <EmptyContent
                title="Utilize o filtro para encontrar um curso"
                subtitle="Selecione os critérios acima e clique em ”Filtrar”"
              />
            ) : (
              <ResultsList />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <HeaderComponent
        title="Cursos"
        action={handleAddNew}
        actionText="Adicionar Novo"
        permissions={['add_course']}
      />
      <S.Wrap>
        <S.Container>
          <Filter
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleFilter={handleFilter}
          />
          <Tabs
            id="courses-activeness-status"
            selectedTab={selectedTab}
            changeStep={setSelectedTab}
          >
            <div
              label="Ativos"
              id="tabs-active-courses"
              disabled={!filtered && searchResults.length === 0}
              numberBadge={coursesData && active ? coursesData.count : null}
              externalClick={() => handleActive(true)}
            >
              <ResultComponent />
            </div>
            <div
              label="Inativos"
              id="tabs-inactive-courses"
              disabled={!filtered && searchResults.length === 0}
              numberBadge={coursesData && !active ? coursesData.count : null}
              externalClick={() => handleActive(false)}
            >
              <ResultComponent />
            </div>
          </Tabs>
        </S.Container>
      </S.Wrap>
    </>
  );
}

export default Courses;
