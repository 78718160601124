import React, { useState, useRef, useEffect } from 'react';
import { getMonth } from 'date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NewEmptyContent from '~/components/NewEmptyContent';
import Checkbox from '~/components/Checkbox';
import TitleCheckbox from '../TitleCheckbox';
import SelectAllCheckbox from '../SelectAllCheckbox';
import { ReactComponent as ExternalLinkComponent } from '~/assets/external-link.svg';
import { STATUS_OPTIONS } from '../../variables';
import { loadMonths } from '../FilterDropdown/utils';
import MultiplierDropdown from '../MultiplierDropdown';
import { formatPrice, useWindowSize } from '~/utils/util';
import { modifyNumberToFormat } from '../../utils';
import * as S from './styles';
import { StatusTag } from '../FilterDropdown/styles';
import {
  TableContainer,
  TableContent,
  TableItem,
  ItemsContainer,
  LeftHeader,
  RightHeader,
  ActivitiesCount,
} from '~/pages/Activities/components/TaskList/styles';

function ListActivities({
  activitiesData,
  handleOpenChat,
  handleOpenOffcanvas,
  selectedIds,
  setSelectedIds,
  managePage,
  selectedStatus,
  handleChangeMultiplier,
  helperChecked,
  checkAll,
  childrenChecked,
  checkChild,
}) {
  const { width } = useWindowSize();
  const [titleWidth, setTitleWidth] = useState(null);
  const rightItemsRef = useRef(null);
  const gridRef = useRef(null);

  const showCheckboxes = !managePage && selectedStatus === 'a';
  const showMultiplier = managePage && (selectedStatus === 'c' || selectedStatus === 'r');
  const showBulkActions = managePage && selectedStatus === 'c';

  const ReturnStatusTag = (tagStatus) => {
    const tag = STATUS_OPTIONS.find((status) => status.value === tagStatus);

    return (
      <StatusTag color={tag.color} background={tag.bgColor}>
        {tag.icon}
        {tag.label}
      </StatusTag>
    );
  };

  const preventCollapse = (event) => {
    event.stopPropagation();
  };

  const returnTitle = (inputMonth, inputYear) => {
    const monthName = loadMonths().find((month) => month.value === inputMonth);
    if (!monthName) {
      return inputYear;
    }
    return `${monthName?.label} ${inputYear}`;
  };

  const returnFormattedPrice = (inputPrice) => {
    if (!inputPrice) return '-';
    const inputValue = modifyNumberToFormat(inputPrice);

    return formatPrice(inputValue);
  };

  const countAllActivities = (aggregations) => {
    if (!aggregations) return;

    let count = 0;
    aggregations.map((aggregation) => {
      return (count = count + aggregation.activities?.length);
    });

    return `${count} ${count > 1 ? 'atividades' : 'atividade'}`;
  };

  const returnMultiplier = (multiplier) => {
    if (!multiplier) return '-';
    return `${multiplier}x`;
  };

  const disableList = (month) => {
    if (managePage) return false;
    const date = new Date();
    const currentMonth = getMonth(date) + 1;
    return currentMonth <= month;
  };

  useEffect(() => {
    if ((!rightItemsRef && !rightItemsRef?.current) || (!gridRef && !gridRef?.current)) return;
    const calculatedWidth = gridRef.current?.offsetWidth - rightItemsRef.current?.offsetWidth - 20;
    if (calculatedWidth <= 0) {
      return setTitleWidth(100);
    }
    if (showBulkActions) {
      return setTitleWidth(calculatedWidth - 34);
    }
    return setTitleWidth(calculatedWidth);
  }, [rightItemsRef, gridRef, width]);

  const renderList = (activity, index, responsible_name) => {
    return (
      <S.Container>
        <S.AccordionStyle>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <S.Header>
                <LeftHeader>
                  {showCheckboxes ? (
                    <S.CheckboxContainer onClick={preventCollapse}>
                      <SelectAllCheckbox
                        id={`id-${activity.month}-${activity.year}`}
                        title={returnTitle(activity.month, activity.year)}
                        selectedItems={selectedIds}
                        setSelectedItems={setSelectedIds}
                        activities={activity.activities}
                        checked={
                          selectedIds[`id-${activity.month}-${activity.year}`]?.length ===
                          activity.activities?.length
                        }
                        disabled={disableList(activity.month)}
                      />
                    </S.CheckboxContainer>
                  ) : showBulkActions ? (
                    <>
                      <S.CheckboxContainer onClick={preventCollapse}>
                        <Checkbox
                          id={`id-${activity.month}-${activity.year}`}
                          label=""
                          checked={helperChecked.includes(`${index}-${responsible_name}`)}
                          onChange={(event) =>
                            checkAll(event, index, `${index}-${responsible_name}`)
                          }
                          value={`${index}-${responsible_name}`}
                          styleContainer={{ margin: '5px 0 0 14px', width: '20px' }}
                          styleLabel={{ marginBottom: 0 }}
                          checkboxOutineColor="#ccc"
                        />
                      </S.CheckboxContainer>
                      <S.ActivityTitle>
                        {returnTitle(activity.month, activity.year)}
                      </S.ActivityTitle>
                    </>
                  ) : (
                    <S.ActivityTitle>{returnTitle(activity.month, activity.year)}</S.ActivityTitle>
                  )}

                  <ActivitiesCount>
                    {`${activity?.activities.length} ${
                      activity?.activities.length > 1 ? 'atividades' : 'atividade'
                    }`}
                  </ActivitiesCount>
                </LeftHeader>
                <RightHeader>
                  <S.HeaderText width="120px">MULTIPLICADOR</S.HeaderText>
                  <S.HeaderText>VALOR</S.HeaderText>
                  <S.HeaderText>QUANTIDADE</S.HeaderText>
                  <S.HeaderText>MULTA</S.HeaderText>
                  <S.HeaderText>TOTAL</S.HeaderText>
                  <S.HeaderText width="160px">PAGAMENTO</S.HeaderText>
                  <S.EmptySpace />
                </RightHeader>
              </S.Header>
            </AccordionSummary>

            <AccordionDetails>
              <TableContainer>
                <TableContent>
                  {activity.activities.map((item) => {
                    return (
                      <TableItem ref={gridRef}>
                        <ItemsContainer>
                          <S.ItemContainer>
                            {showCheckboxes ? (
                              <TitleCheckbox
                                parentId={`id-${activity.month}-${activity.year}`}
                                selectedItems={selectedIds}
                                setSelectedItems={setSelectedIds}
                                id={item.id}
                                title={item.name}
                                disabled={disableList(activity.month)}
                              />
                            ) : showBulkActions ? (
                              <>
                                <S.CheckboxContainer onClick={preventCollapse}>
                                  <Checkbox
                                    id={`id-${item.id}`}
                                    label=""
                                    checked={childrenChecked.includes(item.id)}
                                    onChange={(event) =>
                                      checkChild(
                                        event,
                                        item.id,
                                        index,
                                        `${index}-${responsible_name}`,
                                      )
                                    }
                                    value={item.id}
                                    styleContainer={{ margin: '5px 0 0 14px', width: '20px' }}
                                    styleLabel={{ marginBottom: 0 }}
                                    checkboxOutineColor="#ccc"
                                  />
                                </S.CheckboxContainer>
                                <S.HeaderTitle width={titleWidth}>{item.name}</S.HeaderTitle>
                              </>
                            ) : (
                              <S.HeaderTitle width={titleWidth}>{item.name}</S.HeaderTitle>
                            )}
                          </S.ItemContainer>
                        </ItemsContainer>
                        <ItemsContainer ref={rightItemsRef}>
                          {showMultiplier ? (
                            <S.MultiplierDropdownContainer>
                              <MultiplierDropdown
                                activityId={item.id}
                                selectedMultiplier={item?.multiplier}
                                handleChange={handleChangeMultiplier}
                              />
                            </S.MultiplierDropdownContainer>
                          ) : (
                            <S.ItemText width="120px">
                              {returnMultiplier(item?.multiplier)}
                            </S.ItemText>
                          )}

                          <S.ItemText>{returnFormattedPrice(item.task_price)}</S.ItemText>
                          <S.ItemText>{item.amount}</S.ItemText>
                          <S.ItemText errorColor={!!item.values_to_pay?.penalty}>
                            {returnFormattedPrice(item.values_to_pay?.penalty)}
                          </S.ItemText>
                          <S.ItemText>
                            {returnFormattedPrice(item.values_to_pay?.total_calculated)}
                          </S.ItemText>
                          <S.ItemText width="160px">{ReturnStatusTag(item.status)}</S.ItemText>
                          <S.IconContainer onClick={() => handleOpenChat(item?.id, item?.name)}>
                            <S.StyledChatIcon has_notes={item?.has_task_notes} />
                          </S.IconContainer>
                          <S.IconContainer
                            onClick={() => handleOpenOffcanvas(item?.id, item?.name)}
                          >
                            <ExternalLinkComponent />
                          </S.IconContainer>
                        </ItemsContainer>
                      </TableItem>
                    );
                  })}
                </TableContent>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </S.AccordionStyle>
      </S.Container>
    );
  };
  return (
    <>
      {activitiesData && activitiesData.length === 0 ? (
        <NewEmptyContent
          title="Nenhuma atividade encontrada"
          subtitle="Conclua a atividade na tela de parceiros e tente novamente"
        />
      ) : (
        <>
          {activitiesData && activitiesData[0].responsible_name ? (
            <S.PartnerWrap>
              {activitiesData?.map((activityParent, parentIndex) => {
                return (
                  <>
                    <S.AccordionStyle>
                      <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <S.PartnerNameContainer>
                            <S.PartnerName>{activityParent?.responsible_name}</S.PartnerName>
                            <S.partnerCount>
                              {countAllActivities(activityParent?.aggregations)}
                            </S.partnerCount>
                          </S.PartnerNameContainer>
                        </AccordionSummary>

                        <AccordionDetails>
                          <S.Wrap>
                            {activityParent?.aggregations?.map((activity) => {
                              return renderList(
                                activity,
                                parentIndex,
                                activityParent?.responsible_name,
                              );
                            })}
                          </S.Wrap>
                        </AccordionDetails>
                      </Accordion>
                    </S.AccordionStyle>
                  </>
                );
              })}
            </S.PartnerWrap>
          ) : (
            <S.Wrap>
              {activitiesData?.map((activity) => {
                return renderList(activity);
              })}
            </S.Wrap>
          )}
        </>
      )}
    </>
  );
}

export default ListActivities;
