import styled from 'styled-components';

export const Title = styled.h1`
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #00205b;
`;
