import { useQuery } from '@tanstack/react-query';

import { LessonAPICMS } from '~/services/apiCallsCMS';

const fetchLessonList = async (params) => {
  try {
    const response = await LessonAPICMS.list(params);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useLessonList(params, options) {
  return useQuery({
    queryKey: ['lessons', params],
    queryFn: () => fetchLessonList(params),
    staleTime: 0,
    ...options?.config,
  });
}
