/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { MdClose } from 'react-icons/md';

import CardComponent from '~/components/CardComponent';

import { Container, HeaderContainer, CloseButton, Title } from './styles';

const useStyles = makeStyles(() => ({
  backdrop: {
    zindex: -1,
    background: 'rgba(0, 32, 91, 0.7)',
  },
}));

const StyledBackdrop = withStyles(() => ({
  root: {},
}))(Backdrop);

export default function ModelComponent({ show, close, name, background, children }) {
  const classes = useStyles();
  return (
    <StyledBackdrop open={show} className={classes.backdrop}>
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-delete-task"
        aria-describedby="modal-delete-task"
        BackdropComponent={Backdrop}
        style={{
          top: 70,
          left: '100% - 300px',
          width: 600,
        }}
        className={classes.modal}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
            invisible: false,
          },
        }}
      >
        <Container>
          <HeaderContainer>
            <Title>{name}</Title>
            <CloseButton onClick={() => close()}>
              <MdClose size={20} color="#FFF" />
            </CloseButton>
          </HeaderContainer>
          <CardComponent background={background} type="important" style={{ height: '100%' }}>
            {children}
          </CardComponent>
        </Container>
      </Modal>
    </StyledBackdrop>
  );
}

ModelComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  name: PropTypes.string,
  children: PropTypes.node,
};

ModelComponent.defaultProps = {
  name: '',
  children: <></>,
};
