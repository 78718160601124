import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const Logo = styled.img`
  @media (max-height: 550px) {
    display: none;
  }
  width: 300px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
`;

export const Title = styled.div`
  margin-bottom: 15px;

  h1 {
    color: #3b3fb6;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
`;

export const Lock = styled.div`
  margin-bottom: 15px;

  img {
    width: 95px;
    height: 65px;
  }
`;

export const Description = styled.div`
  margin-bottom: 30px;
  margin-left: 50px;
  margin-right: 50px;

  span {
    color: #767676;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 50px;

  a {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const useStyles = makeStyles(() => ({
  logoUI: {
    alignItems: 'center',
  },
  lockImg: {
    height: 'auto',
    maxWidth: '100%',
  },

  inputUI: {
    maxWidth: '100%',
    margin: 'auto',
  },
}));
