import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .input-container {
      position: relative;
    }

    label {
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      position: absolute;
      font-size: 1rem;
      cursor: text;
      transition: 0.2s ease-in-out;
      box-sizing: border-box;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      letter-spacing: 0.15px;

      color: #828282;
    }

    .label-birthday {
      color: #767676;
      font-size: 9px;
      font-weight: 500;
      letter-spacing: 0.35px;
      line-height: 11px;
      top: -12px;
      left: 15px;
      pointer-events: none;
    }

    label {
      width: 100%;
      height: 3rem;
      font-size: 1rem;
    }

    .textInput {
      height: 56px;
      width: 550px;
      display: flex;
      flex: 1;
      padding: 5px 15px 5px 15px;
      border-radius: 4px;
      margin-bottom: 25px;
      background-color: #eeeeee;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      padding-top: 15px;

      &:focus {
        background-color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;

        display: flex;
        align-items: center;
        letter-spacing: 0.15px;

        color: #00205b;
      }

      &:not([value='']):not(:focus) {
        background-color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;

        display: flex;
        align-items: center;
        letter-spacing: 0.15px;

        color: #00205b;
      }

      &:valid + label,
      &:focus + label {
        color: #767676;
        font-size: 9px;
        font-weight: 500;
        letter-spacing: 0.35px;
        line-height: 11px;
        top: -12px;
        left: 15px;
        pointer-events: none;
      }

      &::placeholder {
        color: #767676;
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 15px;
      }
    }

    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    hr {
      border: 0;
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
      margin: 10px 0 20px;
    }

    .labelDisabled {
      color: #767676;
      font-size: 9px;
      font-weight: 500;
      letter-spacing: 0.35px;
      line-height: 11px;
      top: -12px;
      left: 15px;
    }

    .SubmitButton {
      margin: 5px 0 0;
      height: 44px;
      background: #3b9eff;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      box-shadow: 0 3px 1px 0 rgb(0, 32, 91, 0.2);
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, '#3b9eff')};
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  justify-content: center;
`;

export const AvatarContainer = styled.div`
  position: relative;
  height: 95px;
  width: 95px;
  border-radius: 50%;
  box-shadow: 5px 5px 5px 0 rgba(0, 32, 91, 0.18);
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 95px;
  margin-left: 25px;
  z-index: 999;
`;

export const Name = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  color: #00205b;

  margin-bottom: 15px;
`;

export const ViewRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #3b3fb6;
  padding-right: 8%;
  padding-left: 8%;
  position: absolute;
  bottom: 0px;
`;

export const SecundaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 35px;
  border: 1px solid #3b3fb6;
  border-radius: 17.5px;

  cursor: pointer;
  color: #3b3fb6;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;

  text-align: center;
  letter-spacing: 0.5px;
`;

export const SubmitButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 105px;
  margin-top: 25px;

  border-radius: 17.5px;
  border-width: 0px;
  background-color: #01cfb5;
  box-shadow: 0 3px 1px 0 rgb(0, 32, 91, 0.2);
  transition: background 0.2s;

  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;

  &:hover {
    background: ${darken(0.03, '#51ffe3')};
  }
`;
