import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import Firebase from '~/config/Firebase';
import { signInRequest } from '~/store/modules/auth/actions';

import logo from '~/assets/logotipo.svg';
import CheckBox from '~/components/CheckboxAuth';

import {
  ButtonDiv,
  Logo,
  InputDiv,
  RememberDiv,
  Separator,
  SupportText,
} from './styles';

export default function SignIn() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  function handleSubmit({ login, password }) {
    if (loading) return;
    setLoading(true);
    Firebase.auth()
      .signInWithEmailAndPassword(login, password)
      .then((result) => {
        const { user } = result;
        const json = user.toJSON();
        const token = json.stsTokenManager.accessToken;
        const { refreshToken } = user;
        dispatch(signInRequest(token, refreshToken, login, remember));
        setLoading(false);
      })
      .catch(() => {
        toast.error('Login ou senha inválidos. Verifique e tente novamente.', {
          toastId: 'signin-submit-toast',
        });
        setLoading(false);
      });
  }

  const schema = Yup.object().shape({
    login: Yup.string()
      .email('E-mail inválido')
      .required('Login é obrigatório'),
    password: Yup.string().required('Senha é obrigatória'),
  });

  return (
    <>
      <Form schema={schema} onSubmit={handleSubmit}>
        <Logo src={logo} alt="Medway" />
        <InputDiv>
          <Input
            id="signin-input-email"
            className="textInput"
            name="login"
            type="email"
            placeholder="Login"
          />
        </InputDiv>
        <InputDiv>
          <Input
            id="signin-input-password"
            className="textInput"
            name="password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Senha"
            style={{ width: '100%', paddingRight: 30 }}
          />
          {showPassword ?
            (<RiEyeFill onClick={() => setShowPassword(!showPassword)}/>) :
            (<RiEyeOffFill onClick={() => setShowPassword(!showPassword)}/>)
          }
        </InputDiv>
        <RememberDiv>
          <CheckBox
            label="Manter-se Conectado"
            checked={remember}
            onChange={setRemember}
          />
        </RememberDiv>
        <ButtonDiv>
          <Link to="/reset_password">Esqueceu a Senha? </Link>
          <Button id="signin-button-submit" type="submit">
            {loading ? 'Carregando...' : 'ENTRAR'}
          </Button>
        </ButtonDiv>

        <Separator />
        <SupportText>
          <span>Precisando de ajuda?</span>
          <span>Contate-nos pelo e-mail karla.ferraz@medway.com.br</span>
        </SupportText>
      </Form>
    </>
  );
}
