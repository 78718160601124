export function setTrack(selectedTrack) {
    return {
      type: '@tracks/SET_TRACK',
      payload: selectedTrack,
    };
  }

export function resetSelectedTrack() {
  return {
    type: '@tracks/RESET_TRACK',
  };
}