import styled from 'styled-components';

const FlexRow = styled.div`
	display: flex;
`;

const FlexColumn = styled(FlexRow)`
	flex-direction: column;
`;

export const ItemContainer = styled(FlexColumn)`
	border-radius: 8px;
	border: ${(props) => (props.selected ? '2px solid #01CFB5' : '2px solid #E5E8EE')};
	box-shadow: ${(props) => (props.selected ? '0px 0px 0px 6px #01CFB533' : 'none')};
`;

export const ItemHeader = styled(FlexRow)`
	padding: 16px 24px;
	gap: 24px;
	align-items: center;
`;

export const ItemLabel = styled.p`
	margin: 0;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px;
	color: #00205b;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-basis: 50%;
	flex-grow: 1;
	cursor: pointer;
`;

export const OutlineButton = styled.button`
	position: absolute;
	top: 32px;
	left: 56px;
	padding: 10px 16px;
	display: flex;
	gap: 12px;
	border: 2px solid #e5e8ee;
	color: #00205b;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px;
	background: #fff;
	border-radius: 320px;
	align-items: center;

	:hover {
		border-color: #d1d7e1;
	}
`;

export const SeeItem = styled(OutlineButton)`
	position: initial;
`;

export const ItemFooter = styled(ItemHeader)`
	border-top: 1px solid #e5e8ee;
	overflow: hidden;
`;
