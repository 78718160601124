import React from 'react';
import ProgressCicle from '~/components/ProgressCicle';
import { PercentText, ProgressContainer, PercentCompleted, UploadContainer } from './styles';

const ProgressCircle = (props) => (
  <ProgressContainer>
    <UploadContainer>
      <ProgressCicle percentage={props.percent} showText={false} radius={62} />
      <PercentCompleted>{`${props.percent}%`}</PercentCompleted>
    </UploadContainer>
    <PercentText> Enviando vídeo...</PercentText>
  </ProgressContainer>
);
export default ProgressCircle;
