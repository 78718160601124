import { useQuery } from '@tanstack/react-query';

import { FeatureAPICMS } from '~/services/apiCallsCMS';

const getFeatures = async (params) => {
  try {
    const response = await FeatureAPICMS.list(params);
    if (response && response.data && response.data) {
      const data = response.data;
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    throw error;
  }
};

export default function useFeatures(params, options) {
  return useQuery({
    queryKey: ['getFeatures'],
    queryFn: () => getFeatures(params),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
