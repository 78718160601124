/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Drawer from '@mui/material/Drawer';

import Loader from '~/components/Loader';
import OffCanvas from '~/components/OffCanvas';
import SelectWithPagination from '~/components/SelectWithPagination';
import TextEditorComponent from '~/components/TextEditorComponent';

import Axios from 'axios';

import { LessonAPICMS } from '~/services/apiCallsCMS';
import { loadPhysicians } from '~/pages/Chapters/components/Settings/utils';
import { useLesson } from '~/hooks-querys/lesson';
import * as S from './styles';

export default function VideoLessonOffCanvas({ show, close, id, lessonModuleName, isFlash }) {
  const [name, setName] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [urlVideo, setUrlVideo] = useState(null);
  const [selectedPhysicians, setSelectedPhysicians] = useState(null);
  const [lessonType, setLessonType] = useState(isFlash ? 'FLASH' : 'REGULAR');
  const [description, setDescription] = useState(null);
  const text = `Fala, galera! \n
    Seja muito bem-vindos à mais uma aula! \n
    e vamos a mais um assunto quem precisam estudar com atenção, pois é bastante frequente nas provas: ${lessonModuleName}! \n
    Aproveitem e bons estudos! \n
    #PRACIMA'`;

  const { data: lesson, isFetching: fetchingLesson } = useLesson(id, {
    config: {
      enabled: !!id && firstLoad,
    },
  });

  const transformOptions = (item) => {
    if (!item?.id) return;

    return {
      label: `${item.name} - ${item.email}`,
      key: item.id,
      value: item.id,
    };
  };

  useEffect(() => {
    if (firstLoad) {
      setDescription(text);
    }
  }, []);

  useEffect(() => {
    if (lesson) {
      setFirstLoad(false);
      setName(lesson.name);
      setDescription(lesson.description);
      setSelectedPhysicians(lesson.physicians.map((physician) => transformOptions(physician)));
      setUrlVideo(lesson.video_url);
      setLessonType(lesson.lesson_type);
    }
  }, [lesson]);

  function save() {
    if (!name) {
      toast.error('Nome é obrigatório!');
      return;
    }

    if (!description) {
      toast.error('Descrição é obrigatório!');
      return;
    }

    if (!urlVideo) {
      toast.error('Vídeo é obrigatório!');
      return;
    }

    const data = {
      lesson_type: lessonType,
      physicians: selectedPhysicians.map((physician) => physician?.value),
      name: name,
      description: description?.text,
      video_url: urlVideo,
    };

    if (lesson) {
      const source = Axios.CancelToken.source();
      LessonAPICMS.update(lesson.id, data, source.token)
        .then(function (response) {
          toast.success('Vídeo aula criada com sucesso');
        })
        .catch(function () {
          toast.error('Houve um erro no cadastro da vídeo aula');
        });
    } else {
      const source = Axios.CancelToken.source();
      LessonAPICMS.create(data, source.token)
        .then(function (response) {
          toast.success('Vídeo aula criada com sucesso');
        })
        .catch(function () {
          toast.error('Houve um erro no cadastro da vídeo aula');
        });
    }
  }

  function renderContent() {
    return (
      <>
        {false ? (
          <Loader text="Carregando..." />
        ) : (
          <S.Content>
            <S.StepContainer>
              <S.Step>
                <S.StepOder>1</S.StepOder>
                <S.StepText>Configuração</S.StepText>
              </S.Step>
              <S.RowContainer>
                <S.Label>Nome</S.Label>
                <S.InputStyle>
                  <input
                    id="name"
                    type="text"
                    placeholder="Nome"
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    value={name}
                  />
                </S.InputStyle>
              </S.RowContainer>
              <S.RowContainer>
                <S.Label>Descrição</S.Label>
                <TextEditorComponent
                  textHTML={description}
                  onEdit={(text) => setDescription({ ...description, text })}
                  simplify
                />
              </S.RowContainer>
              <S.RowContainer>
                <S.Label>Professor</S.Label>
                <SelectWithPagination
                  height={48}
                  name="physicians"
                  isMulti
                  value={selectedPhysicians}
                  onChange={(physician) => setSelectedPhysicians(physician)}
                  loadOptions={loadPhysicians}
                  placeholder="Professor"
                />
              </S.RowContainer>
              <S.RowContainer>
                <S.Label>Vídeo</S.Label>
                <S.SubLabel> Adicione url do vídeo ou faça upload</S.SubLabel>
                <S.InputStyle>
                  <input
                    id="name"
                    type="text"
                    placeholder="Url"
                    onChange={(event) => {
                      setUrlVideo(event.target.value);
                    }}
                    value={urlVideo}
                  />
                </S.InputStyle>
              </S.RowContainer>
            </S.StepContainer>
          </S.Content>
        )}
      </>
    );
  }

  return (
    <OffCanvas
      show={show}
      close={close}
      name="Adicionar vídeo aula"
      children={renderContent()}
      actionAdd={save}
    ></OffCanvas>
  );
}
