export function saveCreatedCourseName(name) {
  return {
    type: '@courses/SAVE_CREATED_COURSE_NAME',
    payload: { name },
  };
}

export function saveCourseStatus(activeStatus) {
  return {
    type: '@courses/SAVE_COURSE_STATUS',
    payload: { activeStatus },
  };
}
