import React, { useState, useCallback, useEffect } from 'react';

import ModalFilter from '~/components/ModalFilter';

import { useTrack } from '~/hooks-querys/tracks';

import Pagination from '~/components/Pagination';
import Loader from '~/components/Loader';

import Filter from './Filter';
import ChallengeItem from './ChallengeItem';
import * as S from './styles';

let isFirstRender = true;

function ChallengeSearch({ show, close }) {
  const [dispatchFilter, setDispatchFilter] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPagination, setHasPagination] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
  });

  const { data: challengeData, isFetching: fetchingChallengeData } = useTrack(
    {
      search: filter.search,
      course: filter.course,
      track_category: filter.category,
      track_type__in: 'b,g',
      enabled: true,
      page: currentPage,
    },
    {
      config: {
        enabled: dispatchFilter,
      },
    },
  );

  useEffect(() => {
    if (!challengeData) return;

    setSearchResults(challengeData.results);
    setTotalPages(Math.ceil(challengeData.count / 30));
    setHasPagination(!!challengeData.next || !!challengeData.previous);
    setDispatchFilter(false);
  }, [challengeData]);

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const onCleanFilter = () => {
    setFilter({ search: '', course: null, category: null, page: 1 });
  };

  const onHandleFilter = () => {
    setDispatchFilter(true);
  };

  const onAddItems = () => {
    close(selectedItems);
  };

  useEffect(() => {
    if (isFirstRender) {
      isFirstRender = false;
      return;
    }
    setDispatchFilter(true);
  }, [currentPage]);

  useEffect(() => {
    setDispatchFilter(false);
  }, [filter?.search]);

  function renderList() {
    return (
      <S.Container>
        {fetchingChallengeData ? (
          <Loader />
        ) : (
          <>
            {searchResults?.length > 0 && (
              <>
                <S.ContainerItems>
                  {searchResults.map((item, key) => (
                    <ChallengeItem
                      challenge={item}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                    />
                  ))}
                </S.ContainerItems>
                <S.ContainerPagination>
                  {searchResults?.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      backPage={goToPreviousPage}
                      nextPage={goToNextPage}
                      setPage={setCurrentPage}
                    />
                  )}
                </S.ContainerPagination>
              </>
            )}
          </>
        )}
      </S.Container>
    );
  }

  return (
    <ModalFilter
      show={show}
      close={close}
      title="Buscar simulados existentes"
      buttonTitle="Adicionar simulado"
      showFooter={searchResults?.length > 0}
      onCleanFilter={onCleanFilter}
      onHandleFilter={onHandleFilter}
      onAddItems={onAddItems}
      childrenFilter={<Filter filter={filter} setFilter={setFilter} />}
      childrenList={renderList()}
    />
  );
}

export default ChallengeSearch;
