import { useQuery } from '@tanstack/react-query';

import { InstitutionAPICMS } from '~/services/apiCallsCMS';

const getInstitutionById = async (id) => {
  try {
    const response = await InstitutionAPICMS.get(id);
    if (response && response.data) {
      const data = response.data;
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    throw error;
  }
};

export default function useInstitutionById(id, options) {
  return useQuery({
    queryKey: ['filtersInstitutions', id],
    queryFn: () => getInstitutionById(id),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
