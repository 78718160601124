import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  padding: 16px 24px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightContent = styled.div`
  display: flex;
  gap: 24px;
`;

export const Button = styled.div`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: none;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #01c3aa;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #e5faf7;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #00205b;
  max-width: 55vw;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

export const Description = styled.p`
  color: #3d5582;
  font-size: 14.2px;
  margin: 0;
`;
