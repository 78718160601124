import React from 'react';
import { SpinnerStyle } from './styles';

export default function LoadingSpinner() {
  return (
    <SpinnerStyle>
      <div className="spinner-container">
        <div className="loading-spinner" />
      </div>
    </SpinnerStyle>
  );
}
