import { useQuery } from '@tanstack/react-query';

import { InstitutionStatesAPICMS } from '~/services/apiCallsCMS';

const getFilterInstitutionStates = async (params) => {
  try {
    const response = await InstitutionStatesAPICMS.list(
      params,
    );
    if (response && response.data) {

      const data = response.data;
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    throw error;
  }
};

export default function useFilterInstitutionStates(params, options) {
  return useQuery({
    queryKey: ['filtersInstitutionStates', params],
    queryFn: () => getFilterInstitutionStates(params),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
