import styled from 'styled-components';
import { darken } from 'polished';
import header from '~/assets/top-auth.svg';
import footer from '~/assets/bottom-auth.svg';

export const Content = styled.div`
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .header {
    position: absolute;
    width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    max-width: 300px;
    flex: 1;

    img {
      margin-bottom: 35px;
    }

    .textInput {
      height: 56px;
      padding: 5px 15px 5px 15px;
      border-radius: 8px;
      margin-top: 15px;
      margin-bottom: 10px;
      background-color: #eeeeee;
      border-width: 0;

      &:focus {
        background-color: #ffffff;
        box-shadow: 0 3px 8px 0 rgba(0, 32, 91, 0.15);
        color: #00205b;
        letter-spacing: 0.5px;
        line-height: 15px;
      }

      &::placeholder {
        color: #767676;
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 15px;
      }
    }

    button {
      height: 50px;
      width: 146px;
      border-radius: 25px;
      border-width: 0px;
      background-color: #00cfb4;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 1px 0 rgb(0, 32, 91, 0.2);
      transition: background 0.2s;

      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;

      &:hover {
        background: ${darken(0.03, '#00205b')};
      }
    }

    a {
      height: 14px;
      color: #00cfb4;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 14px;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const HeaderDiv = styled.div`
  position: absolute;
  top: -20px;
  height: 117px;
  width: 100%;
  background-image: url(${header});
  background-repeat: no-repeat;
  background-size: 300px 120px;
  background-position: right top;

  @media (max-height: 650px) {
    display: none;
  }
`;

export const FooterDiv = styled.div`
  position: absolute;
  bottom: 0;
  min-height: 53px;
  width: 100%;
  margin-left: 25%;

  background-image: url(${footer});
  background-repeat: no-repeat;

  @media (max-height: 650px) {
    display: none;
  }
`;
