import React from 'react';

import * as S from './styles';

export default function ListedQuestionComments({ explanationContent }) {
  const titles = ['Introdução', 'Alternativa A', 'Alternativa B', 'Alternativa C', 'Alternativa D', 'Alternativa E', 'Visão do aprovado'];
  return (
    <S.Container>
      {
      Object.entries(explanationContent).map((entry, index) => {
        const [key, value] = entry;
        if (value) {
          return (
            <S.TextContainer key={key}>
              <S.Title>{titles[index]}</S.Title>
              <S.Text dangerouslySetInnerHTML={{ __html: value}} />
            </S.TextContainer>
          )
        }
      })}
    </S.Container>
  );
}
