import { combineReducers } from 'redux';

import auth from './auth/reducer';
import cmsEndpoints from './cmsEndpoints/reducer';
import search from './search/reducer';
import user from './user/reducer';
import chapter from './chapter/reducer';
import track from './track/reducer';
import tracks from './tracks/reducer';
import questionFilters from './questionFilters/reducer';
import courses from './courses/reducer';

export default combineReducers({
  auth,
  cmsEndpoints,
  search,
  user,
  chapter,
  track,
  tracks,
  questionFilters,
  courses,
});
