/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Modal from '@material-ui/core/Modal';

import CardComponent from '~/components/CardComponent';

import { Container, HeaderContainer, Title, TitleContainer } from './styles';

export default function ModalScreen({ show, close, name, children, haveOverflowY, customHeader }) {
  return (
    <Modal
      open={show}
      onClose={close}
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        margin: 'auto',

        overflow: haveOverflowY ? 'auto' : 'none',
      }}
      BackdropProps={{ invisible: false }}
    >
      <Container>
        <CardComponent
          type="important"
          style={{ padding: 32, zIndex: 2, maxWidth: 650 }}
          background="#fff"
        >
          {customHeader || (
            <HeaderContainer>
              <TitleContainer>
                <Title>{name}</Title>
              </TitleContainer>
            </HeaderContainer>
          )}
          {children}
        </CardComponent>
      </Container>
    </Modal>
  );
}

ModalScreen.defaultProps = {
  name: '',
  width: '610px',
  height: '336px',
  children: <></>,
  customHeader: null,
  id: null,
};
