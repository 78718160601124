const optionsSpecialtiesMock = [
  {
    order: null,
    name: 'Clínica Médica',
    label: 'CM',
    value: null,
    values: [null, null],
    colors: {
      text: '#407EC9',
      background: '#D9E5F4',
    },
  },
  {
    order: null,
    name: 'Cirurgia',
    label: 'CIR',
    value: null,
    values: [null, null],
    colors: {
      text: '#01C3AA',
      background: '#CCFCF4',
    },
  },
  {
    order: null,
    name: 'Pediatria',
    label: 'PED',
    value: null,
    values: [null, null],
    colors: {
      text: '#FFC649',
      background: '#FFF4DB',
    },
  },
  {
    order: null,
    name: 'Preventiva',
    label: 'PREV',
    value: null,
    values: [null, null],
    colors: {
      text: '#3B3FB6',
      background: '#D8D9F0',
    },
  },
  {
    order: null,
    name: 'Ginecologia e Obstetrícia',
    label: 'GO',
    value: null,
    values: [null, null],
    colors: {
      text: '#AC145A',
      background: '#EED0DE',
    },
  },
];

const questionsSelectedsMock = [
  {
    order: 1,
    name: 'Clínica Médica',
    label: 'CM',
    value: 20,
    values: [1, 20],
    colors: {
      text: '#407EC9',
      background: '#D9E5F4',
    },
  },
  {
    order: 2,
    name: 'Cirurgia',
    label: 'CIR',
    value: 20,
    values: [21, 40],
    colors: {
      text: '#01C3AA',
      background: '#CCFCF4',
    },
  },
  {
    order: 3,
    name: 'Pediatria',
    label: 'PED',
    value: 20,
    values: [41, 60],
    colors: {
      text: '#FFC649',
      background: '#FFF4DB',
    },
  },
  {
    order: 4,
    name: 'Ginecologia e Obstetrícia',
    label: 'GO',
    value: 20,
    values: [61, 80],
    colors: {
      text: '#AC145A',
      background: '#EED0DE',
    },
  },
  {
    order: 5,
    name: 'Preventiva',
    label: 'PREV',
    value: 20,
    values: [81, 100],
    colors: {
      text: '#3B3FB6',
      background: '#D8D9F0',
    },
  },
];

export { optionsSpecialtiesMock, questionsSelectedsMock };
