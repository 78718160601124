import { useQuery } from '@tanstack/react-query';

import { GetQuestionsAPICMS } from '~/services/apiCallsCMS';

const searchQuestionsModalListTracks = async (id) => {
  try {
    const response = await GetQuestionsAPICMS.list_tracks(id);
    
    if (response && response.data) {
      const data = response.data;
      return data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
};

export default function useSearchQuestionsModalListTracks(id, options) {
  return useQuery({
    queryKey: ['searchQuestionsModalListTracks', id],
    queryFn: () => searchQuestionsModalListTracks(id),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
