import styled from 'styled-components';

export const QuestionAlternativesList = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  margin-bottom: 20px;
  border: ${(props) => (props.correct ? '1px solid #00CFB4' : '0')};
  border-radius: 10px;
  padding: 5px;
  width: 100%;
`;

export const QuestionAlternative = styled.div`
  display: flex;
  min-height: 35px;
  width: 35px;
  border-radius: 8px;
  background-color: ${(props) => (props.selected ? '#3B3FB6' : '#f5f5fb')};
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.addMargin ? '-16px' : '0px')};
`;

export const ViewRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-height: 35px;
`;

export const QuestionItem = styled.div`
  height: 14px;
  color: ${(props) => (props.selected ? '#fff' : '#3B3FB6')};
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.42px;
  line-height: 14px;
  text-align: center;
`;

export const QuestionAlternativesContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const QuestionAlternativeContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const QuestionItemText = styled.div`
  color: #767676;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 15px;
  width: 100%;
`;

export const QuestionItemTextEdit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  color: #767676;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  width: 100%;
  user-select: none;
`;

export const TrashButton = styled.div`
  display: flex;
  max-width: 32px;
  min-height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 320px;
  border: 2px solid #e5e8ee;
`;

export const IconContainer = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const RowContainer = styled.div`
  width: 100%;
`;

export const ButtonAddAlternative = styled.div`
  display: flex;
  cursor: pointer;
  height: 32px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 320px;
  background: #019d8a;
  color: #fff;
  border: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  width: 230px;
  margin: auto;

  &:hover {
    background: #01b69f;
  }
`;
