import styled from 'styled-components';

export const PercentCompleted = styled.div`
  width: 33px;
  height: 22px;
  position: absolute;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00205b;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
`;

export const ProgressContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 56px;
  gap: 16px;
  position: relative;
  width: 438px;
  height: 253px;
  left: 30%;
  background: #ffffff;
  border: 2px dashed #01cfb5;
  box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
  border-radius: 8px;
`;

export const PercentText = styled.span`
  width: 175px;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00205b;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const UploadContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  isolation: isolate;
  width: 64px;
  height: 64px;
  border: 4px solid #f0f2f5;
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;
