export const TRACK_TYPE = {
  PESSOAL: 'a',
  SIMULADO: 'b',
  EXAME: 'c',
  REVISAO: 'e',
  TRILHA_INTELIGENTE: 'f',
  PRETRILHA: 'h',
  POSTRILHA: 'i',
};

export const REPORT_TYPE = {
  FLASHCARD: 'f',
  LESSON: 'l',
  QUESTION: 'q',
  TRACK: 't',
};

export const TAG_TYPE = {
  TOPIC: 't',
  FOCUS: 'f',
  CFA: 'c',
};

export const STATUS_OPTIONS = {
  p: {
    key: 'p',
    label: 'Pendente',
    color: '#001C50',
    bgColor: '#E5E8EE',
  },
  i: {
    key: 'i',
    label: 'Em andamento',
    color: '#124A8F',
    bgColor: '#DAE6F4',
  },
  c: {
    key: 'c',
    label: 'Concluído',
    color: '#098D4E',
    bgColor: '#D8F4E6',
  },
};

export const SPECIALITY_OPTIONS = {
  GO: {
    key: 'GO',
    label: 'Ginecologia e Obstetrícia',
    color: '#fff',
    bgColor: '#AC145A',
  },
  CM: {
    key: 'CM',
    label: 'Clínica Médica',
    color: '#fff',
    bgColor: '#407EC9',
  },
  CIR: {
    key: 'CIR',
    label: 'Cirurgia Geral',
    color: '#2A2C30',
    bgColor: '#00EFC8',
  },
  PED: {
    key: 'CIR',
    label: 'Pediatria',
    color: '#2A2C30',
    bgColor: '#FFC649',
  },
  PREV: {
    key: 'PREV',
    label: 'Medicina Preventiva e Social',
    color: '#FFFFFF',
    bgColor: '#3B3FB6',
  },
  OTHER: {
    key: 'SEM-ESP',
    label: 'Outros',
    color: '#001C50',
    bgColor: '#E5E8EE',
  },
};

export const FIELD_TYPE = {
  RADIO: 'a',
  SELECT: 'b',
  TEXT: 'c',
  DATE: 'd',
  TIME: 'e',
  DATETIME: 'g',
  URL: 'h',
  INTEGER: 'i',
};
