import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

export const Wrap = styled.div`
  display: flex;
  gap: 16px;
`;

export const StyledChip = styled.div`
  .MuiChip-root {
    border: 2px solid #e5e8ee;
  }

  .MuiChip-label {
    max-width: 180px;
    color: #00205b;
    font-family: Montserrat;
    font-size: 12.6px;
    font-weight: 600;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 20px;
  height: 20px;

  path {
    fill: #00205b;
  }
  border-radius: 1000px;
  border: 2px solid #d1d7e1;
`;
