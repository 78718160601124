import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 48px;
  max-width: 1388px;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 40px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const StepContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 32px 56px;
  width: 100%;
  max-width: 1224px;
  flex: none;
  order: 1;
  align-self: auto;
  flex-grow: 0;
  z-index: 0;
  position: relative;
`;
export const StepText = styled.span`
  width: 350px;
  height: 31px;
  font-style: normal;
  font-weight: 600;
  font-size: 22.8px;
  line-height: 30px;
  color: #00205b;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const StepOder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  background: #01c3aa;
  color: #ffffff;
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20.3px;
  line-height: 36px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ContainerConfig = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 32px;
  width: 100%;
  max-width: 1288px;
  flex: 0;
  order: 1;
  flex-grow: 0;
  z-index: 0;
  input[type='file'] {
    display: none;
  }
`;
export const Space = styled.div`
  height: 15px;
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
`;

export const FooterButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid #e5e8ee;
  margin-top: 16px;
`;

export const Label = styled.div`
  color: #00205b;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.3px;
`;

export const Button = styled.button`
  display: flex;
  gap: 10px;
  padding: 0 16px;
  height: 40px;
  border-radius: 320px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #e2e5ee;

  &:hover {
    border: 2px solid #b7bed7;
    background: #fff;
  }
`;

export const ButtonLabel = styled.div`
  color: #077859;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;
