import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const Label = styled.p`
  margin: 0;
  color: #3d5582;
  font-size: 14.2px;
  line-height: 18.96px;
  margin-top: 2px;
`;

export const BoldLabel = styled(Label)`
  font-weight: 600;
`;
