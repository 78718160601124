import { useQuery } from '@tanstack/react-query';

import { GetFlashcardsItemsAPICMS } from '~/services/apiCallsCMS';

const getFlashcardsItems = async (params) => {
  try {
    const response = await GetFlashcardsItemsAPICMS.list(
      params,
    );
    if (!response && !response.data && !response.data.results) {
      throw new Error('No results found');
    }

    return response.data.results;
 
  } catch (error) {
    throw error;
  }
};

export default function useFlashcardsItems(params, options) {
  return useQuery({
    queryKey: ['flashcardsItems', params],
    queryFn: () => getFlashcardsItems(params),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
