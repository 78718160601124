import React from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { Ring } from 'react-spinners-css';
import WhiteTooltip from '../WhiteTooltop';
import { formatPrice } from '~/utils/util';
import * as S from './styles';

function DashboardCards({ size, data }) {
  const convertNumberToPrice = (isPrice, number) => {
    if (!isPrice) return number;

    return formatPrice(number, true);
  };

  return (
    <S.Wrap>
      {data.map((item) => {
        return (
          <S.Card small={size === 'small'} disabled={item.disabled}>
            <S.IconAndTitleContainer>
              {item.icon}
              <S.CardHeader>
                <S.TitleContainer>
                  <S.Title>{item.title}</S.Title>
                  {item.tooltipText && (
                    <WhiteTooltip text={item.tooltipText}>
                      <HiOutlineInformationCircle
                        size={16}
                        color="#3D5582"
                        style={{ cursor: 'pointer' }}
                      />
                    </WhiteTooltip>
                  )}
                </S.TitleContainer>
                {item.buttonText && item.buttonAction && (
                  <S.CardButton onClick={item.buttonAction}>
                    {item.loadingButton ? (
                      <Ring size={16} color="#fff" />
                    ) : (
                      <>
                        {item.buttonIcon && item.buttonIcon}
                        {item.buttonText}
                      </>
                    )}
                  </S.CardButton>
                )}
              </S.CardHeader>
            </S.IconAndTitleContainer>
            <S.NumberAndLabelContainer>
              <S.NumberContainer>
                {item.isPrice && <S.PriceValue className="price-value">R$</S.PriceValue>}
                <S.Number className="number">
                  {convertNumberToPrice(item.isPrice, item.number)}
                </S.Number>
              </S.NumberContainer>
              <S.SelectedLabel
                className="selected-label"
                key={`selected-label-${item.selectedNumber}`}
              >
                {item.hasOwnProperty('selectedNumber') && (
                  <>
                    <b>{item.selectedNumber}</b>
                    {item.selectedNumber > 1 ? 'selecionados' : 'selecionado'}
                  </>
                )}
              </S.SelectedLabel>
            </S.NumberAndLabelContainer>
          </S.Card>
        );
      })}
    </S.Wrap>
  );
}

export default DashboardCards;
