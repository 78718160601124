import styled from 'styled-components';
import { ReactComponent as ArrowLeft } from '~/assets/drops-arrow-left.svg';

export const ModalContent = styled.div`
  padding: 32px 56px;
  border-bottom: 1px solid #e5e8ee;
  width: 100%;
`;

export const ModalText = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  color: #4f5154;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 24px;
  width: 100%;
`;

export const FillButton = styled.button`
  display: flex;
  border-radius: 320px;
  background: #0caa87;
  height: 40px;
  width: 100%;
  padding: 10px 16px;

  justify-content: center;
  align-items: center;
  color: #fff;

  font-size: 14px;
  font-weight: 600;
  border: none;
  gap: 10px;

  :hover {
    background: #01cfb5;
  }

  :disabled {
    color: #8f9db7;
    background: #e5e8ee;
    cursor: default;
  }
`;

export const BackButton = styled.button`
  padding: 10px 16px;
  gap: 10px;
  display: flex;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: #077859;
  background: #fff;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 320px;

  :hover {
    background: #daf5f1;
  }
`;

export const StyledArrowLeft = styled(ArrowLeft)`
  path {
    fill: #077859;
  }
`;
