import styled from 'styled-components';

export const CardModal = styled.div`
	max-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: white;
	max-width: 1288px;
	box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
	border-radius: 8px;
	align-items: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const CloseButtonStyle = { 
	position: 'absolute', 
	top: '16px',
	right: '16px',
	fill: '#ACACAE',
	cursor: 'pointer',
	width: '24px',
	height: '24px',
};

export const Title = styled.h1`
	margin: 0;
	color: #00205B;
	font-size: 29px;
	font-weight: 600;
	line-height: 38px;
`;

export const FillButton = styled.button`
	border-radius: 320px;
	background: #01b69f;
	height: 48px;
	width: 100%;
	padding: 0px 30px;
	justify-content: center;
	align-items: center;
	color: #fff;
	text-align: center;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21.3px;
	border: none;

	:hover {
		background: #01cfb5;
	};

	:disabled {
		color: #8F9DB7;
		background: #E5E8EE;
		cursor: default;
	}
`;

export const OutlineButton = styled.button`
	position: absolute;
	top: 32px;
	left: 56px;
	padding: 10px 16px;
	display: flex;
	gap: 12px;
	border: 2px solid #E5E8EE;
	color: #00205B;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px;
	background: #fff;
	border-radius: 320px;
	align-items: center;

	:hover {
		border-color: #D1D7E1;
	}
`;

export const TextButton = styled.button`
	padding: 16px 24px;
	gap: 16px;
	display: flex;
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	text-align: center;
	color: ${(props) => (props.disabled ? '#8F9DB7' : '#01C3AA')};
	background: #fff;
	border: none;
	align-items: center;
	justify-content: center;
	border-radius: 320px;
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

	:hover {
		background: ${(props) => (props.disabled ? '#fff' : '#E5FAF7')};
	}
`;
