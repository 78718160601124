import { useQuery } from '@tanstack/react-query';
import { TrackCategoryAPICMS } from '~/services/apiCallsCMS';

const getTrackCategory = async (params) => {
  try {
    const response = await TrackCategoryAPICMS.list(params);

    if (response && response.data && response.data) {
      const data = response.data;
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    throw error;
  }
};
export default function useTrackCategory(params, options) {
  return useQuery({
    queryKey: ['trackCategory', params],
    queryFn: () => getTrackCategory(params),
    staleTime: 10 * (60 * 1000), //10 minutes
    ...options?.config,
  });
}
