import styled from 'styled-components';
import { ReactComponent as RemoveIcon } from '~/assets/drops-error.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SelectDescription = styled.p`
  margin: 0;
  font-size: 12.6px;
  font-weight: 400;
  color: #00205b;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 80px;
  width: 100%;
  border-bottom: 1px solid #e5e8ee;
`;

export const ListItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ListLabel = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #2a2c30;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RemoveButton = styled.button`
  height: 48px;
  padding: 0px 20px;
  display: flex;
  gap: 12px;
  border-radius: 320px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  color: #077859;
  background: transparent;
  border: none;

  :hover {
    background: #daf5f1;
  }
`;

export const StyledRemoveIcon = styled(RemoveIcon)`
  width: 22px;
  height: 22px;
  path {
    fill: #077859;
  }
`;
