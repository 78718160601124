import React from 'react';

import Modal from '@material-ui/core/Modal';
import { Mixpanel } from '~/services/analytics';

import SuccessMessage from '~/components/UploadVideoS3/SuccessMessage';

import CloudLogo from '~/assets/drops-cloud-logo.svg';

import DismissLogo from '~/assets/dismiss_logo.svg';
import DownloadLogo from '~/assets/donwload-logo.svg';
import InfoLogo from '~/assets/information_logo.svg';
import PlusLogo from '~/assets/drops-plus-logo.svg';

import * as I from '~/components/VideoCommentUpload/styles';
import * as S from './styles';

function ImportQuestions({ disabled, csvFile, setShowModal, handleQuestions, handleCsvFile, top }) {
  return (
    <Modal open onClose={() => setShowModal(false)}>
      <S.Container>
        <S.ModalContainer top={top}>
          <S.ModalDismissContainer onClick={() => setShowModal(false)}>
            <img src={DismissLogo} alt="Close modal" />
          </S.ModalDismissContainer>
          <S.ModalHeader>
            Importar questões
            <S.ModalAlert>
              <img src={InfoLogo} alt="info" />
              Para utilizar este importador, use o template como referência para gerar o CSV a ser
              importado aqui.
            </S.ModalAlert>
            <S.ImportButton
              onClick={() => {
                window.open(
                  'https://docs.google.com/spreadsheets/d/1bNg20urApRLcOEHujL1kJayMWbLUlbvsc_bVZ-sWMDQ/edit?usp=sharing',
                  '_blank',
                );
                Mixpanel.track("Clicou 'Criação de trilhas novas - importar questões'");
              }}
              style={{ marginTop: '20px' }}
            >
              <img src={DownloadLogo} alt="import questions" />
              Baixar template
            </S.ImportButton>
          </S.ModalHeader>
          {csvFile ? (
            <>
              <SuccessMessage csv title="Enviado com sucesso" subtitle="O arquivo foi processado" />
            </>
          ) : (
            <>
              <I.MainContainer style={{ position: 'relative', left: '3px', width: '380px' }}>
                <I.IconGroup>
                  <I.PlusContainer style={{ right: 160 }}>
                    <img src={PlusLogo} alt="Plus logo" />
                  </I.PlusContainer>
                  <I.Group>
                    <img src={CloudLogo} alt="Cloud logo" />
                  </I.Group>
                </I.IconGroup>
                <I.MainLb>Clique e selecione um arquivo</I.MainLb>
                <I.InputHide>
                  <label htmlFor="file">
                    <input
                      type="file"
                      id="file"
                      accept="text/csv"
                      onChange={(event) => handleCsvFile(event.target.files[0])}
                    />
                    <I.BtSelect
                      onClick={() =>
                        Mixpanel.track("Clicou 'Criação de trilhas novas - selecionar arquivo'")
                      }
                    >
                      Selecionar arquivo
                    </I.BtSelect>
                  </label>
                </I.InputHide>
              </I.MainContainer>
            </>
          )}
          <S.FooterModalContainer>
            <S.ModalSubmitButton onClick={handleQuestions} disabled={disabled}>
              Importar
            </S.ModalSubmitButton>
          </S.FooterModalContainer>
        </S.ModalContainer>
      </S.Container>
    </Modal>
  );
}

export default ImportQuestions;
