export const APPEAL_STATUS = [
  {
    value: 'a',
    key: 1,
    label: 'Sem alteração',
  },
  {
    value: 'b',
    key: 2,
    label: 'Cabe recurso',
  },
  {
    value: 'c',
    key: 3,
    label: 'Alterado pela banca',
  },
];

export const CHANGED_STATUS = [
  {
    value: 'd',
    key: 4,
    label: 'Questão anulada',
  },
  {
    value: 'e',
    key: 5,
    label: 'Expansão de gabarito',
  },
  {
    value: 'f',
    key: 6,
    label: 'Alteração de gabarito',
  },
];
