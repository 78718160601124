import React from 'react';
import PropTypes from 'prop-types';
import { Ring, Ellipsis } from 'react-spinners-css';

export default function Loader({
  color,
  backgroundColor,
  text,
  spinner,
  showText,
  size,
  customStyle,
}) {
  return (
    <div
      style={{
        backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        ...customStyle,
      }}
    >
      {spinner === 'ellipsis' && <Ellipsis size={size} color={color} />}
      {spinner === 'ring' && <Ring size={40} color={color} />}
      {showText && (
        <div
          style={{
            color: '#00205B',
            fontWeight: 'bold',
            fontFamily: 'MontSerrat',
            fontSize: 14,
            textAlign: 'center',
            position: 'absolute',
            top: 50,
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
}

Loader.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
  spinner: PropTypes.string,
  showText: PropTypes.bool,
  size: PropTypes.number,
};

Loader.defaultProps = {
  color: '#3B3FB6',
  backgroundColor: 'transparent',
  text: 'Carregando...',
  spinner: 'ellipsis',
  showText: true,
  size: 80,
};
