import produce from 'immer';

export const INITIAL_STATE = {
  name: null,
  activeStatus: null,
};

export default function courses(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@courses/SAVE_CREATED_COURSE_NAME': {
        draft.name = action.payload.name;
        break;
      }
      case '@courses/SAVE_COURSE_STATUS': {
        draft.activeStatus = action.payload.activeStatus;
        break;
      }
      default:
    }
  });
}
