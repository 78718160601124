import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 3px;
  width: 100%;
  background-color: rgba(242, 242, 242, 0.4);
`;

export const Content = styled.div`
  width: 98%;
  margin: 16px 1%;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  align-self: flex-start;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 28px;
  justify-content: space-between;
  border-bottom: 1px solid #dadadb;
`;

export const TitleContainer = styled.div`
  display: flex;
  color: #00205b;
  gap: 24px;
  width: 100%;
`;

export const Title = styled.h1`
  font-family: Montserrat;
  color: #00205b;
  font-size: 22.8px;
  font-weight: 600;
  line-height: 30.4px;
  letter-spacing: 0.25px;
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;
`;

export const NumberContainer = styled.div`
  width: 40px;
  height: 40px;
  padding: 14px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background: #01c3aa;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const AccordionStyle = styled.div`
  .MuiAccordion-root {
    background: transparent;
    box-shadow: none;
  }

  .MuiAccordionSummary-expandIcon {
    width: 32px;
    height: 32px;
    border: 2px solid #e5e8ee;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    path {
      fill: #019d8a;
    }
  }

  .MuiIconButton-edgeEnd {
    margin-right: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 44px;
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root {
    width: 100%;
  }
`;

export const ContentText = styled.p`
  margin: 0;
  color: #00205b;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.3px;
`;

export const ContentControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RadioContainer = styled.div`
  display: flex;
  gap: 16px 24px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const SelectContainer = styled.div`
  width: 100%;
  max-width: 212px;
  flex-shrink: 0;
`;

export const RadioItemContainer = styled.div`
  flex-shrink: 0;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  height: 40px;
  gap: 8px;
  padding: 10px 16px;
  background: ${(props) => (props.disabled ? '#F0F2F5' : '#01B69F')};
  color: ${(props) => (props.disabled ? '#D1D7E1' : '#FFF')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  border: none;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: ${(props) => (props.disabled ? '#F0F2F5' : '#01cfb5')};
  }
  path {
    fill: ${(props) => (props.disabled ? '#D1D7E1' : '#FFF')};
  }
`;

export const ProfessorAndTinyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
