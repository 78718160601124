import styled from 'styled-components';
import { ReactComponent as ChatIcon } from '~/assets/chat-icon.svg';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ItemText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(props) => (props.errorColor ? '#EE1F38' : '#2a2c30')};
  width: ${(props) => props.width || 'unset'};
  min-width: 100px;
`;

export const HeaderText = styled.h3`
  margin: 0;
  height: 40px;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: #00205b;
  width: ${(props) => props.width || 'unset'};
  min-width: 100px;
`;

export const EmptySpace = styled.div`
  visibility: hidden;
  width: 48px;
  height: 1px;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.button`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const AccordionStyle = styled.div`
  .MuiAccordion-root {
    background: transparent;
    box-shadow: none;
  }

  .MuiAccordionSummary-expandIcon {
    width: 32px;
    height: 32px;
    border: 2px solid #e5e8ee;
  }

  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    path {
      fill: #019d8a;
    }
  }

  .MuiIconButton-edgeEnd {
    margin-right: 0;
  }

  .MuiAccordionSummary-root {
    min-height: 40px;
    margin-bottom: 12px;
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderTitle = styled.h1`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #00205b;
  margin: 0 0 0 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => props.width + 'px' || 'unset'};
`;

export const ActivityTitle = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #00205b;
`;

export const MultiplierDropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
`;

export const PartnerNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const PartnerName = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #00205b;
`;

export const partnerCount = styled.p`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #2a2c30;
`;

export const PartnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledChatIcon = styled(ChatIcon)`
  width: 15px;
  height: 15px;
  path {
    fill: ${(props) => (props.has_notes ? '#019D8A' : '#00205B')};
  }
`;
