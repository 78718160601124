import styled from 'styled-components';

export const ButtonOutsideIcon = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid #00cfb4;
  background-color: #00cfb4;
  cursor: pointer;

  transition: 0.3s ease-in-out;

  *,
  & {
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    cursor: pointer;
    transform: translateX(3px);

    .icon {
      transform: scale(1.1);
    }
  }
`;

export const ButtonInsideIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  height: 27px;
  width: 27px;
  border-radius: 50%;

  background-color: #00cfb4;
`;

export const SecundaryButton = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 21px;

  text-align: right;
  letter-spacing: 0.25px;

  cursor: pointer;
`;

export const MinimalButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid #00cfb4;

  background-color: #00cfb4;
  cursor: pointer;

  transition: 0.3s ease-in-out;

  *,
  & {
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    cursor: pointer;
    transform: translateX(3px);

    .icon {
      transform: scale(1.1);
    }
  }
`;

export const DefaultButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 100%;
  padding-right: 51px;
  padding-left: 27px;

  border: 1px solid #01cfb5;
  border-right: 0px solid;
  box-sizing: border-box;
  border-radius: 17.5px;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;

  text-align: right;
  letter-spacing: 0.5px;

  color: #01cfb5;
  cursor: pointer;
  user-select: none;

  transition: 0.3s ease-in-out;

  *,
  & {
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    cursor: pointer;
    transform: translateX(3px);

    .icon {
      transform: scale(1.1);
    }
  }
`;

export const ImportantButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 100%;
  padding-right: 51px;
  padding-left: 27px;

  border: 1px solid #01cfb5;
  border-right: 0px solid;
  background-color: #01cfb5;
  box-sizing: border-box;
  border-radius: 17.5px;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;

  text-align: right;
  letter-spacing: 0.5px;

  color: #fff;
  cursor: pointer;
  user-select: none;
`;
