import React from 'react';
import ModalWithHeader from '~/components/ModalWithHeader';
import {
  ModalContent,
  ModalText,
  ModalFooter,
  BackButton,
  FillButton,
  StyledArrowLeft,
} from '../../styles';

export default function ModalDelete(
  {
    showModal,
    handleClose,
    handleDelete,
  }) {
  return (
    <ModalWithHeader
      show={showModal}
      close={handleClose}
      title="Excluir flashcard"
    >
      <ModalContent>
        <ModalText>
          Você deseja excluir esse flashcard? Após confirmar não será possível recuperá-lo.
        </ModalText>
      </ModalContent>
      <ModalFooter>
        <BackButton onClick={handleClose}>
          <StyledArrowLeft />
          Voltar
        </BackButton>
        <FillButton onClick={handleDelete}>
          Excluir
        </FillButton>
      </ModalFooter>
    </ModalWithHeader>
  );
}


