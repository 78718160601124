import styled from 'styled-components';
import { MdChevronRight } from 'react-icons/md';
import { MdAddCircleOutline } from 'react-icons/md';

export const Container = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 2px solid #e5e8ee;
  padding: 0 24px;
  cursor: ${(props) => (props.draggable ? 'grab' : 'pointer')};
  width: 100%;
  display: flex;
  min-height: 64px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

export const NumberText = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #00205b;
`;

export const NameOption = styled.p`
  margin: 0;
  color: #00205b;
  font-weight: 600;
  font-size: 20.3px;
  font-weight: 600;
  line-height: 27px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

export const ButtonUp = styled.div`
  width: 14px;
  height: 14px;
`;

export const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  gap: 4px;
  width: 65px;
  height: 24px;
  background: ${(props) => props.color};
  border-radius: 1000px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const RangeText = styled.span`
  width: 36px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
`;

export const DragIconAndName = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  min-width: 0;
`;

export const RangeTextAndChipContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: #00205b;
  font-size: 16px;
  line-height: 21px;
`;

export const RangeAndExpandIcon = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const ExpandIcon = styled(MdChevronRight)`
  transform: ${(props) => (props.open ? 'rotate(270deg)' : 'rotate(90deg)')};
`;

export const ExpandIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #e5e8ee;

  &:hover {
    border-color: #d1d7e1;
  }
`;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background: #e5e8ee;
  padding: 20px 28px;
  margin-bottom: 24px;
  border-radius: 8px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #e5e8ee;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 1000px;
  border: none;
  background: #fff;
  font-size: 12.6px;
  font-weight: 600;
  line-height: 15.12px;
  color: #077859;
  height: 32px;
  padding: 0 12px;

  &:hover {
    background: #daf5f1;
  }
`;

export const RemoveIcon = styled(MdAddCircleOutline)`
  transform: rotate(45deg);
`;
