/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { TextField } from '@material-ui/core';
import ButtonWithPopper from '~/components/ButtonWithPopper';
import CardComponent from '~/components/CardComponent';
import Loader from '~/components/Loader';
import { InputStyle } from '~/components/InputMUI/styles';
import { useGetTaskNote } from '~/hooks-querys/tasks';
import { useGetOffcanvasData } from '~/hooks-querys/financial';
import { TaskNoteAPICMS, TaskFinancialAPICMS } from '~/services/apiCallsCMS';

import { Mixpanel } from '~/services/analytics';

import * as S from './styles';

export default function OffCanvasMessaging({ show, close, activityId, user }) {
  const [observation, setObservation] = useState(null);
  const [observationValue, setObservationValue] = useState('');
  const managePage = user.permissions.includes('manage_activities');

  const {
    data: taskData,
    isLoading: loadingTaskData,
    refetch: refetchTask,
  } = useGetOffcanvasData(activityId, {
    config: {
      enabled: !!activityId,
    },
  });

  const [isReport, setIsReport] = useState(taskData?.status === 'r');

  const {
    data: notes,
    isLoading: loadingTaskNote,
    refetch: refetchTaskNote,
  } = useGetTaskNote(
    {
      task: activityId,
    },
    {
      config: {
        enabled: !!activityId,
      },
    },
  );

  useEffect(() => {
    if (!taskData) return;
    setIsReport(taskData.status === 'r');
  }, [taskData]);

  const callBackTask = () => {
    refetchTaskNote();
    setObservation(null);
    setObservationValue('');
    let status;

    if (taskData.status === 'r' && !isReport) {
      status = 'a';
    } else if (isReport) {
      status = 'r';
    }

    if (status) {
      TaskFinancialAPICMS.update(activityId, { status }).then(function () {
        refetchTask();
      });
    }
  };

  const handleObservation = () => {
    if (!observationValue) {
      return toast.error('Necessário enviar algum texto na observação.');
    }
    Mixpanel.track("Clicou 'Financeiro - Enviar chat'", {
      'Nome atividade': taskData.name,
      Status: isReport ? 'Ligado' : 'Desligado',
    });
    if (!observation) {
      TaskNoteAPICMS.create({
        task: taskData.id,
        note: observationValue,
      }).then(function () {
        toast.success('Observação enviada com sucesso!');
        callBackTask();
      });
    } else {
      TaskNoteAPICMS.update(observation.id, { note: observationValue }).then(function () {
        toast.success('Observação atualizada com sucesso!');
        callBackTask();
      });
    }
  };

  const handleChangeReport = () => {
    if (!managePage && isReport) {
      return toast.error('Somente o time gestor pode retirar o status de Reportado da Task.');
    }

    Mixpanel.track("Clicou 'Financeiro - Reportar'", {
      'Nome atividade': taskData.name,
      Status: !isReport ? 'Ligado' : 'Desligado',
    });
    setIsReport(!isReport);
  };

  const handleDeleteObservation = (note) => {
    Mixpanel.track("Clicou 'Financeiro - Mais opções Chat - Excluir'", {
      'Nome atividade': taskData.name,
      Status: isReport ? 'Ligado' : 'Desligado',
    });
    TaskNoteAPICMS.delete(note.id).then(function () {
      toast.success('Observação excluída com sucesso');
      refetchTaskNote();
    });
  };

  const handleEditObservation = (note) => {
    Mixpanel.track("Clicou 'Financeiro - Mais opções Chat - Enviar'", {
      'Nome atividade': taskData.name,
      Status: isReport ? 'Ligado' : 'Desligado',
    });
    setObservation(note);
    setObservationValue(note.note);
  };

  const handleChangeObservationInput = (event) => {
    setObservationValue(event.target.value);
  };

  const returnFormatedDate = (dateStr) => {
    const date = new Date(dateStr);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const returnFormatedHours = (dateStr) => {
    const date = new Date(dateStr);

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  const returnNameInitialsBadge = (initials) => {
    return <S.InitialsName>{initials}</S.InitialsName>;
  };

  const returnNameInitials = (name) => {
    const splittedName = name.split(' ');

    if (!splittedName || splittedName.length <= 0) return '';
    if (splittedName.length === 1) {
      return returnNameInitialsBadge(splittedName[0].substring(0, 1).toUpperCase());
    }

    const first = splittedName[0].substring(0, 1).toUpperCase();
    const last = splittedName[splittedName.length - 1].substring(0, 1).toUpperCase();
    return returnNameInitialsBadge(first + last);
  };

  return (
    <Drawer
      open={show}
      onClose={close}
      anchor="right"
      sx={{
        '& .MuiDrawer-paper': {
          overflowY: 'hidden',
        },
      }}
    >
      <S.Container>
        {loadingTaskData || loadingTaskNote ? (
          <Loader />
        ) : (
          <CardComponent type="important" style={{ width: '100%' }} background="#fff">
            <S.Header>
              <CloseIcon
                onClick={close}
                style={{
                  position: 'absolute',
                  top: '24px',
                  right: '24px',
                  fill: '#ACACAE',
                  cursor: 'pointer',
                  width: '24px',
                  height: '24px',
                }}
              />
              <S.Title>{taskData?.name}</S.Title>
            </S.Header>

            <S.ObservationContainer>
              <>
                <S.TitleContainer>
                  <S.ObservationTitle>Escreva uma atualização</S.ObservationTitle>
                  {(taskData.status === 'a' || taskData.status === 'r') && (
                    <S.ContainerSwitch>
                      <S.SwitchButton checked={isReport} onClick={() => handleChangeReport()}>
                        <S.CircleButton checked={isReport} />
                      </S.SwitchButton>
                      <S.SwitchLabel>Reportar</S.SwitchLabel>
                    </S.ContainerSwitch>
                  )}
                </S.TitleContainer>
                <InputStyle>
                  <TextField
                    type="text"
                    id={`input-component-${activityId}`}
                    label="Adicione alguma observação"
                    value={observationValue}
                    variant="outlined"
                    placeholder="Adicione uma observação"
                    onChange={handleChangeObservationInput}
                    style={{ minHeight: '100px' }}
                  />
                </InputStyle>
              </>
              <S.ButtonContainer>
                <S.Button onClick={handleObservation}>
                  <S.StyledSendIcon /> Enviar
                </S.Button>
              </S.ButtonContainer>
            </S.ObservationContainer>

            <S.NotesContainer>
              {notes?.map((note) => {
                return (
                  <>
                    <S.NotesHeader>
                      {returnNameInitials(note.note_user.name)}
                      <S.ContainerColumn>
                        <S.NoteUser>{note.note_user.name}</S.NoteUser>
                        <S.NoteDate>{returnFormatedDate(note.updated_at)}</S.NoteDate>
                      </S.ContainerColumn>
                      <S.ContainerRowRight>
                        <S.NoteDate>{returnFormatedHours(note.updated_at)}</S.NoteDate>
                        {note.user === user.id && (
                          <S.PopperContainer>
                            <ButtonWithPopper minWidth="120px" popperStyle={{ zIndex: '1201' }}>
                              <S.ButtonPopper onClick={() => handleEditObservation(note)}>
                                Editar
                              </S.ButtonPopper>
                              <S.ButtonPopper onClick={() => handleDeleteObservation(note)}>
                                Excluir
                              </S.ButtonPopper>
                            </ButtonWithPopper>
                          </S.PopperContainer>
                        )}
                      </S.ContainerRowRight>
                    </S.NotesHeader>
                    <S.ContainerRow>
                      <S.NoteText>{note.note}</S.NoteText>
                    </S.ContainerRow>
                  </>
                );
              })}
            </S.NotesContainer>
          </CardComponent>
        )}
      </S.Container>
    </Drawer>
  );
}
