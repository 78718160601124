import React, { useEffect, useState } from 'react';
import { Mixpanel } from '~/services/analytics';
import AddSpecialties from './AddSpecialties';
import ListSpecialties from './ListSpecialties';
import TotalQuestions from './TotalQuestions';
import { Container } from './styles';
import { optionsSpecialtiesMock, questionsSelectedsMock } from './mock';

const SelectionQuestions = ({
  setQuestionsSelected,
  setNumberQuestions,
  allowDrag = true,
  hiddenRemoveIcon = true,
}) => {
  const [questionsSelecteds, setQuestionsSelecteds] = useState(questionsSelectedsMock);
  const [totalQuestionsSelecteds, setTotalQuestionsSelecteds] = useState(0);

  useEffect(() => {
    if (questionsSelecteds) {
      setQuestionsSelected(questionsSelecteds);
      const _questionsSelectedsReduced = sumAllQuestions(questionsSelecteds);
      setNumberQuestions(_questionsSelectedsReduced);
      setTotalQuestionsSelecteds(_questionsSelectedsReduced);
    }
  }, [questionsSelecteds]);

  const AddedSpecialties = (value) => {
    value.value = 10;
    value.order = questionsSelecteds.length + 1;

    if (questionsSelecteds[questionsSelecteds.length - 1]?.values[1]) {
      value.values = [
        questionsSelecteds[questionsSelecteds.length - 1].values[1] + 1,
        questionsSelecteds[questionsSelecteds.length - 1].values[1] + 10,
      ];
    } else {
      value.values = [1, 10];
    }

    // this was done because of a bug in react
    const newValue = { ...value };
    setQuestionsSelecteds((current) => [...current, newValue]);

    Mixpanel.track("Clicou 'Nova prova - Adicionar especialidade'", {
      'Especialidade adicionada': newValue.name,
    });
  };

  const sumAllQuestions = (value) => {
    return value.reduce((acc, item) => {
      if (item.value) {
        return acc + item.value;
      }
      return acc;
    }, 0);
  };

  const setNewOrderQuestions = (value) => {
    return value.map((item, index) => {
      if (index === 0) {
        if (item.values[0] !== 1) {
          item.values = [1, item.value];
        } else {
          item.values[1] = item.value || 0;
        }
      } else {
        const previuesValue = value[index - 1].values[1] + 1;
        item.values = [previuesValue, (item.value || 0) + previuesValue - 1];
      }
      return item;
    });
  };

  const removeSpecialtie = (index) => {
    Mixpanel.track("Clicou 'Nova prova - Remover especialidade", {
      'Especialidade removida': questionsSelecteds[index].name,
    });
    const _questionsSelected = [...questionsSelecteds];
    _questionsSelected.splice(index, 1);
    setNewOrderQuestions(_questionsSelected);
    setQuestionsSelecteds(_questionsSelected);
  };

  const setChangeValueOption = (data) => {
    const newValues = [...data];
    setQuestionsSelecteds(newValues);
  };

  const onSortOptions = (dragItem, dragOverItem) => {
    const _itemsOptions = [...questionsSelecteds];
    const draggedItemContent = _itemsOptions.splice(dragItem, 1)[0];
    _itemsOptions.splice(dragOverItem, 0, draggedItemContent);
    setNewOrderQuestions(_itemsOptions);
    setQuestionsSelecteds(_itemsOptions);
    Mixpanel.track("Clicou 'Nova prova - Reposicionar especialidade'", {
      Especialidade: _itemsOptions[dragOverItem].name,
      Posicionamento: dragOverItem + 1,
    });
  };

  return (
    <>
      <Container>
        <div className="list">
          <ListSpecialties
            allowDrag={allowDrag}
            options={questionsSelecteds}
            removeSpecialtie={removeSpecialtie}
            hiddenRemoveIcon={hiddenRemoveIcon}
            onSortOptions={onSortOptions}
            setChangeValueOption={setChangeValueOption}
            setOptions={(value) => {
              const newOrderQuestions = setNewOrderQuestions(value);
              const _questionsSelectedsSummed = sumAllQuestions(newOrderQuestions);
              setTotalQuestionsSelecteds(_questionsSelectedsSummed);
            }}
          />
          <AddSpecialties options={optionsSpecialtiesMock} addedSpecialties={AddedSpecialties} />
        </div>
      </Container>
    </>
  );
};

export default SelectionQuestions;
