import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactComponent as DropsChevron } from '~/assets/drops-chevron.svg';
import * as S from './styles';

export default function MultiselectAutocompleteWithList({
  title,
  selectedOptions,
  setSelectedOptions,
  placeholder,
  options,
  fetchSuggestions,
  loading,
  multiSelectLimit = 5,
  disabled,
  selectDescription,
}) {
  const updateInput = (input) => {
    if (input) {
      fetchSuggestions(input, options);
    }
  };

  const onDelete = (label) => {
    setSelectedOptions((option) => option.filter((opt) => opt.label !== label));
  };

  return (
    <S.Container>
      <S.TitleContainer>
        {!!title && title}
        <S.SelectContainer>
          <Autocomplete
            value={selectedOptions}
            loading={loading}
            multiple
            clearText="Limpar"
            closeText="Fechar"
            loadingText="Carregando..."
            noOptionsText="Nenhuma opção encontrada"
            id="tags-outlined"
            options={options}
            getOptionLabel={(option) => option.label}
            filterSelectedOptions
            onChange={(_, value) => setSelectedOptions(value)}
            onInputChange={(event) => updateInput(event?.target?.value)}
            getOptionDisabled={() => selectedOptions.length >= multiSelectLimit}
            disabled={disabled}
            fullWidth
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '8px',
                      border: '2px solid #E5E8EE',
                    },
                    '&:hover.Mui-disabled fieldset': {
                      border: '2px solid #E5E8EE',
                      boxShadow: 'none',
                    },
                    '&:hover fieldset': {
                      border: '3px solid #3D5582',
                      boxShadow: '0px 0px 0px 6px rgba(61, 85, 130, 0.2)',
                    },
                    '&.Mui-focused fieldset': {
                      border: '3px solid #01cfb5',
                      boxShadow: '0px 0px 0px 6px rgba(1, 207, 181, 0.2)',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: '#077859',
                    },
                  },
                  '.MuiAutocomplete-endAdornment svg': {
                    width: '22px',
                    height: '22px',
                  },
                  '.MuiAutocomplete-endAdornment:hover svg': {
                    path: { fill: '#01cfb5' },
                  },
                  '.MuiAutocomplete-endAdornment:hover .MuiAutocomplete-clearIndicator path': {
                    fill: '#0000008a',
                  },
                }}
              />
            )}
            renderTags={() => null}
            popupIcon={<DropsChevron />}
          />
          {selectDescription && <S.SelectDescription>{selectDescription}</S.SelectDescription>}
        </S.SelectContainer>
      </S.TitleContainer>

      <S.ListContainer>
        {selectedOptions.map((option) => (
          <S.ListItemContainer key={option.label}>
            <S.ListItem>
              <S.ListLabel>{option.label}</S.ListLabel>
              <S.RemoveButton onClick={() => onDelete(option.label)}>
                <S.StyledRemoveIcon />
                Desvincular
              </S.RemoveButton>
            </S.ListItem>
          </S.ListItemContainer>
        ))}
      </S.ListContainer>
    </S.Container>
  );
}

MultiselectAutocompleteWithList.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.object.isRequired,
};

MultiselectAutocompleteWithList.defaultProps = {
  placeholder: '',
  options: [],
};
