import React, { useEffect, useRef, useState, useMemo } from 'react';

import { groupArrayByProp } from '~/utils/util';
import ItemSpecialties from './ItemSpecialties';
import { optionsSpecialtiesMock } from './mock';

import { Container } from './styles';

const ListSpecialitiesAndQuestions = ({ questions, setQuestions, canAction }) => {
  const [mappedQuestions, setMappedQuestions] = useState([]);
  const [mappedSpecialities, setMappedSpecialities] = useState([]);
  const dragQuestionItem = useRef(null);
  const dragOverQuestionItem = useRef(null);

  const filterSpecialties = (specialties, options) => {
    const specialtiesKeys = Object.keys(specialties);
    return options.filter((option) => specialtiesKeys.includes(option.name));
  };

  const sortedQuestions = useMemo(() => {
    if (!questions) return {};
    return groupArrayByProp(questions, 'speciality_name');
  }, [questions]);

  const filteredSpecialties = useMemo(() => {
    return filterSpecialties(sortedQuestions, optionsSpecialtiesMock);
  }, [sortedQuestions, optionsSpecialtiesMock]);

  useEffect(() => {
    setMappedSpecialities(filteredSpecialties);
    setMappedQuestions(sortedQuestions);
  }, [filteredSpecialties, sortedQuestions]);

  const onDragQuestionStart = (index) => {
    dragQuestionItem.current = index;
  };

  const onDragQuestionEnter = (index) => {
    dragOverQuestionItem.current = index;
  };

  return (
    <Container>
      {mappedSpecialities &&
        mappedSpecialities.map((item, index) => {
          return (
            <ItemSpecialties
              option={item}
              name={item.name}
              key={`${item.name}-${index}`}
              onDragQuestionStart={onDragQuestionStart}
              onDragQuestionEnter={onDragQuestionEnter}
              allQuestions={mappedQuestions}
              setMappedQuestions={setMappedQuestions}
              setQuestions={setQuestions}
              dragQuestionItem={dragQuestionItem}
              dragOverQuestionItem={dragOverQuestionItem}
              canAction={canAction}
            />
          );
        })}
    </Container>
  );
};

export default ListSpecialitiesAndQuestions;
