import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import { MdChevronLeft } from 'react-icons/md';
import * as Yup from 'yup';

import Firebase from '~/config/Firebase';

import logo from '~/assets/logotipo.svg';

import { Logo, ButtonDiv, Lock, Description, useStyles } from './styles';

import LockImage from '~/assets/lock.png';

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);

  function handleSubmit({ email }) {
    if (email && email.trim() !== '') {
      setLoading(true);
      Firebase.auth()
        .sendPasswordResetEmail(email.trim())
        .then((result) => {
          toast.success('Foi enviado um e-mail com as instruções.');
          setLoading(false);
        })
        .catch(() => {
          toast.error('Não foi possível recuperar a senha.');
          setLoading(false);
        });
    } else {
      toast.warning('Preencha o e-mail');
    }
  }

  const schema = Yup.object().shape({
    email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  });

  const classes = useStyles();
  return (
    <>
      <Form schema={schema} onSubmit={handleSubmit}>
        <Logo src={logo} alt="Medway" />
        <Lock>
          <img className={classes.lockImg} src={LockImage} alt="Esqueceu a senha ?" />
        </Lock>

        <title>
          <h1>Esqueceu a Senha ?</h1>
        </title>
        <Description>
          <span>
            Deixa que te ajudamos. Digite abaixo o e-mail que você se cadastrou para que possamos
            enviar um link com uma nova senha.
          </span>
        </Description>

        <Input
          className="textInput"
          style={{ width: '70%', margin: 'auto' }}
          name="email"
          type="email"
          placeholder="Digite Seu E-mail"
        />
        <ButtonDiv>
          <Link to="/">
            <MdChevronLeft size={36} color="#00CFB4" />
            Voltar
          </Link>
          <button type="submit">{loading ? 'Carregando...' : 'Enviar'}</button>
        </ButtonDiv>
      </Form>
    </>
  );
}
