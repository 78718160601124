import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  left: 63px;
  top: 64px;
`;

export const QuestionsNavigation = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 80px;
  width: 94px;
  height: 100vh;
  padding-left: 7px;
  justify-content: center;
  background-color: #b2b2d4;
  overflow: auto;

  z-index: 90;
`;

export const QuestionNavContainer = styled.div`
  display: flex;
  position: relative;
  height: 44px;
  width: 44px;
  border-radius: 8px;
  background-color: ${(props) => (props.selected ? 'rgba(245,245,251,0.3);' : '#b2b2d4')};
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const QuestionsCancelTag = styled.div`
  height: 10px;
  width: 10px;
  position: absolute;
  top: 5px;
  right: 5px;
`;
export const QuestionsTag = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 2.5px;
  background-color: #3b3fb6;
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const QuestionNavNumber = styled.div`
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: center;
`;

export const Scroll = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(59, 63, 182, 0.1);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(59, 63, 182, 0.2);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(59, 63, 182, 0.4);
  }
  ::-webkit-scrollbar-thumb:active {
    background: rgba(59, 63, 182, 0.9);
  }
`;
