import styled from 'styled-components';

export const OriginalInstitution = styled.div`
  color: #767676;
  font-weight: 600;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.45px;
  line-height: 13px;
  margin-top: 5px;
`;

export const QuestionNumber = styled.div`
  color: #3b3fb6;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
`;

export const QuestionTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const QuestionTopLeft = styled.div``;

export const QuestionTopRight = styled.div`
  display: flex;
  flex-direction: row;
`;


export const Tag = styled.div`
  display: flex;
  height: 21px;
  border-radius: 8.5px;
  background-color: #3b3fb6;
  justify-content: center;
  align-items: center;
`;

export const TagName = styled.div`
  height: 13px;
  color: #fff;
  font-weight: bold;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 13px;
`;

export const EditEnunciated = styled.div`
  display: flex;
  float: right;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 15px;
  background-color: #eee;
  transition: all .2s linear;

  &:hover {
    background-color: #fff;
  }
`;