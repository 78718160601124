import React from 'react';
import GreenBorder from '~/components/GreenBorder';
import { titles } from '../../mocks/mock';

import * as S from './styles';

function Forms({ content }) {
  return (
    <S.Container>
      <GreenBorder />
      {titles.map((title, titleIndex) => {
        return (
          <div key={`section_${titleIndex}_${title.number}_${title.title}`}>
            <S.Section>
              <S.TitleContainer>
                <S.NumberIndicator>{title.number}</S.NumberIndicator>
                <S.Title>{title.title}</S.Title>
              </S.TitleContainer>
              {content[titleIndex].length > 0 &&
                content[titleIndex].map((component, componentIndex) => {
                  return (
                    <div key={`subsection_${componentIndex}`}>
                      {(component.title || component.subtitle) && (
                        <S.ContentTitleContainer>
                          {component.title && <S.ContentTitle>{component.title}</S.ContentTitle>}
                          {component.subtitle && (
                            <S.ContentSubtitle>{component.subtitle}</S.ContentSubtitle>
                          )}
                        </S.ContentTitleContainer>
                      )}
                      <S.Items
                        hasTitle={!!component.title}
                        borderBottom={component.borderBottom}
                        shortPadding={component.shortPadding}
                      >
                        {component.items.map((item, componentItemIndex) => {
                          return (
                            <S.ItemContainer key={`item_container_${componentItemIndex}`}>
                              {item.subtitle && <S.Subtitle>{item.subtitle}</S.Subtitle>}
                              <S.InputsContainer fullWidth={item.fullWidth}>
                                {item.items.map((component, componentIndex) => {
                                  return <div key={`component_${componentIndex}`}>{component}</div>;
                                })}
                              </S.InputsContainer>
                            </S.ItemContainer>
                          );
                        })}
                      </S.Items>
                    </div>
                  );
                })}
            </S.Section>
            {titles.length !== titleIndex + 1 && <S.StyledHr />}
          </div>
        );
      })}
    </S.Container>
  );
}

export default Forms;
