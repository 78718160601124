import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  padding: 48px;
  max-width: 1388px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const FilterContainer = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Header = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const Filter = styled.div`
  max-width: 604px;
  width: 100%;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  gap: 10px;

  input {
    width: 100%;
    height: 19px;
    border: none;
    background: none;

    ::placeholder {
      color: #3d5582;
      font-weight: 400;
      font-size: 14.2px;
      line-height: 19px;
    }
  }
`;

export const ButtonFilter = styled.button`
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }
`;

export const ListChapterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardBook = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
`;

export const HeaderCardBook = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const InfoBook = styled.div`
  flex-wrap: wrap;
  padding-right: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

export const TitleChapter = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #00205b;
`;

export const SubjectName = styled.span`
  border: 1px solid #00205b;
  border-radius: 1000px;
  padding: 4px 8px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
`;

export const CouserName = styled(SubjectName)`
  background-color: #e5e8ee;
  border: 1px solid #e5e8ee;
`;

export const Controls = styled.div`
  display: flex;
  gap: 24px;
`;

export const Publish = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    font-weight: 400;
    font-size: 14.2px;
    line-height: 19px;
    color: ${(props) => (props.published ? '#00205B' : '#d1d7e1')};
  }
`;

export const SwitchButton = styled.label`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin-bottom: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    :checked + span {
      background-color: #01cfb5;
    }

    :checked + span:before {
      transform: translateX(-12px);
    }
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d1d7e1;
    border-radius: 12px;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    :before {
      position: absolute;
      content: '';
      height: 12px;
      width: 12px;
      right: 4px;
      bottom: 4px;
      background-color: white;
      border-radius: 50%;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
`;

export const ButtonView = styled.div`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }
`;

export const ButtonEdit = styled.div`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: none;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #01c3aa;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #e5faf7;
  }
`;

export const FooterCardBook = styled.div`
  border-top: 1px solid #e5e8ee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AvatarImage = styled.div`
  background: ${(props) => `url(${props.url}) #F0F2F5`};
  border-radius: 1000px;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserName = styled.span`
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
`;

export const LastModified = styled.span`
  font-weight: 400;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
`;

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 12.6px;
    line-height: 17px;
    color: #001e56;
  }
`;

export const Pagination = styled.div`
  display: flex;
`;
export const ButtonPagination = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: none;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #01c3aa;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #e5faf7;
  }

  :disabled {
    color: #d1d7e1;
    cursor: default;
    background: none;

    svg {
      path {
        fill: #d1d7e1;
      }
    }
  }
`;
