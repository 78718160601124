export function updateEndpoints(data) {
  return {
    type: '@endpoints/UPDATE_ENDPOINTS',
    payload: { data },
  };
}

export function updateEndpointsSuccess(endpoints) {
  return {
    type: '@endpoints/UPDATE_ENDPOINTS_SUCCESS',
    payload: { endpoints },
  };
}

export function updateEndpointsFailure() {
  return {
    type: '@endpoints/UPDATE_ENDPOINTS_FAILURE',
  };
}
