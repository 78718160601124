import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	padding: 32px 56px;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	overflow-y: auto;
	padding-bottom: 300px;
`;

export const Step = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 24px;
	width: 100%;
	height: 40px;
	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
`;

export const StepContainer = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex: none;
	order: 1;
	align-self: auto;
	flex-grow: 0;
	z-index: 0;
`;
export const StepText = styled.span`
	height: 31px;
	font-style: normal;
	font-weight: 600;
	font-size: 22.8px;
	line-height: 30px;
	color: #00205b;
	flex: none;
	order: 0;
	flex-grow: 0;
`;

export const StepOder = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 40px;
	height: 40px;
	background: #01c3aa;
	color: #ffffff;
	border-radius: 1000px;
	flex: none;
	order: 0;
	flex-grow: 0;
	font-style: normal;
	font-weight: 600;
	font-size: 20.3px;
	line-height: 36px;
	color: #ffffff;
	flex: none;
	order: 0;
	flex-grow: 0;
`;

export const Label = styled.div`
	height: 27px;
	width: 100%;
	font-style: normal;
	font-weight: 600;
	font-size: 20.3px;
	line-height: 27px;
	color: #00205b;
	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
	margin-bottom: 20px;
`;

export const InputStyle = styled.div`
	width: 100%;
	height: 48px;
	border: 2px solid #e5e8ee;
	border-radius: 8px;
	background: #f0f2f5;
	padding: 7px 16px;
	display: flex;
	:hover {
		box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
		background-color: #fff;
		border: 3px solid #3d5582;
	}
	:focus-within {
		box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
		background-color: #fff;
		border: 3px solid #01cfb5;
	}
	input {
		width: 100%;
		height: 30px;
		border: none;
		background: none;
		color: #3d5582;
		font-size: 16px;

		::placeholder {
			color: #3d5582;
			font-weight: 400;
		}
		::-webkit-inner-spin-button {
			color: red;
		}
	}
`;

export const RowContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	flex: none;
	order: 2;
	align-self: stretch;
	flex-grow: 0;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	margin: 20px 0px;
`;

export const CustomInputFile = styled.div`
	display: flex;
	border: 2px solid #e5e8ee;
	border-radius: 8px;
	cursor: pointer;
	width: 544px;
	:hover {
		box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
		background-color: #fff;
		border: 3px solid #3d5582;
	}
	:active {
		box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
		background-color: #fff;
		border: 3px solid #01cfb5;
		color: #01cfb5;
	}
`;

export const FileName = styled.div`
	background: #f0f2f5;
	border: transparent;
	border-radius: 6px 0 0 6px;
	height: 48px;
	width: 100%;
	padding-inline: 20px;
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #3d5582;
	:hover {
		background-color: #fff;
	}
`;

export const ButtonSelectFile = styled.div`
	border-left: 2px solid #e5e8ee;
	border-radius: 0 8px 8px 0;
	width: 127px;
	height: 48px;
	padding: 13px 20px;
	background-color: #fff;
	font-size: 16px;
`;
