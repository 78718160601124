import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  width: 568px;
`;

export const Header = styled.div`
  position: relative;
  padding: 48px 56px 24px;
  background: #ffffff;
  border-bottom: 1px solid #e5e8ee;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #00205b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div`
  overflow-y: auto;
  height: calc(100vh - 100px);
`;

export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const ContentItem = styled.div`
  height: 48px;
  display: flex;
  gap: 8px;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentTitle = styled.h1`
  margin: 0;
  text-transform: uppercase;
  width: 180px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #2a2c30;
  flex-shrink: 0;
`;

export const ContentDescription = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${(props) => (props.error ? '#EE1F38' : '#2a2c30')};
`;

export const LinkButton = styled.button`
  border: none;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 2px solid #e5e8ee;
  border-radius: 320px;
  background: #fff;

  :hover {
    border-color: #d1d7e1;
  }
`;

export const LinkButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ObservationContainer = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid #e5e8ee;
`;

export const ObservationTitle = styled.p`
  margin: 0;
  color: #2a2c30;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PopperContainer = styled.div`
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonPopper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  min-height: 48px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00205b;
  background: #fff;
  border: none;
`;
