import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@mui/material/Typography';

import { GoEye } from 'react-icons/go';
import ProgressBar from '~/components/ProgressBar';
import Tags from '~/components/Tags';
import Checkbox from '~/components/Checkbox';

import WarningIcon from '~/assets/drops-warning-soft.svg';

import * as S from './styles';

function TrackItem({ track, selectedItems, setSelectedItems }) {
	const handleCheck = (id) => {
		if (selectedItems.includes(id)) {
			const selectedWithRemovedId = selectedItems.filter((item) => item !== id);

			setSelectedItems(selectedWithRemovedId);
			return;
		}

		setSelectedItems((checkedQuestions) => [...selectedItems, id]);
		return;
	};

	const opentrack = (id) => {
		window.open(`${process.env.REACT_APP_URL}/exam/${id}`, '_blank');
	};

	const getTag = (questionCount, questionObjectiveCount) => {
		if (questionCount > questionObjectiveCount) {
			return 'Objetiva + Discursiva';
		}
		if (questionCount === questionObjectiveCount) {
			return 'Objetiva';
		}
		return 'Discursiva';
	};

	const getPrePosTag = (type) => {
		if (type === 'pre_track') {
			return 'Trilha Pré';
		}

		if (type === 'pos_track') {
			return 'Trilha Pós';
		}

		return '';
	};

	function hasPendenciesExtra() {
		if (
			track.all_questions_have_same_tags &&
			track.all_questions_have_same_speciality &&
			track.all_questions_have_same_residency
		)
			return false;
		else return true;
	}

	function getTooltip() {
		let tooltip = '';
		if (!track.all_questions_have_same_tags) tooltip += 'Tem mais de um foco \n';
		if (!track.all_questions_have_same_speciality) tooltip += 'Tem mais de uma especialidade \n';
		if (!track.all_questions_have_same_residency)
			tooltip += 'Tem mais de um nível de residência \n';

		return tooltip;
	}

	return (
		<S.ItemContainer>
			<S.ItemHeader>
				<Checkbox
					id={track.id}
					label=""
					checked={selectedItems.includes(track.id)}
					onChange={() => handleCheck(track.id)}
					value={track.id}
					styleContainer={{ margin: '5px 0 0', width: '21px' }}
					styleLabel={{ marginBottom: 0 }}
					checkboxOutineColor="#ccc"
				/>
				{hasPendenciesExtra(track) && (
					<S.TooltipDiv>
						<Tooltip
							title={
								<Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
									{getTooltip(track)}
								</Typography>
							}
							placement="top-start"
							enterDelay={300}
							arrow
						>
							<img src={WarningIcon} alt="Warning icon" style={{ cursor: 'pointer' }} />
						</Tooltip>
					</S.TooltipDiv>
				)}
				<S.FlexColumn>
					<S.ItemLabel onClick={() => handleCheck(track.id)}>
						{`[${track.id}] - ${track.name}`}
					</S.ItemLabel>
					<S.ItemSubtitle>{track.questions_count} questões</S.ItemSubtitle>
				</S.FlexColumn>
				<S.QuestionsTag>
					{getTag(track.questions_count, track.question_objective_count)}
				</S.QuestionsTag>
				{track.chapter_pre_pos_track !== '' && (
					<S.PrePosTag>{getPrePosTag(track.chapter_pre_pos_track)}</S.PrePosTag>
				)}

				<S.SeeItem onClick={() => opentrack(track.id)}>
					<GoEye />
					{'Visualizar'}
				</S.SeeItem>
			</S.ItemHeader>

			<S.ItemFooter>
				{track.courses.slice(0, 4)?.map((course, index) => (
					<S.CouserName key={index}>{course.name}</S.CouserName>
				))}
			</S.ItemFooter>
		</S.ItemContainer>
	);
}

export default TrackItem;
