export function requestChapter(params) {
  return {
    type: '@chapter/REQUEST_CHAPTER',
    params,
  };
}

export function setListChapter({ listChapter }) {
  return {
    type: '@chapter/SET_LIST_CHAPTER',
    payload: { listChapter },
  };
}

export function requestChapterSelected(id) {
  return {
    type: '@chapter/REQUEST_CHAPTER_SELECTED',
    id,
  };
}

export function setListChapterSelected({ listChapterSelected }) {
  return {
    type: '@chapter/SET_LIST_CHAPTER_SELECTED',
    payload: { listChapterSelected },
  };
}

export function clearChapter() {
  return {
    type: '@chapter/CLEAR_CHAPTER',
  };
}
