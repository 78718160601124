/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ReactComponent as StarIcon } from '~/assets/rating-star.svg';

import * as S from './styles';

export default function RatingFeedbackComponent({ averageGrade, evaluationsNumber }) {
  return (
    <S.Container>
      <StarIcon />
      <S.BoldLabel>{`${Math.trunc(averageGrade * 100) / 100}`}</S.BoldLabel>
      <S.Label>{`(${evaluationsNumber} ${
        evaluationsNumber <= 1 ? 'avaliação' : 'avaliações'
      })`}</S.Label>
    </S.Container>
  );
}
