import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function Checkbox({
  checked,
  label,
  labelColorChecked,
  labelColorUnChecked,
  onChange,
  backgroundColorChecked,
  checkColor,
  borderColorChecked,
  styleContainer,
  disabled,
  showLabel,
  styleLabel,
  checkboxOutineColor,
}) {
  return (
    <Styled
      onClick={() => {
        if (!disabled) {
          onChange(!checked);
        }
      }}
      style={styleContainer}
      backgroundColorChecked={backgroundColorChecked}
      labelColorChecked={labelColorChecked}
      labelColorUnChecked={labelColorUnChecked}
      borderColorChecked={borderColorChecked}
      checkColor={checkColor}
      checkboxOutineColor={checkboxOutineColor}
    >
      <input
        type="checkbox"
        name={label}
        checked={checked}
        disabled={disabled}
        onChange={() => onChange(!checked)}
      />
      {showLabel && (
        <label htmlFor={label} style={styleLabel}>
          {label}
        </label>
      )}
    </Styled>
  );
}
Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  labelColorChecked: PropTypes.string,
  labelColorUnChecked: PropTypes.string,
  backgroundColorChecked: PropTypes.string,
  borderColorChecked: PropTypes.string,
  styleContainer: PropTypes.shape(),
  disabled: PropTypes.bool,
  showLabel: PropTypes.bool,
  checkColor: PropTypes.string,
  styleLabel: PropTypes.shape(),
  checkboxOutineColor: PropTypes.string,
};

Checkbox.defaultProps = {
  label: 'Checkbox',
  labelColorChecked: null,
  labelColorUnChecked: null,
  onChange: () => {},
  checked: false,
  borderColorChecked: null,
  backgroundColorChecked: null,
  checkColor: null,
  styleContainer: { marginBottom: 30 },
  disabled: false,
  showLabel: true,
  styleLabel: {},
  checkboxOutineColor: null,
};
export default Checkbox;

const Styled = styled.div`
  display: flex;
  flex-direction: row;
  > input {
    opacity: 0;
    position: absolute;
  }

  > input + label {
    position: relative;
    padding-left: 35px;
    height: 15px;
    color: ${(props) => (props.labelColorUnChecked ? props.labelColorUnChecked : '#767676')};
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 15px;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 21px;
      height: 21px;
      border: 1px solid
        ${(props) => (props.checkboxOutineColor ? props.checkboxOutineColor : '#eee')};
      background: #fff;
      border-radius: 5px;
    }
    &:hover::before {
      box-shadow: 0px 0px 0px 4px rgba(61, 85, 130, 0.2);
      border: 2px solid #3d5582;
    }
    &:hover::after {
      box-shadow: 0px 0px 0px 4px rgba(1, 207, 181, 0.2);
      border: 2px solid #01cfb5;
    }
    &:after {
      content: '✔';
      position: absolute;
      bottom: 0px;
      left: 0;
      padding-top: 2px;
      padding-left: 2px;
      width: 21px;
      height: 21px;
      font-size: 16px;
      color: ${(props) => (props.checkColor ? props.checkColor : '#01CFB5')};
      border: ${(props) =>
        props.borderColorChecked ? `2px solid ${props.borderColorChecked}` : '2px solid #D1D7E1'};
      border-radius: 5px;
      background: ${(props) =>
        props.backgroundColorChecked ? props.backgroundColorChecked : '#ffffff'};
      transition: all 0.2s;
    }

    label {
      padding-top: 5px;
      font-size: 16px;
      letter-spacing: 0.62px;
      line-height: 19px;
    }
  }

  > input:not(:checked) + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }
  > input:disabled:not(:checked) + label {
    &:before {
      box-shadow: none;
      border-color: #ccc;
      background-color: #ddd;
      cursor: not-allowed;
    }
  }

  > input:checked + label {
    color: ${(props) => (props.labelColorChecked ? props.labelColorChecked : '#00205B')};
    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
  > input:disabled:checked + label {
    &:after {
      color: #fff;
      background-color: #bbb;
      border: 1px solid #bbb;
    }
  }
  > input:disabled + label {
    color: #343434;
  }
  > input:checked:focus + label,
  input:not(:checked):focus + label {
    &:before {
      border: 1px dotted blue;
    }
  }
`;
