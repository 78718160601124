import styled from 'styled-components';
import {ReactComponent as ArrowLeft} from '~/assets/drops-arrow-left.svg';
import { ReactComponent as WarningIcon } from '~/assets/flashcards-warning.svg';

export const ModalContent = styled.div`
  padding: 32px 56px;
  border-bottom: 1px solid #E5E8EE;
`;

export const ModalText = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  color: #4F5154;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 24px;
  width: 100%;
`;

export const FillButton = styled.button`
  display: flex;
  border-radius: 320px;
  background: #0CAA87;
  height: 40px;
  width: 100%;
  padding: 10px 16px;

  justify-content: center;
  align-items: center;
  color: #fff;

  font-size: 14px;
  font-weight: 600;
  border: none;
  gap: 10px;

  :hover {
    background: #01cfb5;
  };

  :disabled {
    color: #8F9DB7;
    background: #E5E8EE;
    cursor: default;
  }
`;

export const BackButton = styled.button`
  padding: 10px 16px;
  gap: 10px;
  display: flex;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: #077859;
  background: #fff;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 320px;

  :hover {
    background: #DAF5F1;
  }
`;

export const StyledArrowLeft = styled(ArrowLeft)`
  path {
    fill: #077859;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 60px;
`;

export const EssayContainer = styled.div`
  padding: 48px 32px;
  width: 100%;
  display: flex;
  gap: 24px;
`;

export const EmptyContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  width: 32px;
  height: 32px;
  path {
    fill: #00205B;
  }
`;

export const ButtonAddFlashcard = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 48px;
  padding: 0 20px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

	:hover {
		background: #01cfb5;
	};
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const tagCommonStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '26px',
  color: '#00205B',
  fontSize: '14.2px',
  fontWeight: 600,
  lineHeight: '18.96px',
  textAlign: 'center',
  padding: '0 10px',
  borderRadius: '1000px',
  flexShrink: 0,
};

export const SpecialityTag = styled.div`
  background: #E5E8EE;
  ${{...tagCommonStyles}}
`;

export const ResidencyDegreeTag = styled.div`
  border: 1px solid #00205B;
  ${{...tagCommonStyles}}
`;