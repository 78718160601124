import Axios from 'axios';

import { QuestionAPICMS, NextAPICMS } from '~/services/apiCallsCMS';

export async function loadMore(next, questions) {
  const source = Axios.CancelToken.source();
  const { data } = await NextAPICMS.get(next, source.token);
  let questionsRaw = [];
  let nextRaw = null;

  if (data && data.results) {
    data.results.forEach((element) => {
      if (element.speciality_name) {
        element.specialities = element.speciality_name.join(',');
      }

      questionsRaw = [...questions, ...data.results];
      nextRaw = data.next;
    });
  }

  return {
    questionsRaw,
    nextRaw,
  };
}

export async function loadQuestions(trackId, ordering, action) {
    return QuestionAPICMS.list({ action, ordering, track: trackId }).then((res) => {
      const { data } = res
      let questionsRaw = [];
      let nextRaw = null;
      if (data && data.results) {
        data.results.forEach((element) => {
          if (element.speciality_name) {
            element.specialities = element.speciality_name.join(',');
          }
        });
    
        questionsRaw = data.results;
        nextRaw = data.next;
      }

      return {
        questionsRaw,
        nextRaw,
      };
    })
}
