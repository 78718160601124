import { useQuery } from '@tanstack/react-query';

import { LessonModuleItemsAPICMS } from '~/services/apiCallsCMS';

const getLessonModuleItems = async (params) => {
  try {
    const response = await LessonModuleItemsAPICMS.getLessonModuleItems(params);
    if (response && response.data) {
      const data = response.data;
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    throw error;
  }
};

export default function useGetLessonModule(params, options) {
  return useQuery({
    queryKey: ['getLessonModuleItems', params],
    queryFn: () => getLessonModuleItems(params),
    staleTime: 0,
    ...options?.config,
  });
}
