import React, { useState, useCallback, useEffect } from 'react';

import { loadTags } from '~/pages/Chapters/components/Settings/utils';
import SelectWithPagination from '~/components/SelectWithPagination';
import SelectComponent from '~/components/SelectComponent';
import { InstitutionAPICMS } from '~/services/apiCallsCMS';

import { useFlashcardsSpecialitys } from '~/hooks-querys/flashcards';
import { transformOptions } from '../util';
import * as S from './styles';

import SearchIcon from '~/assets/drops-search.svg';

const flashcardStatus = [
  {
    value: 3,
    key: 3,
    label: 'Reportado',
  },
];

function Filter({ filter, setFilter, categorys, loadingResidencyDegree }) {
  const [specialitys, setSpecialitys] = useState([]);
  const [residencyDegreee, setResidencyDegreee] = useState();

  const { data: specialitysData, isLoading: loadingSpecialities } = useFlashcardsSpecialitys({
    no_page: true,
    ordering: 'name',
    main_speciality: true,
  });

  const handleSearch = (search) => {
    setFilter({ ...filter, search: search.target.value });
  };

  const handleTag = (tag) => {
    setFilter({ ...filter, tag: tag });
  };

  const handleIdSearch = (search) => {
    setFilter({ ...filter, id: search.target.value });
  };

  const handleCategory = (category) => {
    let newFilters = {
      residency_degree: category,
    };
    const differentResidencyDegrees =
      (category.abbreviation.includes('R1') &&
        !filter.residency_degree?.abbreviation.includes('R1')) ||
      (!category.abbreviation.includes('R1') &&
        filter.residency_degree?.abbreviation.includes('R1'));

    if (differentResidencyDegrees) {
      newFilters.tag = null;
    }

    setFilter({ ...filter, ...newFilters });
  };

  const handleSpeciality = (speciality) => {
    setFilter({ ...filter, speciality: speciality });
  };

  const handleInstitution = (institution) => {
    setFilter({ ...filter, institution: institution });
  };

  const handleFlashcardStatus = (status) => {
    return setFilter({ ...filter, status: status });
  };

  useEffect(() => {
    if (!specialitysData) return;
    setSpecialitys(specialitysData.map(transformOptions));
  }, [specialitysData]);

  useEffect(() => {
    if (!filter.residency_degree) return;
    return setResidencyDegreee(filter.residency_degree.value);
  }, [filter?.residency_degree]);

  const transformInstitutionOptions = (institution) => {
    if (!institution.id || !institution.name) return;

    return {
      label: institution.state ? `${institution.name} - ${institution.state}` : institution.name,
      key: institution.id,
      value: institution.id,
      institution,
    };
  };

  const loadInstitutions = useCallback(async (search, loadedOptions, { page }) => {
    const { data } = await InstitutionAPICMS.list({
      page,
      search,
      ordering: 'name',
      residency: true,
    });

    return {
      options: data.results.map(transformInstitutionOptions),
      hasMore: data.next,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  const selectValueStyle = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <S.FlexColumn>
      <S.FlexRow>
        <S.FilterInput>
          <img src={SearchIcon} alt="Search icon" />
          <input
            name="search"
            type="text"
            value={filter.search}
            onChange={handleSearch}
            placeholder="Pesquisar"
          />
        </S.FilterInput>
        <S.FilterInput>
          <img src={SearchIcon} alt="Search icon" />
          <input
            name="search"
            type="number"
            value={filter.id}
            onChange={handleIdSearch}
            placeholder="Buscar por ID"
          />
        </S.FilterInput>
        <SelectComponent
          name="speciality"
          placeholder={loadingSpecialities ? 'Carregando...' : 'Especialidade'}
          value={filter.speciality}
          onChange={handleSpeciality}
          options={specialitys}
          width={'100%'}
          controlStyle={{ marginLeft: 0 }}
          singleValueStyle={selectValueStyle}
          placeholderStyle={selectValueStyle}
          valueContainerStyle={{ flexWrap: 'nowrap' }}
        />
        <SelectWithPagination
          name="institutions"
          height={40}
          value={filter.institution}
          onChange={handleInstitution}
          loadOptions={loadInstitutions}
          placeholder="Instituição"
          placeholderStyle={{
            display: 'flex',
            fontSize: '14px',
          }}
          valueContainerStyle={{
            whiteSpace: 'nowrap',
            flexWrap: 'nowrap',
          }}
          singleValueStyle={selectValueStyle}
          width={'100%'}
        />
      </S.FlexRow>
      <S.FlexRow>
        <SelectComponent
          name="residency-degree"
          placeholder={loadingResidencyDegree ? 'Carregando...' : 'Nível de residência'}
          value={filter.residency_degree}
          onChange={handleCategory}
          options={categorys}
          width={'100%'}
          controlStyle={{ marginLeft: 0 }}
          singleValueStyle={selectValueStyle}
          placeholderStyle={selectValueStyle}
          valueContainerStyle={{ flexWrap: 'nowrap' }}
        />
        <SelectWithPagination
          name="tag"
          height={40}
          value={filter.tag}
          onChange={handleTag}
          loadOptions={(search, loadedOptions, page) =>
            loadTags(search, loadedOptions, {
              ...page,
              new_residency_degree: filter?.residency_degree?.value,
            })
          }
          placeholder="Tags"
          placeholderStyle={{
            display: 'flex',
            fontSize: '14px',
          }}
          valueContainerStyle={{
            whiteSpace: 'nowrap',
            flexWrap: 'nowrap',
          }}
          containerWidth={'100%'}
          singleValueStyle={selectValueStyle}
          residencyDegree={residencyDegreee}
          asyncPaginateKey={`async-paginate-${residencyDegreee}`}
        />
        <SelectComponent
          name="flashcard-status"
          placeholder="Status do flashcard"
          value={filter.status}
          onChange={handleFlashcardStatus}
          options={flashcardStatus}
          width={'100%'}
          controlStyle={{ marginLeft: 0 }}
          singleValueStyle={selectValueStyle}
          placeholderStyle={selectValueStyle}
          valueContainerStyle={{ flexWrap: 'nowrap' }}
        />
      </S.FlexRow>
    </S.FlexColumn>
  );
}

export default Filter;
