import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 48px;

  .tab-list-item {
    height: 100%;
    width: 100%;
    list-style: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #00205b;
    align-items: center;
    display: flex;
    justify-content: center;

    :hover {
      border: 2px solid #e5e8ee;
      border-radius: 8px;
    }
  }

  .add-border {
    border-bottom: 1px solid #00cfb4;
  }

  .tab-list-active {
    color: #01c3aa;
  }

  .badge {
    position: absolute;
    top: 5px;
    right: 10px;
    border-radius: 50%;
    background: #01cfb5;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;

    text-align: center;
    letter-spacing: 0.4px;

    color: #ffffff;
  }

  .badge-active {
    border-radius: 50%;
    background: #3b3fb6;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;

    text-align: center;
    letter-spacing: 0.4px;

    color: #ffffff;
  }

  .tab-list-disabled {
    pointer-events: none;
    color: #D1D7E1;

    :hover {
      border: none;
    }
  }
`;

export const Border = styled.div`
  position: absolute;
  bottom: -8px;
  width: 100%;
  height: 2px;
  background: #01cfb5;
  border-radius: 1000px 1000px 0px 0px;
`;

export const StyledNumberBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  color: ${(props) => (props.active ? '#FFFFFF' : '#001C50')};
  background-color: ${(props) => (props.active ? '#01C3AA' : '#E5E8EE')};
  font-size: 12.6px;
  font-weight: 600;
  text-align: center;
  margin-left: 12px;
  border-radius: 320px;
`;
