import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as ChevronRightIcon } from '~/assets/drops-chevron-right-blue.svg';
import { ReactComponent as DragIcon } from '~/assets/drag-indicator.svg';

import Loader from '~/components/Loader';
import ProtectedContent from '~/components/ProtectedContent';

import ChildList from './ChildList';
import EditableLabel from './EditableLabel';
import Icons from './Icons';
import * as S from './styles';

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    maxWidth: 150,
    background: '#fff',
    boxShadow: '0px 2px 4px 0px #181A1E14, 0px 0px 2px 0px #181A1E29',
    color: '#181A1E',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    padding: '10px 16px',
  },
  customArrow: {
    color: '#fff',
    '&:before': {
      boxShadow: '0px 2px 4px 0px #181A1E14, 0px 0px 2px 0px #181A1E29',
    },
  },
}));

function ListAndOrder({
  parentData,
  setParentData,
  selectedParent,
  setSelectedParent,
  parentAllowDrag,
  allowEditLabel,
  childAllowDrag,
  childLoading,
  hasChild,
  updateDataName,
  updateOrderListParent,
  updateOrderListChild,
  allowEditChildLabel,
  updateDataChildName,
}) {
  const classes = useStyles();
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const onDragStart = (index) => {
    dragItem.current = index;
  };

  const onDragEnter = (index) => {
    dragOverItem.current = index;
  };

  const onSortOptions = (dragItem, dragOverItem) => {
    const _itemsOptions = [...parentData];
    const draggedItemContent = _itemsOptions.splice(dragItem, 1)[0];
    _itemsOptions.splice(dragOverItem, 0, draggedItemContent);
    setParentData(_itemsOptions);

    const ids = _itemsOptions.map((item) => item.id);
    updateOrderListParent(ids);
  };

  const handleSelectedFolder = (id) => {
    if (selectedFolder === id) {
      setSelectedFolder(null);
    } else {
      setSelectedFolder(id);
      setSelectedParent(id);
    }
  };

  useEffect(() => {
    setSelectedFolder(selectedParent);
  }, [selectedParent]);

  const RenderButton = (button, item) => {
    return (
      <S.Button
        key={`button_${button.icon}_${item.name}`}
        hasBorder={button.hasBorder}
        onClick={button.action(item.id, item.order_number)}
        disabled={button.disabled}
      >
        <Icons disabled={button.disabled} iconName={button.icon} />
        <S.ButtonText disabled={button.disabled}>{button.text}</S.ButtonText>
      </S.Button>
    );
  };

  return (
    <S.Container>
      {parentData &&
        parentData.map((item, index) => {
          const open = item?.id === selectedFolder;
          return (
            <S.ItemContent key={`draggable_item_${item.id}`}>
              <S.CardContainer
                draggable={parentAllowDrag}
                onDragStart={() => onDragStart(index)}
                onDragEnter={() => onDragEnter(index)}
                onDragEnd={() => onSortOptions(dragItem.current, dragOverItem.current)}
              >
                <S.LeftElements>
                  <S.NumberAndDragIconContainer>
                    <S.Number>{item.order_number}</S.Number>
                    <Tooltip
                      title="Arraste para mudar a ordem"
                      arrow
                      placement="top"
                      classes={{ arrow: classes.customArrow, tooltip: classes.customTooltip }}
                    >
                      <DragIcon />
                    </Tooltip>
                  </S.NumberAndDragIconContainer>
                  <S.NameAndBadgeContainer>
                    {allowEditLabel ? (
                      <EditableLabel
                        id={item.id}
                        initialText={item.name}
                        updateDataName={updateDataName}
                      />
                    ) : (
                      <S.Name>{item.name} </S.Name>
                    )}

                    {item.filled !== undefined && (
                      <S.Badge
                        color={item.filled ? '#098D4E' : '#B99503'}
                        bg_color={item.filled ? '#D8F4E6' : '#FDF6D7'}
                      >
                        {item.filled ? 'Preenchido' : 'Não preenchido'}
                      </S.Badge>
                    )}
                  </S.NameAndBadgeContainer>
                </S.LeftElements>
                <S.RightElements>
                  {item.buttons && item.buttons.length > 0 && (
                    <>
                      {item.buttons.map((button) => {
                        return !!button.permission ? (
                          <ProtectedContent permissions={[button.permission]}>
                            {RenderButton(button, item)}
                          </ProtectedContent>
                        ) : (
                          RenderButton(button, item)
                        );
                      })}
                    </>
                  )}
                  {hasChild && (
                    <S.ContainerArrow open={open} onClick={() => handleSelectedFolder(item.id)}>
                      <ChevronRightIcon />
                    </S.ContainerArrow>
                  )}
                </S.RightElements>
              </S.CardContainer>
              {open && (
                <>
                  {childLoading ? (
                    <Loader />
                  ) : (
                    <ChildList
                      childData={item.content}
                      childAllowDrag={childAllowDrag}
                      updateOrderList={updateOrderListChild}
                      allowEditChildLabel={allowEditChildLabel}
                      updateDataChildName={updateDataChildName}
                    />
                  )}
                </>
              )}
            </S.ItemContent>
          );
        })}
    </S.Container>
  );
}

export default ListAndOrder;
