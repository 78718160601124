import produce from 'immer';

const INITIAL_STATE = {
  chapters: {
    requesting: false,
    error: false,
    chapters: [],
  },
  chapterSelected: {
    requesting: false,
    error: false,
    chapter: [],
  },
};

export default function chapter(state = INITIAL_STATE, action = {}) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@chapter/REQUEST_CHAPTER': {
        draft.chapters.requesting = true;
        break;
      }
      case '@chapter/SET_LIST_CHAPTER': {
        draft.chapters = {
          requesting: false,
          error: false,
          chapters: action.payload.listChapter,
        };
        break;
      }
      case '@chapter/FETCH_LIST_CHAPTER': {
        draft.chapters.requesting = action.payload.requesting;
        draft.chapters.error = action.payload.error;
        break;
      }
      case '@chapter/REQUEST_CHAPTER_SELECTED': {
        draft.chapterSelected.requesting = true;
        break;
      }
      case '@chapter/SET_LIST_CHAPTER_SELECTED': {
        draft.chapterSelected = {
          requesting: false,
          error: false,
          chapter: action.payload.listChapterSelected,
        };
        break;
      }
      case '@chapter/FETCH_LIST_CHAPTER_SELECTED': {
        draft.chapterSelected.requesting = action.payload.requesting;
        draft.chapterSelected.error = action.payload.error;
        break;
      }
      case '@chapter/CLEAR_CHAPTER': {
        draft.chapterSelected.chapter = [];
        break;
      }
      default:
        return state;
    }
  });
}
