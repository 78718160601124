import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Header = styled.div`
  display: flex;
  gap: 16px;
`;

export const TitleHeader = styled.h2`
  font-weight: 600;
  font-size: 28.8px;
  line-height: 38px;
  color: #00205b;
`;

export const Chip = styled.span`
  align-self: center;
  border: 1px solid #0cba66;
  border-radius: 100px;
  padding: 3.5px 10px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #0cba66;
`;
