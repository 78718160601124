import styled from 'styled-components';

export const SuccessContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px, 56px, 40px, 56px;
  gap: 20px;
  position: relative;
  width: ${(props) => (props.csv ? '438px' : '380px')};
  height: 253px;
  left: ${(props) => (props.csv ? '30%' : '3')};
  background: #ffffff;
  border: 2px dashed #e5e8ee;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
  border-radius: 8px;
  border: 2px dashed #3d5582;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background: #01cfb5;
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const SuccessMainLb = styled.span`
  width: 217px;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #00205b;
`;

export const SuccessSubLb = styled.span`
  width: 217px;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12.6px;
  line-height: 17px;
  text-align: center;
  color: #3d5582;
  flex: none;
  flex-grow: 0;
`;
