import React from 'react';
import * as S from './styles';

function Inputs(props) {
  return (
    <S.InputStyle>
      <input
        onChange={props.onChange}
        disabled={props.disabled}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
      />
    </S.InputStyle>
  );
}

export default Inputs;
