import React, { useState, useCallback, useEffect } from 'react';

import ModalFilter from '~/components/ModalFilter';

import { useListLessonDocument } from '~/hooks-querys/lessonDocument';
import { useLessonList } from '~/hooks-querys/lesson';

import Pagination from '~/components/Pagination';
import Loader from '~/components/Loader';

import Filter from './Filter';
import DocumentItem from './DocumentItem';
import * as S from './styles';

let isFirstRender = true;

function DocumentLessonSearch({ show, close, isDocument }) {
  const [dispatchFilter, setDispatchFilter] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPagination, setHasPagination] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
  });
  const fetchUrl = isDocument ? useListLessonDocument : useLessonList;

  const { data: documentData, isFetching: fetchingDocumentData } = fetchUrl(
    {
      search: filter.search,
      page: currentPage,
    },
    {
      config: {
        enabled: dispatchFilter,
      },
    },
  );

  useEffect(() => {
    if (!documentData) return;

    setSearchResults(documentData.results);
    setTotalPages(Math.ceil(documentData.count / 30));
    setHasPagination(!!documentData.next || !!documentData.previous);
    setDispatchFilter(false);
  }, [documentData]);

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const onCleanFilter = () => {
    setFilter({ search: '', course: null, category: null, page: 1 });
  };

  const onHandleFilter = () => {
    setDispatchFilter(true);
  };

  const onAddItems = () => {
    close(selectedItems);
  };

  useEffect(() => {
    if (isFirstRender) {
      isFirstRender = false;
      return;
    }
    setDispatchFilter(true);
  }, [currentPage]);

  useEffect(() => {
    setDispatchFilter(false);
  }, [filter?.search]);

  function renderList() {
    return (
      <S.Container>
        {fetchingDocumentData ? (
          <Loader />
        ) : (
          <>
            {searchResults?.length > 0 && (
              <>
                <S.ContainerItems>
                  {searchResults.map((item, key) => (
                    <DocumentItem
                      item={item}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                    />
                  ))}
                </S.ContainerItems>
                <S.ContainerPagination>
                  {searchResults?.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      backPage={goToPreviousPage}
                      nextPage={goToNextPage}
                      setPage={setCurrentPage}
                    />
                  )}
                </S.ContainerPagination>
              </>
            )}
          </>
        )}
      </S.Container>
    );
  }

  return (
    <ModalFilter
      show={show}
      close={close}
      title={isDocument ? 'Buscar documentos existentes' : 'Buscar vídeo-aulas existentes'}
      buttonTitle={isDocument ? 'Adicionar documento' : 'Adicionar vídeo-aula'}
      showFooter={searchResults?.length > 0}
      onCleanFilter={onCleanFilter}
      onHandleFilter={onHandleFilter}
      onAddItems={onAddItems}
      childrenFilter={<Filter filter={filter} setFilter={setFilter} />}
      childrenList={renderList()}
    />
  );
}

export default DocumentLessonSearch;
