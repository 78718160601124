import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '@material-ui/core/';

import { UserAPICMS } from '~/services/apiCallsCMS';

import { updateProfileLocalRequest } from '~/store/modules/user/actions';

import { isEqualProfile } from '~/utils/profile';
import HeaderComponent from '~/components/HeaderComponent';
import * as S from './styles';

function Dashboard() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const auth = useSelector((state) => state.auth);

  const getInfosProfile = useCallback(async () => {
    const { data } = await UserAPICMS.get(profile.id);
    if (data && !isEqualProfile(profile, data)) {
      dispatch(updateProfileLocalRequest(data));
    }
  }, [profile, auth, dispatch]);

  useEffect(() => {
    getInfosProfile();
  }, []);

  return (
    <>
      <HeaderComponent title="Início" />
      <Container>
        <S.EmptyContent />
      </Container>
    </>
  );
}

export default Dashboard;
