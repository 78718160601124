import React from 'react';
import { ReactComponent as DoubleArrow } from '~/assets/double-arrow.svg';

import * as S from './styles';

function Pagination({ backPage, nextPage, currentPage, totalPages, setPage }) {
  const handlePageClick = (page) => {
    setPage(page);
  };

  const RenderDot = () => {
    if (totalPages <= 1) {
      return;
    }

    let pages = [];
    const lastPages = Math.ceil((currentPage / totalPages) * 100) > 50;

    if (totalPages > 1 && totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    }

    if (totalPages > 5) {
      if (currentPage + 3 <= totalPages && !lastPages) {
        let breakPage = 0;
        const firstPage = currentPage === 1 ? currentPage : currentPage - 1;

        for (let i = firstPage; i < totalPages; i++) {
          breakPage++;
          pages.push(i);
          if (breakPage === 3) {
            pages.push('...');
            pages.push(totalPages);
            break;
          }
        }
      } else {
        pages.push(1);
        pages.push('...');
        const firstPage = currentPage === totalPages ? totalPages - 2 : currentPage - 1;
        const lastPage = currentPage === totalPages ? totalPages : currentPage + 1;
        for (let i = firstPage; i <= lastPage; i++) {
          pages.push(i);
        }
      }
    }

    let renderComponents = [];

    pages.forEach((page) => {
      if (page !== '...') {
        renderComponents.push(
          <S.PaginationDot selected={page === currentPage} onClick={() => handlePageClick(page)}>
            {page}
          </S.PaginationDot>,
        );
      }

      if (page === '...') {
        renderComponents.push(<S.ThreeDotsContainer>...</S.ThreeDotsContainer>);
      }
    });

    return renderComponents;
  };

  return (
    <S.Container>
      <S.BackFowardButton disabled={currentPage === 1} onClick={backPage}>
        <S.DoubleArrowContainer disabled={currentPage === 1} backwards={true}>
          <DoubleArrow />
        </S.DoubleArrowContainer>
        Anterior
      </S.BackFowardButton>
      {RenderDot()?.map((component) => component)}
      <S.BackFowardButton disabled={currentPage === totalPages} onClick={nextPage}>
        Próximo
        <S.DoubleArrowContainer disabled={currentPage === totalPages}>
          <DoubleArrow />
        </S.DoubleArrowContainer>
      </S.BackFowardButton>
    </S.Container>
  );
}

export default Pagination;
