import styled from 'styled-components';

export const EditTitle = styled.div`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  line-height: 21.3px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
  color: #00205B;
`;

export const EditSubtitle = styled(EditTitle)`
  font-size: 14px;
  margin-bottom: 12px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 5px;
`;
