import { format, add, eachYearOfInterval } from 'date-fns';

export const transformOptions = (item) => {
  if (!item?.id || !item?.name) return;

  let returnValues = {
    label: item.verbose || item.name,
    key: item.id,
    value: item.id,
  };

  return returnValues;
};

export const loadYears = () => {
  const currentYear = new Date();
  const nextYear = add(new Date(), {
    years: 1,
  });

  const dateInterval = eachYearOfInterval({
    start: currentYear,
    end: nextYear,
  });

  return dateInterval.map((date) => {
    const onlyYear = format(date, 'yyyy');
    return { label: onlyYear, key: 'deadline_year', value: onlyYear };
  });
};

export const loadMonths = () => {
  return [
    { label: 'Janeiro', key: 'deadline_month', value: 1 },
    { label: 'Fevereiro', key: 'deadline_month', value: 2 },
    { label: 'Março', key: 'deadline_month', value: 3 },
    { label: 'Abril', key: 'deadline_month', value: 4 },
    { label: 'Maio', key: 'deadline_month', value: 5 },
    { label: 'Junho', key: 'deadline_month', value: 6 },
    { label: 'Julho', key: 'deadline_month', value: 7 },
    { label: 'Agosto', key: 'deadline_month', value: 8 },
    { label: 'Setembro', key: 'deadline_month', value: 9 },
    { label: 'Outubro', key: 'deadline_month', value: 10 },
    { label: 'Novembro', key: 'deadline_month', value: 11 },
    { label: 'Dezembro', key: 'deadline_month', value: 12 },
  ];
};
