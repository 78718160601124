import styled from 'styled-components';

export const Title = styled.h1`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #00205b;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
