import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5fb;
  width: calc(100% - 226px);
  min-height: calc(100vh - 84px);
  margin-left: 160px;
  padding: 24px;
`;

export const PrevQuestion = styled.div`
  position: fixed;
  top: 128px;
  left: 174px;
  height: calc(100vh - 192px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 3%;
  opacity: 0.8;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 9px 17px 0 rgba(0, 32, 91, 0.2);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(0.9);
    box-shadow: 10px 1px 5px -6px rgba(53, 79, 133, 0.44);
    -webkit-box-shadow: 12px 1px 5px -6px rgba(53, 79, 133, 0.44);
    -moz-box-shadow: 12px 1px 5px -6px rgba(53, 79, 133, 0.44);
    width: 4%;
  }
`;

export const NextQuestion = styled.div`
  position: fixed;
  top: 128px;
  right: 0;
  height: calc(100vh - 192px);
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 3%;
  opacity: 0.8;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: -10px 9px 17px 0 rgba(0, 32, 91, 0.2);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(0.9);
    box-shadow: -9px 1px 5px -6px rgba(53, 79, 133, 0.44);
    -webkit-box-shadow: -9px 1px 5px -6px rgba(53, 79, 133, 0.44);
    -moz-box-shadow: -9px 1px 5px -6px rgba(53, 79, 133, 0.44);
    width: 4%;
  }
`;

export const QuestionCard = styled.div`
  padding-bottom: 1px;
  border-radius: 10px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 9px 17px 0 rgba(0, 32, 91, 0.2);
`;

export const QuestionContainer = styled.div`
  flex: 1;
`;

export const QuestionContentContainer = styled.div`
  padding: 20px;
`;

export const CardPlaceHolder = styled.div`
  flex: 1;
  padding-top: 50px;
  justify-content: flex-start;
  align-items: center;
  height: 200px;
`;

export const SeparatorContainer = styled.div`
  width: 100%;
  height: 70px;
  background-color: ${(props) => props.commentEnabled && 'rgba(242, 242, 242, 0.4);'};
`;

export const SeparatorIconContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Interrogator = styled.p`
  color: #01cfb5;
  position: absolute;
  width: 45px;
  height: 45px;
  top: 1px;
  left: 13px;
`;

export const SeparatorText = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  margin-left: 17px;
  margin-bottom: 0;
  color: ${(props) => (props.commentEnabled ? '#FFFFFF;' : '#3B3FB6;')};
`;

export const Separator = styled.div`
  width: 100%;
  height: 70px;
  margin-top: 15px;
  background: ${(props) => (props.backgroundColor ? `${props.backgroundColor}` : '#E5E5E5')};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 18px 36px;
`;

export const VideoContainer = styled.div`
  background: #fff;
  display: flex;
  gap: 32px;
  border: 1px solid #dadadb;
  flex-direction: column;
  padding: 32px 0;
`;

export const VideoHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 28px 24px 28px;
  border-bottom: 1px solid #dadadb;
  justify-content: space-between;
`;

export const VideoNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #01c3aa;
  border-radius: 1000px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
`;

export const VideoTitle = styled.h1`
  margin: 0;
  font-size: 22.8px;
  font-weight: 600;
  line-height: 30.4px;
  color: #00205b;
`;

export const VideoRevisionContainer = styled.div`
  display: flex;
  gap: 24px;
  padding: 10px 28px;
  align-items: center;
`;

export const VideoLabel = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.3px;
  white-space: nowrap;
  color: #00205b;
`;

export const HeaderTitle = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;
