/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import HeaderComponent from '~/components/HeaderComponent';
import Loader from '~/components/Loader';
import GreenBorder from '~/components/GreenBorder';
import Inputs from '~/components/Inputs';
import SelectComponent from '~/components/SelectComponent';
import SelectWithPagination from '~/components/SelectWithPagination';
import ImportModal from '../components/ImportModal';
import CsvIco from '~/assets/file_icon.svg';
import LinkLogo from '~/assets/link_question.svg';
import { ReactComponent as RemoveLogo } from '~/assets/remove_question.svg';
import ImportLogo from '~/assets/import-questions-logo.svg';
import { useResidencyDegree } from '~/hooks-querys/residencydegree';
import { useFlashcardsSpecialitys, useGetFlashcardsByArray } from '~/hooks-querys/flashcards';
import { useGetFlashcardDeckById } from '~/hooks-querys/flashcard_deck';
import { loadTags, getTags } from '~/pages/Chapters/components/Settings/utils';
import { FlashcardDeckAPICMS } from '~/services/apiCallsCMS';
import { Mixpanel } from '~/services/analytics';
import {
  transformOptions,
  selectWithPaginationStyles,
  loadInstitutions,
  getInstitutions,
} from '../utils';
import history from '~/services/history';
import { ButtonSave, ButtonBack } from '../../AdminExams/CreateTrack/styles';
import * as S from './styles';

export default function NewFlashcardsDecks() {
  const { location } = useHistory();
  const querys = location.search;
  const params = new URLSearchParams(querys);
  let deckId = params.get('id');
  const [formsData, setFormsData] = useState({});
  const [residencyDegreeOptions, setResidencyDegreeOptions] = useState([]);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [disabledCsv, setDisabledCsv] = useState(true);
  const [infosToGet, setInfosToGet] = useState({ institutions: [], tags: [] });
  const [loadingContent, setLoadingContent] = useState(false);
  const [newCsvFile, setNewCsvFile] = useState(null);
  const [allFlashcards, setAllFlashcards] = useState([]);
  const [loadingFlashcards, setLoadingFlashcards] = useState(false);
  const [loadingAllFlashcards, setLoadingAllFlashcards] = useState(false);
  const [flashcardsCsvInfo, setFlashcardsCsvInfo] = useState({
    totalFlashcards: 0,
    currentPage: 1,
    hasNext: false,
    totalPages: 1,
  });

  const { data: residencyDegreeData, isLoading: loadingResidencyDegree } = useResidencyDegree({
    no_page: 1,
    ordering: 'abbreviation',
  });

  const { data: specialitysData, isLoading: loadingSpecialities } = useFlashcardsSpecialitys({
    no_page: true,
    ordering: 'name',
    main_speciality: true,
  });

  const { data: dataFlashcardDeckById, isLoading: loadingDataFlashcardsDeckById } =
    useGetFlashcardDeckById(deckId, {
      config: { enabled: !!deckId },
    });

  const flashcardInformations = useGetFlashcardsByArray(
    { ids: dataFlashcardDeckById?.flashcards || [] },
    {
      config: {
        enabled: !!dataFlashcardDeckById && dataFlashcardDeckById?.flashcards.length > 0,
      },
    },
  );

  const handleName = (event) => {
    event.persist();
    return setFormsData({ ...formsData, name: event.target.value });
  };

  const handleChange = (value, prop) => {
    return setFormsData({ ...formsData, [prop]: value });
  };

  const handleResidencyDegree = (residencyDegree) => {
    const differentResidencyDegrees = residencyDegree.value !== formsData.residencyDegree?.value;
    let params = { residencyDegree: residencyDegree };
    if (differentResidencyDegrees) {
      params.tags = null;
    }

    return setFormsData({ ...formsData, ...params });
  };

  const handleImportButton = () => {
    setShowCsvModal(true);
  };

  const populateFlashcardsInfos = async () => {
    setLoadingFlashcards(true);
    try {
      const { data } = await FlashcardDeckAPICMS.get_flashcards_csv(newCsvFile, {
        page: flashcardsCsvInfo.currentPage,
      });

      setFlashcardsCsvInfo({
        totalFlashcards: data?.count || 0,
        currentPage: flashcardsCsvInfo.currentPage + 1,
        hasNext: !!data.next,
        totalPages: Math.ceil(data?.count / 30),
      });

      setLoadingFlashcards(false);
      setAllFlashcards((allFlashcards) => [...allFlashcards, ...data?.results]);

      if (flashcardsCsvInfo.currentPage === Math.ceil(data?.count / 30)) {
        setLoadingAllFlashcards(false);
      }
    } catch {
      setLoadingFlashcards(false);
      setLoadingAllFlashcards(false);
    }
  };

  useEffect(() => {
    if (loadingFlashcards) return;

    if (flashcardsCsvInfo.hasNext) {
      setLoadingAllFlashcards(true);
      populateFlashcardsInfos();
    }
  }, [allFlashcards]);

  function handleImportFlashcards() {
    if (!csvFile) return;

    setCsvFile(null);
    setAllFlashcards([]);
    setFlashcardsCsvInfo({
      totalFlashcards: 0,
      currentPage: 1,
      hasNext: false,
      totalPages: 1,
    });
    Mixpanel.track("Clicou 'Adicionar Deck - Importar Flashcards'");
    const newFormData = new FormData();
    newFormData.append('csv', csvFile);
    setNewCsvFile(newFormData);
    setShowCsvModal(false);
  }

  function handleCsvFile(event) {
    setCsvFile(event);
    setDisabledCsv(false);
  }

  const handleCloseCsvModal = () => {
    setShowCsvModal(false);
  };

  const validateForm = () => {
    if (!formsData.name) {
      toast.error('O deck de flashcards deve possuir um nome');
      return false;
    }
    if (!formsData.specialitys || (formsData.specialitys && formsData.specialitys.length <= 0)) {
      toast.error('O deck de flashcards deve possuir pelo menos uma especialidade');
      return false;
    }
    if (!formsData.residencyDegree) {
      toast.error('O deck de flashcards deve possuir um nível de residência');
      return false;
    }
    if (!formsData.tags || (formsData.tags && formsData.tags.length <= 0)) {
      toast.error('O deck de flashcards deve possuir pelo menos um tema e foco');
      return false;
    }
    if (!formsData.institutions || (formsData.institutions && formsData.institutions.length <= 0)) {
      toast.error('O deck de flashcards deve possuir pelo menos uma instituição');
      return false;
    }
    if (allFlashcards.length <= 0) {
      toast.error('O deck de flashcards deve possuir pelo menos um flashcard');
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (validateForm()) {
      const institutionsIds = formsData?.institutions.map((institution) => institution.value);
      const tagsIds = formsData?.tags.map((tag) => tag.value);
      const specialitysIds = formsData?.specialitys.map((speciality) => speciality.value);
      const allFlashcardsIds = allFlashcards.map((flashcard) => flashcard.id);

      const params = {
        title: formsData?.name,
        tags: tagsIds,
        institutions: institutionsIds,
        residency_degree: formsData?.residencyDegree?.value,
        specialities: specialitysIds,
        flashcards: allFlashcardsIds,
      };

      const institutionsForMixpanel = formsData?.institutions?.map(
        (institution) => institution.label,
      );
      const specialitysForMixpanel = formsData?.specialitys.map((speciality) => speciality.label);

      const mixpanelParams = {
        'Nome do Deck': formsData?.name,
        Tags: tagsIds,
        Instituições: institutionsForMixpanel,
        'Nível de Residência': formsData?.residencyDegree?.label,
        Especialidade: specialitysForMixpanel,
        'ID dos Flashcards': allFlashcardsIds,
        'Quantidade de Flashcards': allFlashcardsIds.length,
      };

      if (deckId) {
        mixpanelParams['ID do deck'] = deckId;
        Mixpanel.track("Clicou 'Adicionar Deck - Editar Deck'", mixpanelParams);
        FlashcardDeckAPICMS.update(deckId, params)
          .then(() => toast.success('Dados salvos com sucesso!'))
          .catch(() => toast.error('Erro ao salvar dados, tente novamente mais tarde.'));
      } else {
        params['is_lesson_module_item'] = true;
        FlashcardDeckAPICMS.create(params)
          .then((response) => {
            toast.success('Deck de flashcards criado com sucesso!');

            if (response?.data?.id) {
              window.localStorage.setItem('newFlashcardDeck', response?.data?.id);
              window.dispatchEvent(new Event('storage'));
              mixpanelParams['ID do deck'] = response?.data?.id;
              history.push(`/flashcards-decks/edit?id=${response.data.id}`);
            }
            Mixpanel.track("Clicou 'Adicionar Deck - Criar Deck'", mixpanelParams);
          })
          .catch(() =>
            toast.error('Erro ao criar deck de flashcards, tente novamente mais tarde.'),
          );
      }
    }
  };

  const handleGoBack = () => {
    Mixpanel.track("Clicou 'Adicionar Deck - Botão de Voltar'");
    return history.goBack();
  };

  function handleRemoveFlashcard(flashcard) {
    setFlashcardsCsvInfo({
      ...flashcardsCsvInfo,
      totalFlashcards: flashcardsCsvInfo.totalFlashcards - 1,
    });
    const listWithRemovedFlashcard = allFlashcards.filter(
      (flashcardItem) => flashcardItem.id !== flashcard.id,
    );
    setAllFlashcards(listWithRemovedFlashcard);
  }

  const fetchInstitutionsAndTags = async () => {
    setLoadingContent(true);
    const requests = [getInstitutions(infosToGet.institutions), getTags(infosToGet.tags)];
    const responses = await Promise.all(requests);

    return responses;
  };

  useEffect(() => {
    if (!flashcardInformations || flashcardInformations?.data.length <= 0) return;
    setAllFlashcards(flashcardInformations?.data);
    setFlashcardsCsvInfo({
      totalFlashcards: flashcardInformations?.data.length || 0,
      currentPage: 1,
      hasNext: false,
      totalPages: 1,
    });
  }, [flashcardInformations.isSuccess]);

  useEffect(() => {
    if (!residencyDegreeData) return;
    setResidencyDegreeOptions(residencyDegreeData.map(transformOptions));
  }, [residencyDegreeData]);

  useEffect(() => {
    if (!specialitysData) return;
    setSpecialityOptions(specialitysData.map(transformOptions));
  }, [specialitysData]);

  useEffect(() => {
    if (!newCsvFile) return;
    populateFlashcardsInfos();
  }, [newCsvFile]);

  useEffect(() => {
    if (!dataFlashcardDeckById || !specialityOptions || !residencyDegreeOptions) return;

    const specialitysById = dataFlashcardDeckById?.specialities || [];
    const institutionsById = dataFlashcardDeckById?.institutions || [];
    const tagsById = dataFlashcardDeckById?.tags || [];

    const loadedSpecialitys = specialityOptions.filter((speciality) =>
      specialitysById.includes(speciality.value),
    );
    const loadedResidencyDegree = residencyDegreeOptions.find(
      (residencyDegree) => dataFlashcardDeckById?.residency_degree === residencyDegree.value,
    );

    const loadedProps = {
      name: dataFlashcardDeckById?.title,
      specialitys: loadedSpecialitys,
      residencyDegree: loadedResidencyDegree,
    };

    setFormsData({ ...formsData, ...loadedProps });
    setInfosToGet({ institutions: institutionsById, tags: tagsById });
  }, [dataFlashcardDeckById, specialityOptions, residencyDegreeOptions]);

  useEffect(() => {
    if (deckId) {
      fetchInstitutionsAndTags().then((responses) => {
        const newParams = {
          institutions: responses[0],
          tags: responses[1],
        };
        setLoadingContent(false);
        return setFormsData({ ...formsData, ...newParams });
      });
    }
  }, [infosToGet]);

  const loadingState = () => {
    return loadingResidencyDegree || loadingSpecialities || loadingContent;
  };

  const disableButton = () => {
    return (loadingDataFlashcardsDeckById && !!deckId) || loadingState() || loadingFlashcards;
  };

  const RenderContent = (step, title, children, noBorder, showCounter) => {
    return (
      <S.Content noBorder={noBorder}>
        <S.StepHeader>
          <S.StepContainer>
            <S.StepOrder>{step}</S.StepOrder>
            <S.StepText>{title}</S.StepText>
          </S.StepContainer>
          {flashcardsCsvInfo.totalFlashcards > 0 && showCounter && (
            <S.StepContainer>
              <S.QuestionsCount>{flashcardsCsvInfo.totalFlashcards}</S.QuestionsCount>
              <S.QuestionsText>
                {`${flashcardsCsvInfo.totalFlashcards === 1 ? 'Flashcard' : 'Flashcards'} no deck`}{' '}
              </S.QuestionsText>
              {flashcardsCsvInfo.totalFlashcards > 0 && (
                <div style={{ cursor: 'pointer' }} onClick={handleImportButton}>
                  <img src={CsvIco} alt="Logo csv" />
                </div>
              )}
            </S.StepContainer>
          )}
        </S.StepHeader>
        {children}
      </S.Content>
    );
  };
  return (
    <>
      <HeaderComponent title={'Novo Deck de Flashcards'} />
      <S.Wrap>
        {loadingDataFlashcardsDeckById && !!deckId ? (
          <Loader />
        ) : (
          <S.Container>
            <GreenBorder />
            {RenderContent(
              '1',
              'Dados principais',
              <S.ItemsContainer>
                <S.Item>
                  <S.SectionText>Nome</S.SectionText>
                  <Inputs
                    type="text"
                    placeholder="Nome do deck"
                    onChange={handleName}
                    value={formsData.name}
                  />
                </S.Item>
              </S.ItemsContainer>,
              true,
              false,
            )}
            {RenderContent(
              '2',
              'Configurações',
              <S.ItemsContainer>
                <S.Item>
                  <SelectComponent
                    value={formsData.specialitys}
                    options={specialityOptions}
                    isMulti
                    onChange={(value) => handleChange(value, 'specialitys')}
                    placeholder="Especialidades"
                    height="48px"
                    width="100%"
                    marginLeft="0"
                    paddingLeft="0"
                  />
                </S.Item>
                <S.Item>
                  <SelectComponent
                    value={formsData.residencyDegree}
                    options={residencyDegreeOptions}
                    onChange={handleResidencyDegree}
                    placeholder="Nível de Residência"
                    height="48px"
                    width="100%"
                    marginLeft="0"
                    paddingLeft="0"
                  />
                </S.Item>
                <S.Item>
                  <SelectWithPagination
                    name="tags"
                    height={48}
                    value={formsData.tags}
                    onChange={(value) => handleChange(value, 'tags')}
                    loadOptions={(search, loadedOptions, page) =>
                      loadTags(search, loadedOptions, {
                        ...page,
                        new_residency_degree: formsData.residencyDegree?.value,
                      })
                    }
                    placeholder={'Tema e foco'}
                    isMulti
                    {...selectWithPaginationStyles}
                    asyncPaginateKey={`async-paginate-${formsData.residencyDegree?.value || ''}`}
                  />
                </S.Item>
                <S.Item>
                  <SelectWithPagination
                    name="institutions"
                    height={48}
                    value={formsData.institutions}
                    onChange={(value) => handleChange(value, 'institutions')}
                    loadOptions={loadInstitutions}
                    placeholder="Instituição"
                    isMulti
                    {...selectWithPaginationStyles}
                  />
                </S.Item>
              </S.ItemsContainer>,
              false,
              false,
            )}
            {RenderContent(
              '3',
              'Importar flashcards no deck',
              <S.ItemsContainer>
                <S.ImportContainer>
                  {loadingContent ? (
                    <Loader />
                  ) : (
                    <>
                      {allFlashcards.length > 0 ? (
                        <>
                          {allFlashcards.map((flashcard) => (
                            <S.FlashcardContainer key={flashcard.id}>
                              <S.FlashcardHeader>
                                <S.FlashcardInfoContent>
                                  <S.FlashcardContent>{flashcard.question}</S.FlashcardContent>
                                </S.FlashcardInfoContent>
                                <S.QuestionButtonsContent>
                                  <S.QuestionButton
                                    onClick={() => {
                                      window.open(
                                        `${process.env.REACT_APP_URL}/flashcards/edit/?id=${flashcard.id}`,
                                        '_blank',
                                      );
                                    }}
                                  >
                                    <img src={LinkLogo} alt="See flashcard" /> Visualizar
                                  </S.QuestionButton>
                                  <S.QuestionButton
                                    disabled={loadingAllFlashcards}
                                    onClick={() => handleRemoveFlashcard(flashcard)}
                                  >
                                    <RemoveLogo /> Remover
                                  </S.QuestionButton>
                                </S.QuestionButtonsContent>
                              </S.FlashcardHeader>
                            </S.FlashcardContainer>
                          ))}
                          {loadingAllFlashcards && <Loader />}
                        </>
                      ) : (
                        <S.ImportButton onClick={handleImportButton}>
                          <img src={ImportLogo} alt="import questions" />
                          Importar flashcards
                        </S.ImportButton>
                      )}
                    </>
                  )}
                </S.ImportContainer>
              </S.ItemsContainer>,
              false,
              true,
            )}
          </S.Container>
        )}
      </S.Wrap>
      <S.Footer>
        <ButtonBack type="button" onClick={handleGoBack}>
          <MdArrowBack size={18} color="#01c3aa" />
          Voltar
        </ButtonBack>
        <ButtonSave onClick={handleSave} disabled={disableButton()}>
          Salvar
          <S.StyledSaveIcon />
        </ButtonSave>
      </S.Footer>
      <ImportModal
        open={showCsvModal}
        handleCsvFile={handleCsvFile}
        handleClose={handleCloseCsvModal}
        handleImport={handleImportFlashcards}
        disabled={disabledCsv}
        csvFile={csvFile}
      />
    </>
  );
}
