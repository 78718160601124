import styled, { css } from 'styled-components';

const wrapperModifiers = {
  default: () => css`
    background: radial-gradient(50% 50% at 50% 50%, rgba(229, 232, 238, 0) 0%, #e5e8ee 100%);
  `,
  error: () => css`
    background: radial-gradient(50% 50% at 50% 50%, rgba(252, 219, 223, 0) 0%, #fcdbdf 100%);
  `,
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ContainerIcon = styled.div`
  ${({ backgroundIcon }) => css`
    display: grid;
    place-items: center;
    width: 80px;
    height: 80px;
    border-radius: 1000px;

    ${backgroundIcon && wrapperModifiers[backgroundIcon]};
  `}
`;

export const ContainerText = styled.div`
  text-align: center;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #3d5582;
`;
