import styled, { css } from 'styled-components';
import { ReactComponent as EditIcon } from '~/assets/drops-edit.svg';
import { ReactComponent as ConfigurationIcon } from '~/assets/configuration-icon.svg';

export const Container = styled.div`
  width: 100%;
  padding-top: 10px;
  justify-content: center;
  background-color: #f5f5fb;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cdcdcd;
  padding: 16px 24px;
`;

export const ItemTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #00205b;
`;

export const ItemSubtitle = styled.div`
  font-size: 14px;
  line-height: 18.96px;
  color: #3d5582;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
`;

export const ListExams = styled.div`
  position: relative;
  width: 100%;
`;

export const MainContainer = styled.div`
  padding-right: 5px;
  width: 100%;
  min-height: 100%;
  background-color: #f5f5fb;
`;

export const ViewCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`;

export const NotFound = styled.div`
  margin-left: 5px;
  margin-top: 20px;
  height: 19px;
  color: #3b3fb6;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderInformationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const HeaderInformations = styled.div`
  display: flex;
  kustify-content: center;
  align-items: center;
  gap: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const ContainerSwitch = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const SwitchButton = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 32px;
  height: 20px;
  background: ${(props) => (props.checked ? '#01CFB5' : '#8f9db7')};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  margin-bottom: 0;
`;

export const SwitchLabel = styled.p`
  margin: 0;
  font-size: 14px;
  color: #00205b;
`;

export const CircleButton = styled.div`
  position: absolute;
  top: 4px;
  left: ${(props) => (props.checked ? 'calc(100% - 16px)' : '4px')};
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: #fff;
  transition: 0.2s;
`;

export const CardProgress = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ChipCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 26px;
  background: #fcdbdf;
  color: #ee1f38;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  ${(props) =>
    props.complete &&
    css`
      background: #d8f4e6;
      color: #0cba66;
    `}
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

export const FillButton = styled.button`
  padding: 10px 16px;
  gap: 10px;
  display: flex;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: #077859;
  background: #fff;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 320px;

  :hover {
    background: #daf5f1;
  }
`;

export const StyledEditIcon = styled(EditIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: #077859;
  }
`;

export const StyledConfigurationIcon = styled(ConfigurationIcon)`
  width: 18px;
  height: 18px;
`;

export const GroupButtonModal = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
`;

export const FilterContainer = styled.div`
  display: flex;
  background-color: #fff;
  padding: 20px 40px;
  gap: 16px;
  border-radius: 8px;
  box-shadow:
    0px 2px 4px 0px #181a1e14,
    0px 0px 2px 0px #181a1e29;

  @media (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;

export const FilterBox = styled.div`
  flex-basis: ${(props) => props.flexBasis};
  flex-grow: 0;

  @media (max-width: 1080px) {
    flex-grow: 1;
  }
`;

export const Filter = styled.div`
  max-width: 604px;
  min-width: 140px;
  width: 100%;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  min-height: 32px;

  &:focus-within {
    background-color: #ffffff;
    box-shadow: 0 3px 8px 0 rgba(0, 32, 91, 0.15);
    color: #00205b;
    letter-spacing: 0.5px;
    line-height: 15px;
  }

  input {
    width: 100%;
    border: none;
    background: none;

    &::placeholder {
      font-size: 14.2px;
      line-height: 18.96px;
      color: #3d5582;
    }
  }
`;

export const ClearButton = styled.button`
  width: 100px;
  color: #077859;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 18.96px;
  padding: 10px 16px;
  border: 2px solid #e5e8ee;
  border-radius: 1000px;
  background-color: #fff;

  :hover {
    border-color: #d1d7e1;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }
`;

export const ButtonFilter = styled.button`
  width: 100px;
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }

  :disabled {
    color: #8f9db7;
    background: #e5e8ee;
    cursor: default;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }
`;

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 12.6px;
    line-height: 17px;
    color: #001e56;
  }
`;

export const Pagination = styled.div`
  display: flex;
`;

export const ButtonPagination = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: none;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #01c3aa;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #e5faf7;
  }

  :disabled {
    color: #d1d7e1;
    cursor: default;
    background: none;

    svg {
      path {
        fill: #d1d7e1;
      }
    }
  }
`;

export const ButtonLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  min-height: 48px;
`;

export const ButtonPopper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  min-height: 48px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00205b;
  background: #fff;
  border: none;
`;
