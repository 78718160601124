/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdEdit } from 'react-icons/md';
import { Ring } from 'react-spinners-css';
import { format } from 'date-fns';
import Axios from 'axios';
import RobotIcon from '~/assets/dropsRobot';
import SaveIcon from '~/assets/drops-save.svg';
import { ReactComponent as HandBookIcon } from '~/assets/handbook-icon.svg';
import Loader from '~/components/Loader';
import ImageZoomModal from '~/components/ImageZoomModal';
import RatingComponent from '~/components/RatingComponent';
import RatingFeedbackComponent from '~/components/RatingFeedbackComponent';
import ButtonWithPopper from '~/components/ButtonWithPopper';
import ModalWithHeader from '~/components/ModalWithHeader';
import NoComment from '../NoComment';
import ListedQuestionComments from './ListedQuestionComments';
import QuestionEditor from './QuestionEditor';
import GeneratedCommentLoadingModal from '../GeneratedCommentLoadingModal';
import IASuggestionPagination from './IASuggestionPagination';
import {
  QuestionAPICMS,
  QuestionExplanationCMS,
  GetQuestionsAPICMS,
  QuestionSuggestionFeedbackAPICMS,
  StudentFeedbackAPICMS,
  ChapterAPICMS,
} from '~/services/apiCallsCMS';
import history from '~/services/history';
import { Mixpanel } from '~/services/analytics';
import { TAG_TYPE } from '~/utils/variables';

import * as S from './styles';

const emptyData = {
  introduction: '',
  option_a: '',
  option_b: '',
  option_c: '',
  option_d: '',
  option_e: '',
  conclusion: '',
};

export default function QuestionComment({ question, onRefresh }) {
  const { examId, question_number } = useParams();
  const [questionExplanation, setQuestionExplanation] = useState({});
  const [explanationNewData, setExplanationNewData] = useState(emptyData);
  const [editExplanation, setEditExplanation] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [difficulty, setDifficulty] = useState(question.difficulty);
  const [notFound, setNotFound] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [waitingIaComment, setWaitingIaComment] = useState(false);
  const [emptyIASuggestion, setEmptyIASuggestion] = useState(true);
  const [IASuggestionCurrentPage, setIASuggestionCurrentPage] = useState(1);
  const [IASuggestionTotalPage, setIASuggestionTotalPage] = useState(0);
  const [generatedIASuggestions, setGeneratedIASuggestions] = useState([]);
  const [IASuggestionsIDs, setIASuggestionsIDs] = useState([]);
  const [iaType, setIaType] = useState('c');
  const [IASuggestionsFeedback, setIASuggestionsFeedback] = useState([]);
  const [rating, setRating] = useState(undefined);
  const [feedback, setFeedback] = useState({ average_grade: 0, total_evaluations: 0 });
  const [showHandbookModal, setShowHandbookModal] = useState(false);
  const [loadingChapter, setLoadingChapter] = useState(false);
  const commentStorageNameRef = useRef();
  const MAX_IA_SUGGESTIONS = 2;

  const explanation = !!question.main_explanation;

  function handlerImage(event) {
    if (event && event.data && event.data.image) {
      setCurrentImage({ uri: event.data.image });
      setFullScreenImage(true);
    }
  }

  const getContent = () => {
    setLoadingContent(true);

    const source = Axios.CancelToken.source();
    QuestionExplanationCMS.list({ question: question.id }, source.token)
      .then((result) => {
        setLoadingContent(false);
        if (result && result.data && result.data.count > 0) {
          setQuestionExplanation(result.data.results[0]);
          setExplanationNewData({
            introduction: result.data.results[0].introduction,
            option_a: result.data.results[0].option_a,
            option_b: result.data.results[0].option_b,
            option_c: result.data.results[0].option_c,
            option_d: result.data.results[0].option_d,
            option_e: result.data.results[0].option_e,
            conclusion: result.data.results[0].conclusion,
          });
          setNotFound(false);
          return;
        }
        setNotFound(true);
        setExplanationNewData({ ...explanationNewData, question: question.id });
      })
      .catch(() => {
        setNotFound(true);
        setExplanationNewData({ ...explanationNewData, question: question.id });
        setLoadingContent(false);
      });
  };

  const handleChangeDifficulty = (feedback) => {
    if (!question.id || !difficulty) return;
    setDifficulty(feedback);
    QuestionAPICMS.update(question.id, { difficulty: feedback }).catch(() => {
      toast.error('Não foi possível salvar a dificuldade');
    });
  };

  function convertDifficulty(value) {
    switch (value) {
      case 'e':
        return 'Fácil';
      case 'm':
        return 'Média';
      case 'h':
        return 'Difícil';
      default:
        return '';
    }
  }

  const hasIntroAndConclusion = () => {
    return (
      explanationNewData.introduction.replaceAll(/(\ |\n|<p>|<\/p>)/gi, '') !== '' &&
      explanationNewData.conclusion.replaceAll(/(\ |\n|<p>|<\/p>)/gi, '') !== ''
    );
  };

  const verifyCommentComplete = () => {
    setLoadingContent(true);
    if (hasIntroAndConclusion()) {
      setEditExplanation(false);
      return;
    }
    setLoadingContent(false);
    toast.error('Introdução e Visão do Aprovado precisam ser preenchidos.');
  };

  const handleEdit = () => {
    Mixpanel.track("Clicou 'Comentário questão - editar comentário salvo'", {
      Questão: question.id,
      'Especialidade da questão': question.speciality_name[0],
      Tags: question.tag,
      'Nível de residência': question.residency_degree,
      Instituição: question.institution,
      Ano: question.year,
      'Questão original': question.is_original ? 'Sim' : 'Não',
    });
    setEditExplanation(true);
  };

  const handleSave = () => {
    Mixpanel.track("Clicou 'Comentário questão - salvar e fechar'", {
      Questão: question.id,
      'Especialidade da questão': question.speciality_name[0],
      Tags: question.tag,
      'Nível de residência': question.residency_degree,
      Instituição: question.institution,
      Ano: question.year,
      'Questão original': question.is_original ? 'Sim' : 'Não',
    });

    verifyCommentComplete();
  };

  const handleCreateComment = () => {
    Mixpanel.track("Clicou 'Comentário questão - criar comentário'", {
      Questão: question.id,
      'Especialidade da questão': question.speciality_name[0],
      Tags: question.tag,
      'Nível de residência': question.residency_degree,
      Instituição: question.institution,
      Ano: question.year,
      'Questão original': question.is_original ? 'Sim' : 'Não',
    });

    if (!editExplanation) {
      setEditExplanation(true);
      return;
    }
    verifyCommentComplete();
  };

  const handleGenerateSuggestion = (isFirstTime) => {
    if (waitingIaComment) {
      setShowLoadingModal(true);
      return;
    }

    const mixpanelTrackName = isFirstTime
      ? "Clicou 'Comentário questão - gerar comentário via I.A.'"
      : "Clicou 'Comentário questão - gerar segundo comentário via I.A.'";

    Mixpanel.track(mixpanelTrackName, {
      Questão: question.id,
      'Especialidade da questão': question.speciality_name[0],
      Tags: question.tag,
      'Nível de residência': question.residency_degree,
      Instituição: question.institution,
      Ano: question.year,
      'Questão original': question.is_original ? 'Sim' : 'Não',
    });

    if (emptyIASuggestion) {
      setEmptyIASuggestion(false);
    } else {
      setIASuggestionCurrentPage(IASuggestionCurrentPage + 1);
    }

    setIASuggestionTotalPage(IASuggestionTotalPage + 1);
    GetQuestionsAPICMS.generate_ia_suggestion(question.id).then(() => {
      setWaitingIaComment(true);
      setShowLoadingModal(true);
    });
  };

  const updateQuestionComments = async (token) => {
    await QuestionExplanationCMS.update(questionExplanation.id, explanationNewData, token);
  };

  const saveOnFocusOut = () => {
    if (questionExplanation.id) {
      const source = Axios.CancelToken.source();
      updateQuestionComments(source.token);
    }
  };

  const getIaSuggestion = async (IAIdentifier) => {
    const params = {
      question_id: question.id,
      ia_type: IAIdentifier || iaType,
    };

    await GetQuestionsAPICMS.get_ia_suggestion(params)
      .then((response) => {
        if (response && response.data && response.data.results[0]) {
          if (response.data.results[0].ia_type === 'c') {
            setIaType('g');
          }
          setEmptyIASuggestion(false);
          setIASuggestionsIDs((IASuggestionsIDs) => [
            ...IASuggestionsIDs,
            response.data.results[0].id,
          ]);
          setGeneratedIASuggestions((generatedIASuggestions) => [
            ...generatedIASuggestions,
            response.data.results[0].suggestion,
          ]);
          setWaitingIaComment(false);
          getFeedbackSuggestion(response.data.results[0].id);
        }
      })
      .catch(() => setWaitingIaComment(false));
  };

  const getFeedbackSuggestion = async (suggestionId) => {
    await QuestionSuggestionFeedbackAPICMS.get_feedback({ suggestion: suggestionId }).then(
      (response) => {
        let grade = undefined;
        let id = null;
        if (response.data.results.length > 0) {
          grade = response.data.results[0].grade;
          id = response.data.results[0].id;
        }
        setIASuggestionsFeedback((IASuggestionsFeedback) => [
          ...IASuggestionsFeedback,
          { grade: grade, id: id },
        ]);
      },
    );
  };

  const handlePreviousPage = () => {
    Mixpanel.track("Clicou 'Comentário questão - Comentário via I.A. anterior'", {
      Questão: question.id,
      'Especialidade da questão': question.speciality_name[0],
      Tags: question.tag,
      'Nível de residência': question.residency_degree,
      Instituição: question.institution,
      Ano: question.year,
      'Questão original': question.is_original ? 'Sim' : 'Não',
    });

    setIASuggestionCurrentPage(IASuggestionCurrentPage - 1);
  };

  const handleNextPage = () => {
    Mixpanel.track("Clicou 'Comentário questão - Comentário via I.A. próximo'", {
      Questão: question.id,
      'Especialidade da questão': question.speciality_name[0],
      Tags: question.tag,
      'Nível de residência': question.residency_degree,
      Instituição: question.institution,
      Ano: question.year,
      'Questão original': question.is_original ? 'Sim' : 'Não',
    });

    setIASuggestionCurrentPage(IASuggestionCurrentPage + 1);
  };

  const handleOpenPdf = () => {
    setLoadingChapter(true);
    const tagIdFromArray = question?.subtags.find((tag) => tag.tag_type === TAG_TYPE.FOCUS).id;

    if (tagIdFromArray) {
      ChapterAPICMS.list({ tag: tagIdFromArray, ordering: '-created_at' })
        .then((response) => {
          if (response && response.data && response.data.results) {
            history.push({
              pathname: `/chapter/preview/${response.data.results[0].id}`,
              state: {
                backTo: `/exam/${examId}/question/${question_number}/comment`,
              },
            });

            return;
          }
          setShowHandbookModal(true);
        })
        .finally(() => setLoadingChapter(false));
    } else {
      setShowHandbookModal(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handlerImage);
    commentStorageNameRef.current = `comment${question?.id}`;
    getIaSuggestion('c').then(() => getIaSuggestion('g'));
  }, []);

  useEffect(() => {
    getContent();
  }, [question]);

  useEffect(() => {
    if (editExplanation) {
      return;
    }
    const source = Axios.CancelToken.source();
    if (!questionExplanation.id && explanationNewData.question) {
      setLoadingContent(true);
      QuestionExplanationCMS.create(explanationNewData, source.token)
        .then(() => {
          setLoadingContent(false);
          toast.success('Comentário criado com sucesso!');
          onRefresh();
        })
        .catch(() => {
          setLoadingContent(false);
          toast.error('Não foi possível criar o comentário');
        });
    } else if (questionExplanation.id) {
      setLoadingContent(true);
      QuestionExplanationCMS.update(questionExplanation.id, explanationNewData, source.token)
        .then(() => {
          setLoadingContent(false);
          toast.success('Comentário salvo com sucesso!');
          onRefresh();
        })
        .catch(() => {
          setLoadingContent(false);
          toast.error('Não foi possível salvar o comentário');
        });
    }
  }, [editExplanation]);

  useEffect(() => {
    if (waitingIaComment) {
      setShowLoadingModal(true);
    }

    const checkComment = () => {
      if (waitingIaComment) {
        getIaSuggestion();
      }
    };

    const interval = setInterval(checkComment, 2000);
    if (!waitingIaComment) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [waitingIaComment]);

  useEffect(() => {
    setIASuggestionTotalPage(generatedIASuggestions.length);
  }, [generatedIASuggestions]);

  useEffect(() => {
    if (!rating) return;

    const params = {
      grade: rating,
      suggestion: IASuggestionsIDs[IASuggestionCurrentPage - 1],
    };

    Mixpanel.track("Clicou 'Comentário questão - Avaliar utilidade comentário via I.A.'", {
      Questão: question.id,
      'Especialidade da questão': question.speciality_name[0],
      Tags: question.tag,
      'Nível de residência': question.residency_degree,
      Instituição: question.institution,
      Ano: question.year,
      'Questão original': question.is_original ? 'Sim' : 'Não',
      Avaliação: rating,
    });

    if (
      !IASuggestionsFeedback[IASuggestionCurrentPage - 1]?.grade &&
      !IASuggestionsFeedback[IASuggestionCurrentPage - 1]?.id
    ) {
      QuestionSuggestionFeedbackAPICMS.create(params).then((response) => {
        let arrayCopy = [...IASuggestionsFeedback];
        arrayCopy[IASuggestionCurrentPage - 1] = { grade: rating, id: response.data?.id };
        setIASuggestionsFeedback(arrayCopy);
      });
      return;
    }

    QuestionSuggestionFeedbackAPICMS.update(
      IASuggestionsFeedback[IASuggestionCurrentPage - 1]?.id,
      { grade: rating },
    ).then(() => {
      let arrayCopy = [...IASuggestionsFeedback];
      arrayCopy[IASuggestionCurrentPage - 1] = {
        grade: rating,
        id: IASuggestionsFeedback[IASuggestionCurrentPage - 1]?.id,
      };
      setIASuggestionsFeedback(arrayCopy);
    });
  }, [rating]);

  useEffect(() => {
    if (!question.id) return;

    const params = {
      content_type: 'question',
      object_id: question.id,
    };

    StudentFeedbackAPICMS.getAverageGrade(params).then((response) => {
      setFeedback({
        average_grade: response.data?.average_grade || 0,
        total_evaluations: response.data?.total_evaluations || 0,
      });
    });
  }, [question.id]);

  return (
    <>
      <S.QuestionCommentContainer>
        <S.ExplanationContainer>
          <S.OpinionContainer>
            <S.OpinionText>Nível de dificuldade dessa questão</S.OpinionText>
            <S.OpinionButtonsContainer>
              {['e', 'm', 'h'].map((feedback) => (
                <S.Buttons
                  key={feedback}
                  onClick={() => handleChangeDifficulty(feedback)}
                  selected={difficulty === feedback}
                >
                  {convertDifficulty(feedback)}
                </S.Buttons>
              ))}
            </S.OpinionButtonsContainer>
          </S.OpinionContainer>
        </S.ExplanationContainer>
        {!explanation && !editExplanation ? (
          <S.ExplanationContainer>
            <S.NoCommentsFound>
              <S.Title>Comentário em texto</S.Title>
              <S.NoCommentContainer>
                <NoComment action={handleCreateComment} />
              </S.NoCommentContainer>
            </S.NoCommentsFound>
          </S.ExplanationContainer>
        ) : (
          <S.CommentsContainer>
            <S.ExplanationContainer>
              <S.HeaderContainer>
                <S.HeaderControlsContainer>
                  <S.TitleContainer>
                    <S.NumberContainer>1</S.NumberContainer>
                    <S.Title>Sugestão via IA</S.Title>
                  </S.TitleContainer>
                  {!emptyIASuggestion && (
                    <S.IAControls>
                      <IASuggestionPagination
                        currentPage={IASuggestionCurrentPage}
                        totalPages={IASuggestionTotalPage}
                        goToPreviousPage={handlePreviousPage}
                        goToNextPage={handleNextPage}
                      />
                      <S.ButtonGenerateNew
                        onClick={() => handleGenerateSuggestion(false)}
                        disabled={IASuggestionTotalPage === MAX_IA_SUGGESTIONS || waitingIaComment}
                      >
                        <RobotIcon />
                        Nova sugestão
                      </S.ButtonGenerateNew>
                    </S.IAControls>
                  )}
                </S.HeaderControlsContainer>
              </S.HeaderContainer>
              <S.ContentContainer>
                {emptyIASuggestion ? (
                  <S.EmptyStateContainer>
                    <S.EmptyIALabel>Clique no botão para gerar uma sugestão</S.EmptyIALabel>
                    <S.GenerateIASuggestionButton onClick={() => handleGenerateSuggestion(true)}>
                      <RobotIcon />
                      Sugerir via IA
                    </S.GenerateIASuggestionButton>
                  </S.EmptyStateContainer>
                ) : (
                  <>
                    <S.GeneratedSuggestionContainer>
                      <S.GeneratedSuggestionText>
                        {generatedIASuggestions[IASuggestionCurrentPage - 1] || (
                          <S.LoadingSuggestion>
                            <Loader spinner="ring" text="" />
                            <S.EmptyIALabel>Gerando sugestão, por favor aguarde!</S.EmptyIALabel>
                          </S.LoadingSuggestion>
                        )}
                      </S.GeneratedSuggestionText>
                    </S.GeneratedSuggestionContainer>
                    {!!generatedIASuggestions[IASuggestionCurrentPage - 1] && (
                      <S.FeedbackContainer>
                        <RatingComponent
                          initialValue={IASuggestionsFeedback[IASuggestionCurrentPage - 1]?.grade}
                          setRating={setRating}
                          size="large"
                        />
                      </S.FeedbackContainer>
                    )}
                  </>
                )}
              </S.ContentContainer>
            </S.ExplanationContainer>
            <S.ExplanationContainer>
              <S.HeaderContainer>
                <S.TitleContainer>
                  <S.NumberContainer>2</S.NumberContainer>
                  <S.Title>Comentário em texto</S.Title>
                  <RatingFeedbackComponent
                    averageGrade={feedback.average_grade}
                    evaluationsNumber={feedback.total_evaluations}
                  />
                </S.TitleContainer>
                <S.ControlButtonsContainer>
                  {!editExplanation && explanation && (
                    <S.ButtonEdit onClick={handleEdit}>
                      <MdEdit />
                      Editar
                    </S.ButtonEdit>
                  )}
                  {editExplanation && (
                    <S.ButtonFill onClick={handleSave}>
                      <img src={SaveIcon} alt="Icone de salvar" />
                      Salvar
                    </S.ButtonFill>
                  )}
                  <ButtonWithPopper>
                    {loadingChapter ? (
                      <S.ButtonLoaderContainer>
                        <Ring size={20} color="#019d8a" />
                      </S.ButtonLoaderContainer>
                    ) : (
                      <S.ButtonPopper onClick={handleOpenPdf}>
                        <HandBookIcon /> Abrir apostila
                      </S.ButtonPopper>
                    )}
                  </ButtonWithPopper>
                </S.ControlButtonsContainer>
              </S.HeaderContainer>
              <S.ContentQuestionContainer>
                <S.MarginContainer>
                  {!editExplanation ? (
                    <S.CommentTextContainer>
                      {loadingContent ? (
                        <S.LoaderContainer>
                          <Loader />
                        </S.LoaderContainer>
                      ) : (
                        <ListedQuestionComments explanationContent={explanationNewData} />
                      )}
                    </S.CommentTextContainer>
                  ) : (
                    <QuestionEditor
                      question={question}
                      commentStorageNameRef={commentStorageNameRef}
                      data={explanationNewData}
                      setData={setExplanationNewData}
                      questionExplanation={questionExplanation}
                      setQuestionExplanation={setQuestionExplanation}
                      notFound={notFound}
                      setNotFound={setNotFound}
                      saveOnFocusOut={saveOnFocusOut}
                    />
                  )}
                </S.MarginContainer>
                {currentImage && (
                  <ImageZoomModal
                    image={currentImage}
                    visible={fullScreenImage}
                    close={() => setFullScreenImage(false)}
                  />
                )}
              </S.ContentQuestionContainer>
            </S.ExplanationContainer>
          </S.CommentsContainer>
        )}

        {question.explanation_last_updated && (
          <S.CommentUpdatedText>
            Última atualização em{' '}
            <strong>{format(new Date(question.explanation_last_updated), 'dd/MM/yyyy')}</strong>.
          </S.CommentUpdatedText>
        )}
      </S.QuestionCommentContainer>
      <GeneratedCommentLoadingModal
        show={showLoadingModal}
        close={() => setShowLoadingModal(false)}
      />
      <ModalWithHeader
        title="Atenção"
        show={showHandbookModal}
        close={() => setShowHandbookModal(false)}
      >
        <S.ModalContent>
          <S.ModalText>Nenhuma apostila encontrada</S.ModalText>
          <S.ModalButton onClick={() => setShowHandbookModal(false)}>Fechar</S.ModalButton>
        </S.ModalContent>
      </ModalWithHeader>
    </>
  );
}
