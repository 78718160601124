import React, { useState, useCallback, useEffect } from 'react';

import { CourseAPICMS } from '~/services/apiCallsCMS';
import { useTrackCategory } from '~/hooks-querys/trackCategory';

import ModalFilter from '~/components/ModalFilter';
import SelectWithPagination from '~/components/SelectWithPagination';
import SelectComponent from '~/components/SelectComponent';
import * as S from './styles';

import SearchIcon from '~/assets/drops-search.svg';

function Filter({ filter, setFilter }) {
	const [selectedCourse, setSelectedCourse] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [categorys, setCategorys] = useState([]);
	const [loading, setLoading] = useState(false);

	const { data: trackCategoryData, isLoading: loadingTrackCategory } = useTrackCategory({
		ordering: 'abbreviation',
	});

	const transformOptions = (item) => {
		if (!item.id || !item.name) return;

		return {
			label: item.name,
			key: item.id,
			value: item.id,
			item,
		};
	};

	useEffect(() => {
		if (!trackCategoryData && !trackCategoryData?.results) return;
		setCategorys((categorys) => [...categorys, ...trackCategoryData.results.map(transformOptions)]);
	}, [trackCategoryData]);

	const loadCourses = useCallback(async (search, loadedOptions, { page }) => {
		setLoading(true);
		const { data } = await CourseAPICMS.list({
			page,
			search,
			ordering: 'name',
			residency: true,
		});
		setLoading(false);
		return {
			options: data.results.map(transformOptions),
			hasMore: data.next,
			additional: {
				page: page + 1,
			},
		};
	}, []);

	const changeFilter = (value) => {
		setFilter({ ...filter, search: value, page: 1 });
	};

	const selectValueStyle = {
		fontSize: '14px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	};

	const selectedProps = {
		width: '100%',
		controlStyle: { marginLeft: 0 },
		singleValueStyle: selectValueStyle,
		placeholderStyle: selectValueStyle,
		valueContainerStyle: { flexWrap: 'nowrap' },
		containerStyle: { minWidth: '140px' },
	};

	return (
		<>
			<S.FilterInput>
				<img src={SearchIcon} alt="Search icon" />
				<input
					type="text"
					value={filter.search}
					onChange={(e) => changeFilter(e.target.value)}
					placeholder="Pesquisar"
				/>
			</S.FilterInput>
			<SelectWithPagination
				name="course"
				height={40}
				width="100%"
				value={selectedCourse}
				onChange={(e) => {
					setSelectedCourse(e);
					setFilter({ ...filter, course: e.value, page: 1 });
				}}
				loadOptions={loadCourses}
				placeholder={loading ? 'Carregando...' : 'Curso'}
			/>
			<SelectComponent
				name="residency-degree"
				placeholder={loadingTrackCategory ? 'Carregando...' : 'Nível de residência'}
				value={selectedCategory}
				onChange={(e) => {
					setSelectedCategory(e);
					setFilter({ ...filter, category: e.value, page: 1 });
				}}
				options={categorys}
				{...selectedProps}
			/>
		</>
	);
}

export default Filter;
