export function saveFilters(data) {
  return {
    type: '@questionFilters/SAVE_FILTERS',
    payload: { data },
  };
}

export function saveShowMoreFilters(data) {
  return {
    type: '@questionFilters/SHOW_MORE_FILTERS',
    payload: { data },
  };
}

export function saveQuestions(data) {
  return {
    type: '@questionFilters/SAVE_QUESTIONS',
    payload: { data },
  };
}

export function resetQuestions() {
  return {
    type: '@questionFilters/RESET_QUESTIONS',
  };
}

export function saveInstitutions(data) {
  return {
    type: '@questionFilters/SAVE_INSTITUTIONS',
    payload: { data },
  };
}

export function saveCourses(data) {
  return {
    type: '@questionFilters/SAVE_COURSES',
    payload: { data },
  };
}