import React, { useEffect } from 'react';
import { useState } from 'react';
import HeaderComponent from '~/components/HeaderComponent';
import { QuestionAPICMS } from '~/services/apiCallsCMS';
import QuestionItem from '~/components/QuestionItem';
import { toast } from 'react-toastify';
import { FilterContainer, Header, Filter } from '../AdminExams/ListTracks/Exams/styles';

export default function QuestionsCMS() {
  const [search, setSearch] = useState('');
  const [question, setQuestion] = useState('');

  async function getQuestions() {
    const data = await QuestionAPICMS.get(search);
    if (data) {
      setQuestion(data.data);
    } else {
      toast.error('Questão não encontrada');
      setSearch('');
      setQuestion(null);
    }
  }
  function showQuestion() {
    const path = `/question/${search}/video`;
    const newWindow = window.open(path, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }
  return (
    <>
      <HeaderComponent title="Questões" description="Acesse todas as questões da base de dados" />
      <FilterContainer>
        <Header>Filtrar questões</Header>
        <Filter>
          <input
            type="text"
            placeholder="Pesquisar"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                getQuestions();
              }
            }}
          />
        </Filter>
      </FilterContainer>
      {question && (
        <QuestionItem
          itemHeight={56}
          itemWidth="100%"
          question={question}
          onPressItem={() => showQuestion()}
        />
      )}
    </>
  );
}
