import React, { useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  MdClose,
  MdZoomIn,
  MdZoomOut,
  MdZoomOutMap,
  MdChevronLeft,
  MdChevronRight,
} from 'react-icons/md';
import { useWindowSize } from '~/utils/util';
import {
  BackgroundCover,
  Screen,
  Header,
  ChevronContainer,
  ContainerImage,
  MenuRight,
} from './styles';

// import ImageZoom from 'react-native-image-pan-zoom';

export default function ImageZoomModal({
  image,
  visible,
  close,
  goToPrev,
  goToNext,
}) {
  const [originalDimension, setOriginalDimension] = useState();
  const { width, height } = useWindowSize();
  const [dimension, setDimension] = useState();

  useEffect(() => {
    setDimension(null);
  }, [image]);

  async function onImgLoad({ target: img }) {
    setOriginalDimension({ height: img.offsetHeight, width: img.offsetWidth });
    setDimension({ height: img.offsetHeight, width: img.offsetWidth });
  }

  function handlerZoomIn() {
    if (
      dimension &&
      dimension.width + 20 <= width &&
      dimension.height + 20 <= height - 80
    ) {
      setDimension({
        height: dimension.height + 20,
        width: dimension.width + 20,
      });
    }
  }
  function handlerZoomOut() {
    if (
      dimension &&
      dimension.width - 20 >= 20 &&
      dimension.height - 20 >= 20
    ) {
      setDimension({
        height: dimension.height - 20,
        width: dimension.width - 20,
      });
    }
  }

  async function handlerReset() {
    if (originalDimension) {
      setDimension({
        height: originalDimension.height,
        width: originalDimension.width,
      });
    }
  }

  return (
    <>
      {image && (
        <BackgroundCover show={visible}>
          <Screen show={visible}>
            <TransformWrapper
              options={{ limitToWrapper: true }}
              defaultScale={1}
              minScale={1}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <Header>
                    <div className="center">
                      <ChevronContainer
                        onClick={() => !!goToPrev && goToPrev()}
                        show={!!goToPrev && visible}
                        isPrev
                      >
                        <MdChevronLeft
                          className="icon"
                          size={40}
                          color="#00205B"
                        />
                      </ChevronContainer>
                      <ChevronContainer
                        onClick={() => !!goToNext && goToNext()}
                        show={!!goToNext && visible}
                      >
                        <MdChevronRight
                          className="icon"
                          size={40}
                          color="#00205B"
                        />
                      </ChevronContainer>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        width: 50,
                        height: 50,
                      }}
                    >
                      <MdClose
                        className="inner-box"
                        color="#00205B"
                        size={34}
                        onClick={() => {
                          handlerReset();
                          close();
                        }}
                      />
                    </div>
                  </Header>

                  <MenuRight>
                    <div
                      style={{
                        justifyContent: 'center',
                        width: 50,
                        height: 50,
                        cursor: 'all-cursor',
                        alignItems: 'center',
                        display: 'flex',
                        alignContent: 'center',
                        position: 'relative',
                        right: '-3px',
                      }}
                    >
                      <MdZoomOut
                        className="inner-box"
                        color="#FFF"
                        size={24}
                        onClick={() => {
                          handlerZoomOut();
                          return zoomOut;
                        }}
                      />
                    </div>

                    <div
                      style={{
                        justifyContent: 'center',
                        width: 50,
                        height: 50,
                        cursor: 'all-cursor',
                        alignItems: 'center',
                        display: 'flex',
                        alignContent: 'center',
                        position: 'relative',
                        right: '-3px',
                      }}
                    >
                      <MdZoomIn
                        className="inner-box"
                        color="#FFF"
                        size={24}
                        onClick={() => {
                          handlerZoomIn();
                          return zoomIn;
                        }}
                      />
                    </div>
                    <div
                      style={{
                        justifyContent: 'center',
                        width: 50,
                        height: 50,
                        cursor: 'all-cursor',
                        alignItems: 'center',
                        display: 'flex',
                        alignContent: 'center',
                        position: 'relative',
                        right: '-3px',
                      }}
                    >
                      <MdZoomOutMap
                        className="inner-box"
                        color="#FFF"
                        size={24}
                        onClick={() => {
                          handlerReset().then(() => resetTransform());
                        }}
                      />
                    </div>
                  </MenuRight>
                  <div
                    style={{
                      border: 'solid 1px #a6a6a6',
                      margin: '20px',
                      background: '#fafafa',
                    }}
                  >
                    <TransformComponent>
                      <ContainerImage>
                        <img
                          src={image.uri}
                          alt=""
                          onLoad={(e) => {
                            onImgLoad(e).then(() => resetTransform());
                          }}
                          style={{
                            width:
                              dimension && dimension.width
                                ? dimension.width
                                : null,
                            height:
                              dimension && dimension.height
                                ? dimension.height
                                : null,
                            maxWidth: width,
                            maxHeight: height - 80,
                          }}
                        />
                      </ContainerImage>
                    </TransformComponent>
                  </div>

                  <div className="titleImage">
                    Utilize o scroll do mouse para dar zoom na imagem.
                  </div>
                </>
              )}
            </TransformWrapper>
          </Screen>
        </BackgroundCover>
      )}
    </>
  );
}

ImageZoomModal.defaultProps = {
  visible: false,
  close: {},
  goToPrev: null,
  goToNext: null,
};
