/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';

import { MdEdit } from 'react-icons/md';
import { updateAvatarRequest } from '~/store/modules/user/actions';

import { Container, ChangeAvatarContainer } from './styles';

export default function AvatarInput({ urlAvatar, width, height }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);

  const { defaultValue, registerField } = useField('avatar');

  const [file, setFile] = useState(defaultValue && defaultValue.id);
  const [preview, setPreview] = useState(defaultValue && defaultValue.url);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: 'avatar_id',
        ref: ref.current,
        path: 'dataset.file',
      });
    }
  }, [ref, registerField]);

  async function handleChange(e) {
    const data = {
      avatar: e.target.files[0],
      id: profile.id,
      student: profile.physician.id,
    };

    dispatch(updateAvatarRequest(data));
  }

  return (
    <Container>
      <>
        <label htmlFor="avatar">
          <img
            src={preview || urlAvatar}
            alt=""
            style={{ width, height, objectFit: 'cover' }}
          />

          <input
            type="file"
            name="avatar"
            id="avatar"
            accept="image/*"
            data-file={file}
            onChange={handleChange}
            ref={ref}
            disabled
          />
        </label>
{/*        <ChangeAvatarContainer onClick={() => ref.current.click()}>
          <MdEdit size={14} color="#fff" />
        </ChangeAvatarContainer>*/}
      </>
    </Container>
  );
}

AvatarInput.propTypes = {
  urlAvatar: PropTypes.oneOfType([PropTypes.string, PropTypes.shape])
    .isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

AvatarInput.defaultProps = {
  width: 120,
  height: 120,
};
