import React, { useState, useEffect } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ItemQuestion from '../ItemQuestion';
import * as S from './styles';

const ItemSpecialties = ({
  option,
  name,
  key,
  onDragQuestionStart,
  onDragQuestionEnter,
  allQuestions,
  setQuestions,
  setMappedQuestions,
  dragQuestionItem,
  dragOverQuestionItem,
  canAction,
}) => {
  const [open, setOpen] = useState(false);
  const [specialityQuestions, setSpecialityQuestions] = useState([]);

  const onSortQuestions = (dragQuestionItem, dragOverQuestionItem) => {
    const _itemsOptions = [...specialityQuestions];
    const draggedItemContent = _itemsOptions.splice(dragQuestionItem, 1)[0];
    _itemsOptions.splice(dragOverQuestionItem, 0, draggedItemContent);
    setSpecialityQuestions(_itemsOptions);

    let allQuestionsCopy = allQuestions;
    allQuestionsCopy[name] = _itemsOptions;
    setMappedQuestions(allQuestionsCopy);

    setQuestions(Object.values(allQuestionsCopy).flat());
  };

  const GetMaxNumber = () => {
    if (!specialityQuestions) return;
    let questionsNumbers = [];

    specialityQuestions.forEach((question) => {
      questionsNumbers.push(question.question_number);
    });
    return questionsNumbers.sort((a, b) => a - b)[questionsNumbers.length - 1];
  };

  const GetMinNumber = () => {
    if (!specialityQuestions) return;
    let questionsNumbers = [];

    specialityQuestions.forEach((question) => {
      questionsNumbers.push(question.question_number);
    });
    return questionsNumbers.sort((a, b) => b - a)[questionsNumbers.length - 1];
  };

  const deleteQuestion = (id) => {
    if (!specialityQuestions) return;

    const _itemsOptions = specialityQuestions.filter((question) => question.id !== id);
    setSpecialityQuestions(_itemsOptions);

    let allQuestionsCopy = allQuestions;
    allQuestionsCopy[name] = _itemsOptions;
    setMappedQuestions(allQuestionsCopy);

    setQuestions(Object.values(allQuestionsCopy).flat());
  };

  useEffect(() => {
    if (!allQuestions) return;
    setSpecialityQuestions(allQuestions[name]);
  }, [allQuestions]);

  return (
    <div onClick={() => setOpen(!open)} key={key}>
      <Collapse collapsedSize={89} in={open} key={`${option.name}_draggable_folder`}>
        <S.Container open={open}>
          <S.NameContainer>
            <S.NameOption>{option.name}</S.NameOption>
          </S.NameContainer>

          <S.RangeAndExpandIcon>
            <S.RangeTextAndChipContainer>
              Alcance
              <S.RangeContainer color={option.colors.text}>
                <S.RangeText>{GetMinNumber()}</S.RangeText>
                <S.RangeText>a</S.RangeText>
                <S.RangeText>{GetMaxNumber()}</S.RangeText>
              </S.RangeContainer>
            </S.RangeTextAndChipContainer>
            <S.ExpandIconContainer>
              <S.ExpandIcon size={18} color="#181A1E" open={open} />
            </S.ExpandIconContainer>
          </S.RangeAndExpandIcon>
        </S.Container>

        <S.QuestionsContainer onClick={(e) => e.stopPropagation()}>
          {specialityQuestions?.length > 0 &&
            specialityQuestions.map((question, questionIndex) => {
              return (
                <ItemQuestion
                  key={`item_question_${question.id}`}
                  id={question.id}
                  name={question.content}
                  number={question.question_number}
                  onDragStart={() => onDragQuestionStart(questionIndex)}
                  onDragEnter={() => onDragQuestionEnter(questionIndex)}
                  onDragEnd={() =>
                    onSortQuestions(dragQuestionItem.current, dragOverQuestionItem.current)
                  }
                  deleteQuestion={deleteQuestion}
                  canAction={canAction}
                />
              );
            })}
        </S.QuestionsContainer>
        <S.Divider />
      </Collapse>
    </div>
  );
};

export default ItemSpecialties;
