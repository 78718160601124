/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';
import * as S from './styles';

export default function IASuggestionPagination(
  {
    currentPage,
    totalPages,
    goToPreviousPage,
    goToNextPage,
  }
) {

  const disablePreviousPage = () => {
    return currentPage === 1;
  };

  const disableNextPage = () => {
    return !(currentPage < totalPages);
  };

  return (
    <S.Container>
      <S.Button disabled={disablePreviousPage()} onClick={goToPreviousPage}>
        <MdArrowBack size={22} />
        Anterior
      </S.Button>
      <S.Pages>
        {`${currentPage} / `}<b>{totalPages}</b>
      </S.Pages>
      <S.Button disabled={disableNextPage()} onClick={goToNextPage}>
        Próximo
        <MdArrowForward size={22} />
      </S.Button>
    </S.Container>

  );
}
