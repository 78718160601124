import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const NumberIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 1000px;
  background: #01c3aa;
  color: #fff;
  font-size: 20.3px;
  font-weight: 600;
  line-height: 36.5px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 56px 0 60px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 22.8px;
  font-weight: 600;
  line-height: 30.4px;
  color: #00205b;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Subtitle = styled.h2`
  margin: 0;
  font-size: 20.3px;
  font-weight: 600;
  line-height: 27px;
  color: #00205b;
`;

export const ContentTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 36px 0 24px;
`;

export const ContentTitle = styled.h1`
  margin: 0;
  font-size: 25.6px;
  font-weight: 600;
  line-height: 34.2px;
  text-align: left;
  color: #00205b;
`;

export const ContentSubtitle = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 21.3px;
  color: #4f5154;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: ${(props) => (props.fullWidth ? 'none' : '544px')};
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: ${(props) => (props.hasTitle ? '0' : '40px')};
  border-bottom: ${(props) => (props.borderBottom ? '1px solid #e5e8ee' : 'none')};
  padding-bottom: ${(props) => (props.shortPadding ? '32px' : '36px')};
`;

export const StyledHr = styled.hr`
  width: 100%;
  margin: 0;
  background: #e5e8ee;
`;
