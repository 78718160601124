import { useQuery } from '@tanstack/react-query';

import { TaskAPICMS } from '~/services/apiCallsCMS';

const getOffcanvasData = async (id, params) => {
  try {
    const response = await TaskAPICMS.getOffcanvasData(id, params);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useGetOffcanvasData(id, params, options) {
  return useQuery({
    queryKey: ['getOffcanvasData', id],
    queryFn: () => getOffcanvasData(id, params),
    staleTime: 0,
    ...options?.config,
  });
}
