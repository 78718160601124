import styled from 'styled-components';

export const InputStyle = styled.div`
  max-width: 544px;
  margin-top: 10px;
  width: 100%;
  height: 48px;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  gap: 10px;
  :hover {
    box-shadow: ${(props) => (props.disabled ? '0px 0px 0px 6px rgba(61, 85, 130, 0.2)' : '')};
    background-color: ${(props) => (props.disabled ? '#fff' : '')};
    border: ${(props) => (props.disabled ? '3px solid #3d5582' : '')};
  }
  :focus-within {
    box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
    background-color: #fff;
    border: 3px solid #01cfb5;
  }
  input {
    width: 100%;
    height: 30px;
    border: none;
    background: none;
    color: #3d5582;
    font-size: 16px;

    ::placeholder {
      color: #3d5582;
      font-weight: 400;
    }
    ::-webkit-inner-spin-button {
      color: red;
    }
  }
`;
