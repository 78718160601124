import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const Label = styled.p`
  margin: 0;
  font-size: 14.2px;
  font-weight: 400;
  line-height: 18.96px;
  color: #4f5154;
`;

export const BottomLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: ${(props) => props.width && `${props.width + 56}px`};
`;

export const BottomLabel = styled.p`
  margin: 0;
  font-size: 11.2px;
  font-weight: 400;
  line-height: 15px;
  color: #00205b;
`;

export const RatingContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
