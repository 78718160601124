import styled from 'styled-components';
import { ReactComponent as ArrowLeft } from '~/assets/drops-arrow-left.svg';
import { ReactComponent as ArrowRight } from '~/assets/drops-arrow-right.svg';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 48px;
  max-width: 1388px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 20px 24px;
  margin: 0 32px;
  border-radius: 8px;
  box-shadow: 0px 32px 64px 0px #181a1e14, 0px 8px 32px 0px #181a1e29;
  position: sticky;
  bottom: 20px;
  z-index: 1000;
`;

const buttonProps = {
  height: '40px',
  padding: '0 16px',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14.2px',
  fontWeight: '600',
  lineHeight: '18.96px',
  textAlign: 'center',
  borderRadius: '320px',
  border: 'none',
};

export const SaveButton = styled.button`
  ${buttonProps}
  background: #019d8a;
  color: #fff;

  &:hover {
    background: #01b69f;
  }
`;

export const BackButton = styled.button`
  ${buttonProps}
  background: transparent;
  color: #019d8a;

  &:hover {
    background: #e5faf7;
  }
`;

export const StyledArrowLeft = styled(ArrowLeft)`
  path {
    fill: #019d8a;
  }
`;

export const StyledArrowRight = styled(ArrowRight)`
  path {
    fill: #fff;
  }
`;
