/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import Axios from 'axios';
import {
  TagAPICMS,
  StudentFeedbackAPICMS,
  QuestionExplanationCMS,
  QuestionAPICMS,
  GetQuestionsAPICMS,
  RateSuggestedTagAPICMS,
} from '~/services/apiCallsCMS';
import SelectWithPagination from '~/components/SelectWithPagination';
import RatingFeedbackComponent from '~/components/RatingFeedbackComponent';
import ImageZoomModal from '~/components/ImageZoomModal';
import Loader from '~/components/Loader';
import EmptyContent from '~/components/EmptyContent';
import MultiselectAutocompleteWithList from '~/components/MultiselectAutocompleteWithList';
import RatingComponent from '~/components/RatingComponent';
import ListedQuestionComments from '../QuestionComment/ListedQuestionComments';
import GeneratedCommentLoadingModal from '../GeneratedCommentLoadingModal';
import { ReactComponent as SaveIcon } from '~/assets/drops-save.svg';
import { ReactComponent as NoCommentIcon } from '~/assets/no-comment.svg';
import RobotIcon from '~/assets/dropsRobot';
import { SelectStyle } from '~/pages/_layouts/default/styles';
import { useGetCfas } from '~/hooks-querys/tag';
import { useWindowSize } from '~/utils/util';

import * as S from './styles';
import { TAG_TYPE } from '~/utils/variables';

const emptyData = {
  introduction: '',
  option_a: '',
  option_b: '',
  option_c: '',
  option_d: '',
  option_e: '',
  conclusion: '',
};

export default function QuestionTags({ question, loadTags }) {
  const tagId = question.subtags;
  const containerRef = useRef();
  const { width } = useWindowSize();
  const [explanationNewData, setExplanationNewData] = useState(emptyData);
  const [loadingContent, setLoadingContent] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [tagSelected, setTagSelected] = useState(null);
  const [feedback, setFeedback] = useState({ average_grade: 0, total_evaluations: 0 });
  const [loadingSave, setLoadingSave] = useState(false);
  const [selectMaxWidth, setSelectMaxWidth] = useState('100%');
  const [cfaOptions, setCfaOptions] = useState([]);
  const [selectedCfaOptions, setSelectedCfaOptions] = useState([]);
  const [loadingTags, setLoadingTags] = useState(false);
  const [loadingTagsAndCfa, setLoadingTagsAndCfa] = useState(true);
  const [generatedFocus, setGeneratedFocus] = useState(null);
  const [generatedCfas, setGeneratedCfas] = useState([]);
  const [ratingSuggestion, setRatingSuggestion] = useState(undefined);
  const [ratingCreateId, setRatingCreateId] = useState(null);
  const [ratingUpdateId, setRatingUpdateId] = useState(null);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [waitingIaSuggestion, setWaitingIaSuggestion] = useState(false);
  const [emptyFocusIASuggestion, setEmptyFocusIASuggestion] = useState(false);
  const [emptyCfaIASuggestion, setEmptyCfaIASuggestion] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [hasCfa, setHasCfa] = useState(false);
  const [loadingFocusSuggestions, setLoadingFocusSuggestions] = useState(false);
  const [loadingCfaSuggestions, setLoadingCfaSuggestions] = useState(false);
  const [saveTagType, setSaveTagType] = useState('all');
  const [ignoreRating, setIgnoreRating] = useState(true);

  const { data: cfasData, isFetching: fetchingCfas } = useGetCfas(tagSelected?.value, {
    config: {
      enabled: !!tagSelected,
    },
  });

  const filterCfa = async (search) => {
    if (!search) {
      return cfaOptions;
    }
    return cfaOptions.filter((item) => cfaOptions.includes(item.label));
  };

  const handlerImage = (event) => {
    if (event && event.data && event.data.image) {
      setCurrentImage({ uri: event.data.image });
      setFullScreenImage(true);
    }
  };

  const getTagsById = useCallback(async (id) => {
    const source = Axios.CancelToken.source();
    const { data } = await TagAPICMS.get(id, source.token);
    return data;
  }, []);

  const getContent = () => {
    setLoadingContent(true);

    const source = Axios.CancelToken.source();
    QuestionExplanationCMS.list({ question: question.id }, source.token)
      .then((result) => {
        setLoadingContent(false);
        if (result && result.data && result.data.count > 0) {
          setExplanationNewData({
            introduction: result.data.results[0].introduction,
            option_a: result.data.results[0].option_a,
            option_b: result.data.results[0].option_b,
            option_c: result.data.results[0].option_c,
            option_d: result.data.results[0].option_d,
            option_e: result.data.results[0].option_e,
            conclusion: result.data.results[0].conclusion,
          });
          setNotFound(false);
          return;
        }
        setNotFound(true);
        setExplanationNewData({ ...explanationNewData, question: question.id });
      })
      .catch(() => {
        setNotFound(true);
        setExplanationNewData({ ...explanationNewData, question: question.id });
        setLoadingContent(false);
      });
  };

  const handleChangeTag = (tag) => {
    setTagSelected(tag);
    setSelectedCfaOptions([]);
  };

  const getIaSuggestion = async () => {
    const params = {
      question_id: question.id,
    };

    await GetQuestionsAPICMS.get_tag_ia_suggestion(params)
      .then((response) => {
        if (response && response.data && response.data.results) {
          if (response.data.results.length === 0) return;

          if (saveTagType === TAG_TYPE.FOCUS || saveTagType === 'all') {
            const generatedFocus = response.data.results.filter(
              (result) => result.tag_type === TAG_TYPE.FOCUS,
            );

            if (generatedFocus && generatedFocus.length > 0) {
              setWaitingIaSuggestion(false);
              const focus = generatedFocus[0]?.tag_output;
              const generatedFocusId = generatedFocus[0].id;

              if (!focus) {
                return setEmptyFocusIASuggestion(true);
              }

              getTagsById(focus).then((result) => {
                if (result) {
                  setGeneratedFocus({
                    key: result.id,
                    label: `${result.new_residency_degree_abbreviation} - ${result.verbose}`,
                    value: result.id,
                    generated_id: generatedFocusId,
                  });
                }
              });
            }
          }

          if (saveTagType === TAG_TYPE.CFA || saveTagType === 'all') {
            const generatedCfa = response.data.results.filter(
              (result) => result.tag_type === TAG_TYPE.CFA,
            );

            if (generatedCfa && generatedCfa.length > 0) {
              setWaitingIaSuggestion(false);
              setRatingCreateId(generatedCfa[0].id);
              getRating(generatedCfa[0].id);
              const cfas = generatedCfa[0]?.tag_output;

              if (!cfas) {
                return setEmptyCfaIASuggestion(true);
              }

              cfas.forEach((cfa) => {
                getTagsById(cfa).then((result) => {
                  if (result) {
                    setGeneratedCfas((generatedCfas) => [
                      ...generatedCfas,
                      {
                        key: result.id,
                        label: `${result.new_residency_degree_abbreviation} - ${result.verbose}`,
                        value: result.id,
                        parent_tag: result.parent_tag,
                      },
                    ]);
                  }
                });
              });
            }
          }
        }
      })
      .finally(() => {
        setLoadingFocusSuggestions(false);
        setLoadingCfaSuggestions(false);
      })
      .catch(() => {
        setWaitingIaSuggestion(false);
        setLoadingFocusSuggestions(false);
        setLoadingCfaSuggestions(false);
      });
  };

  const generateIaSuggestion = async (tagType) => {
    if (waitingIaSuggestion) return;
    const params = {
      tag_type: tagType,
      ...(tagType === TAG_TYPE.CFA && { tag: tagSelected?.value || generatedFocus.value || false }),
    };

    setSaveTagType(tagType);
    return await GetQuestionsAPICMS.generate_tag_ia_suggestion(question.id, params);
  };

  const handleGenerateSuggestion = (type) => {
    if (waitingIaSuggestion) {
      return setShowLoadingModal(true);
    }

    setWaitingIaSuggestion(true);
    generateIaSuggestion(type);
  };

  const getRating = (suggestionId) => {
    RateSuggestedTagAPICMS.get_rating({ tag_suggestion: suggestionId })
      .then((response) => {
        if (
          response &&
          response?.data &&
          response.data?.results &&
          response.data.results.length > 0
        ) {
          setRatingSuggestion(response.data.results[0].grade);
          setRatingUpdateId(response.data.results[0].id);
        }
      })
      .finally(() => setIgnoreRating(false));
  };

  const showFocusLinkButton = () => {
    if (!tagSelected) return true;
    return tagSelected.value !== generatedFocus.value;
  };

  const showCfaLinkButton = (id) => {
    return !selectedCfaOptions.find((cfa) => cfa.key === id);
  };

  const showIaSuggestion = () => {
    return !hasCfa;
  };

  const disableCfaIaButton = () => {
    if (!hasFocus && !hasCfa) {
      return !generatedFocus;
    }
    return false;
  };

  const handleLinkFocus = () => {
    return setTagSelected(generatedFocus);
  };

  const handleLinkCfa = (cfa) => {
    getTagsById(cfa.parent_tag)
      .then((result) => {
        if (result) {
          setTagSelected({
            key: result.id,
            label: `${result.new_residency_degree_abbreviation} - ${result.verbose}`,
            value: result.id,
          });
          setSelectedCfaOptions((selectedCfaOptions) => [...selectedCfaOptions, cfa]);
        } else {
          toast.error('Selecione um Tema/foco válido');
        }
      })
      .catch(() => toast.error('Erro ao vincular CFA'));
  };

  const handleSave = () => {
    setLoadingSave(true);

    if (tagSelected && tagSelected.value) {
      getTagsById(tagSelected.value).then((result) => {
        if (result) {
          const params = {
            tag: selectedCfaOptions.map((cfa) => cfa.value).concat([result.id, result.parent_tag]),
            subtags: [result.id],
            residency_degree: question.residency_degree,
          };

          QuestionAPICMS.update(question.id, params)
            .then(() => {
              setLoadingSave(false);
              toast.success('Tag salva com sucesso!');
            })
            .catch(() => {
              setLoadingSave(false);
              toast.error('Ocorreu algum problema!');
            });
        }
      });
    } else {
      toast.error('Selecione um Foco/Tema');
      setLoadingSave(false);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handlerImage);
    setLoadingFocusSuggestions(true);
    setLoadingCfaSuggestions(true);
    getIaSuggestion();
  }, []);

  useEffect(() => {
    getContent();
  }, [question]);

  useEffect(() => {
    if (!question.id) return;

    const params = {
      content_type: 'question',
      object_id: question.id,
    };

    StudentFeedbackAPICMS.getAverageGrade(params).then((response) => {
      setFeedback({
        average_grade: response.data?.average_grade || 0,
        total_evaluations: response.data?.total_evaluations || 0,
      });
    });
  }, [question.id]);

  useEffect(() => {
    if (!tagId) return;

    if (!Array.isArray(tagId) || tagId.length === 0) {
      return;
    }

    const tagIdFromArray = tagId.find((tag) => tag.tag_type === TAG_TYPE.FOCUS).id;

    if (!tagIdFromArray) {
      return toast.error('Erro ao retornar a tag');
    }

    if (tagIdFromArray) {
      setLoadingTags(true);
      setHasFocus(true);
      getTagsById(tagIdFromArray)
        .then((result) => {
          if (result) {
            setTagSelected({
              key: result.id,
              label: `${result.new_residency_degree_abbreviation} - ${result.verbose}`,
              value: result.id,
            });
          }
        })
        .finally(() => setLoadingTags(false));
    }

    const cfaIdsFromArray = tagId
      .filter((tag) => tag.tag_type === TAG_TYPE.CFA)
      .map((ids) => {
        return ids.id;
      });

    if (cfaIdsFromArray.length > 0) {
      setHasCfa(true);
      cfaIdsFromArray.forEach((cfa) => {
        getTagsById(cfa).then((result) => {
          if (result) {
            setSelectedCfaOptions((selectedCfaOptions) => [
              ...selectedCfaOptions,
              {
                key: result.id,
                label: `${result.new_residency_degree_abbreviation} - ${result.verbose}`,
                value: result.id,
                parent_tag: result.parent_tag,
              },
            ]);
          }
        });
      });
    }
  }, [tagId]);

  useEffect(() => {
    if (!containerRef) return;

    if (width < 1525) {
      setSelectMaxWidth(containerRef.current.offsetWidth * 0.8);
    } else {
      setSelectMaxWidth('100%');
    }
  }, [containerRef, width]);

  useEffect(() => {
    if (!cfasData || fetchingCfas) return;
    setCfaOptions(cfasData);
  }, [cfasData]);

  useEffect(() => {
    if (fetchingCfas || loadingTags) return;
    setLoadingTagsAndCfa(false);
  }, [fetchingCfas, loadingTags]);

  useEffect(() => {
    if (waitingIaSuggestion) {
      setShowLoadingModal(true);
    }

    const checkComment = () => {
      if (waitingIaSuggestion) {
        getIaSuggestion();
      }
    };

    const interval = setInterval(checkComment, 2000);
    if (!waitingIaSuggestion) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [waitingIaSuggestion]);

  useEffect(() => {
    if (!ratingSuggestion) return;
    if (ignoreRating) {
      return setIgnoreRating(false);
    }

    if (ratingUpdateId) {
      RateSuggestedTagAPICMS.update(ratingUpdateId, { grade: ratingSuggestion });
    } else if (ratingCreateId) {
      const params = { tag_suggestion: ratingCreateId, grade: ratingSuggestion };
      RateSuggestedTagAPICMS.create(params).then((response) => {
        return setRatingUpdateId(response.data.id);
      });
    }
  }, [ratingSuggestion]);

  return (
    <>
      <S.QuestionCommentContainer>
        {!loadingTagsAndCfa && (
          <S.ExplanationContainer>
            <S.HeaderContainer>
              <S.TitleContainer>
                <S.NumberContainer>1</S.NumberContainer>
                <S.Title>Sugerir tagueamento via IA</S.Title>
              </S.TitleContainer>
            </S.HeaderContainer>

            <S.TagContainer>
              {loadingFocusSuggestions && loadingCfaSuggestions ? (
                <Loader />
              ) : (
                <>
                  <S.TagRow>
                    {!generatedFocus && !emptyFocusIASuggestion ? (
                      <>
                        <S.TagTitle>Tema e Foco</S.TagTitle>
                        <S.GenerateViaIaButtonContainer>
                          <S.GenerateViaIaButton
                            onClick={() => handleGenerateSuggestion(TAG_TYPE.FOCUS)}
                          >
                            <RobotIcon /> Sugerir via IA
                          </S.GenerateViaIaButton>
                        </S.GenerateViaIaButtonContainer>
                      </>
                    ) : (
                      <>
                        <S.GeneratedFocusAndCfaContainer>
                          <S.GeneratedFocusContainer>
                            <S.TitleAndFocusContainer>
                              <S.TagTitle>Tema e foco</S.TagTitle>
                              {emptyFocusIASuggestion ? (
                                <>Nenhum foco encontrado</>
                              ) : (
                                <>{generatedFocus.label}</>
                              )}
                            </S.TitleAndFocusContainer>
                            {emptyFocusIASuggestion ? (
                              <></>
                            ) : (
                              <S.LinkButton show={showFocusLinkButton()} onClick={handleLinkFocus}>
                                Vincular <S.StyledRightArrow />
                              </S.LinkButton>
                            )}
                          </S.GeneratedFocusContainer>
                        </S.GeneratedFocusAndCfaContainer>
                      </>
                    )}
                  </S.TagRow>

                  <>
                    {!emptyFocusIASuggestion && (
                      <>
                        {generatedCfas.length === 0 && !emptyCfaIASuggestion ? (
                          <S.TagColumn>
                            <S.TagRow>
                              <S.TagTitle>CFA</S.TagTitle>
                              <S.GenerateViaIaButtonContainer>
                                <S.GenerateViaIaButton
                                  disabled={disableCfaIaButton()}
                                  onClick={() => handleGenerateSuggestion(TAG_TYPE.CFA)}
                                >
                                  <RobotIcon /> Sugerir via IA
                                </S.GenerateViaIaButton>
                              </S.GenerateViaIaButtonContainer>
                            </S.TagRow>
                            <S.CfaGenerationLabel>
                              Escolha o Tema e Foco para habilitar sugestão via IA de CFA
                            </S.CfaGenerationLabel>
                          </S.TagColumn>
                        ) : (
                          <S.TagRow>
                            <S.GeneratedFocusAndCfaContainer>
                              <S.GeneratedFocusContainer>
                                <S.GeneratedFocusAndCfaContainer>
                                  <S.TagTitle>CFA</S.TagTitle>
                                  {emptyCfaIASuggestion ? (
                                    <S.GeneratedCfaContainer>
                                      Nenhum CFA encontrado
                                    </S.GeneratedCfaContainer>
                                  ) : (
                                    <>
                                      {generatedCfas.map((cfa) => {
                                        return (
                                          <S.GeneratedCfaContainer>
                                            {cfa.label}
                                            <S.LinkButton
                                              show={showCfaLinkButton(cfa.key)}
                                              onClick={() => handleLinkCfa(cfa)}
                                            >
                                              Vincular <S.StyledRightArrow />
                                            </S.LinkButton>
                                          </S.GeneratedCfaContainer>
                                        );
                                      })}
                                      <RatingComponent
                                        initialValue={ratingSuggestion}
                                        setRating={setRatingSuggestion}
                                        size="large"
                                      />
                                    </>
                                  )}
                                </S.GeneratedFocusAndCfaContainer>
                              </S.GeneratedFocusContainer>
                            </S.GeneratedFocusAndCfaContainer>
                          </S.TagRow>
                        )}
                      </>
                    )}
                  </>
                </>
              )}
            </S.TagContainer>
          </S.ExplanationContainer>
        )}

        <S.ExplanationContainer>
          <S.HeaderContainer>
            <S.TitleContainer>
              <S.NumberContainer>
                {showIaSuggestion() && !loadingTagsAndCfa ? 2 : 1}
              </S.NumberContainer>
              <S.Title>Taguear</S.Title>
            </S.TitleContainer>
            {!loadingTagsAndCfa && (
              <S.SaveButton onClick={handleSave} disabled={loadingSave}>
                <SaveIcon />
                {loadingSave ? 'Salvando...' : 'Salvar'}
              </S.SaveButton>
            )}
          </S.HeaderContainer>
          <S.TagContainer ref={containerRef}>
            {loadingTagsAndCfa ? (
              <Loader />
            ) : (
              <>
                <S.TagRow>
                  <S.TagTitle>Tema e Foco</S.TagTitle>
                  <SelectWithPagination
                    loadOptions={(search, loadedOptions, page) =>
                      loadTags(search, loadedOptions, {
                        ...page,
                        new_residency_degree: question?.new_residency_degree,
                      })
                    }
                    text={false}
                    backgroundColor="#F2F2F2"
                    value={tagSelected}
                    onChange={handleChangeTag}
                    placeholder="Selecione um foco"
                    menuPlacement="auto"
                    customNoOptionsMessage="Nenhuma encontrada"
                    singleValueStyle={{ ...S.selectValueContainerStyle, width: '95%' }}
                    valueContainerStyle={S.selectValueContainerStyle}
                    optionsStyle={SelectStyle}
                    controlStyle={{
                      ...SelectStyle,
                      width: '100%',
                      borderRadius: 8,
                      background: '#F1F2F2',
                      color: '#747678',
                    }}
                    placeholderStyle={{ color: '#d2d2d2', paddingLeft: 2 }}
                    height={48}
                    width={'100%'}
                    containerWidth={selectMaxWidth}
                  />
                </S.TagRow>
                <S.TagRow>
                  <div style={{ width: '100%' }}>
                    <MultiselectAutocompleteWithList
                      title={<S.TagTitle>CFA</S.TagTitle>}
                      selectedOptions={selectedCfaOptions}
                      setSelectedOptions={setSelectedCfaOptions}
                      placeholder={fetchingCfas ? 'Carregando...' : 'Adicionar CFA'}
                      options={cfaOptions}
                      fetchSuggestions={filterCfa}
                      loading={fetchingCfas}
                      disabled={!tagSelected?.value}
                      selectDescription="Adicione até no máximo 5 CFAs"
                    />
                  </div>
                </S.TagRow>
              </>
            )}
          </S.TagContainer>
        </S.ExplanationContainer>
      </S.QuestionCommentContainer>
      <S.CommentContainer>
        <S.HeaderContainer>
          <S.TitleContainer>
            <S.Title>Comentário em texto</S.Title>
            <RatingFeedbackComponent
              averageGrade={feedback.average_grade}
              evaluationsNumber={feedback.total_evaluations}
            />
          </S.TitleContainer>
        </S.HeaderContainer>
        <S.ContentQuestionContainer>
          <S.MarginContainer>
            <>
              {loadingContent ? (
                <S.LoaderContainer>
                  <Loader />
                </S.LoaderContainer>
              ) : (
                <>
                  {notFound ? (
                    <EmptyContent title={'Nenhum comentário encontrado'} icon={<NoCommentIcon />} />
                  ) : (
                    <ListedQuestionComments explanationContent={explanationNewData} />
                  )}
                </>
              )}
            </>
          </S.MarginContainer>
          {currentImage && (
            <ImageZoomModal
              image={currentImage}
              visible={fullScreenImage}
              close={() => setFullScreenImage(false)}
            />
          )}
        </S.ContentQuestionContainer>
      </S.CommentContainer>
      <GeneratedCommentLoadingModal
        show={showLoadingModal}
        close={() => setShowLoadingModal(false)}
      />
    </>
  );
}
