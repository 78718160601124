import React from 'react';
import {ReactComponent as FlashcardsCheck} from '~/assets/flashcards-check.svg';
import ModalWithHeader from '~/components/ModalWithHeader';
import {
  ModalContent,
  ModalText,
  ModalFooter,
  BackButton,
  FillButton,
  StyledArrowLeft,
} from '../../styles';

export default function ModalPublishAll(
  {
    showModal,
    handleClose,
    enableAll,
  }) {
  return (
    <ModalWithHeader
      show={showModal}
      close={handleClose}
      title="Liberar todos flashcards"
    >
      <ModalContent>
        <ModalText>
          Eu estou ciente que estou publicando todos os flashcards no deck para os aluno
        </ModalText>
      </ModalContent>
      <ModalFooter>
        <BackButton onClick={handleClose}>
          <StyledArrowLeft />
          Voltar
        </BackButton>
        <FillButton onClick={enableAll}>
          Liberar flashcards
        <FlashcardsCheck />
      </FillButton>
    </ModalFooter>
  </ModalWithHeader>
  );
}


