import { useQuery } from '@tanstack/react-query';

import { TaskNoteAPICMS } from '~/services/apiCallsCMS';

const getTaskNote = async (params) => {
  try {
    const response = await TaskNoteAPICMS.getTaskNote(params);
    if (!response && !response.data && !response.data.results) {
      throw new Error('No results found');
    }

    return response.data.results;
  } catch (error) {
    throw error;
  }
};

export default function useGetTaskById(params, options) {
  return useQuery({
    queryKey: ['getTaskNote', params],
    queryFn: () => getTaskNote(params),
    staleTime: 0,
    cacheTime: 0,
    ...options?.config,
  });
}
