import styled from 'styled-components';

export const StatusTag = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  height: 24px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 0 0 0 8px;
  pointer-events: none;

  color: ${(props) => props.color || 'black'};
  background: ${(props) => props.backgroundColor || 'white'};
`;

export const StatusCheckboxContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`;
