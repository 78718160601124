/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import HeaderComponent from '~/components/HeaderComponent';
import Tabs from '~/components/Tabs';
import { Mixpanel } from '~/services/analytics';
import history from '~/services/history';
import Challenges from './Challenges';
import Exams from './Exams';

import { Container, Wrap } from './styles';

function ListTracks() {
  const { location } = useHistory();

  const [titleHeader, setTitleHeader] = useState();
  const [actionText, setActionText] = useState();

  useEffect(() => {
    if (location.pathname === '/exam') {
      setTitleHeader('Provas');
      setActionText('Nova Prova');
    } else {
      setTitleHeader('Banco de questões Originais - Simulados');
      setActionText('Novo Simulado');
    }
  }, [location.pathname]);

  const getComponent = (statusTab) => {
    switch (location.pathname) {
      case '/exam':
        return <Exams statusTab={statusTab} />;
      case '/challenge':
        return <Challenges statusTab={statusTab} />;
      default:
        return <Exams statusTab={statusTab} />;
    }
  };

  function handleClick() {
    if (location.pathname === '/exam') {
      Mixpanel.track("Clicou 'Lista de provas - Nova prova'", {
        'Categoria de prova': 'Exame',
      });
    }
    history.push({ pathname: `${location.pathname}/new` });
  }

  return (
    <>
      <HeaderComponent
        title={titleHeader}
        description="Crie e gerencie o banco de questões"
        action={() => handleClick()}
        actionText={actionText}
        permissions={['add_track']}
      />
      <Wrap>
        <Container>
          <Tabs id="tabs-list-exams">
            <div label="Criação" id="list-exams-tab-exam">
              {getComponent('creation')}
            </div>
            <div label="Publicadas" id="list-exams-tab-challenge">
              {getComponent('published')}
            </div>
          </Tabs>
        </Container>
      </Wrap>
    </>
  );
}

export default ListTracks;
