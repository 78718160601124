import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as DragIcon } from '~/assets/drag-indicator.svg';
import Icons from './Icons';
import * as S from './styles';

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    maxWidth: 150,
    background: '#fff',
    boxShadow: '0px 2px 4px 0px #181A1E14, 0px 0px 2px 0px #181A1E29',
    color: '#181A1E',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    padding: '10px 16px',
  },
  customArrow: {
    color: '#fff',
    '&:before': {
      boxShadow: '0px 2px 4px 0px #181A1E14, 0px 0px 2px 0px #181A1E29',
    },
  },
}));

function ListAndOrderParent({ parentData, setParentData, parentAllowDrag }) {
  const classes = useStyles();
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const onDragStart = (index) => {
    dragItem.current = index;
  };

  const onDragEnter = (index) => {
    dragOverItem.current = index;
  };

  const onSortOptions = (dragItem, dragOverItem) => {
    const _itemsOptions = [...parentData];
    const draggedItemContent = _itemsOptions.splice(dragItem, 1)[0];
    _itemsOptions.splice(dragOverItem, 0, draggedItemContent);
    setParentData(_itemsOptions);
  };

  return (
    <S.Container>
      {parentData &&
        parentData.map((item, index) => {
          return (
            <S.ItemContent key={`draggable_item_${item.id}`}>
              <S.CardContainer
                draggable={parentAllowDrag}
                onDragStart={() => onDragStart(index)}
                onDragEnter={() => onDragEnter(index)}
                onDragEnd={() => onSortOptions(dragItem.current, dragOverItem.current)}
              >
                <S.LeftElements>
                  <S.NumberAndDragIconContainer>
                    <S.Number>{index + 1}</S.Number>
                    <Tooltip
                      title="Arraste para mudar a ordem"
                      arrow
                      placement="top"
                      classes={{ arrow: classes.customArrow, tooltip: classes.customTooltip }}
                    >
                      <DragIcon />
                    </Tooltip>
                  </S.NumberAndDragIconContainer>
                  <S.NameAndBadgeContainer>
                    <S.Name>{item.name}</S.Name>
                    {item.filled !== undefined && (
                      <S.Badge
                        color={item.filled ? '#098D4E' : '#B99503'}
                        bg_color={item.filled ? '#D8F4E6' : '#FDF6D7'}
                      >
                        {item.filled ? 'Preenchido' : 'Não preenchido'}
                      </S.Badge>
                    )}
                  </S.NameAndBadgeContainer>
                </S.LeftElements>
                <S.RightElements>
                  {item.buttons && item.buttons.length > 0 && (
                    <>
                      {item.buttons.map((button) => {
                        return (
                          <S.Button
                            key={`button_${button.icon}_${item.name}`}
                            hasBorder={button.hasBorder}
                            onClick={button.action}
                          >
                            <Icons iconName={button.icon} />
                            <S.ButtonText>{button.text}</S.ButtonText>
                          </S.Button>
                        );
                      })}
                    </>
                  )}
                </S.RightElements>
              </S.CardContainer>
            </S.ItemContent>
          );
        })}
    </S.Container>
  );
}

export default ListAndOrderParent;
