import React from 'react';
import { ReactComponent as DashboardIcon } from '~/assets/sidebar-icons/dashboard.svg';
import { ReactComponent as ChapterIcon } from '~/assets/sidebar-icons/chapters.svg';
import { ReactComponent as ExamIcon } from '~/assets/sidebar-icons/exams.svg';
import { ReactComponent as ChallengeIcon } from '~/assets/sidebar-icons/challenges.svg';
import { ReactComponent as TrackIcon } from '~/assets/sidebar-icons/tracks.svg';
import { ReactComponent as FlashcardIcon } from '~/assets/sidebar-icons/flashcards.svg';
import { ReactComponent as CourseIcon } from '~/assets/sidebar-icons/courses.svg';
import { ReactComponent as ActivitiesIcon } from '~/assets/sidebar-icons/activities.svg';
import { ReactComponent as FinancialIcon } from '~/assets/sidebar-icons/financial.svg';
import { ReactComponent as FlashcardsDecksIcon } from '~/assets/sidebar-icons/flashcards-decks.svg';

export const menuItems = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    key: 'activities',
    name: 'Atividades',
    icon: <ActivitiesIcon />,
  },
  {
    key: 'chapter',
    name: 'Capítulos',
    icon: <ChapterIcon />,
  },
  {
    key: 'exam',
    name: 'Provas',
    icon: <ExamIcon />,
  },
  {
    key: 'challenge',
    name: 'Simulados',
    icon: <ChallengeIcon />,
  },
  {
    key: 'tracks',
    name: 'Trilhas',
    icon: <TrackIcon />,
  },
  {
    key: 'flashcards',
    name: 'Flashcards',
    icon: <FlashcardIcon />,
  },
  {
    key: 'flashcards-decks',
    name: 'Decks Flashcards',
    icon: <FlashcardsDecksIcon />,
  },
  {
    key: 'courses',
    name: 'Cursos',
    icon: <CourseIcon />,
  },
  {
    key: 'financial',
    name: 'Financeiro',
    icon: <FinancialIcon />,
  },
];
