import styled from 'styled-components';
import { ReactComponent as EditIcon } from '~/assets/drops-edit.svg';

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #fff;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 4px 0px #181a1e14, 0px 0px 2px 0px #181a1e29;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #00205b;
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const Button = styled.button`
  border-radius: 320px;
  background: #fff;
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 40px;
  color: #077859;
  font-size: 14.2px;
  font-weight: 600;
  line-height: 17.04px;

  &:hover {
    background: #daf5f1;
  }
`;

export const StyledEditIcon = styled(EditIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: #077859;
  }
`;
