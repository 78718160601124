import { useQuery } from '@tanstack/react-query';

import { Chapter } from '~/services';

const getChapters = async (params) => {
  const response = await Chapter.list(params);
  return response.data.results;
};

export default function useChapter(params, options) {
  return useQuery({
    queryKey: ['chapters', params],
    queryFn: () => getChapters(params),
    staleTime: 60 * 1000,
    ...options?.config,
  });
}
