import React from 'react';
import DragIcon from '~/assets/drag-indicator.svg';
import RemoveItem from '~/assets/drops-remove-specialtie.svg';
import { NameOption, OrderOption, RangeContainer, ValueOption, RangeText, Content } from './styles';

const ItemSpecialties = ({
  draggable,
  option,
  index,
  onDragStart,
  onDragEnter,
  onDragEnd,
  changeOption,
  removeSpecialtie,
  hiddenRemove,
}) => {
  const changeValueItem = (event) => {
    const { value } = event.target;
    option.value = parseInt(value, 10);
    changeOption(option);
  };

  return (
    <Content shadow={option.colors.text}>
      {draggable && (
        <div className="tooltip-container">
          <div>Arraste para mudar a ordem</div>
          <div className="triangulo-para-direita" />
        </div>
      )}
      <div
        className="container"
        draggable={draggable}
        onDragStart={() => onDragStart(index)}
        onDragEnter={() => onDragEnter(index)}
        onDragEnd={() => onDragEnd(index)}
      >
        <div className="infosIndex">
          <OrderOption>{index + 1}</OrderOption>
          {draggable && <img src={DragIcon} alt="drag icon" />}
          <NameOption colors={option.colors}>{option.name}</NameOption>
        </div>
        <div className="infos1">
          <ValueOption
            type="number"
            defaultValue={option.value || null}
            onChange={(event) => {
              changeValueItem(event);
            }}
          />

          {draggable && (
            <div className="infos2">
              Alcance
              <RangeContainer color={option.colors.text}>
                <RangeText>{option.values[0]}</RangeText>
                <RangeText>a</RangeText>
                <RangeText>{option.values[1]}</RangeText>
              </RangeContainer>
            </div>
          )}
          <div className="infos2 item-remove">
            <img src={RemoveItem} />
            {hiddenRemove && (
              <span className="remove" onClick={() => removeSpecialtie(index)}>
                Remover
              </span>
            )}
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ItemSpecialties;
