import { useQuery } from '@tanstack/react-query';

import { LessonModuleAPICMS } from '~/services/apiCallsCMS';

const fetchLessonModuleById = async (id) => {
  try {
    const response = await LessonModuleAPICMS.get(id);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useLessonModuleById(id, options) {
  return useQuery({
    queryKey: ['lessonModulesById', id],
    queryFn: () => fetchLessonModuleById(id),
    staleTime: 0,
    ...options?.config,
  });
}
