import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 28px;
  gap: 16px;
  border-radius: 8px;
  background: #f1f2f2;
`;

export const EmptyChild = styled.div`
  width: 100%;
  height: 16px;
  border-bottom: 1px solid #e5e8ee;
`;

export const Content = styled.div`
  height: 72px;
  padding: 24px;
  background: #fff;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => (props.draggable ? 'grab' : 'default')};
`;
