import React, { useState } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';

import { MdOutlineSearch } from 'react-icons/md';
import { InputStyle } from './styles';

function SearchInputMaterialUi({
  placeholder,
  label,
  value,
  setSelectedValue,
  handleBlur,
  textfieldStyle,
  size,
  type,
  id,
  maxLength,
  handleKeydown,
}) {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <InputStyle>
      <TextField
        type={type}
        id={`input-component-${id}`}
        label={label}
        value={value}
        variant="outlined"
        placeholder={placeholder}
        size={size}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          handleBlur();
        }}
        onChange={handleChange}
        style={textfieldStyle}
        onInput={(e) => {
          e.target.value = maxLength
            ? Math.max(0, parseInt(e.target.value)).toString().slice(0, maxLength)
            : e.target.value;
        }}
        onKeyDown={handleKeydown}
        InputProps={{
          startAdornment: isFocused && (
            <InputAdornment position="start">
              <MdOutlineSearch />
            </InputAdornment>
          ),
        }}
      />
    </InputStyle>
  );
}

export default SearchInputMaterialUi;
