/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SearchIcon from '~/assets/drops-search.svg';
import * as S from './styles';

function Filter({ searchTerm, setSearchTerm, handleFilter }) {
  const handleSearch = (search) => {
    setSearchTerm(search.target.value);
  };

  return (
    <S.Container>
      <S.Text>Filtrar dados</S.Text>
      <S.InputAndButtonContainer>
        <S.Filter>
          <img src={SearchIcon} alt="Search icon" />
          <input
            className="textInput"
            name="search"
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Pesquisar"
          />
        </S.Filter>
        <S.Button onClick={handleFilter}> Filtrar </S.Button>
      </S.InputAndButtonContainer>
    </S.Container>
  );
}

export default Filter;
