/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Modal from '@material-ui/core/Modal';
import Drawer from '@mui/material/Drawer';

import CardComponent from '~/components/CardComponent';

import * as S from './styles';

export default function OffCanvas({ show, close, name, children, actionAdd }) {
  return (
    <Drawer
      open={show}
      onClose={close}
      anchor="right"
      sx={{
        '& .MuiDrawer-paper': {
          overflowY: 'hidden',
        },
      }}
    >
      <S.Container>
        <CardComponent type="important" style={{ width: '100%' }} background="#fff">
          <S.HeaderContainer>
            <S.Title>{name}</S.Title>
          </S.HeaderContainer>
          {children}
          <S.FooterContainer>
            <S.ContainerButton>
              <S.ButtonSecondary onClick={close}>Voltar</S.ButtonSecondary>
              <S.ButtonPrimary onClick={actionAdd}>Adicionar</S.ButtonPrimary>
            </S.ContainerButton>
          </S.FooterContainer>
        </CardComponent>
      </S.Container>
    </Drawer>
  );
}

OffCanvas.defaultProps = {
  name: '',
  children: <></>,
};
