import produce from 'immer';

export const INITIAL_STATE = {
  searchTermExams: '',
  searchTermTracks: '',
  searchTermSubjects: '',
  searchTermGoals: '',
  searchModules: '',
  filtersExams: {},
  filtersExamsReview: {},
  filtersNews: {},
  filtersEvents: [],
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@search/UPDATE_SEARCH_EXAM_REQUEST': {
        draft.searchTermExams = action.payload.data;
        break;
      }
      case '@search/UPDATE_SEARCH_TRACK_REQUEST': {
        draft.searchTermTracks = action.payload.data;
        break;
      }
      case '@search/UPDATE_SEARCH_SUBJECT_REQUEST': {
        draft.searchTermSubjects = action.payload.data;
        break;
      }
      case '@search/UPDATE_SEARCH_GOAL_REQUEST': {
        draft.searchTermGoals = action.payload.data;
        break;
      }
      case '@search/UPDATE_FILTER_EXAM_REQUEST': {
        draft.filtersExams = action.payload.data;
        break;
      }
      case '@search/UPDATE_FILTER_MODULES_REQUEST': {
        draft.searchModules = action.payload.data;
        break;
      }
      case '@search/UPDATE_FILTER_EXAM_REVIEW_REQUEST': {
        draft.filtersExamsReview = action.payload.data;
        break;
      }
      case '@search/UPDATE_FILTER_NEWS_REQUEST': {
        draft.filtersNews = action.payload.data;
        break;
      }
      case '@search/UPDATE_FILTER_EVENT_REQUEST': {
        draft.filtersEvents = action.payload.data;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.filtersExams = null;
        draft.filtersNews = null;
        draft.filtersEvents = null;
        draft.searchModules = null;
        break;
      }
      default:
    }
  });
}
