import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles, createStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Border, Container, StyledNumberBadge } from './styles';

class Tab extends Component {
  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label, borderUnselected, badge, disabled, numberBadge },
    } = this;

    let className = 'tab-list-item';

    if (activeTab === label) {
      className += ' tab-list-active';
    }
    if (activeTab !== label && borderUnselected) {
      className += ' add-border';
    }
    if (disabled) {
      className += ' tab-list-disabled';
    }

    const StyledBadge = withStyles(() =>
      createStyles({
        badge: {
          right: -20,
          top: 3,
          backgroundColor: activeTab === label ? '#3B3FB6' : '#01CFB5',
          color: '#fff',
        },
      }),
    )(Badge);

    return (
      <Container>
        <li className={className} onClick={onClick}>
          <StyledBadge overlap="rectangular" badgeContent={badge}>
            {label}
            {numberBadge != null && (
              <StyledNumberBadge active={activeTab === label && !disabled}>
                {numberBadge}
              </StyledNumberBadge>
            )}
          </StyledBadge>
        </li>
        {activeTab === label && !disabled && <Border />}
      </Container>
    );
  }
}

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  borderUnselected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Tab;
