import React from 'react';
import ErrorLogo from '~/assets/drops-cloud-error-logo.svg';
import PlusErrorLogo from '~/assets/drops-error-logo.svg';
import {
  PlusContainerError,
  MainContainerError,
  ContainerError,
  IconGroupError,
  MainLbError,
  SubLbError,
  GroupError,
} from './styles';

const ErrorInput = () => (
  <MainContainerError>
    <IconGroupError>
      <PlusContainerError>
        <img src={PlusErrorLogo} alt="Error logo" />
      </PlusContainerError>
      <GroupError>
        <img src={ErrorLogo} alt="Cloud error logo" />
      </GroupError>
    </IconGroupError>
    <ContainerError>
      <MainLbError>Ocorreu um erro</MainLbError>
      <br />
      <SubLbError>Atualize a página e tente novamente</SubLbError>
    </ContainerError>
  </MainContainerError>
);
export default ErrorInput;
