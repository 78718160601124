import styled from 'styled-components';
import { ReactComponent as WarningIco } from '~/assets/warning_ico.svg';

export const Loading = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    width:100%;
    z-index: 9;
    height: 100%;
    background-color: #f6f7f8c9;
    align-items: center;
  
`;

export const Styled = styled.div`
.tox-editor-header {
    background-color: ${(props) => props.headerBgColor} !important;
  }

.tox-toolbar__primary {
  background-color: ${(props) => props.headerBgColor} !important;
}

.tox .tox-edit-area__iframe {
  background: ${(props) => props.textAreaBgColor}; !important
}
`;

export const WarningBox = styled.div`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: #FDF6D7;
  margin-bottom: 16px;
`;

export const WarningLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background: #F3C404;
  width: 36px;
  height: 36px;
`;

export const WarningLogo = styled(WarningIco)`
  display: flex;
  width: 22px;
  height: 22px;
`;

export const WarningMainText = styled.span`
  align-self: stretch;
  color: #181a1e;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px;
`;

export const WarningSubText = styled.span`
  align-self: stretch;
  color: #4f5154;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;