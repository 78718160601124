/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { createContext, useContext, useState } from 'react';
import { MdChevronLeft } from 'react-icons/md';

import history from '~/services/history';

const BackButtonContext = createContext({});

const TO_HIDE = ['/dashboard', '/index'];
const TO_HOME = ['/exam'];

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
  },
  backButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 240,
    cursor: 'pointer',
    position: 'relative',
    left: -113,
  },
  labelName: {
    fontWeight: 600,
    fontSize: 10,
    letterSpacing: 0.25,
    color: '#3b3fb6',
    position: 'relative',
    cursor: 'pointer',
    left: -220,
  },
  labelDescription: {
    display: 'flex',
    flex: 1,
    paddingTop: 51,
    color: '#00205b',
    fontSize: 16,
    lineHeight: 19,
    cursor: 'pointer',
  },
};

function BackButtonProvider({ children }) {
  const [path, setPath] = useState('/');
  const [name, setName] = useState('voltar para Home');
  const [description, setDescription] = useState();
  const [extra, setExtra] = useState({});

  function setBackButtonContext({ path, name, extra, description }) {
    setPath(path, setPath);
    setName(name, setName);
    setDescription(description, setDescription);
    setExtra(extra, setExtra);
  }

  function isSameCurrentPath(pathCompare) {
    return pathCompare === path;
  }

  function handleClick(goTo) {
    if (goTo) {
      history.push(goTo, { ...extra });
      return;
    }
    history.goBack();
  }

  function getBackButton() {
    const currentPath = window.location.pathname;
    if (TO_HIDE.includes(currentPath) || !path || !name) {
      return <></>;
    }
    const toHome = TO_HOME.includes(currentPath);
    return (
      <div style={styles.container} key="back-button" id="back-button">
        <div style={styles.backButton} onClick={() => handleClick(toHome ? '/dashboard' : path)}>
          <MdChevronLeft size={23} color="#3B3FB6" />
        </div>
        <div style={styles.labelName} onClick={() => handleClick(toHome ? '/dashboard' : path)}>
          {toHome ? 'voltar para Home' : name}
        </div>
        {description && (
          <div
            style={styles.labelDescription}
            onClick={() => handleClick(toHome ? '/dashboard' : path)}
          >
            {description}
          </div>
        )}
      </div>
    );
  }

  return (
    <BackButtonContext.Provider
      value={{
        getBackButton,
        setBackButtonContext,
        isSameCurrentPath,
      }}
    >
      {children}
    </BackButtonContext.Provider>
  );
}

function useBackButton() {
  const context = useContext(BackButtonContext);

  if (!context) {
    throw new Error('useLesson must be used whitin a LessonProvider');
  }

  return context;
}

export { BackButtonProvider, useBackButton };
