import styled from 'styled-components';

export const Enunciated = styled.div`
  margin-top: 20px;
  color: #343434;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 15px;
`;

export const InputContainer = styled.div`
  position: relative;

  label {
    top: 0;
    left: 0;
    right: 0;
    color: #616161;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 1rem;
    cursor: text;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    height: 3rem;
  }

  .textInput {
    display: flex;
    flex: 1;
    padding: 5px 15px 5px 15px;
    border-radius: 8px;
    margin-bottom: 30px;
    background-color: #eeeeee;
    border-width: 0;

    &:focus {
      background-color: #ffffff;
      box-shadow: 0 3px 8px 0 rgba(0, 32, 91, 0.15);
      color: #00205b;
      letter-spacing: 0.5px;
      line-height: 15px;
    }

    &:not([value='']):not(:focus) {
      background-color: #ffffff;
      box-shadow: 0 3px 8px 0 rgba(0, 32, 91, 0.15);
      color: #00205b;
      letter-spacing: 0.5px;
      line-height: 15px;
    }

    &:valid + label,
    &:focus + label {
      color: #767676;
      font-size: 9px;
      font-weight: 500;
      letter-spacing: 0.35px;
      line-height: 11px;
      top: -12px;
      left: 15px;
      pointer-events: none;
    }

    &::placeholder {
      color: #767676;
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 15px;
    }
  }
`;

export const ContainerActionsButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 36px 0 26px 0;
`;
