import { useQuery } from '@tanstack/react-query';

import { LessonModuleAPICMS } from '~/services/apiCallsCMS';

const fetchLessonModuleBySubject = async (params) => {
  try {
    const response = await LessonModuleAPICMS.list(params);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return { lessonSubject: params.lesson_subject, lessonModules: response.data };
  } catch (error) {
    throw error;
  }
};

export default function useLessonModuleBySubject(params, options) {
  return useQuery({
    queryKey: ['lessonModulesBySubject', params],
    queryFn: () => fetchLessonModuleBySubject(params),
    staleTime: 0,
    ...options?.config,
  });
}
