import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import initSubscriber from 'redux-subscriber';

import createStore from './createStore';
import persistReducers from './persistReducers';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

// const sagaMonitor = __DEV__ ? console.tron.createSagaMonitor() : null;

// const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const store = createStore(persistReducers(rootReducer), middlewares);
const subscribe = initSubscriber(store);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor, subscribe };
