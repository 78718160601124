import styled from 'styled-components';
import { ReactComponent as RightArrow } from '~/assets/drops-arrow-right.svg';

export const selectValueContainerStyle = {
  fontFamily: 'Montserrat',
  fontSize: 16,
  fontWeight: 400,
  color: '#00205B',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dadadb;
`;

export const QuestionCommentContainer = styled.div`
  padding-top: 3px;
  width: 100%;
  background-color: rgba(242, 242, 242, 0.4);
  display: flex;
  padding: 16px;
  gap: 10px;
  border-top: 1px solid #dadadb;

  @media (max-width: 1550px) {
    flex-direction: column;
  }
`;

export const ExplanationContainer = styled.div`
  border: 1px solid #e5e5e5;
  background: #ffffff;
  align-self: stretch;
  flex-basis: 50%;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 28px;
  justify-content: space-between;
  border-bottom: 1px solid #dadadb;
  margin-bottom: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  color: #00205b;
  gap: 24px;
  width: 100%;
`;

export const NumberContainer = styled.div`
  width: 40px;
  height: 40px;
  padding: 14px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background: #01c3aa;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  font-family: Montserrat;
  color: #00205b;
  font-size: 22.8px;
  font-weight: 600;
  line-height: 30.4px;
  letter-spacing: 0.25px;
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;
`;

export const ContentQuestionContainer = styled.div`
  width: 100%;
  padding: 0 28px;

  @media (min-width: 1550px) {
    max-height: 776px;
    overflow-y: scroll;
  }
`;

export const MarginContainer = styled.div`
  margin-bottom: 10px;
`;

export const LoaderContainer = styled.div`
  margin-bottom: 24px;
`;

export const SaveButton = styled.button`
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  border-radius: 1000px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;
  height: 48px;
  background: #0caa87;
  font-size: 16px;
  line-height: 19.2px;
  align-items: center;

  :hover {
    background: #098968;
  }

  :disabled {
    background: #e2e5ee;
    color: #8b97bd;

    path {
      fill: #8b97bd;
    }
  }

  path {
    fill: #fff;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 28px;
  background: #ffffff;
  gap: 24px;
`;

export const TagRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TagColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TagTitle = styled.h2`
  margin: 0;
  font-size: 20.3px;
  font-weight: 600;
  line-height: 27px;
  color: #4f5154;
  flex-basis: 20%;
  flex-shrink: 0;
`;

export const SelectContainer = styled.div`
  width: 100%;
`;

export const GenerateViaIaButtonContainer = styled.div`
  width: 100%;
`;

export const GenerateViaIaButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
  gap: 12px;
  height: 48px;
  border: 2px solid #e2e5ee;
  border-radius: 320px;
  background: #fff;
  font-weight: 600;
  font-size: 16px;
  color: #077859;

  :hover {
    background: #daf5f1;
  }

  path {
    fill: #077859;
  }

  :disabled {
    color: #8b97bd;
    pointer-events: none;

    path {
      fill: #8b97bd;
    }
  }
`;

export const GeneratedFocusAndCfaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TitleAndFocusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const GeneratedFocusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LinkButton = styled.button`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  gap: 12px;
  height: 48px;
  background: #0caa87;
  border-radius: 320px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  border: none;

  path {
    fill: #fff;
  }

  :hover {
    background: #098968;
  }
`;

export const GeneratedCfaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e8ee;
  align-items: center;
`;

export const StyledRightArrow = styled(RightArrow)`
  width: 22px;
  height: 22px;
`;

export const CfaGenerationLabel = styled.div`
  font-size: 12.6px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #00205b;
  padding: 16px 0;
`;
