import React, { useState, useCallback, useEffect } from 'react';

import { CourseAPICMS } from '~/services/apiCallsCMS';
import { useTrackCategory } from '~/hooks-querys/trackCategory';

import ModalFilter from '~/components/ModalFilter';
import SelectWithPagination from '~/components/SelectWithPagination';
import SelectComponent from '~/components/SelectComponent';
import * as S from './styles';

import SearchIcon from '~/assets/drops-search.svg';

function Filter({ filter, setFilter }) {
	const changeFilter = (value) => {
		setFilter({ ...filter, search: value, page: 1 });
	};

	const selectValueStyle = {
		fontSize: '14px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	};

	const selectedProps = {
		width: '100%',
		controlStyle: { marginLeft: 0 },
		singleValueStyle: selectValueStyle,
		placeholderStyle: selectValueStyle,
		valueContainerStyle: { flexWrap: 'nowrap' },
		containerStyle: { minWidth: '140px' },
	};

	return (
		<>
			<S.FilterInput>
				<img src={SearchIcon} alt="Search icon" />
				<input
					type="text"
					value={filter.id}
					onChange={(e) => changeFilter(e.target.value)}
					placeholder="Pesquisar por ID ou nome"
				/>
			</S.FilterInput>
		</>
	);
}

export default Filter;
