import styled from 'styled-components';

export const StatusTag = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  gap: 6px;
  height: 24px;
  border-radius: 8px;
  cursor: pointer;
  background: ${(props) => props.backgroundColor || '#e5e8ee'};
`;

export const StatusTagText = styled.div`
  display: flex;
  gap: 6px;
  align-itemns: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.color || '#00205b'};
`;
