import styled from 'styled-components';

export const Name = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #00205b;
  width: 100%;
  cursor: pointer;
`;

export const InputText = styled.input`
  padding: 8px;
  width: 100%;
  border: 2px solid #01cfb5;
  box-shadow: none;
`;

export const Container = styled.div`
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  width: 100%;
  :hover {
    background: #e5e8ee;
  }
`;
