import styled from 'styled-components';

export const Content = styled.div`
  min-width: 256px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 2px 4px 0px #181A1E14, 0px 0px 2px 0px #181A1E29;
  overflow-y: scroll;
  max-height: 120vh;
`;

export const ItemContainer = styled.div`
  cursor: pointer;
`;