import styled from 'styled-components';

export const EmptyContentIconContaner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${(props) => (props.isError ? 
    'radial-gradient(50% 50% at 50% 50%, rgba(252, 219, 223, 0) 0%, #FCDBDF 100%)' : 
    '#E5E8EE')};
`;

export const EmptyContentIcon = styled.div`
  width: 22.14px;
  height: 22.14px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  path {
    fill: ${(props) => (props.isError ? '#EE1F38' : '#3D5582')};
  }
`;

export const EmptyContentContainer = styled.div`
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;gap: 16px;
`;

export const EmptyContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4x;
`;

export const EmptyContentTitle = styled.h3`
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205B;
  margin: 0;
`;

export const EmptyContentSubtitle = styled.p`
  font-size: 16px;
  line-height: 21.3px;
  color: #3D5582;
  margin: 0;
`;
