import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 6px;
`;

export const RouterName = styled.span`
  font-weight: 400;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
`;

export const ArrowRight = styled.img`
  margin-left: 6px;
`;
