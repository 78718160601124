import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  padding: 48px;
  max-width: 1388px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ViewRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 72px;
  margin-right: 30px;
  z-index: 100;
  background-color: #f5f5fb;

  .textInput {
    height: 36px;
    padding: 5px 15px 5px 15px;
    border-radius: 2px;
    margin-bottom: 25px;
    background-color: #ffff;
    border-width: 0;

    &:focus {
      background-color: #ffffff;
      box-shadow: 0 3px 8px 0 rgba(0, 32, 91, 0.15);
      color: #00205b;
      letter-spacing: 0.5px;
      line-height: 15px;
    }

    &::placeholder {
      color: #767676;
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 15px;
    }
  }
`;

export const ButtonSearch = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 35px;
  width: 35px;
  margin-top: 10px;
  right: 40px;
  z-index: 150;

  cursor: pointer;
  border-radius: 18px;
  background-color: #00cfb4;
  box-shadow: 0 0 5px 0 #51ffe3;
`;
