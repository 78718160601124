import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactComponent as DropsChevron } from '~/assets/drops-chevron.svg';
import * as S from './styles';

export default function MultiselectInstitutions({
  title,
  selectedOptions,
  setSelectedOptions,
  placeholder,
  options,
  fetchSuggestions,
  loading,
  multiSelectLimit = 5,
  disabled,
  listMaxHeight,
}) {
  const updateInput = (input) => {
    if (input) {
      fetchSuggestions(input, options);
    }
  };

  const onDelete = (label) => {
    setSelectedOptions(selectedOptions.filter((opt) => opt.label !== label));
  };

  return (
    <S.Container>
      <S.TitleContainer>
        {!!title && title}
        <S.SelectContainer>
          <Autocomplete
            value={selectedOptions}
            loading={loading}
            multiple
            clearText="Limpar"
            closeText="Fechar"
            loadingText="Carregando..."
            noOptionsText="Nenhuma opção encontrada"
            id="tags-outlined"
            options={options}
            getOptionLabel={(option) => option.label}
            filterSelectedOptions
            onChange={(_, value) => setSelectedOptions(value)}
            onInputChange={(event) => updateInput(event?.target?.value)}
            getOptionDisabled={() => selectedOptions.length >= multiSelectLimit}
            disabled={disabled}
            fullWidth
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={selectedOptions.length > 0 ? '' : placeholder}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '40px',
                    background: '#F0F2F5',
                    padding: '0 9px',

                    '& input': {
                      '&::placeholder': {
                        opacity: 1,
                        fontFamily: 'Montserrat',
                        color: '#3D5582',
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: '21px',
                      },
                    },

                    '&:hover': {
                      background: '#fff',
                    },
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '8px',
                      border: '2px solid #E5E8EE',
                    },
                    '&:hover.Mui-disabled fieldset': {
                      border: '2px solid #E5E8EE',
                      boxShadow: 'none',
                    },
                    '&:hover fieldset': {
                      border: '3px solid #3D5582',
                      boxShadow: '0px 0px 0px 6px rgba(61, 85, 130, 0.2)',
                    },
                    '&.Mui-focused fieldset': {
                      border: '3px solid #01cfb5',
                      boxShadow: '0px 0px 0px 6px rgba(1, 207, 181, 0.2)',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: '#077859',
                    },
                  },
                  '.MuiAutocomplete-endAdornment svg': {
                    width: '22px',
                    height: '22px',
                  },
                  '.MuiAutocomplete-endAdornment:hover svg': {
                    path: { fill: '#01cfb5' },
                  },
                  '.MuiAutocomplete-endAdornment:hover .MuiAutocomplete-clearIndicator path': {
                    fill: '#0000008a',
                  },
                }}
              />
            )}
            renderTags={(value) => {
              return (
                <>
                  <S.OptionsContainer>
                    {value.map((option, index) => {
                      return `${index !== 0 ? ', ' : ''} ${option.label}`;
                    })}
                  </S.OptionsContainer>
                </>
              );
            }}
            popupIcon={<DropsChevron />}
          />
        </S.SelectContainer>
      </S.TitleContainer>

      {selectedOptions.length > 0 && (
        <S.ValuesContainer style={{ maxHeight: listMaxHeight }}>
          {selectedOptions?.map((option) => (
            <S.Value key={option.label}>
              {option.label}
              <S.RemoveValueButton onClick={() => onDelete(option.label)}>✕</S.RemoveValueButton>
            </S.Value>
          ))}
        </S.ValuesContainer>
      )}
    </S.Container>
  );
}

MultiselectInstitutions.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.object.isRequired,
};

MultiselectInstitutions.defaultProps = {
  placeholder: '',
  options: [],
};
