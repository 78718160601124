import { useQuery } from '@tanstack/react-query';
import { TaskAPICMS } from '~/services/apiCallsCMS';

const listTasks = async (params) => {
  try {
    const response = await TaskAPICMS.list(params);

    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useListTasks(params, options) {
  return useQuery({
    queryKey: ['listTasks', params],
    queryFn: () => listTasks(params),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
