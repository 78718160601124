import React from 'react';
import PropTypes from 'prop-types';
import { MdArrowForward } from 'react-icons/md';

import {
  ButtonOutsideIcon,
  ButtonInsideIcon,
  MinimalButton,
  DefaultButton,
  ImportantButton,
} from './styles';

function ButtonComponent({
  type,
  text,
  onClick,
  styleContainer,
  primaryColor,
  textColor,
  customIcon,
}) {
  return (
    <div style={styleContainer}>
      {type === 'secundary' && (
        <MinimalButton onClick={onClick}>
          <ButtonInsideIcon>
            {customIcon || <MdArrowForward size={18} color="#FFF" />}
          </ButtonInsideIcon>
        </MinimalButton>
      )}
      {type === 'minimal' && (
        <MinimalButton onClick={onClick}>
          <ButtonInsideIcon>
            {customIcon || <MdArrowForward size={18} color="#FFF" />}
          </ButtonInsideIcon>
        </MinimalButton>
      )}
      {type === 'default' && (
        <DefaultButton
          onClick={onClick}
          style={{ color: textColor, backgroundColor: primaryColor }}
        >
          {text}
          <ButtonOutsideIcon>
            <ButtonInsideIcon>
              {customIcon || <MdArrowForward size={18} color="#FFF" />}
            </ButtonInsideIcon>
          </ButtonOutsideIcon>
        </DefaultButton>
      )}
      {type === 'noIcon' && (
        <DefaultButton
          onClick={onClick}
          style={{ color: textColor, backgroundColor: primaryColor }}
        >
          {text}
        </DefaultButton>
      )}
      {type === 'important' && (
        <ImportantButton
          onClick={onClick}
          style={{ color: textColor, backgroundColor: primaryColor }}
        >
          {text}
          <ButtonOutsideIcon>
            <ButtonInsideIcon>
              {customIcon || <MdArrowForward size={18} color="#FFF" />}
            </ButtonInsideIcon>
          </ButtonOutsideIcon>
        </ImportantButton>
      )}
    </div>
  );
}
ButtonComponent.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  styleContainer: PropTypes.shape(),
  primaryColor: PropTypes.string,
  textColor: PropTypes.string,
  customIcon: PropTypes.node,
};

ButtonComponent.defaultProps = {
  type: 'default',
  text: 'continuar',
  onClick: () => {},
  styleContainer: {},
  primaryColor: '#fff',
  textColor: '#01cfb5',
  customIcon: null,
};

export default ButtonComponent;
