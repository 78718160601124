import React, { useState, useEffect } from 'react';
import { IoChevronDownSharp } from 'react-icons/io5';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { STATUS_OPTIONS } from '../../variables';
import * as S from './styles';

function StatusDropdown({ name, activityId, status, changeStatus }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [nextValue, setNextValue] = useState([]);

  const open = anchorEl;
  const id = open ? name : undefined;

  const selectedStatus = STATUS_OPTIONS.find((statusItem) => statusItem.value === status);
  const handleClick = (event) => {
    if (!anchorEl) {
      return setAnchorEl(event.currentTarget);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleClose, true);

    if (status === 'r') {
      setNextValue(STATUS_OPTIONS);
    } else {
      const currentIndex = STATUS_OPTIONS.findIndex((statusItem) => statusItem.value === status);
      if (currentIndex + 1 > STATUS_OPTIONS.length) {
        return setNextValue([]);
      }
      setNextValue([STATUS_OPTIONS[currentIndex + 1]]);
    }

    return () => {
      document.removeEventListener('scroll', handleClose, true);
    };
  }, []);

  return (
    <>
      {selectedStatus && (
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <S.DropdownButton backgroundColor={selectedStatus.bgColor} onClick={handleClick}>
              <IoChevronDownSharp size={16} color={selectedStatus.color} />
              <S.DropdownButtonText color={selectedStatus.color}>
                {selectedStatus.label}
              </S.DropdownButtonText>
            </S.DropdownButton>

            <Popper
              id={id}
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ maxHeight: ' calc(100vh - 228px)', zIndex: '1201' }}
            >
              <S.DropdownMenu>
                {nextValue.map((statusItem) => {
                  return (
                    <S.DropdownItem
                      onClick={() => {
                        changeStatus(statusItem.value, activityId);
                        handleClose();
                      }}
                    >
                      {statusItem.label}
                    </S.DropdownItem>
                  );
                })}
              </S.DropdownMenu>
            </Popper>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
}

export default StatusDropdown;
