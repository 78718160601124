import React, { useEffect, useState, useCallback } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';

import HeaderComponent from '~/components/HeaderComponent';
import Loader from '~/components/Loader';
import ProtectedContent from '~/components/ProtectedContent';
import history from '~/services/history';
import { Chapter, Section } from '~/services';
import { useDispatch, useSelector } from 'react-redux';
import { clearChapter, requestChapterSelected } from '~/store/modules/chapter/actions';
import { toast } from 'react-toastify';
import { Mixpanel } from '~/services/analytics';
import * as S from './styles';

function PreviewChapter() {
  const { id } = useParams();

  const { location } = useHistory();
  const dispatch = useDispatch();

  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    chapterSelected: { chapter, requesting },
  } = useSelector((state) => state.chapter);

  const getChapter = useCallback(() => {
    dispatch(requestChapterSelected(id));
  }, [dispatch]);

  const getSections = useCallback(async (cancelToken) => {
    setLoading(true);
    try {
      const { data } = await Section.list({ chapter: id }, cancelToken);
      setSections(data.results);
    } catch (error) {
      toast.error('Erro ao carregar o conteúdo do capítulo. Tente novamente.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getSections(source.token, id);
    getChapter();
    return () => {
      dispatch(clearChapter());
      source.cancel();
    };
  }, [id]);

  function renderSections(section) {
    const explanation = section.content;
    return (
      <div key={`${section.id}_section`}>
        <S.SectionContainer id={section.id}>
          <S.SectionTitle subTitle={!!section.parent_section}>{section.name}</S.SectionTitle>
          {section.content && (
            <S.HTMLContainer
              dangerouslySetInnerHTML={{
                __html: `
                    <style>
                      img {
                        max-width: 100%;
                        height: auto;
                      }
                    </style>
                    <div style=
                      "font-size: 14px;
                      font-family: 'Montserrat', sans-serif !important;
                      color: #767676;
                      letter-spacing: 0;
                      line-height: 22px;">${explanation}</div>`,
              }}
            />
          )}
        </S.SectionContainer>
        {section?.sub_sections?.length > 0 &&
          section?.sub_sections?.map((sub_sections) => renderSections(sub_sections))}
      </div>
    );
  }

  const handlePublish = async () => {
    setLoading(true);
    try {
      await Chapter.update(chapter?.id, { visible: true });
      Mixpanel.track(
        location.state.editMode
          ? "Clicou 'Tela do capítulo - Revisão - Botão publicar o capítulo'"
          : "Clicou 'Novo capítulo - Revisão - Botão publicar o capítulo'",
        {
          'Nome do capítulo': chapter.name,
          'Nome da apostila': chapter.subject_name,
        },
      );
      toast.success('Sucesso ao publicar capítulo');
    } catch (error) {
      toast.error('Erro ao publicar capítulo');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderComponent title={chapter?.name} />
      {loading || requesting ? (
        <Loader />
      ) : (
        <S.Wrap>
          <S.Container>
            <S.SectionsContainer>
              <S.MainContainer>
                <S.TitleChapter>{chapter?.name}</S.TitleChapter>
                {sections.map((section) => renderSections(section))}
                <S.Bibliography>Bibliografia</S.Bibliography>
                <S.ContentBibliography
                  dangerouslySetInnerHTML={{ __html: chapter?.bibliography }}
                />
              </S.MainContainer>
            </S.SectionsContainer>
          </S.Container>
          <S.FooterActions>
            <S.ButtonBack
              type="button"
              onClick={() => {
                history.push(location?.state?.backTo);
              }}
            >
              <MdArrowBack size={18} color="#01c3aa" />
              Voltar
            </S.ButtonBack>
            <ProtectedContent permissions={['change_chapter']}>
              <S.ButtonSave onClick={() => handlePublish()}>
                Publicar
                <MdArrowForward size={18} color="#FFF" />
              </S.ButtonSave>
            </ProtectedContent>
          </S.FooterActions>
        </S.Wrap>
      )}
    </>
  );
}

export default PreviewChapter;
