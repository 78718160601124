import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Mixpanel } from '~/services/analytics';
import history from '~/services/history';

import { apiCMS } from '../../../services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { token, refreshToken, email, keepConnected } = payload;

    apiCMS.defaults.headers.Authorization = `Bearer ${token}`;

    const response = yield call(apiCMS.post, 'default-users/login/', {
      email,
    });

    const userData = response.data;
    apiCMS.defaults.headers.Authorization = `Bearer ${token}`;
    sessionStorage.setItem('email', email);
    Mixpanel.setup(userData);
    yield put(signInSuccess(token, refreshToken, userData, keepConnected));
    history.push('/dashboard');
  } catch (err) {
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token, signed } = payload.auth;

  if (token && signed) {
    apiCMS.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
]);
