import React from 'react';
import Modal from '@material-ui/core/Modal';
import { CardModal, Title, Header, StyledCloseIcon } from './styles';

export default function ModalWithHeader (
	{
		children,
		show, 
		close,
		title,
	}){
		return (
			<Modal
				open={show}
				onClose={close}
			>
				<CardModal>
					<StyledCloseIcon onClick={close} />
					<Header>
						<Title>{title}</Title>
					</Header>
					{children}
				</CardModal>
			</Modal>
		);
	};
