import { useQuery } from '@tanstack/react-query';

import { TypeActivityAPICMS } from '~/services/apiCallsCMS';

const getTypeActivity = async (params) => {
  try {
    const response = await TypeActivityAPICMS.getTypeActivity(params);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useGetTypeActivity(params, options) {
  return useQuery({
    queryKey: ['getTypeActivity', params],
    queryFn: () => getTypeActivity(params),
    staleTime: 2 * 60000,
    ...options?.config,
  });
}
