/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { getUTCFullYear, getUTCMonth, getUTCDate, isAfter } from 'date-fns';
import SelectWithPagination from '~/components/SelectWithPagination';
import ButtonWithPopper from '~/components/ButtonWithPopper';
import Checkbox from '~/components/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as CloseIcon } from '~/assets/drops-error-logo.svg';
import StatusDropdown from '../StatusDropdown';
import { ReactComponent as AddPersonIcon } from '~/assets/add-person.svg';
import { ReactComponent as ExternalLinkComponent } from '~/assets/external-link.svg';
import { SPECIALITY_OPTIONS, STATUS_OPTIONS } from '~/utils/variables';
import { loadDefaultUsers } from '../FilterDropdown/utils';
import history from '~/services/history';
import { Mixpanel } from '~/services/analytics';
import * as S from './styles';

function TaskList({
  tasks = [],
  aggregate,
  handleChangeStatus,
  handleUpdatePartner,
  handleDeleteActivity,
  handleOffCanvas,
  user,
  helperChecked,
  checkAll,
  childrenChecked,
  checkChild,
}) {
  const [showSelectPartner, setShowSelectPartner] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState();
  const managePage = user.permissions.includes('manage_activities');

  const preventCollapse = (event) => {
    event.stopPropagation();
  };

  const RenderCheckboxTitle = (props) => {
    return (
      <div onClick={preventCollapse}>
        <Checkbox
          id={props.id}
          label=""
          checked={helperChecked.includes(props.helper)}
          onChange={(event) => checkAll(event, props.helper)}
          value={props.helper}
          styleContainer={{ margin: '5px 0 0', width: '21px' }}
          styleLabel={{ marginBottom: 0 }}
          checkboxOutineColor="#ccc"
        />
      </div>
    );
  };

  const returnTitle = (helper, name, index) => {
    switch (aggregate) {
      case 'status':
        const tag = STATUS_OPTIONS[helper];
        if (!tag) {
          return <></>;
        }
        return (
          <>
            <RenderCheckboxTitle id={tag.key} helper={index} />
            <S.StatusTag id={`label-${tag.key}`} color={tag.color} backgroundColor={tag.bgColor}>
              {tag.label}
            </S.StatusTag>
          </>
        );
      case 'activity':
      case 'institution_id':
        return (
          <>
            <RenderCheckboxTitle id={name || 'outros'} helper={index} />
            <S.ActivityTitle>{name}</S.ActivityTitle>
          </>
        );
      case 'speciality_id':
        const speciality = SPECIALITY_OPTIONS[helper] || SPECIALITY_OPTIONS.OTHER;
        return (
          <>
            <RenderCheckboxTitle id={speciality.key} helper={index} />
            <S.StatusTag
              id={`label-${speciality.key}`}
              color={speciality.color}
              backgroundColor={speciality.bgColor}
            >
              {speciality.label}
            </S.StatusTag>
          </>
        );
      case 'responsible':
        return (
          <S.ResponsibleContainer>
            <RenderCheckboxTitle id={name || 'outros'} helper={index} />
            {!name ? (
              <S.ActivityTitle>Outros</S.ActivityTitle>
            ) : (
              <>
                <S.ActivityTitle>{name}</S.ActivityTitle>
                <S.ActivityTitle>{'-'}</S.ActivityTitle>
                <S.ResponsibleEmail>{helper}</S.ResponsibleEmail>
              </>
            )}
          </S.ResponsibleContainer>
        );
    }
  };

  const returnNameInitialsBadge = (initials, responsibleId, activityId, status) => {
    return (
      <S.InitialsName>
        {(managePage || (responsibleId === user.id && status !== 'c')) && (
          <S.RemoveInitialName
            className="delete-icon"
            onClick={() => handleUpdatePartner(activityId, '')}
          >
            <CloseIcon />
          </S.RemoveInitialName>
        )}
        {initials}
      </S.InitialsName>
    );
  };

  const returnNameInitials = (activityId, name, responsibleId, status) => {
    const splittedName = name.split(' ');

    if (!splittedName || splittedName.length <= 0) return '';
    if (splittedName.length === 1) {
      return returnNameInitialsBadge(
        splittedName[0].substring(0, 1).toUpperCase(),
        responsibleId,
        activityId,
        status,
      );
    }

    const first = splittedName[0].substring(0, 1).toUpperCase();
    const last = splittedName[splittedName.length - 1].substring(0, 1).toUpperCase();
    return returnNameInitialsBadge(first + last, responsibleId, activityId, status);
  };

  const renderDeliveryDate = (deadline, endedAt) => {
    let delayedDate = false;

    if (endedAt) {
      delayedDate = isAfter(new Date(endedAt), new Date(deadline));
    } else {
      delayedDate = isAfter(new Date(), new Date(deadline));
    }

    const dateAsDate = new Date(deadline);
    const year = dateAsDate.getUTCFullYear();
    const month = dateAsDate.getUTCMonth() + 1;
    const day = dateAsDate.getUTCDate();

    return (
      <S.DataText error={delayedDate}>
        {delayedDate && <S.DelayedIcon />}
        {`${day}/${month}/${year}`}
      </S.DataText>
    );
  };

  const handleSelectedPartner = (partner, activityId) => {
    Mixpanel.track("Clicou 'Lista de atividades - Atribuir parceiro'", {
      'Parceiro atribuído': partner.label,
    });
    return handleUpdatePartner(activityId, partner.value);
  };

  const handleAttributePartner = (activityId, user) => {
    Mixpanel.track("Clicou 'Lista de atividades - Atribuir parceiro'", {
      'Parceiro atribuído': user.name,
    });
    handleUpdatePartner(activityId, user.id);
  };

  const handleEditActivity = (activityId) => {
    history.push(`/edit-activity/${activityId}`);
  };

  const renderAttributeButton = (activityId) => {
    if (managePage) {
      return (
        <>
          {showSelectPartner.includes(activityId) ? (
            <SelectWithPagination
              height={38}
              name="partner"
              value={selectedPartner}
              onChange={(partner) => handleSelectedPartner(partner, activityId)}
              loadOptions={loadDefaultUsers}
              placeholder="Parceiro"
              singleValueStyle={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '90%',
              }}
              asyncPaginateKey={`async-paginate-${selectedPartner}`}
            />
          ) : (
            <S.AttributePartnerButton
              onClick={() =>
                setShowSelectPartner((showSelectedPartner) => [...showSelectedPartner, activityId])
              }
            >
              <AddPersonIcon />
              Atribuir
            </S.AttributePartnerButton>
          )}
        </>
      );
    } else {
      return (
        <S.AttributePartnerButton onClick={() => handleAttributePartner(activityId, user)}>
          <AddPersonIcon />
          Atribuir
        </S.AttributePartnerButton>
      );
    }
  };

  const returnResponsibleField = (activityId, responsible, responsibleName, status) => {
    if (!responsible) {
      return renderAttributeButton(activityId);
    } else {
      return returnNameInitials(activityId, responsibleName, responsible, status);
    }
  };

  const renderEditButton = (activityId, status) => {
    return (
      <>
        {managePage && status !== 'c' && (
          <S.PopperContainer>
            <ButtonWithPopper minWidth="120px">
              <S.ButtonPopper onClick={() => handleEditActivity(activityId)}>Editar</S.ButtonPopper>
              <S.ButtonPopper onClick={() => handleDeleteActivity(activityId)}>
                Excluir
              </S.ButtonPopper>
            </ButtonWithPopper>
          </S.PopperContainer>
        )}
        {managePage && status === 'c' && <S.EmptySpace></S.EmptySpace>}
      </>
    );
  };

  return (
    <S.Container>
      {tasks &&
        tasks?.map((task, index) => {
          return (
            <S.AccordionStyle>
              <Accordion
                defaultExpanded={true}
                sx={{
                  backgroundColor: 'transparent',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    backgroundColor: 'transparent',
                  }}
                >
                  <S.TableHeader>
                    <S.LeftHeader>
                      {returnTitle(task?.helper, task?.name, index)}
                      <S.ActivitiesCount>
                        {`${task?.activities.length} ${
                          task?.activities.length > 1 ? 'atividades' : 'atividade'
                        }`}
                      </S.ActivitiesCount>
                    </S.LeftHeader>
                    <S.RightHeader>
                      <S.HeaderText>PRAZO DE ENTREGA</S.HeaderText>
                      <S.HeaderText>PARCEIRO</S.HeaderText>
                      <S.HeaderText>STATUS</S.HeaderText>
                      {managePage && <S.EmptySpace></S.EmptySpace>}
                    </S.RightHeader>
                  </S.TableHeader>
                </AccordionSummary>
                <AccordionDetails>
                  <S.TableContainer>
                    <S.TableContent>
                      {task?.activities.map((activity) => {
                        return (
                          <S.TableItem>
                            <S.TitleContainer>
                              <S.CheckboxContainer>
                                <Checkbox
                                  id={activity.id}
                                  label=""
                                  checked={childrenChecked.includes(activity.id)}
                                  onChange={(event) => checkChild(event, activity.id, index)}
                                  value={activity.id}
                                  styleContainer={{ margin: '5px 0 0', width: '21px' }}
                                  styleLabel={{ marginBottom: 0 }}
                                  checkboxOutineColor="#ccc"
                                />
                              </S.CheckboxContainer>

                              <S.ItemTitle>{activity?.name}</S.ItemTitle>
                            </S.TitleContainer>

                            <S.ItemsContainer>
                              <S.ItemContainer>
                                {renderDeliveryDate(activity?.deadline, activity?.ended_at)}
                              </S.ItemContainer>
                              <S.ItemContainer>
                                {returnResponsibleField(
                                  activity?.id,
                                  activity?.responsible,
                                  activity?.responsible__name,
                                  activity?.status,
                                )}
                              </S.ItemContainer>
                              <S.ItemContainer>
                                <StatusDropdown
                                  name="status-dropdown"
                                  activityId={activity?.id}
                                  status={activity?.status}
                                  changeStatus={handleChangeStatus}
                                />
                              </S.ItemContainer>
                              <S.IconContainer
                                onClick={() => handleOffCanvas(activity?.id, activity?.name)}
                              >
                                <ExternalLinkComponent />
                              </S.IconContainer>
                              {renderEditButton(activity?.id, activity?.status)}
                            </S.ItemsContainer>
                          </S.TableItem>
                        );
                      })}
                    </S.TableContent>
                  </S.TableContainer>
                </AccordionDetails>
              </Accordion>
            </S.AccordionStyle>
          );
        })}
    </S.Container>
  );
}

export default TaskList;
