import styled from 'styled-components';

export const CardModal = styled.div`
	max-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: white;
	max-width: 1288px;
	box-shadow:
		0px 0px 2px rgba(24, 26, 30, 0.16),
		0px 2px 4px rgba(24, 26, 30, 0.08);
	border-radius: 8px;
	align-items: center;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const CloseButtonStyle = {
	position: 'absolute',
	top: '16px',
	right: '16px',
	fill: '#ACACAE',
	cursor: 'pointer',
	width: '24px',
	height: '24px',
};

export const Header = styled.div`
	flex-direction: column;
	width: 100%;
	padding: 36px 56px 24px;

	color: #00205b;
	font-size: 25.6px;
	font-style: normal;
	font-weight: 600;
	line-height: 34.2px;
	border-bottom: 1px solid #e5e8ee;
	margin-bottom: 32px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0px 56px 24px;
	gap: 24px;
`;

export const ContainerResult = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	max-height: 40vh;
	padding: 0px 56px 24px;
	gap: 24px;
	overflow-y: auto;
	margin-bottom: ${(props) => (props.showFooter ? '72px' : '0px')};
`;

export const ButtonSecondary = styled.button`
	display: flex;
	height: 40px;
	padding: 0px 16px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex: 1 0 0;
	border-radius: 320px;
	border: 2px solid #e2e5ee;
	color: #077859;
	background: #fff;

	font-size: 14.2px;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
`;

export const ButtonPrimary = styled.button`
	display: flex;
	height: 40px;
	padding: 0px 16px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex: 1 0 0;
	border-radius: 320px;
	border: none;
	background: #0caa87;

	color: #fff;

	font-size: 14.2px;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
`;

export const ContainerButton = styled.div`
	display: flex;
	margin-left: auto;
`;
export const Footer = styled.div`
	display: flex;
	width: 100%;
	padding: 16px 24px;
	justify-content: flex-end;
	align-items: center;
	gap: 24px;
	position: absolute;
	bottom: 0px;
	background-color: #fff;
	border-radius: 8px;
`;
