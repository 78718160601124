import produce from 'immer';

export const INITIAL_STATE = {};

export default function cmsEndpoints(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@endpoints/UPDATE_ENDPOINTS_SUCCESS': {
        draft.endpoints = action.payload.endpoints;
        break;
      }
      default:
    }
  });
}
