import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import FlagIcon from '~/assets/drops-flag.svg';
import ChevronRightIcon from '~/assets/drops-chevron-right.svg';
import { routeName } from './constants';
import * as S from './styles';

export default function BreadCrumb() {
  const { location } = useHistory();

  const [routes, setRoutes] = useState();
  const ignorePathList = ['/tracks/new', '/flashcards/edit', '/courses/new'];

  function preparePath(path) {
    const paths = [];
    for (let i = 0; i < path.length; i++) {
      if (isNaN(parseFloat(path[i]))) {
        let prepareRoutes = path.slice(0, i + 1).join('/');
        if (prepareRoutes === '') {
          prepareRoutes = '/';
        }
        if (!ignorePathList.includes(prepareRoutes) && prepareRoutes !== '/dashboard') {
          paths.push(prepareRoutes);
        }
      }
    }
    setRoutes(paths);
  }

  useEffect(() => {
    const path = location.pathname.split('/');
    preparePath(path);
  }, [location]);

  return (
    <S.Container>
      <img src={FlagIcon} alt="Flag Icon" />
      {routes?.map((route, index) => {
        const isLastRoute = routes.length - 1;
        return (
          <div key={index}>
            {index === isLastRoute ? (
              <S.RouterName>{routeName[route]}</S.RouterName>
            ) : (
              <Link to={route}>
                <S.RouterName>{routeName[route]}</S.RouterName>
              </Link>
            )}
            {index < isLastRoute && <S.ArrowRight src={ChevronRightIcon} alt="Flag Icon" />}
          </div>
        );
      })}
    </S.Container>
  );
}
