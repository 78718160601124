import styled from 'styled-components';
import Truncate from 'react-truncate';

export const ViewCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`;

export const RowCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  gap: 24px;
`;

export const ContainerTitle = styled.div`
  display: flex;
  gap: 16px;
`;

export const ItemTitle = styled(Truncate)`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #00205b;
`;

export const Chip = styled.div`
  display: flex;
  align-items: center;
  background: #e5e8ee;
  border-radius: 1000px;
  height: 24px;
  padding-inline: 8px;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
  text-transform: uppercase;
`;

export const ItemSubtitle = styled.span`
  font-weight: 400;
  font-size: 14.2px;
  line-height: 19px;
  color: #3d5582;

  strong {
    font-weight: 600;
  }
`;

export const DivRow = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const EditButton = styled.button`
  background-color: transparent;
  height: 48px;
  color: #01c3aa;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding-inline: 20px;
  border: none;
  border-radius: 1000px;
  transition: all 0.2s ease-in-out;

  :hover {
    background-color: #e5faf7;
  }
`;

export const Divisor = styled.div`
  width: 100%;
  border-top: 1px solid #e5e8ee;
`;

export const CardProgress = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ChipCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 26px;
  background:  ${(props) => props.background};
  color:  ${(props) => props.color};
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
`;