import React, { useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import HeaderComponent from '~/components/HeaderComponent';
import Loader from '~/components/Loader';
import { clearChapter, requestChapterSelected } from '~/store/modules/chapter/actions';

import Bibliography from '../components/Bibliography';
import EditChapter from '../components/EditContent';
import Settings from '../components/Settings';

import * as S from './styles';

function CreateChapter() {
  const dispatch = useDispatch();

  const {
    chapterSelected: { chapter, requesting },
  } = useSelector((state) => state.chapter);

  const [chapterId, setChapterId] = useState();
  const [step, setStep] = useState({
    first: true,
    second: false,
    third: false,
  });

  const getChapter = useCallback(
    (id) => {
      dispatch(requestChapterSelected(id));
    },
    [dispatch],
  );

  useEffect(() => {
    if (chapterId) {
      getChapter(chapterId);
    }
    return () => {
      dispatch(clearChapter());
    };
  }, [chapterId, step]);

  const changeStep = (actualStep) => {
    setStep({ ...step, ...actualStep });
  };

  const changeChapterId = (id) => {
    setChapterId(id);
  };

  return (
    <>
      <HeaderComponent title="Novo Capítulo" />
      <S.Wrap>
        {requesting ? (
          <Loader />
        ) : (
          <S.Container>
            {step.first && (
              <Settings
                changeStep={changeStep}
                chapter={chapter}
                changeChapterId={changeChapterId}
              />
            )}
            {step.second && <EditChapter changeStep={changeStep} chapter={chapter} />}
            {step.third && <Bibliography changeStep={changeStep} chapter={chapter} />}
          </S.Container>
        )}
      </S.Wrap>
    </>
  );
}

export default CreateChapter;
