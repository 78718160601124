import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Container } from '@material-ui/core/';

import TableList from '~/components/TableList';

import { ListGenericItemsAPI } from '~/services/apiCallsCMS';

function ListGenericItems() {
  const { modelKey } = useParams();
  const endpoints = useSelector((state) => state.cmsEndpoints.endpoints);

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!endpoints) return;
    const source = Axios.CancelToken.source();

    ListGenericItemsAPI.get(endpoints.side_menu[modelKey].sub_menu[0].url, source.token).then(
      (result) => {
        if (result.data) {
          setColumns(result.data['columns']);
          setData(result.data['data']);
        }
      },
    );

    return () => {
      source.cancel();
    };
  }, [modelKey, endpoints]);

  return (
    <Container>
      <TableList
        order="asc"
        orderBy="years"
        data={data}
        columns={columns.map((col) => {
          return {
            id: col.db_name,
            numeric: false,
            disablePadding: true,
            label: col.verbose,
          };
        })}
      />
    </Container>
  );
}

export default ListGenericItems;
