import styled from 'styled-components';

export const Filter = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0px 56px 0px;
	gap: 24px;
`;

export const ButtonSecondary = styled.div`
	max-width: 604px;
	width: 100%;
	border: 2px solid #e5e8ee;
	border-radius: 8px;
	background: #f0f2f5;
	padding: 7px 16px;
	display: flex;
	gap: 10px;

	input {
		width: 100%;
		height: 19px;
		border: none;
		background: none;

		::placeholder {
			color: #3d5582;
			font-weight: 400;
			font-size: 14.2px;
			line-height: 19px;
		}
	}
`;

export const ContainerPagination = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	padding: 32px 0px;
`;

export const ContainerItems = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
`;

export const Container = styled.div`
	flex-direction: column;
	display: flex;
	width: 100%;
`;
