import React, { useState } from 'react';

import Files from "react-butterfiles";

const MultiImageUploader = ({ headerText, fileImgs, setFileImgs }) => {
  const [state, setState] = useState({
    errors: [],
  });

  const [dragging, setDragging] = useState(false);

  const handleErrors = (errors) => {
    setState({ errors });
  };

  const handleFiles = async (files, selectedIndex) => {
    const newValue = [...fileImgs];

    const convertedImages = [];
    for (let i = 0; i < files.length; i++) {
      const image = files[i];
      convertedImages.push({
        src: image.src.base64,
        file: image.src.file
      });
    }

    newValue.splice(selectedIndex, 0, ...convertedImages);
    setFileImgs(newValue);
  };

  const removeFile = (selectedIndex) => {
    const newValue = [...fileImgs];
    newValue.splice(selectedIndex, 1);
    setFileImgs(newValue);
  }

  return (
    <div id="wrapper">
      <Files
        id={"image-gallery"}
        multiple
        convertToBase64
        accept={["image/jpg", "image/jpeg", "image/png"]}
        onError={handleErrors}
        onSuccess={files => {
          // Will append images at the end of the list.
          handleFiles(files, fileImgs.length);
        }}
      >
        {({ browseFiles, getDropZoneProps, getLabelProps }) => (
          <div>
            <label {...getLabelProps({
              style: { display: "block", fontSize: 15, textAlign: "center"}
            })}>
              {headerText}
            </label>
            <div
              {...getDropZoneProps({
                id: "my-image-gallery",
                className: "gallery " +
                  (dragging ? " dragging" : ""),
                onDragEnter: () => setDragging(true),
                onDragLeave: () => setDragging(false),
                onDrop: () => setDragging(false),
              })}
            >
              <ul>
                {fileImgs.map((image, index) => (
                  <li
                    key={index}
                    onClick={() => removeFile(index)}
                  >
                    <div className="remove-icon">X</div>
                    <img src={image.src} />
                  </li>
                ))}
                <li
                  className="new-image"
                  onClick={() => {
                    browseFiles({
                      onErrors: handleErrors,
                      onSuccess: files => {
                        // Will append images at the end of the list.
                        handleFiles(
                          files,
                          fileImgs.length
                        );
                      }
                    });
                  }}
                >
                  <div>+</div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </Files>

      {state.errors.length > 0 && <div>An error occurred.</div>}
    </div>
  );
};

export default MultiImageUploader;
