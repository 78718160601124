import styled from 'styled-components';

export const CardModal = styled.div`
    position: relative;
    background-color: white;
    max-width: 600px;
    box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
    border-radius: 8px;
    padding: 40px 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const CardLoadingModal = styled(CardModal)`
    max-width: 234px;
`;

export const Icon = styled.img`
    width: 64px;
    height: 64px;
    margin-bottom: 24px;
`;

export const Title = styled.h1`
    margin: 0;
    font-weight: 600;
    font-size: 22.8px;
    line-height: 30.4px;
    color: #00205B;
    margin-bottom: 4px;
`;

export const Subtitle = styled.p`
    margin: 0;
    font-size: 14.2px;
    line-height: 18.96px;
    color: #3D5582;
    margin-bottom: 20px;
`;

export const Button = styled.button`
    border: 2px solid #E5E8EE;
    padding: 13px 20px;
    color: #00205B;
    font-weight: 600;
    font-size: 16px;
    line-height: 23.3px;
    border-radius: 320px;
    background-color: #fff;
`;